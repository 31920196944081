import {SimplifiedData} from "@co-common-libs/payroll";
import {RemunerationReport as RemunerationReportResource} from "@co-common-libs/resources";
import {SECOND_MILLISECONDS, formatDate, formatTime} from "@co-common-libs/utils";
import {
  DeleteDialog,
  ErrorColorButton,
  FilePdfIcon,
  ResponsiveDialog,
} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentRole,
  getCustomerSettings,
  getRemunerationReportLookup,
  getShareToken,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {NetworkError, jsonFetch, useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  DialogContent,
  IconButton,
  useTheme,
} from "@material-ui/core";
import {DoLoadInstance, LessorSync, PageLayout, UserSalaryReportCard} from "app-components";
import _ from "lodash";
import FileDelimitedIcon from "mdi-react/FileDelimitedIcon";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const messages = defineMessages({
  allCompleted: {
    defaultMessage: "Alle fuldførte",
  },
  childsFirstSickDay: {
    defaultMessage: "Barns første sygedag",
  },
  compensatory: {
    defaultMessage: "Afspadsering",
  },
  daysOffWithoutPay: {
    defaultMessage: "Fridage uden løn",
  },
  delete: {
    defaultMessage: "Slet",
  },
  floatingHoliday: {
    defaultMessage: "Ferie-fridage",
  },
  onlyValidated: {
    defaultMessage: "Kun godkendte",
  },
  pdfs: {
    defaultMessage: "Udskrifter",
  },
  remunerationReport: {
    defaultMessage: "Lønrapport",
  },
  remunerationReportDay: {
    defaultMessage: "Lønrapport: {date}",
  },
  remunerationReportDayWithTitle: {
    defaultMessage: "{title}: {date}",
  },
  remunerationReportPeriod: {
    defaultMessage: "Lønrapport: {from}–{to}",
  },
  remunerationReportPeriodWithTitle: {
    defaultMessage: "{title}: {from}–{to}",
  },
  sickLeave: {
    defaultMessage: "Sygefravær",
  },
  timeOff: {
    defaultMessage: "FRI",
  },
  tryAgain: {
    defaultMessage: "Forsøg igen",
  },
  unknownUser: {
    defaultMessage: "ukendt medarbejder",
  },
  useForSalaryVouchers: {
    defaultMessage: "Brug til lønbilag",
  },
  useForSalaryVouchersDialogTitle: {
    defaultMessage: "Brug til lønbilag?",
  },
  vacation: {
    defaultMessage: "Feriedage",
  },
});

type MaybeCustomerSettings = {
  remunerationGroups?: {
    dataloen?: object;
  }[];
};

const SalaryVoucherButton = React.memo(function SalaryVoucherButton({
  data,
  disabled,
  onClick,
}: {
  data?: RemunerationReportData;
  disabled?: boolean;
  onClick: () => void;
}): JSX.Element {
  const customerSettings = useSelector(getCustomerSettings);
  const remunerationGroupIdentifiers = useMemo(
    () => new Set(data?.users.map((user) => user.remunerationGroup) || []),
    [data?.users],
  );
  const settings = data?.customerSettings
    ? (data?.customerSettings as MaybeCustomerSettings)
    : null;
  const usesDataloen = useMemo(
    () =>
      settings?.remunerationGroups
        ? Object.entries(settings.remunerationGroups).some(
            ([groupName, group]) => remunerationGroupIdentifiers.has(groupName) && group.dataloen,
          )
        : false,
    [remunerationGroupIdentifiers, settings?.remunerationGroups],
  );

  let label = <FormattedMessage defaultMessage="Brug til lønbilag" />;
  if (usesDataloen) {
    label = <FormattedMessage defaultMessage="Brug til lønbilag og overfør til DATALØN" />;
  } else if (customerSettings.enableLessorUpload || customerSettings.enableMaanssonsSalaryUpload) {
    label = <FormattedMessage defaultMessage="Brug til lønbilag og overfør til LESSOR" />;
  }
  return (
    <Button
      color="primary"
      disabled={!!disabled}
      style={{marginRight: 8}}
      variant="contained"
      onClick={onClick}
    >
      {label}
    </Button>
  );
});

const EXTRASYNC_TIMEOUT_SECONDS = 2;
export const REMUNERATION_REPORT_READY_CHECK_TIMEOUT =
  EXTRASYNC_TIMEOUT_SECONDS * SECOND_MILLISECONDS;

interface DownloadButtonProps {
  downloadURL?: string;
  Icon: React.ComponentType | React.ExoticComponent;
}

const DownloadButton = React.memo(function DownloadButton(props: DownloadButtonProps): JSX.Element {
  const {downloadURL, Icon} = props;
  const shareToken = useSelector(getShareToken);

  if (downloadURL) {
    return (
      <IconButton color="primary" href={`${downloadURL}?token=${shareToken}`} target="_blank">
        <Icon />
      </IconButton>
    );
  } else {
    return <CircularProgress size={24} />;
  }
});

type DataLoenError =
  | "INVALID_DATALOEN_SETTINGS"
  | "INVALID_IN_DATALOEN"
  | "MISSING_EMPLOYEE_NUMBER";

interface DataloenErrorsProps {
  errors?: {
    employees?: {[userInitials: string]: DataLoenError[]};
    error?: string;
  };
}

function DataloenErrors(props: DataloenErrorsProps): JSX.Element | null {
  const {errors} = props;
  const theme = useTheme();
  if (!errors) {
    return null;
  }
  const {employees, error} = errors;
  if (error) {
    return (
      <div>
        <FormattedMessage
          defaultMessage="Ukendt fejl fra DataLøn"
          id="remuneration-report.text.dataloen-unknown-error"
        />
      </div>
    );
  } else if (employees) {
    return (
      <div style={{color: theme.palette.error.main}}>
        <FormattedMessage
          defaultMessage="Overførsel til DataLøn fejlede pga. følgende:"
          id="remuneration-report.text.dataloen-error"
        />
        {Object.keys(employees)
          .sort()
          .map((initials) => {
            const err = employees[initials];
            return (
              <div key={initials}>
                {initials}:{" "}
                {err.map((errorType) => (
                  <span key={errorType}>
                    {errorType === "MISSING_EMPLOYEE_NUMBER" ? (
                      <FormattedMessage
                        defaultMessage="Medarbejdernummer ikke opsat."
                        id="remuneration-report.text.employee-number-missing"
                      />
                    ) : errorType === "INVALID_DATALOEN_SETTINGS" ? (
                      <FormattedMessage
                        defaultMessage="DataLøn-opsætning for løngruppe ugyldig"
                        id="remuneration-report.text.dataloen-settings-invalid"
                      />
                    ) : errorType === "INVALID_IN_DATALOEN" ? (
                      <FormattedMessage
                        defaultMessage="Medarbejdernr. og lønperiodekode-kombination findes ikke i dataløn."
                        id="remuneration-report.text.dataloe-not-found"
                      />
                    ) : (
                      errorType
                    )}
                  </span>
                ))}
              </div>
            );
          })}
      </div>
    );
  } else {
    return null;
  }
}

type RemunerationReportData = SimplifiedData & {
  customerSettings: object;
};

interface RemunerationReportContentProps {
  data: RemunerationReportData;
  onRequestDeleteRemunerationReportDialog: () => void;
  remunerationReport: RemunerationReportResource;
}

const RemunerationReportContent = React.memo(function RemunerationReportContent(
  props: RemunerationReportContentProps,
): JSX.Element {
  const [fetchError, setFetchError] = useState<NetworkError | null>(null);
  const [useForSalaryVouchersDialogOpen, setUseForSalaryVouchersDialogOpen] = useState(false);
  const setUseForSalaryVouchersDialogOpenTrue = useCallWithTrue(setUseForSalaryVouchersDialogOpen);
  const setUseForSalaryVouchersDialogOpenFalse = useCallWithFalse(
    setUseForSalaryVouchersDialogOpen,
  );

  const {data, remunerationReport} = props;
  const customerSettings = useSelector(getCustomerSettings);
  const currentRole = useSelector(getCurrentRole);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const dispatch = useDispatch();
  const intl = useIntl();
  const {url} = remunerationReport;
  const extraSync = useCallback(() => {
    setFetchError(null);

    setTimeout(() => {
      jsonFetch(url)
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.syncDoneTimestamp || responseData.syncError) {
              dispatch(actions.addToOffline(responseData));
            } else if (!responseData.syncDoneTimestamp && !responseData.syncError) {
              extraSync();
            }
          }
          return;
        })
        .catch((error) => {
          setFetchError(error);
        });
    }, REMUNERATION_REPORT_READY_CHECK_TIMEOUT);
  }, [dispatch, url]);

  const {enableLessorUpload, enableMaanssonsSalaryUpload, remunerationGroups} = customerSettings;
  const handleUseForSalaryVouchersDialogOk = useCallback(() => {
    setUseForSalaryVouchersDialogOpen(false);
    dispatch(actions.update(url, [{member: "useForSalaryVouchers", value: true}]));
    if (
      enableLessorUpload ||
      enableMaanssonsSalaryUpload ||
      Object.values(remunerationGroups).some((group) => !!group.dataloen)
    ) {
      extraSync();
    }
  }, [
    enableLessorUpload,
    enableMaanssonsSalaryUpload,
    remunerationGroups,
    dispatch,
    extraSync,
    url,
  ]);

  const employeesWithOnlyUncompletedTasks = new Set(
    remunerationReport.employeesWithUncompletedTasks,
  );
  (remunerationReport.includedEmployees || []).forEach((employeeURL) => {
    employeesWithOnlyUncompletedTasks.delete(employeeURL);
  });
  const userReports = _.sortBy(data.users, (userData) => userData.initials).map(
    (userData, index) => {
      const visibleBonusLabels = userData.visibleBonusLabels || data.visibleBonusLabels;
      const visibleRateLabels = userData.visibleRateLabels || data.visibleRateLabels;
      const {visibleLabels} = userData;
      return (
        <UserSalaryReportCard
          key={`${index} - ${userData.initials} - ${userData.remunerationGroup}`}
          calendarDayBonusLabels={customerSettings.remunerationCalendarDayBonusLabels}
          countBonusLabels={data.countBonusLabels || []}
          currentRole={currentRole || undefined}
          customerSettings={customerSettings}
          intervalBonusLabels={data.intervalBonusLabels || []}
          rateLabels={data.rateLabels || []}
          showBreaks={!userData.paidBreaks || !!userData.forcedUnpaidBreakMinutes}
          startRateLabel={data.startRateLabel ?? undefined}
          taskBonusLabels={customerSettings.remunerationTaskBonusLabels}
          userData={userData}
          visibleBonusLabels={visibleBonusLabels ?? undefined}
          visibleLabels={visibleLabels}
          visibleRateLabels={visibleRateLabels ?? undefined}
          workDayBonusLabels={data.workDayBonusLabels || []}
        />
      );
    },
  );
  let periodContainsUncompletedTasksMessage: JSX.Element | undefined;
  if (data.reportContainsUncompletedTasksInPeriod) {
    periodContainsUncompletedTasksMessage = (
      <div style={{color: "red"}}>
        <FormattedMessage
          defaultMessage="ADVARSEL: Der findes uafsluttede opgaver der ikke er medtaget i denne rapport. Se hvem der er berørt, under den enkelte medarbejder."
          tagName="h3"
        />
      </div>
    );
  }
  let employeesWithOnlyUncompletedTasksMessage: JSX.Element | undefined;
  if (employeesWithOnlyUncompletedTasks.size) {
    const initialsList = Array.from(employeesWithOnlyUncompletedTasks)
      .map((employeeURL) => {
        const employee = userUserProfileLookup(employeeURL);
        return employee?.alias ?? intl.formatMessage(messages.unknownUser);
      })
      .join(", ");
    employeesWithOnlyUncompletedTasksMessage = (
      <div style={{color: "red"}}>
        <FormattedMessage
          defaultMessage="ADVARSEL: Følgende medarbejdere har kun uafsluttede opgaver og indgår ikke i rapporten: {initialsList}."
          tagName="h3"
          values={{initialsList}}
        />
      </div>
    );
  }
  let onlyValidatedMessage;
  if (remunerationReport.onlyValidated) {
    onlyValidatedMessage = (
      <FormattedMessage
        defaultMessage="Denne lønrapport indeholder udelukkende opgaver, som var godkendt på oprettelsestidspunktet."
        id="remuneration-report.label.only-validated"
        tagName="div"
      />
    );
  } else {
    onlyValidatedMessage = (
      <FormattedMessage
        defaultMessage="Denne lønrapport indeholder udelukkende opgaver, som var fuldført eller godkendt på oprettelsestidspunktet."
        id="remuneration-report.label.all-completed"
        tagName="div"
      />
    );
  }
  let missingCompletedMessage;
  if (remunerationReport.notValidatedPresentAtCreation && remunerationReport.onlyValidated) {
    missingCompletedMessage = (
      <FormattedMessage
        defaultMessage="Der er fuldførte opgaver i perioden som ved rapportens oprettelse endnu ikke var godkendt — disse er derfor ikke medtaget i rapporten."
        id="remuneration-report.label.missing-completed-warning"
        tagName="div"
      />
    );
  }
  let useForSalaryVouchersMessage;
  if (remunerationReport.useForSalaryVouchers) {
    useForSalaryVouchersMessage = (
      <FormattedMessage defaultMessage="Bruges til lønbilag." tagName="div" />
    );
  }
  let createdByInitilas;
  const createdByUserProfile = userUserProfileLookup(remunerationReport.createdBy);
  if (createdByUserProfile) {
    createdByInitilas = createdByUserProfile.alias;
  }

  return (
    <div>
      <Card>
        <CardHeader title={intl.formatMessage(messages.remunerationReport)} />
        <CardContent>
          <FormattedMessage defaultMessage="Oprettet:" id="remuneration-report.label.created" />
          &nbsp;
          {formatDate(remunerationReport.deviceTimestamp)}
          &nbsp;
          {formatTime(remunerationReport.deviceTimestamp)}
          <br />
          <FormattedMessage
            defaultMessage="Oprettet af:"
            id="remuneration-report.label.created-by"
          />
          &nbsp;
          {createdByInitilas}
          <br />
          <FormattedMessage defaultMessage="Download:" id="remuneration-report.label.download" />
          &nbsp;
          <DownloadButton
            downloadURL={remunerationReport.pdfDownload as string}
            Icon={FilePdfIcon}
          />
          &nbsp;
          <DownloadButton
            downloadURL={remunerationReport.csvDownload as string}
            Icon={FileDelimitedIcon}
          />
          <br />
          {onlyValidatedMessage}
          {missingCompletedMessage}
          {useForSalaryVouchersMessage}
          {periodContainsUncompletedTasksMessage}
          {employeesWithOnlyUncompletedTasksMessage}
          <div>
            <SalaryVoucherButton
              data={data}
              disabled={remunerationReport.useForSalaryVouchers}
              onClick={setUseForSalaryVouchersDialogOpenTrue}
            />
            <ErrorColorButton
              variant="contained"
              onClick={props.onRequestDeleteRemunerationReportDialog}
            >
              {intl.formatMessage(messages.delete)}
            </ErrorColorButton>
          </div>
          <LessorSync
            uploadDoneTimestamp={remunerationReport.syncDoneTimestamp || undefined}
            uploadError={fetchError || remunerationReport.syncError || undefined}
            uploadStartTimestamp={remunerationReport.syncStartTimestamp || undefined}
          />
          <DataloenErrors errors={remunerationReport.syncError?.dataloen} />
        </CardContent>
      </Card>
      {userReports}
      <ResponsiveDialog
        key="use-for-salary-vouchers-dialog"
        open={useForSalaryVouchersDialogOpen}
        title={intl.formatMessage(messages.useForSalaryVouchersDialogTitle)}
        onCancel={setUseForSalaryVouchersDialogOpenFalse}
        onOk={handleUseForSalaryVouchersDialogOk}
      >
        <DialogContent>
          <FormattedMessage
            defaultMessage="Marker som brugt til lønbilag?"
            id="remuneration-report.label.mark-as-salary-vouchers"
          />
          &nbsp;
          {customerSettings.salaryVouchersVisibleToEmployees ? (
            <FormattedMessage
              defaultMessage="Dette gør de enkelte medarbejderes tal synlige for dem."
              id="remuneration-report.label.salary-voucher-visible"
            />
          ) : null}
        </DialogContent>
      </ResponsiveDialog>
    </div>
  );
});

interface RemunerationReportProps {
  instance: RemunerationReportResource;
}

const RemunerationReport = React.memo(function RemunerationReport(
  props: RemunerationReportProps,
): JSX.Element {
  const [data, setData] = useState<RemunerationReportData | null>(null);
  const [deleteRemunerationReportDialogOpen, setDeleteRemunerationReportDialogOpen] =
    useState(false);
  const setDeleteRemunerationReportDialogOpenTrue = useCallWithTrue(
    setDeleteRemunerationReportDialogOpen,
  );
  const setDeleteRemunerationReportDialogOpenFalse = useCallWithFalse(
    setDeleteRemunerationReportDialogOpen,
  );

  const [error, setError] = useState<Error | null>(null);
  const dispatch = useDispatch();
  const {instance} = props;
  const {dataUrl} = instance;

  const doFetch = useCallback(() => {
    if (dataUrl) {
      jsonFetch(dataUrl)
        .then((response) => {
          setData(response.data);
          return;
        })
        .catch((fetchError) => {
          setError(fetchError);
        });
    }
  }, [dataUrl]);

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  const handleTryLoadAgain = useCallback(() => {
    setError(null);
    doFetch();
  }, [doFetch]);

  const handleDeleteRemunerationReportDialogOk = useCallback(() => {
    setDeleteRemunerationReportDialogOpen(false);
    dispatch(actions.remove(instance.url));
    window.setTimeout(() => {
      dispatch(actions.go("/remunerationReport"));
    });
  }, [dispatch, instance]);

  const {fromDate, title, toDate} = instance;
  const intl = useIntl();
  let headerTitle: string;
  const titleString = title;
  if (fromDate === toDate) {
    headerTitle = titleString
      ? intl.formatMessage(messages.remunerationReportDayWithTitle, {
          date: _.capitalize(formatDate(fromDate)),
          title: titleString,
        })
      : intl.formatMessage(messages.remunerationReportDay, {
          date: _.capitalize(formatDate(fromDate)),
        });
  } else {
    headerTitle = titleString
      ? intl.formatMessage(messages.remunerationReportPeriodWithTitle, {
          from: _.capitalize(formatDate(fromDate)),
          title: titleString,
          to: _.capitalize(formatDate(toDate)),
        })
      : intl.formatMessage(messages.remunerationReportPeriod, {
          from: _.capitalize(formatDate(fromDate)),
          to: _.capitalize(formatDate(toDate)),
        });
  }
  const deleteRemunerationReportDialog = (
    <DeleteDialog
      key="delete-remuneration-report-dialog"
      open={deleteRemunerationReportDialogOpen}
      onCancel={setDeleteRemunerationReportDialogOpenFalse}
      onOk={handleDeleteRemunerationReportDialogOk}
    >
      <FormattedMessage defaultMessage="Slet lønrapport?" />
    </DeleteDialog>
  );
  const dialogs = [deleteRemunerationReportDialog];
  const deleteButton = (
    <ErrorColorButton variant="contained" onClick={setDeleteRemunerationReportDialogOpenTrue}>
      {intl.formatMessage(messages.delete)}
    </ErrorColorButton>
  );
  if (data) {
    return (
      <PageLayout withPadding dialogs={dialogs} toolbar={headerTitle}>
        <RemunerationReportContent
          data={data}
          remunerationReport={instance}
          onRequestDeleteRemunerationReportDialog={setDeleteRemunerationReportDialogOpenTrue}
        />
      </PageLayout>
    );
  } else if (error) {
    return (
      <PageLayout withPadding dialogs={dialogs} toolbar={headerTitle}>
        <div style={{textAlign: "center"}}>
          <Button color="primary" variant="contained" onClick={handleTryLoadAgain}>
            {intl.formatMessage(messages.tryAgain)}
          </Button>
        </div>
        {deleteButton}
      </PageLayout>
    );
  } else {
    return (
      <PageLayout withPadding dialogs={dialogs} toolbar={headerTitle}>
        <div style={{textAlign: "center"}}>
          <CircularProgress />
        </div>
        {deleteButton}
      </PageLayout>
    );
  }
});

const LoadRemunerationReport = React.memo(function LoadRemunerationReport(): JSX.Element {
  const intl = useIntl();
  return (
    <DoLoadInstance
      Component={RemunerationReport}
      loadingTitle={intl.formatMessage(messages.remunerationReport)}
      lookupSelector={getRemunerationReportLookup}
      resourceName="remunerationReport"
    />
  );
});

export default LoadRemunerationReport;
