import {ReadonlyConfig, schema} from "@co-common-libs/config";
import {Location, Role} from "@co-common-libs/resources";
import {
  ActiveEconomySystemIntegrationKind,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";

type RoleIdentifier = (typeof schema.definitions.roles.enum)[number];

function getRoleIdentifiers(
  role: Pick<
    Role,
    | "jobber"
    | "machineOperator"
    | "manager"
    | "mechanic"
    | "projectManager"
    | "seniorMachineOperator"
  > | null,
): Set<RoleIdentifier> {
  const identifiers = new Set<RoleIdentifier>();
  if (role) {
    if (role.manager) {
      identifiers.add("manager");
    }
    if (role.seniorMachineOperator) {
      identifiers.add("senior_machine_operator");
    }
    if (role.projectManager) {
      identifiers.add("project_manager");
    }
    if (role.machineOperator) {
      identifiers.add("machine_operator");
    }
    if (role.mechanic) {
      identifiers.add("mechanic");
    }
    if (role.jobber) {
      identifiers.add("jobber");
    }
  }
  return identifiers;
}

const isField = (location: Pick<Location, "geojson">): boolean => !!location?.geojson;

function workplaceChangeAllowedBySync(
  config: ReadonlyConfig,
  activeEconomySystemKind: ActiveEconomySystemIntegrationKind,
): boolean {
  const {
    brugerdataSync,
    c5Sync,
    economicEnableWorkplaceImport,
    economicInvoicePerWorkplace,
    economicSync,
    economicUploadWorkPlaces,
    navSync,
    navSyncProfile,
    unicontaSync,
  } = config;
  if (unicontaSync || navSync || brugerdataSync || navSyncProfile === "bejstrupm") {
    return true;
  } else if (c5Sync) {
    return false;
  } else if (economicSync) {
    if (economicUploadWorkPlaces) {
      return true;
    } else {
      return !economicEnableWorkplaceImport && !economicInvoicePerWorkplace;
    }
  } else {
    console.assert(activeEconomySystemKind === ActiveEconomySystemIntegrationKind.NONE);
    return true;
  }
}

export interface ExtendedLocationSettings {
  canChangeWorkplace: boolean;
  canCreateLocation: boolean;
  canCreateWorkplace: boolean;
  canEditLocation: boolean;
  canEditLocationType: (location: Pick<Location, "geojson">) => boolean;
  canEditWorkplace: boolean;
  canMergeLocations: boolean;
}

export function extendedLocationSettings(
  config: ReadonlyConfig,
  role: Pick<
    Role,
    | "jobber"
    | "machineOperator"
    | "manager"
    | "mechanic"
    | "projectManager"
    | "seniorMachineOperator"
  > | null,
): ExtendedLocationSettings {
  const {
    allowMachineOperatorToEditLocationLocationType,
    rolesMayCreateLocations,
    rolesMayEditLocations,
  } = config;
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  const roleIdentifiers = getRoleIdentifiers(role);

  const roleCanCreateLocation = rolesMayCreateLocations.some((identifier) =>
    roleIdentifiers.has(identifier),
  );

  const roleCanEditLocation = rolesMayEditLocations.some((identifier) =>
    roleIdentifiers.has(identifier),
  );

  const canChangeWorkplace = workplaceChangeAllowedBySync(config, economySystemIntegrationKind);

  const canCreateLocation =
    roleCanCreateLocation &&
    economySystemIntegrationKind !== ActiveEconomySystemIntegrationKind.IMPORT_ONLY;

  const canEditLocation =
    roleCanEditLocation &&
    economySystemIntegrationKind !== ActiveEconomySystemIntegrationKind.IMPORT_ONLY;

  return {
    canChangeWorkplace,
    canCreateLocation,
    canCreateWorkplace: canCreateLocation && canChangeWorkplace,
    canEditLocation,
    canEditLocationType: (location: Pick<Location, "geojson">) =>
      !isField(location) && (roleCanEditLocation || allowMachineOperatorToEditLocationLocationType),
    canEditWorkplace: canEditLocation && canChangeWorkplace,
    canMergeLocations: canEditLocation,
  };
}
