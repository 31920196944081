import {Role, User, UserProfile, UserUrl} from "@co-common-libs/resources";
import {UserDialog} from "@co-frontend-libs/components";
import {
  AppState,
  getCustomerSettings,
  getUserArray,
  getUserRoleLookup,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createSelector, createStructuredSelector} from "reselect";

interface UserDialogStateProps {
  labelVariant: "CHAUFFEUR" | "EMPLOYEE" | "MACHINEOPERATOR";
  userArray: readonly User[];
  userRoleLookup: (url: UserUrl) => Role | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface UserDialogOwnProps {
  onCancel(): void;
  onNone?: () => void;
  onOk(url: UserUrl): void;
  open: boolean;
  title?: string;
}

const getEmployeeLabelVariant: (state: AppState) => "CHAUFFEUR" | "EMPLOYEE" | "MACHINEOPERATOR" =
  createSelector(getCustomerSettings, (customerSettings) => customerSettings.employeeLabelVariant);

const filterUsersToMachineOperators = (
  users: readonly User[],
  userRoleLookup: (url: UserUrl) => Role | undefined,
): readonly User[] => {
  return users.filter((user) => {
    const role = userRoleLookup(user.url);
    return !!role && role.machineOperator;
  });
};

const getMachineOperatorUsers: (state: AppState) => readonly User[] = createSelector(
  getUserArray,
  getUserRoleLookup,
  filterUsersToMachineOperators,
);

export const ConnectedMachineOperatorDialog: React.ComponentType<UserDialogOwnProps> = connect<
  UserDialogStateProps,
  object,
  UserDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, UserDialogStateProps>({
    labelVariant: getEmployeeLabelVariant,
    userArray: getMachineOperatorUsers,
    userRoleLookup: getUserRoleLookup,
    userUserProfileLookup: getUserUserProfileLookup,
  }),
  {},
)(UserDialog);
