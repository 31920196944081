import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {formatAddress} from "@co-common-libs/utils";
import {
  DecimalField,
  ErrorColorButton,
  ResponsiveDialog,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {Button, DialogContent} from "@material-ui/core";
import {plainObjectReducer} from "app-utils";
import React, {useCallback, useReducer, useState} from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {useCustomerSetters} from "./info-blocks";
import {LocationSelectCreateDialog} from "./location-dialog";
import {UnitSelect} from "./unit-select";

const messages = defineMessages({
  address: {
    defaultMessage: "Arbejdssted",
    id: "delivery-location-dialog.label.task-address",
  },
  areaHa: {
    defaultMessage: "Areal (ha)",
    id: "delivery-location-dialog.title.area",
  },
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  delete: {
    defaultMessage: "Slet",
    id: "delivery-location-dialog.label.delete",
  },
  editDeliveryLocation: {
    defaultMessage: "Ret leveringssted",
    id: "delivery-location-dialog.title.edit-delivery-location",
  },
  newDeliveryLocation: {
    defaultMessage: "Nyt leveringssted",
    id: "delivery-location-dialog.title.new-delivery-location",
  },
  note: {
    defaultMessage: "Note",
    id: "delivery-location-dialog.label.note",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  selectCustomer: {
    defaultMessage: "Vælg",
    id: "delivery-location-dialog.label.select-customer",
  },
  selectDeliveryLocation: {
    defaultMessage: "Leveringssted",
    id: "delivery-location-dialog.title.select-delivery-location",
  },
  selectWorkplace: {
    defaultMessage: "Vælg",
    id: "delivery-location-dialog.label.select-workplace",
  },
  totalAmount: {
    defaultMessage: "Total mængde ({unit})",
    id: "delivery-location-dialog.label.total-amount",
  },
  unit: {
    defaultMessage: "Enhed",
    id: "delivery-location-dialog.label.unit",
  },
  unitChoice: {
    defaultMessage: "Vælg enhed",
    id: "delivery-location-dialog.label.unit-choice",
  },
});

interface OkPayload {
  amount: number | null;
  areaHa: number | null;
  customer: Customer | null;
  location: Location | null;
  note: string;
  relatedUnit: Unit | null;
  unit: string;
}

interface DeliveryLocationDialogProps {
  amount?: number | undefined;
  areaHa?: number | undefined;
  canDelete?: boolean;
  customer?: Customer | undefined;
  customerSettings: Config;
  haRequired?: boolean | undefined;
  isNew: boolean;
  location?: Location | undefined;
  note?: string | undefined;
  onCancel: () => void;
  onCustomerChanged?: (customerUrl: CustomerUrl | null) => void;
  onOk: (data: OkPayload) => void;
  onRequestDelete?: () => void;
  open: boolean;
  relatedUnit?: Unit | undefined;
  unit?: string | undefined;
}

export function DeliveryLocationDialog(props: DeliveryLocationDialogProps): JSX.Element {
  const customerSettings = useSelector(getCustomerSettings);
  const unitLookup = useSelector(getUnitLookup);
  const locationLookup = useSelector(getLocationLookup);
  const customerLookup = useSelector(getCustomerLookup);
  const {formatMessage} = useIntl();
  const {canDelete, isNew, onCancel, onCustomerChanged, onOk, onRequestDelete, open} = props;

  const initial: OkPayload = {
    amount: props.amount || null,
    areaHa: props.areaHa || null,
    customer: props.customer || null,
    location: props.location || null,
    note: props.note || "",
    relatedUnit: props.relatedUnit || null,
    unit: props.unit || "",
  };

  const [payload, setPayload] = useReducer(plainObjectReducer<OkPayload>, initial);
  const [customerLocationDialogOpen, setCustomerLocationDialogOpen] = useState(false);

  const handleCustomerChanged = useCallback(
    (customerUrl: CustomerUrl | null): void => {
      const newCustomer = customerUrl && customerLookup(customerUrl);
      setPayload({customer: newCustomer || null, location: undefined});
      if (onCustomerChanged) {
        onCustomerChanged(customerUrl);
      }
    },
    [customerLookup, onCustomerChanged],
  );

  const [modal, selectCustomerClicked] = useCustomerSetters(handleCustomerChanged);

  const disabled =
    customerSettings.sharedTransportLog &&
    customerSettings.transportLogUnitPerLocation &&
    !payload.relatedUnit;

  const handleOk = useCallback((): void => {
    onOk(payload);
  }, [onOk, payload]);
  const handleAreaHaChange = useCallback((newValue: number | null): void => {
    setPayload({areaHa: newValue});
  }, []);
  const handleAmountChange = useCallback((newValue: number | null): void => {
    setPayload({amount: newValue});
  }, []);
  const handleNoteChange = useCallback((value: string): void => {
    setPayload({note: value});
  }, []);
  const handleSelectUnitChange = useCallback(
    (unitURL: UnitUrl): void => {
      const selectedUnit = unitLookup(unitURL);
      if (!selectedUnit) {
        return;
      }
      setPayload({relatedUnit: selectedUnit, unit: selectedUnit.name || selectedUnit.symbol});
    },
    [unitLookup],
  );
  const handleLocationSelectButton = useCallback((_event: unknown): void => {
    setCustomerLocationDialogOpen(true);
  }, []);
  const handleWorkPlaceDialogCancel = useCallback((): void => {
    setCustomerLocationDialogOpen(false);
  }, []);
  const handleWorkPlaceDialogOk = useCallback(
    (url: LocationUrl): void => {
      const newLocation = locationLookup(url);
      const newCustomer = newLocation?.customer && customerLookup(newLocation.customer);

      setPayload({customer: newCustomer || null, location: newLocation});
      setCustomerLocationDialogOpen(false);
    },
    [locationLookup, customerLookup],
  );

  const dialogContent = (
    <div>
      <Grid>
        <Cell palm="12/12">
          <div>
            <FormattedMessage
              defaultMessage="Kunde"
              id="pickup-location-dialog.label.customer"
              tagName="h4"
            />
            <Button color="secondary" variant="contained" onClick={selectCustomerClicked}>
              {formatMessage(messages.selectCustomer)}
            </Button>
            {payload.customer && (
              <div>
                <FormattedMessage
                  defaultMessage="Navn: {name}"
                  id="delivery-location-dialog.label.customer-name"
                  tagName="div"
                  values={{name: payload.customer.name}}
                />
                <FormattedMessage
                  defaultMessage="Adresse: {address}"
                  id="delivery-location-dialog.label.customer-address"
                  tagName="div"
                  values={{address: formatAddress(payload.customer)}}
                />
              </div>
            )}
          </div>
          <div>
            <FormattedMessage
              defaultMessage="Arbejdssted"
              id="order-instance.label.workplace"
              tagName="h4"
            />
            <div>
              <Button color="secondary" variant="contained" onClick={handleLocationSelectButton}>
                {formatMessage(messages.selectDeliveryLocation)}
              </Button>
              <div>{payload.location?.address || payload.location?.name || ""}</div>
            </div>
          </div>
          {props.haRequired ? (
            <DecimalField
              fullWidth
              decimalPlaces={customerSettings.transportLogDecimals}
              label={formatMessage(messages.areaHa)}
              margin="dense"
              maxDigits={9}
              value={payload.areaHa || null}
              onChange={handleAreaHaChange}
            />
          ) : null}
          {!isNew && onRequestDelete && (
            <div>
              <ErrorColorButton disabled={!canDelete} variant="contained" onClick={onRequestDelete}>
                {formatMessage(messages.delete)}
              </ErrorColorButton>
            </div>
          )}
        </Cell>
        <Cell palm="12/12">
          {customerSettings.transportLogUnitPerLocation ? (
            <UnitSelect
              relatedUnit={payload.relatedUnit?.url}
              onSelectUnitChange={handleSelectUnitChange}
            />
          ) : null}
          <DecimalField
            fullWidth
            decimalPlaces={customerSettings.transportLogDecimals}
            label={formatMessage(messages.totalAmount, {
              unit: payload.relatedUnit
                ? payload.relatedUnit.symbol || payload.relatedUnit.name
                : payload.unit,
            })}
            margin="dense"
            maxDigits={9}
            value={payload.amount != null ? payload.amount : null}
            onChange={handleAmountChange}
          />
          <TrimTextField
            fullWidth
            multiline
            label={formatMessage(messages.note)}
            margin="dense"
            maxRows={30}
            minRows={2}
            value={payload.note}
            variant="outlined"
            onChange={handleNoteChange}
          />
        </Cell>
      </Grid>
      {customerSettings.sharedTransportLog && customerSettings.transportLogUnitPerLocation ? (
        <FormattedMessage defaultMessage="* Krævet" id="delivery-location-dialog.label.required" />
      ) : null}
    </div>
  );
  return (
    <div>
      <ResponsiveDialog
        okDisabled={disabled}
        open={open}
        title={
          isNew
            ? formatMessage(messages.newDeliveryLocation)
            : formatMessage(messages.editDeliveryLocation)
        }
        onCancel={onCancel}
        onOk={handleOk}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
      <LocationSelectCreateDialog
        customerURL={payload.customer?.url || null}
        includeWorkplaceOnlyLocations={false}
        open={customerLocationDialogOpen}
        titleVariant="DELIVERY"
        onCancel={handleWorkPlaceDialogCancel}
        onOk={handleWorkPlaceDialogOk}
      />
      {modal}
    </div>
  );
}
