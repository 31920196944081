import {Config} from "@co-common-libs/config";
import {
  Contact,
  ContactUrl,
  Culture,
  CultureUrl,
  Customer,
  CustomerUrl,
  DeliveryLocation,
  DeliveryLocationUrl,
  Location,
  LocationUrl,
  LocationUseLog,
  Machine,
  MachineUrl,
  MachineUse,
  Order,
  OrderUrl,
  PatchUnion,
  PickupLocation,
  PickupLocationUrl,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  Product,
  ProductUrl,
  ProductUseLog,
  Project,
  ProjectUrl,
  ReportingSpecification,
  ReportingSpecificationUrl,
  ResourceTypeUnion,
  Role,
  SprayLocation,
  SprayLog,
  Task,
  TaskUrl,
  Timer,
  TimerStart,
  TimerUrl,
  TransportLog,
  Unit,
  UnitUrl,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  YieldDeliveryLocation,
  YieldLog,
  YieldPickupLocation,
  emptyTransportLog,
  urlToId,
} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {dateToString, notUndefined} from "@co-common-libs/utils";
import {
  DateField,
  DeleteDialog,
  ErrorColorButton,
  TimeField,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  ConnectedCultureDialog,
  ConnectedMachineOperatorDialog,
} from "@co-frontend-libs/connected-components";
import {
  AppState,
  PathTemplate,
  actions,
  getContactArray,
  getContactLookup,
  getCultureLookup,
  getCurrentRole,
  getCurrentUserSelectableProjectArray,
  getCurrentUserURL,
  getCustomerLookup,
  getDeliveryLocationArray,
  getDeliveryLocationLookup,
  getDeviceConfigKey,
  getExtendedCustomerSettings,
  getLocationArray,
  getLocationLookup,
  getLocationUseLogArray,
  getMachineLookup,
  getNotificationDialogOpen,
  getOrderLookup,
  getPickupLocationArray,
  getPickupLocationLookup,
  getPriceGroupLookup,
  getPriceItemLookup,
  getProductLookup,
  getProductUseLogArray,
  getProjectLookup,
  getReportingSpecificationLookup,
  getSprayLocationArray,
  getSprayLogArray,
  getTaskArray,
  getTaskLookup,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getTransportLogArray,
  getUnitLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
  getYieldDeliveryLocationArray,
  getYieldLogArray,
  getYieldPickupLocationArray,
  makeOptionalPathParameterGetter,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {Button, Card, CardContent} from "@material-ui/core";
import {
  AddOrderBDReference,
  ContactBlock,
  CustomerBlock,
  DeliveryLocationDialog,
  DoLoadInstance,
  Linkify,
  LocationBlock,
  PageLayout,
  PickupLocationDialog,
  ProjectBlock,
  RemoveOrderBDReference,
  TaskCheckDialog,
  TaskFields,
  TransportLogDialog,
} from "app-components";
import {
  LoadInstanceRelated,
  PureComponent,
  changeCustomerCulture,
  computeIntervalsTruncated,
  copyTaskWithLogsLocations,
  focusButton,
  getGenericPrimaryTimer,
  getTaskSecondaryTimerList,
  mergeIntervals,
  taskChangeContact,
  taskChangeCustomerCulture,
} from "app-utils";
import {bind} from "bind-decorator";
import {ExtendedConfig} from "extended-config";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages, useIntl} from "react-intl";
import {connect} from "react-redux";
import {Dispatch, bindActionCreators} from "redux";
import {createStructuredSelector} from "reselect";
import type {Writable} from "ts-essentials";
import {v4 as uuid} from "uuid";
import CopiedDialog from "../task-instance/copied-dialog";
import TaskInstance from "./task-instance";

const messages = defineMessages({
  archiveLoaded: {
    defaultMessage: "Indlæst fra arkiv",
    id: "task-instance.label.archive-loaded",
  },
  cancel: {defaultMessage: "Fortryd", id: "dialog.label.cancel"},
  confirmDelete: {
    defaultMessage: "Slet",
    id: "damage-report-instance.confirm-delete",
  },
  date: {
    defaultMessage: "Ønsket dato",
  },
  deleteOrder: {defaultMessage: "Slet", id: "order-instance.label.delete"},
  deleteQuestion: {
    defaultMessage: "Slet?",
    id: "order-instance.dialog-title.delete",
  },
  desiredTime: {
    defaultMessage: "Klokkeslæt",
  },
  fetching: {
    defaultMessage: "Henter fra arkiv",
    id: "task-instance.label.fetching",
  },
  filterLabel: {
    defaultMessage: "Filtrer liste",
  },
  ha: {
    defaultMessage: "HA ifølge kunden",
  },
  missing: {
    defaultMessage: "Kunne ikke finde opgave på server",
    id: "task-instance.label.missing",
  },
  notes: {defaultMessage: "Noter"},
  offline: {
    defaultMessage: "Kunne ikke forbinde til server",
    id: "task-instance.label.offline",
  },
  ok: {defaultMessage: "OK", id: "dialog.label.ok"},
  referenceNumber: {
    defaultMessage: "Referencenummer",
    id: "order-entry.label.reference-number",
  },
  searchForMore: {
    defaultMessage: "\u2014 Søg for at se flere \u2014",
  },
  selectContact: {
    defaultMessage: "Vælg kontaktperson",
  },
  selectCultureButton: {
    defaultMessage: "Vælg",
    id: "order-instance.label.select-culture",
  },
  selectCustomer: {
    defaultMessage: "Vælg kunde",
  },
  selectTaskType: {
    defaultMessage: "Vælg arbejdsområde",
  },

  selectTractorButton: {
    defaultMessage: "Select tractor",
    id: "order-instance.label.select-tractor",
  },
  selectVibratorButton: {
    defaultMessage: "Select vibrator",
    id: "order-instance.label.select-vibrator",
  },
  start: {defaultMessage: "Start", id: "order-instance.label.start"},
  title: {defaultMessage: "Opgave", id: "order-instance.title"},
  tryAgain: {
    defaultMessage: "Forsøg igen",
    id: "task-instance.label.try-again",
  },
  validatedDialogTitle: {
    defaultMessage: "Godkend?",
    id: "task-instance.dialog-title.validate-and-record",
  },
});

interface ValidatedDialogProps {
  customerSettings: Config;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  task?: Task | undefined;
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerStartArray: readonly TimerStart[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

class ValidatedDialog extends PureComponent<ValidatedDialogProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  getTimerStarts(): TimerStart[] {
    if (!this.props.task) {
      return [];
    }
    const taskURL = this.props.task.url;
    return _.sortBy(
      this.props.timerStartArray.filter((instance) => instance.task === taskURL),
      getNormalisedDeviceTimestamp,
    );
  }
  render(): JSX.Element | null {
    const {formatMessage} = this.context;
    const {
      customerSettings,
      machineLookup,
      priceGroupLookup,
      task,
      timerArray,
      timerLookup,
      workTypeLookup,
    } = this.props;
    if (!task) {
      return null;
    }
    const title = formatMessage(messages.validatedDialogTitle);
    const now = new Date();
    now.setUTCMilliseconds(0);
    const timerStarts = this.getTimerStarts();
    const computedIntervals = task.recordedInC5
      ? task.computedTimeSet
      : computeIntervalsTruncated(timerStarts);
    const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
    const intervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
    ).map((value) => ({
      ...value,
      timer: (value.timer && timerLookup(value.timer)) || null,
    }));
    let computedStartTime;
    let computedEndTime;
    if (computedIntervals.length) {
      computedStartTime = computedIntervals[0].fromTimestamp;
      computedEndTime =
        computedIntervals[computedIntervals.length - 1].toTimestamp || now.toISOString();
    }
    let finalStartTime;
    let finalEndTime;
    if (intervals.length) {
      finalStartTime = intervals[0].fromTimestamp;
      finalEndTime = intervals[intervals.length - 1].toTimestamp || now.toISOString();
    }

    const genericPrimaryTimer = getGenericPrimaryTimer(timerArray);
    const secondaryTimers = getTaskSecondaryTimerList(task, customerSettings, {
      machineLookup,
      priceGroupLookup,
      timerArray,
      workTypeLookup,
    });
    return (
      <TaskCheckDialog
        computedEndTime={computedEndTime}
        computedIntervals={computedIntervals}
        computedStartTime={computedStartTime}
        finalEndTime={finalEndTime}
        finalIntervals={intervals}
        finalStartTime={finalStartTime}
        genericPrimaryTimer={genericPrimaryTimer}
        open={this.props.open}
        secondaryTimers={new Set(secondaryTimers)}
        task={task}
        title={title}
        onCancel={this.props.onCancel}
        onOk={this.props.onOk}
      />
    );
  }
}

interface OrderInstanceProps extends OrderInstanceContainerProps {
  order: Order;
  taskList: readonly Task[];
}

interface OrderInstanceState {
  copiedDialogOpen: boolean;
  copiedTaskMachineList: readonly Machine[] | null;
  copiedTaskWorkType: WorkType | null;
  cultureDialogOpen: boolean;
  deleteDialogOpen: boolean;
  deliveryLocationDialog: {
    amount: number | null;
    areaHa: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    open: boolean;
    relatedUnit: Unit | null;
    task: Task | null;
    transportLog: TransportLog | null;
    unit: string;
    url: DeliveryLocationUrl | null;
  };
  editTransportLogButton: HTMLButtonElement | null;
  editTransportLogDialogTask: Task | null;
  editTransportLogDialogTransportLog: TransportLog | null;
  machineOperatorDialogButton: HTMLButtonElement | null;
  machineOperatorDialogCallback: ((url: UserUrl) => void) | null;
  pickupLocationDialog: {
    amount: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    open: boolean;
    relatedUnit: Unit | null;
    task: Task | null;
    transportLog: TransportLog | null;
    unit: string;
    url: PickupLocationUrl | null;
  };
  taskDeleteDialogCallback: (() => void) | null;
  validatedDialogCallback: (() => void) | null;
  validatedDialogTask: Task | null;
}

class OrderInstance extends PureComponent<OrderInstanceProps, OrderInstanceState> {
  state: OrderInstanceState = {
    copiedDialogOpen: false,
    copiedTaskMachineList: null,
    copiedTaskWorkType: null,
    cultureDialogOpen: false,
    deleteDialogOpen: false,
    deliveryLocationDialog: {
      amount: null,
      areaHa: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      relatedUnit: null,
      task: null,
      transportLog: null,
      unit: "",
      url: null,
    },
    editTransportLogButton: null,
    editTransportLogDialogTask: null,
    editTransportLogDialogTransportLog: null,
    machineOperatorDialogButton: null,
    machineOperatorDialogCallback: null,
    pickupLocationDialog: {
      amount: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      relatedUnit: null,
      task: null,
      transportLog: null,
      unit: "",
      url: null,
    },
    taskDeleteDialogCallback: null,
    validatedDialogCallback: null,
    validatedDialogTask: null,
  };
  componentDidMount(): void {
    focusButton(this.selectCustomerButton.current);
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  _taskInstances = new Map();
  @bind
  handleRequestMachineOperatorDialog(
    callback: (url: UserUrl) => void,
    ref: HTMLButtonElement | null,
  ): void {
    this.setState({
      machineOperatorDialogButton: ref,
      machineOperatorDialogCallback: callback,
    });
  }
  @bind
  handleMachineOperatorDialogCancel(): void {
    focusButton(this.state.machineOperatorDialogButton);
    this.setState({
      machineOperatorDialogButton: null,
      machineOperatorDialogCallback: null,
    });
  }
  @bind
  handleMachineOperatorDialogOk(selected: UserUrl): void {
    focusButton(this.state.machineOperatorDialogButton);
    const callback = this.state.machineOperatorDialogCallback;
    this.setState({machineOperatorDialogButton: null, machineOperatorDialogCallback: null}, () => {
      if (callback) {
        callback(selected);
      }
    });
  }

  @bind
  handleRelatedWorkplaceChanged(selected: LocationUrl | null): void {
    this.setRelatedLocation(selected, "relatedWorkplace");
  }

  @bind
  handleRelatedPickupLocationChanged(selected: LocationUrl | null): void {
    this.setRelatedLocation(selected, "relatedPickupLocation");
  }

  private setRelatedLocation<
    FieldName extends keyof Pick<Task, "relatedPickupLocation" | "relatedWorkplace">,
  >(selected: LocationUrl | null, fieldName: FieldName): void {
    const task = this.props.taskID
      ? this.props.taskLookup(instanceURL("task", this.props.taskID))
      : this.props.taskList.length === 1
        ? this.props.taskList[0]
        : null;

    if (task && task[fieldName] !== selected) {
      this.props.update(task.url, [{member: fieldName, value: selected}]);
    }

    focusButton(this.selectWorkplaceButton);
  }
  @bind
  handleRequestDeleteDialog(callback: () => void): void {
    this.setState({taskDeleteDialogCallback: callback});
  }
  @bind
  handleTaskDeleteDialogOk(): void {
    const callback = this.state.taskDeleteDialogCallback;
    this.setState({taskDeleteDialogCallback: null}, () => {
      if (callback) {
        callback();
      }
    });
  }
  @bind
  handleTaskDeleteDialogCancel(): void {
    this.setState({taskDeleteDialogCallback: null});
  }
  @bind
  handleRequestValidatedDialog(callback: () => void, task: Task): void {
    this.setState({
      validatedDialogCallback: callback,
      validatedDialogTask: task,
    });
  }
  @bind
  handleValidatedDialogOk(): void {
    const callback = this.state.validatedDialogCallback;
    this.setState(
      {
        validatedDialogCallback: null,
        validatedDialogTask: null,
      },
      () => {
        if (callback) {
          callback();
        }
      },
    );
  }
  @bind
  handleValidatedDialogCancel(): void {
    this.setState({
      validatedDialogCallback: null,
      validatedDialogTask: null,
    });
  }
  @bind
  handleTaskCopy(task: Task): void {
    const {
      currentRole,
      currentUserURL,
      customerSettings,
      deliveryLocationArray,
      locationLookup,
      machineLookup,
      order,
      pickupLocationArray,
      priceGroupLookup,
      productLookup,
      projectLookup,
      reportingSpecificationLookup,
      sprayLocationArray,
      sprayLogArray,
      transportLogArray,
      workTypeLookup,
      yieldDeliveryLocationArray,
      yieldLogArray,
      yieldPickupLocationArray,
    } = this.props;
    if (!order || !currentUserURL || !currentRole) {
      return;
    }

    const today = dateToString(new Date());
    let orderURL = order.url;
    if (!customerSettings.taskCopyFields.includes("order")) {
      const orderCopyID = uuid();
      const orderCopyURL = instanceURL("order", orderCopyID);
      const customerURL = order.customer;
      const orderCopy: Writable<Order> = {
        ...order,
        billed: false,
        createdBy: currentUserURL,
        customer: customerURL,
        date: today,
        id: orderCopyID,
        remoteUrl: "",
        url: orderCopyURL,
      };
      delete orderCopy.created;
      orderURL = orderCopyURL;
      this.props.create(orderCopy);
    }
    const overrides: Partial<Writable<Task>> = {
      createdBy: currentUserURL,
      order: orderURL,
    };
    if (task.date == null || task.date < today) {
      overrides["date"] = today;
    }
    if (!currentRole.manager) {
      overrides.machineOperator = currentUserURL;
    }
    const copyResult = copyTaskWithLogsLocations(
      task,
      customerSettings.taskCopyFields,
      overrides,
      {
        deliveryLocationArray,
        locationLookup,
        machineLookup,
        pickupLocationArray,
        priceGroupLookup,
        productLookup,
        projectLookup,
        reportingSpecificationLookup,
        sprayLocationArray,
        sprayLogArray,
        transportLogArray,
        workTypeLookup,
        yieldDeliveryLocationArray,
        yieldLogArray,
        yieldPickupLocationArray,
      },
      this.props.dispatch,
      this.context,
      customerSettings,
    );
    const taskCopy: Task = copyResult[0] as any;
    const taskCopyID = urlToId(taskCopy.url);
    const taskWorkType = taskCopy.workType ? workTypeLookup(taskCopy.workType) : null;
    const taskMachineList = ((taskCopy.machineuseSet || []) as readonly MachineUse[])
      .map((machineUse) => {
        const machineURL = machineUse.machine;
        return machineLookup(machineURL);
      })
      .filter(notUndefined);
    copyResult.forEach((instance) => {
      this.props.createOrUpdate(instance);
    });
    window.setTimeout(() => {
      this.props.go(
        "/order/:id/:taskID",
        {
          id: urlToId(orderURL),
          taskID: taskCopyID,
        },
        {},
        "REPLACE",
      );
      this.setState({
        copiedDialogOpen: true,
        copiedTaskMachineList: taskMachineList,
        copiedTaskWorkType: taskWorkType || null,
      });
    }, 0);
  }
  @bind
  handleCopiedDialogClose(): void {
    this.setState({
      copiedDialogOpen: false,
      copiedTaskMachineList: null,
      copiedTaskWorkType: null,
    });
  }
  @bind
  handleSave(_event: unknown): void {
    if (this.props.taskID || this.props.taskList.length === 1) {
      const id = this.props.taskID || urlToId(this.props.taskList[0].url);
      window.setTimeout(() => {
        this.props.go("/task/:id", {id}, {}, this.props.taskID ? "PUSH" : "REPLACE");
      });
    } else {
      this.props.back();
    }
  }

  @bind
  handleCustomerChanged(customerUrl: CustomerUrl | null): void {
    if (customerUrl !== this.props.order.customer) {
      this.changeCustomerCulture(customerUrl, null);
    }
  }

  @bind
  handleContactChanged(contactUrl: ContactUrl | null): void {
    if (contactUrl !== this.props.order.contact) {
      this.changeContact(contactUrl);
    }
  }

  @bind
  handleProjectChanged(value: ProjectUrl | null): void {
    const {dispatch, order, taskID, taskList, taskLookup} = this.props;
    if (!order.project) {
      dispatch(actions.updateDiff({project: value}, order));
    }
    const task = taskID
      ? taskLookup(instanceURL("task", taskID))
      : taskList.length === 1
        ? taskList[0]
        : null;
    if (task) {
      dispatch(actions.updateDiff({project: value}, task));
    }
  }

  changeCustomerCulture(customerUrl: CustomerUrl | null, cultureUrl: CultureUrl | null): void {
    const {currentUserURL, customerSettings, order, taskID} = this.props;
    if (!currentUserURL) {
      return;
    }
    if (taskID) {
      const {
        contactArray,
        create,
        customerLookup,
        orderLookup,
        taskArray,
        taskLookup,
        unitLookup,
        update,
      } = this.props;
      const task = taskLookup(instanceURL("task", taskID));
      if (!task) {
        return;
      }
      const orderID = taskChangeCustomerCulture(
        customerUrl,
        cultureUrl,
        {
          contactArray,
          create,
          customerLookup,
          orderLookup,
          task,
          taskArray,
          unitLookup,
          update,
        },
        customerSettings,
        currentUserURL,
      );
      if (orderID && orderID !== order.id) {
        this.handleTaskMovedToNewOrder(orderID, taskID);
      }
    } else {
      const {contactArray, customerLookup, taskList, unitLookup, update} = this.props;
      changeCustomerCulture(
        order,
        customerUrl,
        cultureUrl,
        {
          contactArray,
          customerLookup,
          taskList,
          unitLookup,
        },
        customerSettings,
        update,
      );
    }
  }

  changeContact(contactUrl: ContactUrl | null): void {
    const {currentUserURL, dispatch, order, taskID} = this.props;
    if (!currentUserURL) {
      return;
    }
    if (taskID) {
      const {create, orderLookup, taskArray, taskLookup, update} = this.props;
      const task = taskLookup(instanceURL("task", taskID));
      if (!task) {
        return;
      }
      const orderID = taskChangeContact(
        contactUrl,
        {
          create,
          orderLookup,
          task,
          taskArray,
          update,
        },
        currentUserURL,
      );
      if (orderID && orderID !== order.id) {
        this.handleTaskMovedToNewOrder(orderID, taskID);
      }
    } else {
      dispatch(actions.updateDiff({contact: contactUrl}, order));
    }
  }

  @bind
  handleCultureSelectButton(event: React.MouseEvent<unknown, MouseEvent>): void {
    event.stopPropagation();
    this.setState({cultureDialogOpen: true});
  }
  @bind
  handleCultureDialogOk(url: CultureUrl): void {
    this.setState({cultureDialogOpen: false});
    const {order} = this.props;
    const oldURL = order.culture;
    if (url !== oldURL) {
      this.changeCustomerCulture(null, url);
    }
    focusButton(this.selectCultureButton.current);
  }
  @bind
  handleCultureDialogCancel(): void {
    this.setState({cultureDialogOpen: false});
    focusButton(this.selectCultureButton.current);
  }
  @bind
  handleDeleteButton(_event: unknown): void {
    this.setState({
      deleteDialogOpen: true,
    });
  }
  @bind
  handleDeleteDialogCancel(): void {
    this.setState({
      deleteDialogOpen: false,
    });
  }
  @bind
  handleDeleteDialogOk(): void {
    this.setState({
      deleteDialogOpen: false,
    });
    const {order, taskList} = this.props;
    if (taskList.length <= 1) {
      if (taskList.length === 1) {
        const task = taskList[0];
        this.props.remove(task.url);
      }
      this.props.remove(order.url);
    }

    this.navigateBackAfterTimeout();
  }

  @bind
  handleDeleteTask(taskURL: string): void {
    const {remove, taskID} = this.props;
    remove(taskURL);

    if (taskID) {
      this.navigateBackAfterTimeout();
    }
  }

  navigateBackAfterTimeout(): void {
    window.setTimeout(() => {
      this.props.backSkip([
        "/order/:id",
        "/order/:id/:taskID",
        "/orderEntry/:id",
        "/task/:id",
        "/taskDetails/:id",
        "/taskEdit/:id",
        "/internalTask/:id",
      ]);
    });
  }

  @bind
  handleRequestDeliveryLocationDialog(task: Task, deliveryLocation: DeliveryLocation | null): void {
    let deliveryLocationDialog: {
      amount: number | null;
      areaHa: number | null;
      customer: Customer | null;
      location: Location | null;
      note: string;
      open: boolean;
      relatedUnit: Unit | null;
      task: Task | null;
      transportLog: TransportLog | null;
      unit: string;
      url: DeliveryLocationUrl | null;
    };
    const taskURL = task.url;
    const transportLog = this.props.transportLogArray.find((t) => t.task === taskURL);
    if (deliveryLocation) {
      deliveryLocationDialog = {
        amount: deliveryLocation.amount,
        areaHa: deliveryLocation.areaHa,
        customer:
          (deliveryLocation.customer && this.props.customerLookup(deliveryLocation.customer)) ||
          null,
        location:
          (deliveryLocation.relatedLocation &&
            this.props.locationLookup(deliveryLocation.relatedLocation)) ||
          null,
        note: deliveryLocation.note,
        open: true,
        relatedUnit:
          (deliveryLocation.relatedUnit && this.props.unitLookup(deliveryLocation.relatedUnit)) ||
          null,
        task: task || null,
        transportLog: transportLog || null,
        unit: deliveryLocation.unit,
        url: deliveryLocation.url,
      };
    } else {
      const customer = this.getCustomer(this.props.order);
      deliveryLocationDialog = {
        amount: null,
        areaHa: null,
        customer: customer || null,
        location: null,
        note: "",
        open: true,
        relatedUnit: null,
        task: task || null,
        transportLog: transportLog || null,
        unit: "",
        url: null,
      };
    }
    this.setState({deliveryLocationDialog});
  }
  @bind
  handleDeliveryLocationDialogOk(params: {
    amount: number | null;
    areaHa: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    relatedUnit: Unit | null;
    unit: string;
  }): void {
    const {amount, areaHa, customer, location, note, relatedUnit, unit} = params;
    const existingURL = this.state.deliveryLocationDialog.url;
    const {task, transportLog} = this.state.deliveryLocationDialog;
    const {dispatch} = this.props;
    if (!transportLog) {
      return;
    }
    let oldDeliveryLocation: Partial<DeliveryLocation> &
      Pick<DeliveryLocation, "order" | "transportlog" | "url">;
    if (existingURL) {
      const existing = this.props.deliveryLocationLookup(existingURL);
      if (!existing) {
        return;
      }
      oldDeliveryLocation = existing;
    } else {
      const id = uuid();
      const url = instanceURL("deliveryLocation", id);
      const transportLogURL = transportLog.url;
      const order = this.props.deliveryLocationArray.filter(
        (d) => d.transportlog === transportLogURL,
      ).length;
      oldDeliveryLocation = {
        id,
        order,
        transportlog: transportLogURL,
        url,
      };
    }
    const newDeliveryLocation: DeliveryLocation = {
      ...oldDeliveryLocation,
      amount,
      areaHa,
      customer: customer ? customer.url : null,
      note,
      relatedLocation: location ? location.url : null,
      relatedUnit: relatedUnit ? relatedUnit.url : null,
      task: task ? task.url : null,
      unit: relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit,
    };
    this.handleDeliveryLocationDialogCancel();
    if (existingURL) {
      dispatch(actions.updateDiff(newDeliveryLocation, oldDeliveryLocation));
    } else {
      this.props.create(newDeliveryLocation);
    }
  }
  @bind
  handleDeliveryLocationDialogCancel(): void {
    const deliveryLocationDialog = {
      amount: null,
      areaHa: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      relatedUnit: null,
      task: null,
      transportLog: null,
      unit: "",
      url: null,
    };
    this.setState({deliveryLocationDialog});
  }
  @bind
  handleRequestPickupLocationDialog(task: Task, pickupLocation: PickupLocation | null): void {
    let pickupLocationDialog: {
      amount: number | null;
      customer: Customer | null;
      location: Location | null;
      note: string;
      open: boolean;
      relatedUnit: Unit | null;
      task: Task | null;
      transportLog: TransportLog | null;
      unit: string;
      url: PickupLocationUrl | null;
    };
    const taskURL = task.url;
    const transportLog = this.props.transportLogArray.find((t) => t.task === taskURL);
    if (pickupLocation) {
      pickupLocationDialog = {
        amount: pickupLocation.amount,
        customer:
          (pickupLocation.customer && this.props.customerLookup(pickupLocation.customer)) || null,
        location:
          (pickupLocation.relatedLocation &&
            this.props.locationLookup(pickupLocation.relatedLocation)) ||
          null,
        note: pickupLocation.note,
        open: true,
        relatedUnit:
          (pickupLocation.relatedUnit && this.props.unitLookup(pickupLocation.relatedUnit)) || null,
        task,
        transportLog: transportLog || null,
        unit: pickupLocation.unit,
        url: pickupLocation.url,
      };
    } else {
      const customer = this.getCustomer(this.props.order);
      pickupLocationDialog = {
        amount: null,
        customer: customer || null,
        location: null,
        note: "",
        open: true,
        relatedUnit: null,
        task,
        transportLog: transportLog || null,
        unit: "",
        url: null,
      };
    }
    this.setState({pickupLocationDialog});
  }
  @bind
  handlePickupLocationDialogOk(params: {
    amount: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    relatedUnit: Unit | null;
    unit: string;
  }): void {
    const {amount, customer, location, note, relatedUnit, unit} = params;
    const existingURL = this.state.pickupLocationDialog.url;
    const {task, transportLog} = this.state.pickupLocationDialog;
    const {dispatch} = this.props;
    if (!transportLog) {
      return;
    }
    let oldPickupLocation: Partial<PickupLocation> &
      Pick<PickupLocation, "order" | "transportlog" | "url">;
    if (existingURL) {
      const existing = this.props.pickupLocationLookup(existingURL);
      if (!existing) {
        return;
      }
      oldPickupLocation = existing;
    } else {
      const id = uuid();
      const url = instanceURL("pickupLocation", id);
      const transportLogURL = transportLog.url;
      const order = this.props.pickupLocationArray.filter(
        (d) => d.transportlog === transportLogURL,
      ).length;
      oldPickupLocation = {
        id,
        order,
        transportlog: transportLogURL,
        url,
      };
    }
    const newPickupLocation: PickupLocation = {
      ...oldPickupLocation,
      amount,
      customer: customer ? customer.url : null,
      note,
      relatedLocation: location ? location.url : null,
      relatedUnit: relatedUnit ? relatedUnit.url : null,
      task: task ? task.url : null,
      unit: relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit,
    };
    this.handlePickupLocationDialogCancel();
    if (existingURL) {
      dispatch(actions.updateDiff(newPickupLocation, oldPickupLocation as PickupLocation));
    } else {
      this.props.create(newPickupLocation);
    }
  }
  @bind
  handlePickupLocationDialogCancel(): void {
    const pickupLocationDialog = {
      address: "",
      amount: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      relatedUnit: null,
      task: null,
      transportLog: null,
      unit: "",
      url: null,
      workplace: null,
    };
    this.setState({pickupLocationDialog});
  }
  @bind
  handleRequestEditTransportLogDialog(
    task: Task,
    transportLog: TransportLog | null,
    ref: HTMLButtonElement | null,
  ): void {
    const {customerSettings} = this.props;
    if (!transportLog && customerSettings.dontShowTransportLogDialog) {
      const id = urlToId(task.url);
      const logURL = instanceURL("transportLog", id);
      const params: TransportLog = {
        ...emptyTransportLog,
        id,
        task: task.url,
        url: logURL,
      };
      this.props.create(params);
    } else {
      this.setState({
        editTransportLogButton: ref,
        editTransportLogDialogTask: task,
        editTransportLogDialogTransportLog: transportLog,
      });
    }
  }
  @bind
  handleTransportLogDialogCancel(): void {
    focusButton(this.state.editTransportLogButton);
    this.setState({
      editTransportLogButton: null,
      editTransportLogDialogTask: null,
      editTransportLogDialogTransportLog: null,
    });
  }
  @bind
  handleTransportLogDialogOk({
    amountPerTrip,
    haRequired,
    kmRequired,
    relatedUnit,
    unit,
  }: {
    amountPerTrip: number | null;
    haRequired: boolean;
    kmRequired: boolean;
    relatedUnit: Unit | null;
    unit: string;
  }): void {
    focusButton(this.state.editTransportLogButton);
    const task = this.state.editTransportLogDialogTask;
    const {dispatch} = this.props;
    const oldTransportLog = this.state.editTransportLogDialogTransportLog;
    this.setState({
      editTransportLogButton: null,
      editTransportLogDialogTask: null,
      editTransportLogDialogTransportLog: null,
    });
    if (!task) {
      return;
    }
    const taskURL = task.url;
    const id = oldTransportLog ? urlToId(oldTransportLog.url) : urlToId(taskURL);
    const url = instanceURL("transportLog", id);

    const newTransportLog: TransportLog = {
      amountPerTrip,
      haRequired,
      kmRequired,
      relatedUnit: relatedUnit ? relatedUnit.url : null,
      task: taskURL,
      unit: relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit,
      url,
    };
    if (!oldTransportLog) {
      this.props.create(newTransportLog);
    } else {
      dispatch(actions.updateDiff(newTransportLog, oldTransportLog));
    }
  }
  @bind
  handleTaskWorkTypeSelect(url: WorkTypeUrl): void {
    const onlyOneTask = this.props.taskList.length === 1;
    if (onlyOneTask) {
      const {order} = this.props;
      this.props.update(order.url, [{member: "workType", value: url}]);
    }
  }
  @bind
  getCustomer(order?: Order): Customer | undefined {
    const customerURL = order ? order.customer : null;
    return customerURL ? this.props.customerLookup(customerURL) : undefined;
  }
  @bind
  handleNotesChange(value: string): void {
    const {order, update} = this.props;
    update(order.url, [{member: "notes", value}]);
  }

  @bind
  handleReferenceNumberChange(value: string): void {
    const {order, update} = this.props;
    update(order.url, [{member: "referenceNumber", value}]);
  }

  @bind
  handleDateChange(value: string | null): void {
    const {order, update} = this.props;
    update(order.url, [{member: "date", value}]);
  }
  @bind
  handleTimeChange(value: string | null): void {
    const {order, update} = this.props;
    update(order.url, [{member: "time", value}]);
  }

  selectProjectButton = React.createRef<HTMLButtonElement>();
  selectCustomerButton = React.createRef<HTMLButtonElement>();
  selectWorkplaceButton = React.createRef<HTMLButtonElement>();
  selectContactButton = React.createRef<HTMLButtonElement>();
  selectCultureButton = React.createRef<HTMLButtonElement>();

  @bind
  handleTaskMovedToNewOrder(orderId: string, taskId: string): void {
    this.props.go("/order/:id/:taskID", {id: orderId, taskID: taskId}, {}, "REPLACE");
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {currentRole, currentUserURL, customerSettings, order, taskID, taskList} = this.props;

    const {
      contact: contactSettings,
      locations: locationSettings,
      projects: projectSettings,
    } = customerSettings;

    const userIsOther = order.createdBy !== currentUserURL;
    const userIsOnlyMachineOperator = !!(currentRole && !currentRole.manager);
    const userIsSeniorMachineOperator = !!(currentRole && currentRole.seniorMachineOperator);
    const userIsOtherMachineOperator = userIsOther && userIsOnlyMachineOperator;
    const anyCompleted = taskList.some((task) => task.completed);
    const anyRecorded = taskList.some((task) => task.validatedAndRecorded);
    let orderIsPossibleToDelete = false;
    if (taskList.length === 1) {
      const task = taskList[0];
      if (!task.completed) {
        const taskURL = task.url;
        orderIsPossibleToDelete = !this.props.timerStartArray.some(
          (t) => !!t.timer && t.task === taskURL,
        );
      }
    } else if (taskList.length === 0) {
      orderIsPossibleToDelete = true;
    }
    const userIsManager = !!(currentRole && currentRole.manager);
    const userIsMachineOperator = !!(currentRole && currentRole.machineOperator);
    const orderIsCreatedByUser = order.createdBy === currentUserURL;
    const userHasDeletePermission =
      userIsManager || (userIsMachineOperator && orderIsCreatedByUser);
    const canDeleteOrder = orderIsPossibleToDelete && userHasDeletePermission;

    const onlyOneTask = taskList.length === 1;

    const userURL = order.createdBy || currentUserURL;
    const userProfile = userURL && this.props.userUserProfileLookup(userURL);
    const userInitials = userProfile ? userProfile.alias : "";
    const customerData = this.getCustomer(order);
    const customerURL = customerData ? customerData.url : null;
    const cultureURL = order.culture;
    const contactURL = order.contact;

    const taskInstances = taskList.map((task) => {
      const {url} = task;
      if (taskID && task.id !== taskID) {
        return null;
      }
      return (
        <div key={url} style={{margin: "0 -11px 22px"}}>
          <div style={{padding: "0 11px"}}>
            <TaskInstance
              ref={(c) => this._taskInstances.set(url, c)}
              createdBy={currentUserURL || undefined}
              culture={cultureURL || undefined}
              customer={customerURL || undefined}
              onlyOneTask={onlyOneTask}
              order={order}
              task={task}
              onCopy={this.handleTaskCopy}
              onDeleteTask={this.handleDeleteTask}
              onRequestDeleteDialog={this.handleRequestDeleteDialog}
              onRequestDeliveryLocationDialog={this.handleRequestDeliveryLocationDialog}
              onRequestEditTransportLogDialog={this.handleRequestEditTransportLogDialog}
              onRequestMachineOperatorDialog={this.handleRequestMachineOperatorDialog}
              onRequestPickupLocationDialog={this.handleRequestPickupLocationDialog}
              onRequestValidatedDialog={this.handleRequestValidatedDialog}
              onTaskMovedToNewOrder={this.handleTaskMovedToNewOrder}
              onWorkTypeSelect={this.handleTaskWorkTypeSelect}
            />
          </div>
        </div>
      );
    });
    let orderDateTimeBlock;
    if (
      !userIsOnlyMachineOperator &&
      (customerSettings.taskOrderShowDate || customerSettings.taskOrderShowTime)
    ) {
      let orderDesiredDateCell;
      if (customerSettings.taskOrderShowDate && (order.date || taskList.length > 1)) {
        orderDesiredDateCell = (
          <Cell palm="12/12">
            <DateField
              autoOk
              fullWidth
              disabled={
                userIsOtherMachineOperator ||
                (userIsOnlyMachineOperator && anyCompleted) ||
                anyRecorded
              }
              label={formatMessage(messages.date)}
              margin="dense"
              value={order.date}
              onChange={this.handleDateChange}
            />
          </Cell>
        );
      }
      let orderDesiredTimeCell;
      if (customerSettings.taskOrderShowTime && (order.time || taskList.length > 1)) {
        orderDesiredTimeCell = (
          <Cell palm="12/12">
            <TimeField
              fullWidth
              disabled={
                userIsOtherMachineOperator ||
                (userIsOnlyMachineOperator && anyCompleted) ||
                anyRecorded
              }
              label={formatMessage(messages.desiredTime)}
              margin="dense"
              value={order.time || undefined}
              onChange={this.handleTimeChange}
            />
          </Cell>
        );
      }
      orderDateTimeBlock = (
        <Grid>
          {orderDesiredDateCell}
          {orderDesiredTimeCell}
        </Grid>
      );
    }

    let notesBlock;
    if (customerSettings.enableOrders && (order.notes || taskList.length > 1)) {
      if (!userIsOnlyMachineOperator && !order.remoteUrl) {
        notesBlock = (
          <div>
            <TrimTextField
              fullWidth
              multiline
              disabled={
                userIsOnlyMachineOperator ||
                (anyRecorded && !customerSettings.alwaysAllowManagerTaskAndOrderNotesEdit)
              }
              label={formatMessage(messages.notes)}
              margin="dense"
              maxRows={30}
              minRows={2}
              value={order.notes || ""}
              variant="outlined"
              onChange={this.handleNotesChange}
            />
          </div>
        );
      } else if (order.notes) {
        notesBlock = (
          <div>
            <FormattedMessage
              defaultMessage="Noter fra administration:"
              id="order-instance.header.notes-from-administration"
              tagName="h4"
            />
            <div>
              <Linkify>{order.notes}</Linkify>
            </div>
          </div>
        );
      }
    }
    let referenceNumberBlock: JSX.Element | undefined;
    if (customerSettings.enableOrderReferenceNumber) {
      referenceNumberBlock = (
        <TrimTextField
          disabled={anyRecorded || !customerSettings.allowOrderReferenceNumberEdit}
          inputProps={{maxLength: 255}}
          label={
            customerSettings.orderReferenceNumberLabel || formatMessage(messages.referenceNumber)
          }
          margin="dense"
          value={order.referenceNumber || ""}
          onChange={this.handleReferenceNumberChange}
        />
      );
    }
    let brugerdataReference: JSX.Element | undefined;
    if (customerSettings.showBrugerdataOrdernumber) {
      brugerdataReference = (
        <>
          <FormattedMessage
            defaultMessage="Brugerdata-ordre: {number}"
            id="order-instance.label.brugerdata-order"
            values={{number: order.remoteUrl}}
          />
          {userIsOnlyMachineOperator ? null : <RemoveOrderBDReference order={order} />}
          {customerSettings.brugerdataCreateOrderButton ? (
            <AddOrderBDReference order={order} />
          ) : null}
        </>
      );
    }
    let task;
    if (this.props.taskID) {
      task = this.props.taskLookup(instanceURL("task", this.props.taskID));
    } else if (this.props.taskList.length === 1) {
      task = this.props.taskList[0];
    }
    const userIsJobber = currentRole && currentRole.jobber;
    let customerBlock: JSX.Element | undefined;

    if (task && customerSettings.externalTaskCustomer) {
      const customerHasContacts =
        customerURL && this.props.contactArray.some((c) => c.active && c.customer === customerURL);
      const contactSelectDisabled =
        !customerURL ||
        (!contactSettings.canManage && !customerHasContacts) ||
        (userIsOtherMachineOperator && !userIsSeniorMachineOperator) ||
        (userIsOnlyMachineOperator && task.completed) ||
        task.validatedAndRecorded;
      const contactClearDisabled =
        !customerURL ||
        !contactURL ||
        (userIsOtherMachineOperator && !userIsSeniorMachineOperator) ||
        (userIsOnlyMachineOperator && task.completed) ||
        task.validatedAndRecorded;
      const hideContactButtons = !(
        !userIsJobber &&
        !(userIsOnlyMachineOperator && !customerSettings.machineOperatorCanCreateCustomerTask)
      );

      const customerSelectDisabled =
        (userIsOtherMachineOperator && !userIsSeniorMachineOperator) ||
        (userIsOnlyMachineOperator && task?.completed) ||
        task.validatedAndRecorded ||
        task.reportApproved;
      const hideCustomerButtons = !(
        customerSettings.enableCustomerSwitch &&
        !userIsJobber &&
        !(userIsOnlyMachineOperator && !customerSettings.machineOperatorCanCreateCustomerTask)
      );

      customerBlock = (
        <Grid>
          <Cell palm="12/12">
            <CustomerBlock
              ref={this.selectCustomerButton}
              clearDisabled={customerSelectDisabled || !customerURL}
              customerUrl={customerURL}
              hideButtons={hideCustomerButtons}
              selectDisabled={customerSelectDisabled}
              onCustomerChanged={this.handleCustomerChanged}
            />
          </Cell>
          {customerSettings.enableCustomerContacts && customerSettings.taskOrderShowContact && (
            <Cell palm="12/12">
              <ContactBlock
                ref={this.selectContactButton}
                clearDisabled={contactClearDisabled}
                contactUrl={contactURL}
                customerUrl={customerURL}
                hideButtons={hideContactButtons}
                selectDisabled={contactSelectDisabled}
                onContactChanged={this.handleContactChanged}
              />
            </Cell>
          )}
        </Grid>
      );
    }
    /* It only makes sense to choose a project when adding og editing a task.
    When adding a new task, theres only one task on the order. When editing taskID is set. */
    let projectSelectionCell;
    let workplaceOrAddressSelectonCell;
    if (task) {
      if (
        customerSettings.enableProjects &&
        (!userIsOnlyMachineOperator || customerSettings.machineOperatorsCanChooseProject)
      ) {
        const {currentUserSelectableProjectArray} = this.props;
        const enabledForDepartment =
          customerSettings.onlyEnableProjectsForDepartments.length === 0 ||
          customerSettings.onlyEnableProjectsForDepartments.includes(task.department);
        const noProjects = customerURL
          ? !currentUserSelectableProjectArray.some(({customer}) => customer === customerURL)
          : !currentUserSelectableProjectArray.length;
        const projectValidated = task.validatedAndRecorded || task.reportApproved;
        const projectSelectDisabled =
          projectValidated || (noProjects && !projectSettings.canCreate) || !enabledForDepartment;
        projectSelectionCell = (
          <Cell palm="12/12">
            <ProjectBlock
              ref={this.selectProjectButton}
              clearDisabled={projectSelectDisabled || !order.project}
              customerUrl={customerURL}
              hideButtons={
                (currentRole && !currentRole.manager) ||
                !customerSettings.machineOperatorsCanChooseProject
              }
              projectUrl={task.project}
              selectDisabled={projectSelectDisabled}
              onCustomerChanged={this.handleCustomerChanged}
              onProjectChanged={this.handleProjectChanged}
            />
          </Cell>
        );
      }
      if (
        customerSettings.taskOrderTaskShowWorkPlace &&
        customerSettings.workplaceButtonUnderCustomer
      ) {
        const hasSelectableWorkPlaces = locationSettings.canChangeWorkplace
          ? this.props.locationArray.some(
              (location) => location.customer === customerURL && location.active,
            )
          : this.props.locationArray.some(
              (location) =>
                location.remoteUrl && location.customer === customerURL && location.active,
            );

        let lastUsedLocations: Set<LocationUrl> | undefined;
        if (customerURL && userURL) {
          const locationUse = this.props.locationUseLogArray.find(
            (l) => l.customer === customerURL && l.user === userURL,
          );
          lastUsedLocations = new Set(locationUse?.locations);
        }

        const isEditAllowed =
          (hasSelectableWorkPlaces || locationSettings.canChangeWorkplace) &&
          !task.validatedAndRecorded &&
          (userIsSeniorMachineOperator ||
            userIsManager ||
            (!!task.machineOperator && task.machineOperator === currentUserURL && !task.completed));
        workplaceOrAddressSelectonCell = (
          <Cell palm="12/12">
            {customerSettings.taskPickupDelivery ? (
              <>
                <LocationBlock
                  hideFieldLocations
                  includeWorkplaceOnlyLocations
                  clearDisabled={!isEditAllowed || !task.relatedPickupLocation}
                  customerLookup={this.props.customerLookup}
                  customerUrl={customerURL}
                  includeLogOnlyLocations={false}
                  lastUsedLocations={lastUsedLocations}
                  locationUrl={task.relatedPickupLocation}
                  selectDisabled={!customerURL || !isEditAllowed}
                  title={formatMessage({defaultMessage: "Afhentningssted"})}
                  titleVariant="WORKPLACE"
                  onLocationChanged={this.handleRelatedPickupLocationChanged}
                />
                <LocationBlock
                  ref={this.selectWorkplaceButton}
                  hideFieldLocations
                  includeWorkplaceOnlyLocations
                  clearDisabled={!isEditAllowed || !task.relatedWorkplace}
                  customerLookup={this.props.customerLookup}
                  customerUrl={customerURL}
                  includeLogOnlyLocations={false}
                  lastUsedLocations={lastUsedLocations}
                  locationUrl={task.relatedWorkplace}
                  selectDisabled={!customerURL || !isEditAllowed}
                  title={formatMessage({defaultMessage: "Arbejds-/leveringssted"})}
                  titleVariant="WORKPLACE"
                  onLocationChanged={this.handleRelatedWorkplaceChanged}
                />
              </>
            ) : (
              <LocationBlock
                ref={this.selectWorkplaceButton}
                hideFieldLocations
                includeWorkplaceOnlyLocations
                clearDisabled={!isEditAllowed || !task.relatedWorkplace}
                customerLookup={this.props.customerLookup}
                customerUrl={customerURL}
                includeLogOnlyLocations={false}
                lastUsedLocations={lastUsedLocations}
                locationUrl={task.relatedWorkplace}
                selectDisabled={!customerURL || !isEditAllowed}
                title={formatMessage({defaultMessage: "Arbejdssted"})}
                titleVariant="WORKPLACE"
                onLocationChanged={this.handleRelatedWorkplaceChanged}
              />
            )}
          </Cell>
        );
      }
    }
    let projectWorkplaceAddressBlock;
    if (projectSelectionCell || workplaceOrAddressSelectonCell) {
      projectWorkplaceAddressBlock = (
        <Grid hAlign="left">
          {projectSelectionCell}
          {workplaceOrAddressSelectonCell}
        </Grid>
      );
    }
    let cultureBlock;
    if (customerSettings.externalTaskCulture) {
      let selectedCultureBlock;
      if (cultureURL) {
        const culture = this.props.cultureLookup(cultureURL);
        if (culture) {
          selectedCultureBlock = <div>{culture.name}</div>;
        }
      }
      cultureBlock = (
        <Grid>
          <Cell palm="12/12">
            <FormattedMessage
              defaultMessage="Kultur"
              id="order-instance.header.culture"
              tagName="h4"
            />
            <Button
              ref={this.selectCultureButton}
              color="secondary"
              disabled={
                (userIsOtherMachineOperator && !userIsSeniorMachineOperator) ||
                (userIsOnlyMachineOperator && anyCompleted) ||
                anyRecorded
              }
              variant="contained"
              onClick={this.handleCultureSelectButton}
            >
              {formatMessage(messages.selectCultureButton)}
            </Button>
            {selectedCultureBlock}
          </Cell>
        </Grid>
      );
    }

    const dialogs = (
      <>
        <ConnectedCultureDialog
          open={this.state.cultureDialogOpen}
          onCancel={this.handleCultureDialogCancel}
          onOk={this.handleCultureDialogOk}
        />
        <DeleteDialog
          open={this.state.deleteDialogOpen}
          onCancel={this.handleDeleteDialogCancel}
          onOk={this.handleDeleteDialogOk}
        >
          <FormattedMessage
            defaultMessage="Slet opgave/ordre?"
            id="order-instance.label.do-delete-order"
          />
        </DeleteDialog>
        <ConnectedMachineOperatorDialog
          open={!!this.state.machineOperatorDialogCallback}
          onCancel={this.handleMachineOperatorDialogCancel}
          onOk={this.handleMachineOperatorDialogOk}
        />
        <DeleteDialog
          open={!!this.state.taskDeleteDialogCallback}
          onCancel={this.handleTaskDeleteDialogCancel}
          onOk={this.handleTaskDeleteDialogOk}
        >
          <FormattedMessage
            defaultMessage="Slet opgave?"
            id="order-instance.label.do-delete-task"
          />
        </DeleteDialog>
        <TransportLogDialog
          amountPerTrip={
            this.state.editTransportLogDialogTransportLog &&
            this.state.editTransportLogDialogTransportLog.amountPerTrip != null
              ? this.state.editTransportLogDialogTransportLog.amountPerTrip
              : undefined
          }
          haRequired={
            this.state.editTransportLogDialogTransportLog
              ? this.state.editTransportLogDialogTransportLog.haRequired
              : undefined
          }
          kmRequired={
            this.state.editTransportLogDialogTransportLog
              ? this.state.editTransportLogDialogTransportLog.kmRequired
              : undefined
          }
          open={!!this.state.editTransportLogDialogTask}
          relatedUnit={
            this.state.editTransportLogDialogTransportLog &&
            this.state.editTransportLogDialogTransportLog.relatedUnit
              ? this.props.unitLookup(this.state.editTransportLogDialogTransportLog.relatedUnit)
              : undefined
          }
          unit={
            this.state.editTransportLogDialogTransportLog
              ? this.state.editTransportLogDialogTransportLog.unit
              : undefined
          }
          onCancel={this.handleTransportLogDialogCancel}
          onOk={this.handleTransportLogDialogOk}
        />
        <DeliveryLocationDialog
          amount={
            this.state.deliveryLocationDialog.amount != null
              ? this.state.deliveryLocationDialog.amount
              : undefined
          }
          areaHa={
            this.state.deliveryLocationDialog.areaHa != null
              ? this.state.deliveryLocationDialog.areaHa
              : undefined
          }
          customer={this.state.deliveryLocationDialog.customer || undefined}
          customerSettings={this.props.customerSettings}
          haRequired={
            this.state.deliveryLocationDialog.transportLog
              ? this.state.deliveryLocationDialog.transportLog.haRequired
              : undefined
          }
          isNew={!this.state.deliveryLocationDialog.url}
          location={this.state.deliveryLocationDialog.location || undefined}
          note={this.state.deliveryLocationDialog.note}
          open={this.state.deliveryLocationDialog.open}
          relatedUnit={
            (this.state.deliveryLocationDialog.transportLog &&
              this.state.deliveryLocationDialog.transportLog.relatedUnit &&
              this.props.unitLookup(this.state.deliveryLocationDialog.transportLog.relatedUnit)) ||
            this.state.deliveryLocationDialog.relatedUnit ||
            undefined
          }
          unit={
            this.state.deliveryLocationDialog.transportLog
              ? this.state.deliveryLocationDialog.transportLog.unit ||
                this.state.deliveryLocationDialog.unit
              : undefined
          }
          onCancel={this.handleDeliveryLocationDialogCancel}
          onCustomerChanged={this.handleDeliveryLocationDialogCancel}
          onOk={this.handleDeliveryLocationDialogOk}
        />
        <PickupLocationDialog
          amount={
            this.state.pickupLocationDialog.amount != null
              ? this.state.pickupLocationDialog.amount
              : undefined
          }
          customer={this.state.pickupLocationDialog.customer || undefined}
          isNew={!this.state.pickupLocationDialog.url}
          location={this.state.pickupLocationDialog.location || undefined}
          note={this.state.pickupLocationDialog.note}
          open={this.state.pickupLocationDialog.open}
          relatedUnit={
            (this.state.pickupLocationDialog.transportLog &&
              this.state.pickupLocationDialog.transportLog.relatedUnit &&
              this.props.unitLookup(this.state.pickupLocationDialog.transportLog.relatedUnit)) ||
            this.state.pickupLocationDialog.relatedUnit ||
            undefined
          }
          unit={
            this.state.pickupLocationDialog.transportLog
              ? this.state.pickupLocationDialog.transportLog.unit ||
                this.state.pickupLocationDialog.unit
              : undefined
          }
          onCancel={this.handlePickupLocationDialogCancel}
          onCustomerChanged={this.handleCustomerChanged}
          onOk={this.handlePickupLocationDialogOk}
        />
        <ValidatedDialog
          customerSettings={this.props.customerSettings}
          machineLookup={this.props.machineLookup}
          open={!!this.state.validatedDialogCallback}
          priceGroupLookup={this.props.priceGroupLookup}
          task={this.state.validatedDialogTask || undefined}
          timerArray={this.props.timerArray}
          timerLookup={this.props.timerLookup}
          timerStartArray={this.props.timerStartArray}
          workTypeLookup={this.props.workTypeLookup}
          onCancel={this.handleValidatedDialogCancel}
          onOk={this.handleValidatedDialogOk}
        />
        <CopiedDialog
          customer={customerData}
          machineList={this.state.copiedTaskMachineList || []}
          open={this.state.copiedDialogOpen && !this.props.notificationDialogOpen}
          workType={this.state.copiedTaskWorkType || undefined}
          onRequestClose={this.handleCopiedDialogClose}
        />
      </>
    );

    return (
      <PageLayout withPadding dialogs={dialogs} toolbar={formatMessage(messages.title)}>
        <Grid>
          <Cell palm="12/12">
            <Card style={{width: "100%"}}>
              <CardContent>
                <h5 style={{marginBottom: 24}}>Oprettet af: {userInitials}</h5>
                {referenceNumberBlock}
                {brugerdataReference}
                {cultureBlock}
                {customerSettings.switchProjectAndCustomerButtonLocation ? (
                  <>
                    {projectWorkplaceAddressBlock}
                    {customerBlock}
                  </>
                ) : (
                  <>
                    {customerBlock}
                    {projectWorkplaceAddressBlock}
                  </>
                )}
                {orderDateTimeBlock}
                {notesBlock}
              </CardContent>
            </Card>
          </Cell>
        </Grid>
        {customerSettings.workplaceButtonUnderCustomer &&
        customerURL &&
        task &&
        (task.fielduseSet?.length ||
          this.props.locationArray.some(
            (field) => field.active && field.geojson && field.customer === customerURL,
          )) ? (
          <Grid>
            <Cell palm="12/12" style={{paddingBottom: 8}}>
              <TaskFields defaultEditMode order={order} showFieldMap={false} task={task} />
            </Cell>
          </Grid>
        ) : null}
        {taskInstances}
        <Grid>
          <Cell palm="12/12" style={{paddingBottom: 8}}>
            <Button color="secondary" variant="contained" onClick={this.handleSave}>
              {formatMessage(messages.start)}
            </Button>
          </Cell>
          <Cell palm="12/12" style={{paddingBottom: 8}}>
            <ErrorColorButton
              disabled={!canDeleteOrder}
              variant="contained"
              onClick={this.handleDeleteButton}
            >
              {formatMessage(messages.deleteOrder)}
            </ErrorColorButton>
          </Cell>
        </Grid>
      </PageLayout>
    );
  }
}

interface OrderInstanceContainerStateProps {
  contactArray: readonly Contact[];
  contactLookup: (url: ContactUrl) => Contact | undefined;
  cultureLookup: (url: CultureUrl) => Culture | undefined;
  currentRole: Role | null;
  currentUserSelectableProjectArray: readonly Project[];
  currentUserURL: UserUrl | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: ExtendedConfig;
  deliveryLocationArray: readonly DeliveryLocation[];
  deliveryLocationLookup: (url: DeliveryLocationUrl) => DeliveryLocation | undefined;
  deviceMachine: any;
  locationArray: readonly Location[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  locationUseLogArray: readonly LocationUseLog[];
  machineLookup: (url: MachineUrl) => Machine | undefined;
  notificationDialogOpen: boolean;
  orderLookup: (url: OrderUrl) => Order | undefined;
  pickupLocationArray: readonly PickupLocation[];
  pickupLocationLookup: (url: PickupLocationUrl) => PickupLocation | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  productUseLogArray: readonly ProductUseLog[];
  projectLookup: (url: ProjectUrl) => Project | undefined;
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined;
  sprayLocationArray: readonly SprayLocation[];
  sprayLogArray: readonly SprayLog[];
  taskArray: readonly Task[];
  taskID: string | null;
  taskLookup: (url: TaskUrl) => Task | undefined;
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerStartArray: readonly TimerStart[];
  transportLogArray: readonly TransportLog[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  yieldDeliveryLocationArray: readonly YieldDeliveryLocation[];
  yieldLogArray: readonly YieldLog[];
  yieldPickupLocationArray: readonly YieldPickupLocation[];
}

interface OrderInstanceContainerDispatchProps {
  back: (count?: number, fallback?: PathTemplate) => void;
  backSkip: (skip: PathTemplate[], fallback?: PathTemplate) => void;
  create: (instance: ResourceTypeUnion) => void;
  createOrUpdate: (instance: ResourceTypeUnion) => void;
  dispatch: Dispatch;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  remove: (url: string) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface OrderInstanceContainerOwnProps {
  instance: Order;
}

type OrderInstanceContainerProps = OrderInstanceContainerDispatchProps &
  OrderInstanceContainerOwnProps &
  OrderInstanceContainerStateProps;

class OrderInstanceContainer extends PureComponent<OrderInstanceContainerProps> {
  render(): JSX.Element {
    const {taskArray} = this.props;
    const order = this.props.instance;
    const orderURL = order.url;
    const baseTaskList = taskArray.filter((task) => task.order === orderURL);
    const taskList = _.sortBy(baseTaskList, [(task) => task.created, (task) => task.id]);
    return <OrderInstance order={order} taskList={taskList} {...this.props} />;
  }
}

const ConnectedOrderInstanceContainer = connect<
  OrderInstanceContainerStateProps,
  OrderInstanceContainerDispatchProps,
  OrderInstanceContainerOwnProps,
  AppState
>(
  createStructuredSelector<AppState, OrderInstanceContainerStateProps>({
    contactArray: getContactArray,
    contactLookup: getContactLookup,
    cultureLookup: getCultureLookup,
    currentRole: getCurrentRole,
    currentUserSelectableProjectArray: getCurrentUserSelectableProjectArray,
    currentUserURL: getCurrentUserURL,
    customerLookup: getCustomerLookup,
    customerSettings: getExtendedCustomerSettings,
    deliveryLocationArray: getDeliveryLocationArray,
    deliveryLocationLookup: getDeliveryLocationLookup,
    deviceMachine: getDeviceConfigKey("machine"),
    locationArray: getLocationArray,
    locationLookup: getLocationLookup,
    locationUseLogArray: getLocationUseLogArray,
    machineLookup: getMachineLookup,
    notificationDialogOpen: getNotificationDialogOpen,
    orderLookup: getOrderLookup,
    pickupLocationArray: getPickupLocationArray,
    pickupLocationLookup: getPickupLocationLookup,
    priceGroupLookup: getPriceGroupLookup,
    priceItemLookup: getPriceItemLookup,
    productLookup: getProductLookup,
    productUseLogArray: getProductUseLogArray,
    projectLookup: getProjectLookup,
    reportingSpecificationLookup: getReportingSpecificationLookup,
    sprayLocationArray: getSprayLocationArray,
    sprayLogArray: getSprayLogArray,
    taskArray: getTaskArray,
    taskID: makeOptionalPathParameterGetter("taskID"),
    taskLookup: getTaskLookup,
    timerArray: getTimerArray,
    timerLookup: getTimerLookup,
    timerStartArray: getTimerStartArray,
    transportLogArray: getTransportLogArray,
    unitLookup: getUnitLookup,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeLookup: getWorkTypeLookup,
    yieldDeliveryLocationArray: getYieldDeliveryLocationArray,
    yieldLogArray: getYieldLogArray,
    yieldPickupLocationArray: getYieldPickupLocationArray,
  }),
  (dispatch) => ({
    dispatch,
    ...bindActionCreators(
      {
        back: actions.back,
        backSkip: actions.backSkip,
        create: actions.create,
        createOrUpdate: actions.createOrUpdate,
        go: actions.go,
        remove: actions.remove,
        update: actions.update,
      },
      dispatch,
    ),
  }),
)(OrderInstanceContainer);

const related: LoadInstanceRelated = [
  {memberName: "order", resourceType: "task", type: "hasForeignKey"},
  {
    memberName: "customer",
    resourceType: "customer",
    type: "targetOfForeignKey",
  },
] as const;

function LoadOrderInstance(): JSX.Element {
  const {formatMessage} = useIntl();
  return (
    <DoLoadInstance
      Component={ConnectedOrderInstanceContainer}
      loadingTitle={formatMessage(messages.title)}
      lookupSelector={getOrderLookup}
      related={related}
      resourceName="order"
    />
  );
}

export default LoadOrderInstance;
