import {Role, User, UserProfile, UserUrl} from "@co-common-libs/resources";
import {MultipleUsersDialog, UserDialog} from "@co-frontend-libs/components";
import {
  AppState,
  getUserArray,
  getUserRoleLookup,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface UserDialogStateProps {
  labelVariant: "CHAUFFEUR" | "EMPLOYEE" | "MACHINEOPERATOR";
  userArray: readonly User[];
  userRoleLookup: (url: UserUrl) => Role | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface UserDialogOwnProps {
  onCancel(): void;
  onOk(url: UserUrl): void;
  open: boolean;
  title?: JSX.Element | string;
}

export const ConnectedUserDialog: React.ComponentType<UserDialogOwnProps> = connect<
  UserDialogStateProps,
  object,
  UserDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, UserDialogStateProps>({
    labelVariant: (_state: AppState) => "EMPLOYEE",
    userArray: getUserArray,
    userRoleLookup: getUserRoleLookup,
    userUserProfileLookup: getUserUserProfileLookup,
  }),
  {},
)(UserDialog);

interface MultipleUsersDialogStateProps {
  userArray: readonly User[];
  userRoleLookup: (url: UserUrl) => Role | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface MultipleUsersDialogOwnProps {
  onCancel(): void;
  onOk(urls: ReadonlySet<UserUrl>): void;
  open: boolean;
  selected?: ReadonlySet<UserUrl> | undefined;
}

export const ConnectedMultipleUsersDialog: React.ComponentType<MultipleUsersDialogOwnProps> =
  connect<MultipleUsersDialogStateProps, object, MultipleUsersDialogOwnProps, AppState>(
    createStructuredSelector<AppState, MultipleUsersDialogStateProps>({
      userArray: getUserArray,
      userRoleLookup: getUserRoleLookup,
      userUserProfileLookup: getUserUserProfileLookup,
    }),
    {},
  )(MultipleUsersDialog);
