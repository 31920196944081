import {UserUrl, urlToId} from "@co-common-libs/resources";
import {makeQuery} from "@co-frontend-libs/db-resources";
import {
  actions,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerSettings,
  getPathName,
  getUserLookup,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, CircularProgress, Grid} from "@material-ui/core";
import {RequestGPSPermissionDialog} from "app-components";
import {useDeviceConfig} from "app-utils";
import bowser from "bowser";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {AnniversaryCard} from "./anniversary-card";
import {ICECard} from "./ice-card";
import {ProfileCard} from "./profile-card";

const TEMPORARY_QUERIES_KEY = "ProfileData";

export const ProfileTab = React.memo(function ProfileTab({
  userUrl,
}: {
  userUrl: UserUrl;
}): JSX.Element | null {
  const padding = bowser.mobile || bowser.tablet ? "1em 11px 1em 11px" : "1em";
  const userLookup = useSelector(getUserLookup);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const currentRole = useSelector(getCurrentRole);
  const currentUserUrl = useSelector(getCurrentUserURL);
  const customerSettings = useSelector(getCustomerSettings);
  const userIsManager = !!(currentRole && currentRole.manager && !currentRole.projectManager);
  const userIsProjectManager = currentRole && currentRole?.projectManager;
  const userIsOther = userUrl !== currentUserUrl;
  const user = userLookup(userUrl);
  const userProfile = userUserProfileLookup(userUrl);

  const dispatch = useDispatch();

  const pathName = useSelector(getPathName);

  useEffect(() => {
    const userID = urlToId(userUrl);
    dispatch(
      actions.temporaryQueriesRequestedForPath(
        [
          makeQuery({
            check: {
              memberName: "url",
              type: "memberEq",
              value: userUrl,
            },
            independentFetch: true,
            instance: userID,
            resourceName: "user",
          }),
          makeQuery({
            check: {
              memberName: "user",
              type: "memberEq",
              value: userUrl,
            },
            filter: {
              userID,
            },
            independentFetch: true,
            resourceName: "userProfile",
          }),
          makeQuery({
            check: {
              memberName: "user",
              type: "memberEq",
              value: userUrl,
            },
            filter: {
              userID,
            },
            independentFetch: true,
            resourceName: "role",
          }),
        ],
        pathName,
        TEMPORARY_QUERIES_KEY,
      ),
    );
  }, [dispatch, pathName, userUrl]);

  const [trackingPermissionGranted, setTrackingPermissionGranted] = useDeviceConfig<boolean>(
    "trackingPermissionGranted",
  );

  const [requestGPSPermissionDialogOpen, setRequestGPSPermissionDialogOpen] = useState(false);
  const setRequestGPSPermissionDialogOpenTrue = useCallWithTrue(setRequestGPSPermissionDialogOpen);

  const handleAllowGPS = useCallback(() => {
    setRequestGPSPermissionDialogOpen(false);
    setTrackingPermissionGranted(true);
  }, [setTrackingPermissionGranted]);

  const handleDisallowGPS = useCallback(() => {
    setRequestGPSPermissionDialogOpen(false);
    setTrackingPermissionGranted(false);
  }, [setTrackingPermissionGranted]);

  if (!user || !userProfile) {
    return <CircularProgress />;
  }
  return (
    <div style={{padding}}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <ProfileCard user={user} userProfile={userProfile} />
        </Grid>
        <Grid item sm={6} xs={12}>
          {userIsManager || !userIsOther || userIsProjectManager ? (
            <ICECard userProfile={userProfile} />
          ) : null}
          {window.cordova &&
          !trackingPermissionGranted &&
          customerSettings.geolocation.enabled &&
          !userIsOther ? (
            <Button
              fullWidth
              color="secondary"
              style={{marginTop: "1em"}}
              variant="contained"
              onClick={setRequestGPSPermissionDialogOpenTrue}
            >
              <FormattedMessage defaultMessage="Aktivér GPS" />
            </Button>
          ) : null}
        </Grid>
        {customerSettings.anniversariesEnabled && userIsManager ? (
          <Grid item xs={12}>
            <AnniversaryCard userUrl={userUrl} />
          </Grid>
        ) : null}
      </Grid>
      <RequestGPSPermissionDialog
        open={requestGPSPermissionDialogOpen}
        onAllowGPS={handleAllowGPS}
        onDisallowGPS={handleDisallowGPS}
      />
    </div>
  );
});
