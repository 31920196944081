import {Config} from "@co-common-libs/config";
import {minuteTruncatedTimestamp} from "@co-common-libs/payroll";
import {
  AccomodationAllowance,
  ComputedTime,
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  MachineUse,
  Order,
  OrderUrl,
  PatchOperation,
  PatchUnion,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  ReportingSpecification,
  ReportingSpecificationUrl,
  ResourceTypeUnion,
  Role,
  RouteLogReport,
  RoutePlan,
  RoutePlanTask,
  RoutePlanTaskActivityOption,
  RoutePlanTaskResult,
  RoutePlanTaskUrl,
  RoutePlanUrl,
  RouteTask,
  RouteTaskActivityOption,
  RouteTaskActivityOptionUrl,
  RouteTaskResult,
  RouteTaskUrl,
  Task,
  TaskFile,
  TaskPhoto,
  TaskUrl,
  Timer,
  TimerStart,
  TimerUrl,
  Unit,
  UnitUrl,
  UserPhoto,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  emptyRouteTask,
  emptyRouteTaskResult,
  emptyTask,
  urlToId,
} from "@co-common-libs/resources";
import {
  ITEM_TYPE_TIMER,
  getLastTimerStart,
  getNormalisedDeviceTimestamp,
  getUnitCode,
  getWorkTypeString,
} from "@co-common-libs/resources-utils";
import {
  MINUTE_MILLISECONDS,
  dateToString,
  formatDate,
  formatTime,
  notUndefined,
  sortByOrderMember,
} from "@co-common-libs/utils";
import {DeleteDialog, ErrorColorButton, MachineChip} from "@co-frontend-libs/components";
import {ConnectedMachineDialogWithoutSmallMachines} from "@co-frontend-libs/connected-components";
import {
  AppState,
  PathTemplate,
  PunchWorkPeriod,
  actions,
  getAccomodationAllowanceArray,
  getCurrentRole,
  getCurrentUserSortedTimerStartArray,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getMachineLookup,
  getOrderLookup,
  getPriceGroupLookup,
  getPriceItemLookup,
  getQueryParameters,
  getReportingSpecificationLookup,
  getRouteLogReportArray,
  getRoutePlanLookup,
  getRoutePlanTaskActivityOptionArray,
  getRoutePlanTaskArray,
  getRoutePlanTaskLookup,
  getRoutePlanTaskResultArray,
  getRouteTaskActivityOptionArray,
  getRouteTaskActivityOptionLookup,
  getRouteTaskArray,
  getRouteTaskLookup,
  getRouteTaskResultArray,
  getShareToken,
  getTaskArray,
  getTaskFileArray,
  getTaskLookup,
  getTaskPhotoArray,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getToken,
  getUnitLookup,
  getUserUserProfileLookup,
  getWorkTypeArray,
  getWorkTypeLookup,
  makeQueryParameterGetter,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {colorMap, getFrontendSentry} from "@co-frontend-libs/utils";
import {Button, Card, CardContent, IconButton, Tab, Tabs} from "@material-ui/core";
import {
  Linkify,
  MachineRemovalBlockedDialog,
  MissingBreakDialog,
  NotesDialog,
  PageLayout,
  PhotoDisplayDialog,
  getComputedTime,
} from "app-components";
import {
  ADD_MACHINE_ACTION,
  ADD_TIME_ACTION,
  CHANGE_MATERIAL_ACTION,
  COMPLETE_ROUTE_ACTION,
  EDIT_MACHINE_ACTION,
  ErrorAction,
  MachineRemovalBlockedReason,
  SELECT_WORK_TYPE_ACTION,
  computeIntervalSums,
  computeIntervalsTruncated,
  computeWorkFromTo,
  concurrencyAllowedForTask,
  getBreakTimer,
  getTaskGenericPrimaryTimerLabel,
  getTaskSecondaryTimerList,
  intervalMinutes,
  machineAlreadySelected,
  machineRemovalBlocked,
  mergeIntervals,
  padZero,
  saveTimerStart,
  userCanRegisterAccommodation,
  willTaskBeRecorded,
} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import PencilIcon from "mdi-react/PencilIcon";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {batch, connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";
import CompletedDialog from "../completed-dialog";
import {GeolocationTab} from "../geolocation-tab";
import {PhotosTab} from "../photos-tab";
import {TaskWarningDialogs} from "../task-warning-dialogs";
import TimeCard from "../time-cards";
import {AddTimeCorrectionDialog} from "../time-correction/add-time-correction-dialog";
import {EditTimeCorrectionDialog} from "../time-correction/edit-time-correction-dialog";
import {editTimeCorrectionsAllowed} from "../utils";
import {ValidatedDialog} from "../validated-dialog";
import {LogTab, computeSums, getRouteTaskData} from "./log-tab";
import {RouteInvoicingTab} from "./route-invoicing-tab";
import {RouteTab} from "./route-tab";
import {RouteTaskCompletedDialog} from "./route-task-completed-dialog";

const messages = defineMessages({
  approveInvoicing: {
    defaultMessage: "Godkend til bogføring",
    id: "route.label.approve-invoicing",
  },
  approveReport: {
    defaultMessage: "Godkend indberetning",
    id: "route.label.approve-report",
  },
  cancelOtherTask: {
    defaultMessage: "Afbryd anden opgave?",
    id: "route.dialog-title.cancel-other-task",
  },
  delete: {defaultMessage: "Slet", id: "route.label.delete"},
  deliverToAdministration: {
    defaultMessage: "Udført",
    id: "route.label.deliver-to-administration",
  },
  goToOldTask: {
    defaultMessage: "Gå til gammel opgave",
    id: "route.label.go-to-old-task",
  },
  GPS: {
    defaultMessage: "GPS",
    id: "task-instance.label.gps",
  },
  infoTab: {
    defaultMessage: "Info",
    id: "route.tab-header.info",
  },
  invoicingTab: {
    defaultMessage: "Fakturering",
    id: "route.tab-header.invoicing",
  },
  invoicingTabShort: {
    defaultMessage: "Fak.",
    id: "route.tab-header.invoicing-short",
  },
  logTab: {
    defaultMessage: "Log",
    id: "route.tab-header.log",
  },
  ok: {defaultMessage: "OK", id: "dialog.label.ok"},
  oldTaskOpen: {
    defaultMessage: "Gammel opgave ikke afsluttet",
    id: "route.dialog-title.old-task-open",
  },
  photosTab: {
    defaultMessage: "Foto",
    id: "route.tab-header.photos",
  },
  routeTab: {
    defaultMessage: "Rute",
    id: "route.tab-header.route",
  },
  selectMachineButton: {
    defaultMessage: "Tilføj",
    id: "route.label.select-machine",
  },
  taskCancelled: {
    defaultMessage: "Opgaven er lukket som aflyst",
    id: "route.header.task-cancelled",
  },
  taskCompletedAsInternal: {
    defaultMessage: "Opgaven er afleveret som intern",
    id: "route.header.task-completed-as-internal",
  },
  timeTab: {
    defaultMessage: "Tid",
    id: "route.tab-header.time",
  },
  title: {
    defaultMessage: "Rute",
    id: "route.title.route",
  },
  validate: {defaultMessage: "Godkend", id: "route.label.validate"},
});

const mapToObject = (map: Map<string, any>): {[key: string]: any} => {
  const newObject: {[key: string]: any} = {};
  map.forEach((value, key) => {
    newObject[key] = value instanceof Map ? mapToObject(value) : value;
  });
  return newObject;
};

const lastOf = (a: string, b: string): string => (a > b ? a : b);
const firstOf = (a: string, b: string): string => (a < b ? a : b);

function overlappingIntervals<
  T extends {readonly fromTimestamp: string; readonly toTimestamp: string},
>(intervals: readonly T[], fromTimestamp: string, toTimestamp: string): T[] {
  console.assert(fromTimestamp <= toTimestamp);
  const result: T[] = [];
  intervals.forEach((interval) => {
    if (interval.fromTimestamp >= toTimestamp || interval.toTimestamp <= fromTimestamp) {
      // starts after or ends before the period we care about
      return;
    } else if (interval.fromTimestamp >= fromTimestamp && interval.toTimestamp <= toTimestamp) {
      // completely inside period we care about
      result.push(interval);
    } else {
      // partially inside period we care about
      result.push({
        ...interval,
        fromTimestamp: lastOf(fromTimestamp, interval.fromTimestamp),
        toTimestamp: firstOf(toTimestamp, interval.toTimestamp),
      });
    }
  });
  return result;
}

interface RouteStateProps {
  accomodationAllowanceArray: readonly AccomodationAllowance[];
  currentRole: Role | null;
  currentUserSortedTimerStartArray: readonly TimerStart[];
  currentUserURL: UserUrl | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  queryParameters: QueryParameters;
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined;
  routeLogReportArray: readonly RouteLogReport[];
  routePlanLookup: (url: RoutePlanUrl) => RoutePlan | undefined;
  routePlanTaskActivityOptionArray: readonly RoutePlanTaskActivityOption[];
  routePlanTaskArray: readonly RoutePlanTask[];
  routePlanTaskLookup: (url: RoutePlanTaskUrl) => RoutePlanTask | undefined;
  routePlanTaskResultArray: readonly RoutePlanTaskResult[];
  routeTaskActivityOptionArray: readonly RouteTaskActivityOption[];
  routeTaskActivityOptionLookup: (
    url: RouteTaskActivityOptionUrl,
  ) => RouteTaskActivityOption | undefined;
  routeTaskArray: readonly RouteTask[];
  routeTaskLookup: (url: RouteTaskUrl) => RouteTask | undefined;
  routeTaskResultArray: readonly RouteTaskResult[];
  shareToken: string | null;
  tab: string;
  taskArray: readonly Task[];
  taskFileArray: readonly TaskFile[];
  taskLookup: (url: TaskUrl) => Task | undefined;
  taskPhotoArray: readonly TaskPhoto[];
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerStartArray: readonly TimerStart[];
  token: string | null;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeArray: readonly WorkType[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface RouteDispatchProps {
  addToOffline: (instance: ResourceTypeUnion) => void;
  back: (count?: number, fallback?: PathTemplate) => void;
  backSkip: (skip: PathTemplate[], fallback?: PathTemplate) => void;
  create: (instance: ResourceTypeUnion) => void;
  forwardBackSkip: (skip: PathTemplate[], fallback?: PathTemplate) => void;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  registerTaskPosition: (taskUrl: TaskUrl) => void;
  registerTimerStartPosition: (timerStart: TimerStart) => void;
  remove: (url: string) => void;
  setMessage: (message: string, timestamp: Date) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface RouteOwnProps {
  activeTimer?: TimerUrl | undefined;
  computedIntervals: readonly ComputedTime[];
  genericPrimaryTimer: Timer;
  intervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: TimerUrl | null;
    readonly toTimestamp: string;
  }[];
  legalIntervals?: readonly PunchWorkPeriod[] | undefined;
  now: Date;
  task: Task;
  timerMinutesMap: ReadonlyMap<TimerUrl, number>;
}

type RouteProps = RouteDispatchProps & RouteOwnProps & RouteStateProps;

interface RouteState {
  approvedDialogOpen: boolean;
  completedDialogOpen: boolean;
  deleteDialogOpen: boolean;
  deletingFile: string | null;
  deletingPhoto: string | null;
  displayedImage: TaskPhoto | UserPhoto | null;
  editIntervalEnd: string | null;
  editIntervalStart: string | null;
  editIntervalTimer: Timer | null;
  machineDialogOpen: boolean;
  machineOperatorTimeCorrectionDialogOpen: boolean;
  machineRemovalBlockedDialogOpen: boolean;
  machineRemovalBlockedReason: MachineRemovalBlockedReason | null;
  managerTimeCorrectionDialogOpen: boolean;
  missingBreakDialogOpen: boolean;
  notesDialogDismissed: boolean;
  oldTaskTimerStart: TimerStart | null;
  oldTimerTimerStart: TimerStart | null;
  performOldTaskCheck: boolean;
  requestedActiveTimer: TimerUrl | null;
  routeTaskCompletedDialogEditing: boolean;
  routeTaskCompletedDialogOpenFor: {
    routePlanTaskUrl: RoutePlanTaskUrl | undefined;
    routeTaskUrl: RouteTaskUrl | undefined;
  } | null;
  validatedDialogOpen: boolean;
}

class Route extends React.Component<RouteProps, RouteState> {
  state: RouteState = {
    approvedDialogOpen: false,
    completedDialogOpen: false,
    deleteDialogOpen: false,
    deletingFile: null,
    deletingPhoto: null,
    displayedImage: null,
    editIntervalEnd: null,
    editIntervalStart: null,
    editIntervalTimer: null,
    machineDialogOpen: false,
    machineOperatorTimeCorrectionDialogOpen: false,
    machineRemovalBlockedDialogOpen: false,
    machineRemovalBlockedReason: null,
    managerTimeCorrectionDialogOpen: false,
    missingBreakDialogOpen: false,
    notesDialogDismissed: false,
    oldTaskTimerStart: null,
    oldTimerTimerStart: null,
    performOldTaskCheck: false,
    requestedActiveTimer: null,
    routeTaskCompletedDialogEditing: false,
    routeTaskCompletedDialogOpenFor: null,
    validatedDialogOpen: false,
  };
  UNSAFE_componentWillMount(): void {
    const query = this.props.queryParameters;
    if (query && query.notesDisplayed) {
      if (parseInt(query.notesDisplayed)) {
        this.setState({
          notesDialogDismissed: true,
        });
      }
    }
  }

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleTimerButton(timerURL: TimerUrl): void {
    window.setTimeout(() => {
      const {
        currentUserSortedTimerStartArray,
        currentUserURL,
        customerSettings,
        task,
        taskLookup,
        workTypeLookup,
      } = this.props;
      const taskURL = task.url;
      const lastUserTimerStart = _.last(currentUserSortedTimerStartArray);
      const lastTaskTimerStart = _.findLast(
        currentUserSortedTimerStartArray,
        (timerStart) => timerStart.task === taskURL,
      );

      if (
        lastUserTimerStart !== lastTaskTimerStart &&
        lastUserTimerStart &&
        lastUserTimerStart.timer &&
        !customerSettings.concurrentTasksAllowed
      ) {
        // possibly conflict with other task...
        const lastTask = taskLookup(lastUserTimerStart.task);
        if (
          !concurrencyAllowedForTask(task, workTypeLookup, customerSettings) ||
          !lastTask ||
          !concurrencyAllowedForTask(lastTask, workTypeLookup, customerSettings)
        ) {
          // Show "Stop other task?"-dialog
          this.setState({
            oldTimerTimerStart: lastUserTimerStart,
            requestedActiveTimer: timerURL,
          });
          return;
        }
      }
      const isFirstTimerStartOnTask = !lastTaskTimerStart;
      if (
        isFirstTimerStartOnTask &&
        this.props.task.date &&
        this.props.task.date !== dateToString(new Date())
      ) {
        // Show "Wrong date, are you sure?"-dialog
        this.setState({requestedActiveTimer: timerURL});
        return;
      }
      const {oldTaskWarningAgeMinutes} = this.props.customerSettings;
      if (
        isFirstTimerStartOnTask &&
        oldTaskWarningAgeMinutes != null &&
        this.state.performOldTaskCheck
      ) {
        const openTaskURLSet = new Set<string>();
        this.props.taskArray.forEach((otherTask) => {
          if (!otherTask.completed && otherTask.machineOperator === currentUserURL) {
            openTaskURLSet.add(otherTask.url);
          }
        });
        if (openTaskURLSet.size) {
          const oldTaskTimerStart = currentUserSortedTimerStartArray.find((timerStart) =>
            openTaskURLSet.has(timerStart.task),
          );
          if (oldTaskTimerStart) {
            const oldTaskTimerStartAge =
              new Date().valueOf() - new Date(oldTaskTimerStart.deviceTimestamp).valueOf();
            if (oldTaskTimerStartAge > oldTaskWarningAgeMinutes * MINUTE_MILLISECONDS) {
              this.setState({
                oldTaskTimerStart,
                requestedActiveTimer: timerURL,
              });
              return;
            }
          }
        }
      }
      this.setState({performOldTaskCheck: true});
      this.timerButtonValid(timerURL, lastTaskTimerStart);
    });
  }
  timerButtonValid(timerURL: TimerUrl, inputLastTimerStart?: TimerStart): void {
    const {currentUserURL} = this.props;
    const lastTimerStart =
      inputLastTimerStart ||
      (currentUserURL && getLastTimerStart(currentUserURL, this.props.timerStartArray));
    const lastTimerStartTaskURL = lastTimerStart && lastTimerStart.task;
    const now = new Date();
    if (
      lastTimerStart &&
      lastTimerStart.task === this.props.task.url &&
      lastTimerStart.timer === timerURL
    ) {
      this.timerChange(null);
    } else {
      const {task} = this.props;
      const oldDate = task.date;
      const oldTime = task.time;
      const nowDate = dateToString(now);
      const hoursDigits = 2;
      const minutesDigits = 2;
      const nowTime = `${padZero(now.getHours(), hoursDigits)}:${padZero(
        now.getMinutes(),
        minutesDigits,
      )}`;
      if (!oldDate || oldDate > nowDate) {
        this.props.update(task.url, [
          {member: "date", value: nowDate},
          {member: "time", value: nowTime},
        ]);
      } else if (!oldTime || (oldDate === nowDate && oldTime > nowTime)) {
        this.props.update(task.url, [{member: "time", value: nowTime}]);
      }
      const timestamp = now.toISOString();
      this.timerChange(timerURL, timestamp);
      if (lastTimerStartTaskURL !== this.props.task.url) {
        const {customerSettings} = this.props;
        const {autoFillUnregisteredMinutesOnStart} = customerSettings;
        if (autoFillUnregisteredMinutesOnStart != null) {
          const {taskLookup, timerStartArray} = this.props;
          const oldTask = lastTimerStartTaskURL ? taskLookup(lastTimerStartTaskURL) : undefined;
          if (oldTask) {
            const computedIntervals = getComputedTime(oldTask, timerStartArray, timestamp);
            const correctionIntervals = oldTask.machineOperatorTimeCorrectionSet || [];
            const managerCorrectionIntervals = oldTask.managerTimeCorrectionSet || [];
            const intervals = mergeIntervals(
              computedIntervals,
              correctionIntervals,
              managerCorrectionIntervals,
              timestamp,
            );
            const lastToTimestamp =
              intervals && !!intervals.length && intervals[intervals.length - 1].toTimestamp;
            if (lastToTimestamp) {
              const {unregisteredBreakAfterMinutes} = customerSettings;
              const lastToDate = new Date(lastToTimestamp);
              const differenceMilliseconds = now.valueOf() - lastToDate.valueOf();
              const differenceMinutes = differenceMilliseconds / MINUTE_MILLISECONDS;
              // eslint-disable-next-line max-depth
              if (differenceMinutes >= 1 && differenceMinutes < unregisteredBreakAfterMinutes) {
                // eslint-disable-next-line max-depth
                if (differenceMinutes <= autoFillUnregisteredMinutesOnStart) {
                  this.handleAddMachineOperatorTimeCorrection(lastToTimestamp, timestamp, timerURL);
                }
              }
            }
          }
        }
      }
    }
  }
  timerChange(timerURL: TimerUrl | null, timestamp: string | null = null): void {
    const userURL = this.props.task.machineOperator;
    const taskURL = this.props.task.url;
    if (userURL && taskURL) {
      saveTimerStart(
        this.props.create,
        timerURL,
        taskURL,
        userURL,
        timestamp,
        this.props.customerSettings.geolocation.registerPositionOnTimerClick &&
          this.props.currentUserURL === this.props.task.machineOperator
          ? this.props.registerTimerStartPosition
          : undefined,
      );
    }
  }
  @bind
  handleOldTaskOpenOk(): void {
    const {requestedActiveTimer} = this.state;
    if (!requestedActiveTimer) {
      return;
    }
    const callback = (): void => {
      this.handleTimerButton(requestedActiveTimer);
    };
    this.setState(
      {
        oldTaskTimerStart: null,
        performOldTaskCheck: false,
        requestedActiveTimer: null,
      },
      callback,
    );
  }
  @bind
  handleOldTaskOpenCancel(): void {
    const {oldTaskTimerStart} = this.state;
    if (!oldTaskTimerStart) {
      return;
    }
    this.setState({oldTaskTimerStart: null, requestedActiveTimer: null});
    window.setTimeout(() => {
      batch(() => {
        const taskID = urlToId(oldTaskTimerStart.task);
        this.props.go("/task/:id", {id: taskID});
      });
    });
  }
  @bind
  handleStopTimerDialogOk(): void {
    const {oldTimerTimerStart, requestedActiveTimer} = this.state;
    if (!requestedActiveTimer || !oldTimerTimerStart) {
      return;
    }
    const callback = (): void => {
      this.handleTimerButton(requestedActiveTimer);
    };
    const stopTaskURL = oldTimerTimerStart.task;
    if (this.props.currentUserURL) {
      saveTimerStart(
        this.props.create,
        null,
        stopTaskURL,
        this.props.currentUserURL,
        null,
        this.props.customerSettings.geolocation.registerPositionOnTimerClick &&
          this.props.currentUserURL === this.props.task.machineOperator
          ? this.props.registerTimerStartPosition
          : undefined,
      );
    }
    this.setState(
      {
        oldTimerTimerStart: null,
        requestedActiveTimer: null,
      },
      callback,
    );
  }
  @bind
  handleStopTimerDialogCancel(): void {
    this.setState({oldTimerTimerStart: null, requestedActiveTimer: null});
  }
  @bind
  handleOtherDateDialogOk(): void {
    const {requestedActiveTimer} = this.state;
    if (!requestedActiveTimer) {
      return;
    }
    const now = new Date();
    const {task} = this.props;
    const nowDate = dateToString(now);
    this.props.update(task.url, [{member: "date", value: nowDate}]);
    const callback = (): void => {
      this.handleTimerButton(requestedActiveTimer);
    };
    this.setState({requestedActiveTimer: null}, callback);
  }
  @bind
  handleOtherDateDialogCancel(): void {
    this.setState({requestedActiveTimer: null});
  }
  @bind
  handleRequestAddMachineOperatorTimeCorrection(): void {
    this.setState({machineOperatorTimeCorrectionDialogOpen: true});
  }
  @bind
  handleRequestAddManagerTimeCorrection(): void {
    this.setState({managerTimeCorrectionDialogOpen: true});
  }
  @bind
  handleCorrectionDialogCancel(): void {
    this.setState({
      machineOperatorTimeCorrectionDialogOpen: false,
      managerTimeCorrectionDialogOpen: false,
    });
  }
  @bind
  handleCorrectionDialogOk(
    fromTimestamp: string,
    toTimestamp: string,
    timer: TimerUrl | null,
  ): void {
    const {machineOperatorTimeCorrectionDialogOpen, managerTimeCorrectionDialogOpen} = this.state;
    this.handleCorrectionDialogCancel();
    if (machineOperatorTimeCorrectionDialogOpen) {
      this.handleAddMachineOperatorTimeCorrection(fromTimestamp, toTimestamp, timer);
    }
    if (managerTimeCorrectionDialogOpen) {
      this.handleAddManagerTimeCorrection(fromTimestamp, toTimestamp, timer);
    }
  }
  @bind
  handleAddMachineOperatorTimeCorrection(
    fromTimestamp: string,
    toTimestamp: string,
    timer: TimerUrl | null,
  ): void {
    this.addTimeCorrection(fromTimestamp, toTimestamp, timer, false);
  }
  @bind
  handleAddManagerTimeCorrection(
    fromTimestamp: string,
    toTimestamp: string,
    timer: TimerUrl | null,
  ): void {
    this.addTimeCorrection(fromTimestamp, toTimestamp, timer, true);
  }
  @bind
  handleTimelineIntervalClick(
    fromTimestamp: string,
    toTimestamp: string,
    timer: Timer | null,
  ): void {
    this.setState({
      editIntervalEnd: toTimestamp,
      editIntervalStart: fromTimestamp,
      editIntervalTimer: timer,
    });
  }
  @bind
  handleTimelineEditIntervalDialogCancel(): void {
    this.setState({
      editIntervalEnd: null,
      editIntervalStart: null,
      editIntervalTimer: null,
    });
  }
  addTimeCorrection(
    fromTimestamp: string,
    toTimestamp: string,
    timer: TimerUrl | null,
    asManager: boolean,
  ): void {
    this.addTimeCorrectionArray([[fromTimestamp, toTimestamp, timer]], asManager);
  }
  addTimeCorrectionArray(
    corrections: readonly (readonly [string, string, TimerUrl | null])[],
    asManager: boolean,
  ): void {
    const {task} = this.props;
    let oldIntervals;
    if (asManager) {
      oldIntervals = task.managerTimeCorrectionSet || [];
    } else {
      oldIntervals = task.machineOperatorTimeCorrectionSet || [];
    }
    let newIntervals = oldIntervals;
    let removeCompletedAsInternal = false;
    const completedAsInternal = task.completedAsInternal || task.continuationTask;

    corrections.forEach(([inputFromTimestamp, inputToTimestamp, timerURL]) => {
      let fromTimestamp = inputFromTimestamp;
      let toTimestamp = inputToTimestamp;
      if (completedAsInternal) {
        const timer = timerURL ? this.props.timerLookup(timerURL) : null;
        const isInvoicedTimer = !!(
          timer &&
          (timer.isGenericEffectiveTime ||
            timer.priceGroup ||
            (timer.workType && _.get(this.props.workTypeLookup(timer.workType), "productive")))
        );
        if (isInvoicedTimer) {
          removeCompletedAsInternal = true;
        }
      }

      const correctionLengthMilliseconds =
        new Date(toTimestamp).valueOf() - new Date(fromTimestamp).valueOf();
      if (correctionLengthMilliseconds < MINUTE_MILLISECONDS) {
        // ignore corrections of less than a minute
        return;
      }
      const intersectsPredicate = (interval: {
        fromTimestamp: string;
        toTimestamp: string;
      }): boolean => {
        return interval.toTimestamp > fromTimestamp && interval.fromTimestamp < toTimestamp;
      };
      const nonIntersectingIntervals = newIntervals.filter(_.negate(intersectsPredicate));
      const intersectingIntervals = newIntervals.filter(intersectsPredicate);
      newIntervals = nonIntersectingIntervals;
      if (!intersectingIntervals.length) {
        newIntervals = [...newIntervals, {fromTimestamp, timer: timerURL, toTimestamp}];
      } else {
        const sameWorkTypePredicate = (interval: {timer: string | null}): boolean => {
          return interval.timer === timerURL;
        };
        const intersectingSameWorktype = intersectingIntervals.filter(sameWorkTypePredicate);
        intersectingSameWorktype.forEach((interval) => {
          const intervalFromTimestamp = interval.fromTimestamp;
          const intervalToTimestamp = interval.toTimestamp;
          if (intervalFromTimestamp < fromTimestamp) {
            fromTimestamp = intervalFromTimestamp;
          }
          if (intervalToTimestamp > toTimestamp) {
            toTimestamp = intervalToTimestamp;
          }
        });
        newIntervals = [...newIntervals, {fromTimestamp, timer: timerURL, toTimestamp}];
        const intersectingDifferentWorkType = intersectingIntervals.filter(
          _.negate(sameWorkTypePredicate),
        );
        intersectingDifferentWorkType.forEach((interval) => {
          // split into part before new interval and part after interval; both might be empty/have negative length...
          const intervalBeyondStart = {...interval, toTimestamp: fromTimestamp};
          const intervalBeyondEnd = {...interval, fromTimestamp: toTimestamp};
          const intervalBeyondStartLength =
            new Date(intervalBeyondStart.toTimestamp).valueOf() -
            new Date(intervalBeyondStart.fromTimestamp).valueOf();
          if (intervalBeyondStartLength > MINUTE_MILLISECONDS) {
            newIntervals = [...newIntervals, intervalBeyondStart];
          }
          const intervalBeyondEndLength =
            new Date(intervalBeyondEnd.toTimestamp).valueOf() -
            new Date(intervalBeyondEnd.fromTimestamp).valueOf();
          if (intervalBeyondEndLength > MINUTE_MILLISECONDS) {
            newIntervals = [...newIntervals, intervalBeyondEnd];
          }
        });
      }
      newIntervals = _.sortBy(newIntervals, (interval) => interval.fromTimestamp);
    });
    // cleanup
    if (newIntervals.length) {
      const cleaned = [];
      let previous = newIntervals[0];
      newIntervals.slice(1).forEach((interval) => {
        if (interval.timer !== previous.timer) {
          cleaned.push(previous);
          previous = interval;
        } else {
          const distance =
            new Date(interval.fromTimestamp).valueOf() - new Date(previous.toTimestamp).valueOf();
          if (distance < MINUTE_MILLISECONDS) {
            previous = {...previous, toTimestamp: interval.toTimestamp};
          } else {
            cleaned.push(previous);
            previous = interval;
          }
        }
      });
      cleaned.push(previous);
      newIntervals = cleaned;
    }
    if (!_.isEqual(newIntervals, oldIntervals)) {
      const patch: PatchOperation<Task>[] = [];

      if (asManager) {
        // perform manager time corrections
        patch.push({member: "managerTimeCorrectionSet", value: newIntervals});
      } else {
        // perform machine operator time corrections
        patch.push({
          member: "machineOperatorTimeCorrectionSet",
          value: newIntervals,
        });
      }
      const correctionIntervals = asManager ? task.machineOperatorTimeCorrectionSet : newIntervals;
      const managerCorrectionIntervals = asManager ? newIntervals : task.managerTimeCorrectionSet;
      const mergedIntervals = mergeIntervals(
        this.props.computedIntervals,
        correctionIntervals,
        managerCorrectionIntervals,
      );
      const timerUrls = new Set(mergedIntervals.map((i) => i.timer));
      const newTimerNotesSet = task.timernotesSet.filter((timerNote) =>
        timerUrls.has(timerNote.timer),
      );
      if (!_.isEqual(newTimerNotesSet, task.timernotesSet)) {
        patch.push({member: "timernotesSet", value: newTimerNotesSet});
      }
      if (completedAsInternal && removeCompletedAsInternal) {
        patch.push(
          {member: "completedAsInternal", value: false},
          {member: "continuationTask", value: null},
        );
      }
      this.props.update(task.url, patch);
    }
  }
  @bind
  handleTimelineEditIntervalDialogOk(
    fromTimestamp: string,
    toTimestamp: string,
    timerURL: TimerUrl | null,
  ): void {
    const {task} = this.props;
    const {editIntervalEnd, editIntervalStart, editIntervalTimer} = this.state;
    this.setState({
      editIntervalEnd: null,
      editIntervalStart: null,
      editIntervalTimer: null,
    });
    if (!task || !editIntervalStart || !editIntervalEnd) {
      return;
    }
    const completed = !!(task ? task.completed : true);
    const validated = !!(task ? task.validatedAndRecorded : false);
    const {currentUserURL} = this.props;
    const role = this.props.currentRole;
    const userIsOnlyMachineOperator = role && !role.manager;
    const userIsOther = !task || task.machineOperator !== currentUserURL;
    let managerCorrections;
    if (!validated && !userIsOnlyMachineOperator) {
      // perform manager time corrections
      managerCorrections = true;
    } else if (!completed && !userIsOther) {
      // perform machine operator time corrections
      managerCorrections = false;
    } else {
      // no edit-permission...
      return;
    }
    const editIntervalTimerURL = editIntervalTimer ? editIntervalTimer.url : null;
    const corrections: [string, string, TimerUrl | null][] = [];

    const {intervals} = this.props;

    if (timerURL === editIntervalTimer) {
      if (fromTimestamp < editIntervalStart) {
        corrections.push([fromTimestamp, editIntervalStart, editIntervalTimerURL]);
      }
      if (toTimestamp > editIntervalEnd) {
        corrections.push([editIntervalEnd, toTimestamp, editIntervalTimerURL]);
      }
    } else {
      corrections.push([fromTimestamp, toTimestamp, timerURL]);
    }
    if (fromTimestamp > editIntervalStart) {
      const previousInterval = _.findLast(intervals, (i) => i.toTimestamp <= editIntervalStart);
      let timer: TimerUrl | null = null;
      if (previousInterval) {
        const distance =
          new Date(editIntervalStart).valueOf() - new Date(previousInterval.toTimestamp).valueOf();
        // if there's a minute or more of unregistered time in between, then the correction should be to unregistered
        if (distance <= MINUTE_MILLISECONDS) {
          ({timer} = previousInterval);
        }
      }
      corrections.push([editIntervalStart, fromTimestamp, timer]);
    }
    if (toTimestamp < editIntervalEnd) {
      const nextInterval = intervals.find((i) => i.fromTimestamp >= editIntervalEnd);
      let timer = null;
      if (nextInterval) {
        const distance =
          new Date(nextInterval.fromTimestamp).valueOf() - new Date(editIntervalEnd).valueOf();
        // if there's a minute or more of unregistered time in between, then the correction should be to unregistered
        if (distance <= MINUTE_MILLISECONDS) {
          ({timer} = nextInterval);
        }
      }
      corrections.push([toTimestamp, editIntervalEnd, timer]);
    }
    this.addTimeCorrectionArray(corrections, managerCorrections);
  }

  @bind
  handleDeleteButton(): void {
    this.setState({deleteDialogOpen: true});
  }
  @bind
  handleDeleteDialogOk(): void {
    this.setState({deleteDialogOpen: false});
    const {task} = this.props;
    const taskURL = task.url;
    const orderURL = task.order;
    let deleteOrder = false;
    if (orderURL) {
      const tasksForOrder = this.props.taskArray.filter((t) => t.order === orderURL);
      if (tasksForOrder.length === 1 && tasksForOrder[0].url === taskURL) {
        deleteOrder = true;
      }
    }
    this.props.remove(taskURL);
    if (orderURL && deleteOrder) {
      this.props.remove(orderURL);
    }
    window.setTimeout(() => {
      batch(() => {
        this.props.backSkip([
          "/order/:id",
          "/order/:id/:taskID",
          "/orderEntry/:id",
          "/task/:id",
          "/taskDetails/:id",
          "/taskEdit/:id",
          "/internalTask/:id",
        ]);
      });
    });
  }
  @bind
  handleDeleteDialogCancel(): void {
    this.setState({deleteDialogOpen: false});
  }

  @bind
  handleValidatedButton(): void {
    // stop timer, just in case
    this.timerChange(null);
    if (this.props.customerSettings.useApproveReport) {
      this.setState({approvedDialogOpen: true});
    } else {
      this.setState({validatedDialogOpen: true});
    }
  }
  @bind
  handleValidatedDialogOk(): void {
    this.setState({
      validatedDialogOpen: false,
    });
    const {task, timerArray, timerStartArray} = this.props;
    const breakTimer = getBreakTimer(timerArray);
    const patch: PatchOperation<Task>[] = [{member: "validatedAndRecorded", value: true}];
    const orderURL = task.order;
    const order = orderURL ? this.props.orderLookup(orderURL) : undefined;
    if (
      !willTaskBeRecorded(
        task,
        order,
        this.props.customerSettings,
        timerStartArray,
        breakTimer?.url,
      )
    ) {
      patch.push({member: "archivable", value: true});
    }
    this.props.update(task.url, patch);
    setTimeout(() => {
      batch(() => {
        this.props.back();
      });
    });
  }
  @bind
  handleValidatedDialogCancel(): void {
    this.setState({validatedDialogOpen: false});
  }
  @bind
  handleApprovedDialogOk(): void {
    this.setState({approvedDialogOpen: false});
    const {task, update} = this.props;
    update(task.url, [{member: "reportApproved", value: true}]);
    setTimeout(() => {
      batch(() => {
        this.props.back();
      });
    });
  }
  @bind
  handleApprovedDialogCancel(): void {
    this.setState({approvedDialogOpen: false});
  }

  @bind
  handleCompletedButton(_event: unknown): void {
    // FIXME: handle admins stopping task on behalf of others
    // stop timer, just in case
    this.timerChange(null);
    const {intervals, timerArray} = this.props;
    let showMissingBreakDialog = false;
    if (this.props.customerSettings.askRegardingMissingBreakOnExternalTaskCompletion) {
      const finalSums = computeIntervalSums(intervals, new Date());
      const breakTimer = getBreakTimer(timerArray);
      const breakTimerURL = breakTimer?.url;
      showMissingBreakDialog = !!breakTimerURL && !finalSums.get(breakTimerURL);
    }
    if (showMissingBreakDialog) {
      this.setState({
        missingBreakDialogOpen: true,
      });
    } else {
      this.setState({
        completedDialogOpen: true,
      });
    }
  }
  @bind
  handleCompletedDialogCancel(): void {
    this.setState({
      completedDialogOpen: false,
    });
  }
  @bind
  handleCompletedDialogOk(
    _event: unknown,
    workshopTaskParameters: {
      machineUse: readonly MachineUse[] | null;
      needsPreparation: boolean;
      needsPreparationMinutes: number;
      needsRepair: boolean;
      repairNote: string;
      selectedMachines: readonly MachineUrl[];
      selectedPreparationMachines: readonly MachineUrl[];
    } | null,
  ): void {
    this.setState({
      completedDialogOpen: false,
    });
    if (workshopTaskParameters) {
      const {needsPreparationMinutes, repairNote, selectedMachines, selectedPreparationMachines} =
        workshopTaskParameters;
      const {currentUserURL} = this.props;
      if (selectedPreparationMachines.length > 0) {
        const machineuseSet = selectedPreparationMachines.map((machine): MachineUse => {
          return {
            machine,
            priceGroup: null,
            transporter: false,
          };
        });

        const id = uuid();
        const url = instanceURL("task", id);
        const workTypeIdentifier = this.props.customerSettings.preparationWorkType;
        const workType = this.props.workTypeArray.find((w) => w.identifier === workTypeIdentifier);
        const workTypeURL = workType ? workType.url : null;
        const preparationTask: Task = {
          ...emptyTask,
          createdBy: currentUserURL,
          id,
          machineOperator: this.props.customerSettings.defaultTaskEmployee
            ? instanceURL("user", this.props.customerSettings.defaultTaskEmployee)
            : null,
          machineuseSet,
          minutesExpectedTotalTaskDuration: needsPreparationMinutes,
          url,
          workType: workTypeURL,
        };
        this.props.create(preparationTask);
      }

      if (selectedMachines.length > 0) {
        const machineuseSet = selectedMachines.map((machine) => {
          return {machine, priceGroup: null, transporter: false};
        });
        const id = uuid();
        const url = instanceURL("task", id);
        const workTypeIdentifier = this.props.customerSettings.repairWorkType;
        const workType = this.props.workTypeArray.find((w) => w.identifier === workTypeIdentifier);
        const workTypeURL = workType ? workType.url : null;

        const repairTask: Task = {
          ...emptyTask,
          createdBy: currentUserURL,
          id,
          machineOperator: this.props.customerSettings.defaultTaskEmployee
            ? instanceURL("user", this.props.customerSettings.defaultTaskEmployee)
            : null,
          machineuseSet,
          notesFromMachineOperator: repairNote,
          url,
          workType: workTypeURL,
        };
        this.props.create(repairTask);
      }
    }
    const {task} = this.props;
    const now = new Date();
    const taskURL = this.props.task.url;
    const timerStarts = _.sortBy(
      this.props.timerStartArray.filter((instance) => instance.task === taskURL),
      getNormalisedDeviceTimestamp,
    );
    const computedIntervals = computeIntervalsTruncated(timerStarts, now.toISOString());
    const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
    const intervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
      now.toISOString(),
    );
    const {workFromTimestamp, workToTimestamp} = computeWorkFromTo(intervals);
    this.props.update(task.url, [
      {member: "cancelled", value: false},
      {member: "completed", value: true},
      {member: "computedTimeSet", value: computedIntervals},
      {member: "workFromTimestamp", value: workFromTimestamp},
      {member: "workToTimestamp", value: workToTimestamp},
    ]);

    // create missing RouteTask instances on task completion...
    const order = (task.order && this.props.orderLookup(task.order)) || undefined;
    if (order?.routePlan) {
      const {routePlanTaskArray, routeTaskArray} = this.props;
      const routePlanUrl = order.routePlan;
      const routeTaskList = _.sortBy(
        routeTaskArray.filter((routeTask) => routeTask.route === taskURL),
        (routeTask) => routeTask.order,
      );
      const routePlanTaskList = _.sortBy(
        routePlanTaskArray.filter((routePlanTask) => routePlanTask.routePlan === routePlanUrl),
        (routePlanTask) => routePlanTask.order,
      );
      const processedRouteTaskEntries = new Set<string>();
      routePlanTaskList.forEach((routePlanTask) => {
        // both routePlanTaskList and routeTaskList are sorted;
        // so multiple equivalent entries will be matched in order...
        const routeTask = routeTaskList.find(
          (r) =>
            r.customer === routePlanTask.customer &&
            r.project === routePlanTask.project &&
            r.relatedLocation === routePlanTask.relatedLocation &&
            r.description === routePlanTask.description &&
            r.notesFromManager === routePlanTask.notesFromManager &&
            r.deadline === routePlanTask.deadline &&
            !processedRouteTaskEntries.has(r.url),
        );
        if (routeTask) {
          processedRouteTaskEntries.add(routeTask.url);
          if (routeTask.order !== routePlanTask.order) {
            this.props.update(routeTask.url, [{member: "order", value: routePlanTask.order}]);
          }
        } else {
          const routeTaskID = uuid();
          const routeTaskURL = instanceURL("routeTask", routeTaskID);
          const newRouteTask: RouteTask = {
            ...emptyRouteTask,
            customer: routePlanTask.customer,
            deadline: routePlanTask.deadline,
            description: routePlanTask.description,
            id: routeTaskID,
            notesFromManager: routePlanTask.notesFromManager,
            order: routePlanTask.order,
            project: routePlanTask.project,
            relatedLocation: routePlanTask.relatedLocation,
            route: taskURL,
            url: routeTaskURL,
          };
          this.props.create(newRouteTask);
        }
      });
    }

    if (this.props.customerSettings.autoCreateLogPrint) {
      this.handleRequestBuildReport();
    }
    window.setTimeout(() => {
      batch(() => {
        this.props.forwardBackSkip([
          "/order/:id",
          "/order/:id/:taskID",
          "/orderEntry/:id",
          "/task/:id",
          "/taskDetails/:id",
          "/taskEdit/:id",
          "/internalTask/:id",
        ]);
      });
    });
  }
  @bind
  handleCompletedValidatedDialogAction(action: ErrorAction): void {
    console.assert(
      action === SELECT_WORK_TYPE_ACTION ||
        action === ADD_MACHINE_ACTION ||
        action === EDIT_MACHINE_ACTION ||
        action === CHANGE_MATERIAL_ACTION ||
        action === COMPLETE_ROUTE_ACTION ||
        action === ADD_TIME_ACTION,
    );
    if (action === ADD_MACHINE_ACTION) {
      this.setState({
        approvedDialogOpen: false,
        completedDialogOpen: false,
        machineDialogOpen: true,
        validatedDialogOpen: false,
      });
    } else if (action === ADD_TIME_ACTION || action === EDIT_MACHINE_ACTION) {
      this.setState({
        approvedDialogOpen: false,
        completedDialogOpen: false,
        validatedDialogOpen: false,
      });
      this.props.putQueryKey("tab", "time");
    } else if (action === COMPLETE_ROUTE_ACTION) {
      this.setState({
        approvedDialogOpen: false,
        completedDialogOpen: false,
        machineDialogOpen: false,
        validatedDialogOpen: false,
      });
      this.props.putQueryKey("tab", "route");
    } else {
      const sentry = getFrontendSentry();
      sentry.captureMessage(
        `Unhandled error action "${action}" encountered in Route.handleCompletedValidatedDialogAction`,
        "error",
      );
      this.setState({
        approvedDialogOpen: false,
        completedDialogOpen: false,
        validatedDialogOpen: false,
      });
    }
  }

  @bind
  handleRouteTaskCompleteClick(
    routePlanTaskUrl: RoutePlanTaskUrl,
    routeTaskUrl: RouteTaskUrl | undefined,
  ): void {
    console.assert(routePlanTaskUrl || routeTaskUrl);
    this.setState({
      routeTaskCompletedDialogEditing: false,
      routeTaskCompletedDialogOpenFor: {routePlanTaskUrl, routeTaskUrl},
    });
  }

  @bind
  handleRouteTaskStartClick(): void {
    if (this.props.activeTimer !== this.props.genericPrimaryTimer.url) {
      this.handleTimerButton(this.props.genericPrimaryTimer.url);
    }
  }

  @bind
  handleRouteTaskCompletedDialogOk(
    selectedPriceGroupURL: PriceGroupUrl,
    priceItemValues: Map<PriceItemUrl, number | null>,
    notesFromTaskAssignee: string,
  ): void {
    if (!this.state.routeTaskCompletedDialogOpenFor) {
      return;
    }
    const {routePlanTaskUrl, routeTaskUrl} = this.state.routeTaskCompletedDialogOpenFor;
    if (!routePlanTaskUrl && !routeTaskUrl) {
      return;
    }
    this.setState({
      routeTaskCompletedDialogEditing: false,
      routeTaskCompletedDialogOpenFor: null,
    });
    const {
      create,
      registerTaskPosition,
      remove,
      routePlanTaskActivityOptionArray,
      routePlanTaskLookup,
      routePlanTaskResultArray,
      routeTaskActivityOptionArray,
      routeTaskLookup,
      routeTaskResultArray,
      task,
      update,
    } = this.props;
    const routeTask = routeTaskUrl ? routeTaskLookup(routeTaskUrl) : undefined;
    const routePlanTask = routePlanTaskUrl ? routePlanTaskLookup(routePlanTaskUrl) : undefined;

    if (!routePlanTask && !routeTask) {
      return;
    }
    const routeTaskActivityOption = routeTaskUrl
      ? routeTaskActivityOptionArray.find(
          (activityOption) =>
            activityOption.activity === selectedPriceGroupURL &&
            activityOption.routeTask === routeTaskUrl,
        )
      : undefined;
    const routePlanTaskActivityOption = routePlanTaskUrl
      ? routePlanTaskActivityOptionArray.find(
          (activityOption) =>
            activityOption.activity === selectedPriceGroupURL &&
            activityOption.routePlanTask === routePlanTaskUrl,
        )
      : undefined;

    if (!routePlanTaskActivityOption && !routeTaskActivityOption) {
      return;
    }

    const filteredRoutePlanTaskResultArray =
      routePlanTaskUrl && routePlanTaskActivityOption
        ? routePlanTaskResultArray.filter(
            (result) =>
              result.routePlanTask === routePlanTaskUrl &&
              result.activityOption === routePlanTaskActivityOption.url,
          )
        : [];

    const filteredRouteTaskResultArray =
      routeTaskUrl && routeTaskActivityOption
        ? routeTaskResultArray.filter(
            (result) =>
              result.routeTask === routeTaskUrl &&
              result.activityOption === routeTaskActivityOption.url,
          )
        : [];

    const now = new Date();
    if (routeTask) {
      // update RouteTask
      const completed = routeTask.completed || now.toISOString();
      if (
        selectedPriceGroupURL !== routeTask.activity ||
        notesFromTaskAssignee !== routeTask.notesFromTaskAssignee ||
        completed !== routeTask.completed
      ) {
        update(routeTask.url, [
          {member: "activity", value: selectedPriceGroupURL},
          {member: "completed", value: completed},
          {member: "notesFromTaskAssignee", value: notesFromTaskAssignee},
        ]);
      }
      if (routeTaskActivityOption) {
        Array.from(priceItemValues).forEach(([priceItemUrl, value], index) => {
          const existingRouteTaskResult = filteredRouteTaskResultArray.find(
            (result) => result.specification === priceItemUrl,
          );
          if (existingRouteTaskResult) {
            // update RouteTaskResult
            if (
              existingRouteTaskResult.quantity !== value ||
              existingRouteTaskResult.order !== index
            ) {
              update(existingRouteTaskResult.url, [
                {member: "order", value: index},
                {member: "quantity", value},
              ]);
            }
          } else {
            // create RouteTaskResult
            const routePlanTaskResult = filteredRoutePlanTaskResultArray.find(
              (result) => result.specification === priceItemUrl,
            );
            const id = uuid();
            const url = instanceURL("routeTaskResult", id);
            const newInstance: RouteTaskResult = {
              ...emptyRouteTaskResult,
              activityOption: routeTaskActivityOption.url,
              id,
              order: index,
              quantity: value,
              quoted: routePlanTaskResult?.quoted ?? null,
              routeTask: routeTask.url,
              specification: priceItemUrl,
              url,
            };
            create(newInstance);
          }
          filteredRouteTaskResultArray.forEach((result) => {
            if (!priceItemValues.has(result.specification)) {
              remove(result.url);
            }
          });
        });
      } else {
        // create RouteTaskActivityOption and RouteTaskResult instances
        const activityOptionId = uuid();
        const activityOptionUrl = instanceURL("routeTaskActivityOption", activityOptionId);
        const newRouteTaskActivityOption: RouteTaskActivityOption = {
          activity: selectedPriceGroupURL,
          id: activityOptionId,
          routeTask: routeTask.url,
          url: activityOptionUrl,
        };
        create(newRouteTaskActivityOption);
        Array.from(priceItemValues).forEach(([priceItemUrl, value], index) => {
          // create RouteTaskResult
          const routePlanTaskResult = filteredRoutePlanTaskResultArray.find(
            (result) => result.specification === priceItemUrl,
          );
          const id = uuid();
          const url = instanceURL("routeTaskResult", id);
          const newInstance: RouteTaskResult = {
            ...emptyRouteTaskResult,
            activityOption: newRouteTaskActivityOption.url,
            id,
            order: index,
            quantity: value,
            quoted: routePlanTaskResult?.quoted ?? null,
            routeTask: routeTask.url,
            specification: priceItemUrl,
            url,
          };
          create(newInstance);
        });
      }
    } else if (routePlanTask) {
      // create RouteTask, RouteTaskActivityOption and RouteTaskResult instances
      const routeTaskID = uuid();
      const routeTaskURL = instanceURL("routeTask", routeTaskID);
      const newRouteTask: RouteTask = {
        ...emptyRouteTask,
        activity: selectedPriceGroupURL,
        completed: now.toISOString(),
        customer: routePlanTask.customer,
        deadline: routePlanTask.deadline,
        description: routePlanTask.description,
        id: routeTaskID,
        notesFromManager: routePlanTask.notesFromManager,
        notesFromTaskAssignee,
        order: routePlanTask.order,
        project: routePlanTask.project,
        relatedLocation: routePlanTask.relatedLocation,
        route: task.url,
        url: routeTaskURL,
      };
      create(newRouteTask);
      const activityOptionId = uuid();
      const activityOptionUrl = instanceURL("routeTaskActivityOption", activityOptionId);
      const newRouteTaskActivityOption: RouteTaskActivityOption = {
        activity: selectedPriceGroupURL,
        id: activityOptionId,
        routeTask: routeTaskURL,
        url: activityOptionUrl,
      };
      create(newRouteTaskActivityOption);
      Array.from(priceItemValues).forEach(([priceItemUrl, value], index) => {
        // create RouteTaskResult
        const routePlanTaskResult = filteredRoutePlanTaskResultArray.find(
          (result) => result.specification === priceItemUrl,
        );
        const id = uuid();
        const url = instanceURL("routeTaskResult", id);
        const newInstance: RouteTaskResult = {
          ...emptyRouteTaskResult,
          activityOption: newRouteTaskActivityOption.url,
          id,
          order: index,
          quantity: value,
          quoted: routePlanTaskResult?.quoted ?? null,
          routeTask: routeTaskURL,
          specification: priceItemUrl,
          url,
        };
        create(newInstance);
      });
    }
    if (this.props.customerSettings.geolocation.registerPositionOnTimerClick) {
      registerTaskPosition(this.props.task.url);
    }
  }
  @bind
  handleRouteTaskCompletedDialogCancel(): void {
    this.setState({
      routeTaskCompletedDialogEditing: false,
      routeTaskCompletedDialogOpenFor: null,
    });
  }
  @bind
  handleRouteTaskCompletedDialogDelete(): void {
    const {routeTaskResultArray, update} = this.props;
    if (!this.state.routeTaskCompletedDialogOpenFor) {
      return;
    }
    const {routeTaskUrl} = this.state.routeTaskCompletedDialogOpenFor;
    if (!routeTaskUrl) {
      return;
    }
    console.assert(this.state.routeTaskCompletedDialogEditing);
    this.setState({
      routeTaskCompletedDialogEditing: false,
      routeTaskCompletedDialogOpenFor: null,
    });
    routeTaskResultArray.forEach((routeTaskResult) => {
      if (routeTaskResult.routeTask === routeTaskUrl) {
        update(routeTaskResult.url, [{member: "quantity", value: null}]);
      }
    });
    update(routeTaskUrl, [
      {member: "activity", value: null},
      {member: "completed", value: null},
      {member: "notesFromTaskAssignee", value: ""},
    ]);
  }
  @bind
  handleMissingBreakDialogOk(): void {
    this.setState({
      completedDialogOpen: true,
      missingBreakDialogOpen: false,
    });
  }
  @bind
  handleMissingBreakDialogCancel(): void {
    this.setState({
      missingBreakDialogOpen: false,
    });
  }
  @bind
  handleSelectMachineButton(): void {
    this.setState({machineDialogOpen: true});
  }
  @bind
  handleMachineDialogCancel(): void {
    this.setState({machineDialogOpen: false});
  }
  @bind
  handleMachineDialogOk(url: MachineUrl): void {
    this.setState({machineDialogOpen: false});
    const {task, update} = this.props;
    if (machineAlreadySelected(task, url)) {
      return;
    }
    const oldMachineUseSet = task.machineuseSet || [];
    const newEntry: MachineUse = {
      machine: url,
      priceGroup: null,
      transporter: false,
    };
    const newMachineUseSet = [...oldMachineUseSet, newEntry];
    update(task.url, [{member: "machineuseSet", value: newMachineUseSet}]);
  }
  @bind
  handleRemoveMachine(index: number): void {
    const {
      customerSettings,
      machineLookup,
      priceGroupLookup,
      reportingSpecificationLookup,
      task,
      timerArray,
      timerMinutesMap,
      workTypeLookup,
    } = this.props;
    const machineUse = task.machineuseSet[index];
    if (!machineUse) {
      return;
    }
    const machineUrl = machineUse.machine;
    const machineRemovalBlockedReason = machineRemovalBlocked(
      task,
      machineUrl,
      timerMinutesMap,
      customerSettings,
      {
        machineLookup,
        priceGroupLookup,
        reportingSpecificationLookup,
        timerArray,
        workTypeLookup,
      },
    );
    if (machineRemovalBlockedReason) {
      this.setState({
        machineRemovalBlockedDialogOpen: true,
        machineRemovalBlockedReason,
      });
    } else {
      const oldMachineUseSet = task.machineuseSet || [];
      const newMachineUseSet = oldMachineUseSet.slice();
      newMachineUseSet.splice(index, 1);
      this.props.update(task.url, [{member: "machineuseSet", value: newMachineUseSet}]);
    }
  }
  @bind
  handleMachineRemovalBlockedDialogClose(): void {
    this.setState({machineRemovalBlockedDialogOpen: false});
  }
  @bind
  handleGoToTaskEdit(): void {
    const {task} = this.props;
    const taskID = urlToId(task.url);
    this.props.go("/taskEdit/:id", {id: taskID});
  }
  @bind
  handleLogEditClick(routeTaskUrl: RouteTaskUrl): void {
    const {routePlanTaskArray, routeTaskLookup} = this.props;
    const routeTask = routeTaskLookup(routeTaskUrl);
    const routePlanTask = routeTask
      ? routePlanTaskArray.find(
          (r) =>
            r.customer === routeTask.customer &&
            r.project === routeTask.project &&
            r.relatedLocation === routeTask.relatedLocation &&
            r.description === routeTask.description &&
            r.order === routeTask.order,
        )
      : undefined;
    this.setState({
      routeTaskCompletedDialogEditing: true,
      routeTaskCompletedDialogOpenFor: {
        routePlanTaskUrl: routePlanTask?.url,
        routeTaskUrl,
      },
    });
  }
  @bind
  handleNotesDialogClose(): void {
    this.setState({
      notesDialogDismissed: true,
    });
  }
  @bind
  handlePhotoDisplay(taskPhoto: TaskPhoto | UserPhoto): void {
    this.setState({
      displayedImage: taskPhoto,
    });
  }
  @bind
  handleDisplayImageRequestClose(): void {
    window.setTimeout(() => {
      this.setState({
        displayedImage: null,
      });
    }, 0);
  }
  @bind
  handleRequestPhotoDelete(taskPhotoURL: string): void {
    this.setState({
      deletingPhoto: taskPhotoURL,
    });
  }
  @bind
  handlePhotoDeleteDialogCancel(): void {
    this.setState({
      deletingPhoto: null,
    });
  }
  @bind
  handlePhotoDeleteDialogOk(): void {
    if (this.state.deletingPhoto != null) {
      this.props.remove(this.state.deletingPhoto);
      this.setState({
        deletingPhoto: null,
      });
    }
  }
  @bind
  handleRequestFileDelete(taskFileURL: string): void {
    this.setState({
      deletingFile: taskFileURL,
    });
  }
  @bind
  handleFileDeleteDialogCancel(): void {
    this.setState({
      deletingFile: null,
    });
  }
  @bind
  handleFileDeleteDialogOk(): void {
    if (this.state.deletingFile != null) {
      this.props.remove(this.state.deletingFile);
      this.setState({
        deletingFile: null,
      });
    }
  }
  @bind
  handleRequestBuildReport(): void {
    const {
      create,
      locationLookup,
      priceGroupLookup,
      priceItemLookup,
      routeTaskActivityOptionLookup,
      routeTaskArray,
      routeTaskResultArray,
      task,
      unitLookup,
      userUserProfileLookup,
      workTypeLookup,
    } = this.props;
    const {currentUserURL} = this.props;
    const taskURL = task.url;
    const completedRouteTaskList = _.sortBy(
      routeTaskArray
        .filter((routeTask) => routeTask.route === taskURL)
        .filter((routeTask) => routeTask.completed),
      (routeTask) => routeTask.completed,
    );
    const completedRouteTaskURLSet = new Set(
      completedRouteTaskList.map((routeTask) => routeTask.url),
    );
    const routeTaskResultSet = new Set(
      routeTaskResultArray.filter((routeTaskResult) =>
        completedRouteTaskURLSet.has(routeTaskResult.routeTask),
      ),
    );
    const routeTaskResultsPerRouteTask = new Map<string, Set<RouteTaskResult>>();
    routeTaskResultSet.forEach((routeTaskResult) => {
      const key = routeTaskResult.routeTask;
      const existingForRouteTask = routeTaskResultsPerRouteTask.get(key);
      if (existingForRouteTask) {
        existingForRouteTask.add(routeTaskResult);
      } else {
        routeTaskResultsPerRouteTask.set(key, new Set([routeTaskResult]));
      }
    });

    const taskEntries = completedRouteTaskList.map((routeTask) =>
      getRouteTaskData(
        routeTask,
        locationLookup,
        routeTaskResultsPerRouteTask.get(routeTask.url) || new Set(),
        routeTaskActivityOptionLookup,
        priceGroupLookup,
        priceItemLookup,
        unitLookup,
        // we include the fields that are not "relevantForExecution" in PDF...
        true,
      ),
    );
    const sums = computeSums(taskEntries);
    const machineOperatorURL = task.machineOperator;
    const machineOperatorProfile = machineOperatorURL
      ? userUserProfileLookup(machineOperatorURL)
      : null;
    const workTypeURL = task.workType;
    const workTypeInstance = workTypeURL ? workTypeLookup(workTypeURL) : undefined;
    const workTypeString = workTypeInstance ? getWorkTypeString(workTypeInstance) : "";
    const data = {
      sums: mapToObject(sums),
      task: {machineOperator: machineOperatorURL, url: taskURL},
      taskEntryList: taskEntries,
      userList: machineOperatorProfile
        ? [{initials: machineOperatorProfile.alias, url: machineOperatorURL}]
        : [],
      userProfileList: machineOperatorProfile ? [machineOperatorProfile] : [],
      workTypeString,
    };
    const id = uuid();
    const url = instanceURL("routeLogReport", id);
    const deviceTimestamp = new Date().toISOString();
    create({
      createdBy: currentUserURL as UserUrl,
      deviceTimestamp,
      id,
      inputData: data,
      route: taskURL,
      url,
    });
  }
  @bind
  handleTabChange(_event: React.ChangeEvent<unknown>, value: string): void {
    this.props.putQueryKey("tab", value);
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings} = this.props;
    const {showMachineOperatorInitialsOnTaskInstance, showMachineOperatorNameOnTaskInstance} =
      this.props.customerSettings;
    const {
      accomodationAllowanceArray,
      activeTimer,
      genericPrimaryTimer,
      intervals,
      machineLookup,
      now,
      priceGroupLookup,
      priceItemLookup,
      tab,
      task,
      timerArray,
      timerLookup,
      timerMinutesMap,
      unitLookup,
      update,
      userUserProfileLookup,
      workTypeLookup,
    } = this.props;
    const {currentUserURL} = this.props;
    const role = this.props.currentRole;
    const userIsManager = !!role && role.manager;
    const userIsOnlyMachineOperator = !!role && !role.manager;
    const userIsJobber = !!role && role.jobber;
    const userIsOther = !task || task.machineOperator !== currentUserURL;
    const userIsSeniorMachineOperator = role && role.seniorMachineOperator;
    const hasActivity = !!intervals.length;
    const allowSeniorToEdit = userIsSeniorMachineOperator && !hasActivity;
    const userIsOtherMachineOperator =
      userIsOther && userIsOnlyMachineOperator && !allowSeniorToEdit;
    const completed = !!(task ? task.completed : true);
    const validated = !!(task ? task.validatedAndRecorded || task.reportApproved : false);
    let finalStartTime;
    let finalEndTime;
    if (intervals.length) {
      finalStartTime = intervals[0].fromTimestamp;
      finalEndTime = intervals[intervals.length - 1].toTimestamp || now.toISOString();
    }
    const genericPrimaryTimerLabel = getTaskGenericPrimaryTimerLabel(
      task,
      workTypeLookup,
      priceGroupLookup,
      customerSettings,
    );
    const secondaryTimers = new Set(
      getTaskSecondaryTimerList(task, customerSettings, {
        machineLookup,
        priceGroupLookup,
        timerArray,
        workTypeLookup,
      }),
    );
    const intervalsWithTimers = intervals
      .map((interval) => {
        const intervalTimerURL = interval.timer;
        const timer = intervalTimerURL ? timerLookup(intervalTimerURL) : undefined;
        if (timer) {
          return {...interval, timer};
        } else {
          return undefined;
        }
      })
      .filter(notUndefined);
    const transportMachineUse = task ? (task.machineuseSet || []).find((u) => u.transporter) : null;
    const transportMachineMap = new Map<string, Machine>();
    if (task) {
      const priceItemUseSet = sortByOrderMember(Object.values(task.priceItemUses || {}));
      const machineuseSet = task.machineuseSet || [];
      machineuseSet.forEach((machineUse) => {
        const priceGroupURL = machineUse.priceGroup;
        const hasHoursLine = priceItemUseSet.some((priceItemUse) => {
          const priceItemURL = priceItemUse.priceItem;
          const priceItem = priceItemLookup(priceItemURL);
          if (
            priceItem &&
            priceItem.priceGroup === priceGroupURL &&
            (priceItem.itemtype === ITEM_TYPE_TIMER || priceItem.genericEffectiveTimerTarget)
          ) {
            const unitLower = getUnitCode(priceItem, unitLookup).toLowerCase();
            if (
              unitLower === "time" ||
              unitLower === "timer" ||
              unitLower === "tim" ||
              unitLower === "tim." ||
              unitLower === "time(r)"
            ) {
              return true;
            }
          }
          return false;
        });
        if (hasHoursLine) {
          const machineURL = machineUse.machine;
          const machine = machineLookup(machineURL);
          if (machine) {
            transportMachineMap.set(machineURL, machine);
          }
        }
      });
    }
    const transportMachine = transportMachineUse
      ? transportMachineMap.get(transportMachineUse.machine)
      : null;

    let workTypeString;
    const workTypeURL = task ? task.workType : null;
    const workTypeInstance = workTypeURL ? workTypeLookup(workTypeURL) : undefined;
    if (workTypeInstance) {
      workTypeString = getWorkTypeString(workTypeInstance);
    }

    const machineOperatorURL = task ? task.machineOperator : null;
    const machineOperatorProfile = machineOperatorURL
      ? userUserProfileLookup(machineOperatorURL)
      : null;
    const machineOperatorInitials = machineOperatorProfile ? machineOperatorProfile.alias : null;
    let machineOperatorString = "";
    if (machineOperatorProfile) {
      const {alias, name} = machineOperatorProfile;
      if (
        showMachineOperatorNameOnTaskInstance &&
        showMachineOperatorInitialsOnTaskInstance &&
        name &&
        alias
      ) {
        machineOperatorString = ` - ${name} (${alias})`;
      } else if (showMachineOperatorNameOnTaskInstance && name) {
        machineOperatorString = ` - ${name}`;
      } else if (showMachineOperatorInitialsOnTaskInstance && alias) {
        machineOperatorString = ` - ${alias}`;
      }
    }

    const disableDelete =
      validated ||
      (userIsOnlyMachineOperator &&
        (userIsOther ||
          completed ||
          hasActivity ||
          (task.createdBy !== currentUserURL &&
            !this.props.customerSettings.machineOperatorCanDeleteAssignedTasks)));

    const order = (task.order && this.props.orderLookup(task.order)) || undefined;
    const hidePrimaryTimer = !!(
      workTypeInstance &&
      customerSettings.hidePrimaryTimeButtonForExternalWorktypes.includes(
        workTypeInstance.identifier,
      )
    );

    const priceGroup = task.priceGroup ? priceGroupLookup(task.priceGroup) : undefined;

    let tabContent;
    if (tab === "time") {
      const timeCard = (
        <TimeCard
          activeTimer={activeTimer}
          completed={completed}
          customerSettings={this.props.customerSettings}
          finalEndTime={finalEndTime}
          finalStartTime={finalStartTime}
          genericPrimaryTimer={genericPrimaryTimer}
          genericPrimaryTimerLabel={genericPrimaryTimerLabel}
          hidePrimaryButton={hidePrimaryTimer}
          intervals={intervalsWithTimers}
          now={now.toISOString()}
          secondaryTimers={secondaryTimers}
          task={task}
          timerMinutes={timerMinutesMap}
          transportMachine={transportMachine || undefined}
          update={update}
          userIsOnlyMachineOperator={userIsOnlyMachineOperator}
          userIsOther={userIsOther}
          userIsOtherMachineOperator={userIsOtherMachineOperator}
          validated={validated}
          onRequestAddMachineOperatorTimeCorrection={
            completed || validated || userIsOther
              ? undefined
              : this.handleRequestAddMachineOperatorTimeCorrection
          }
          onRequestAddManagerTimeCorrection={
            validated || userIsOnlyMachineOperator
              ? undefined
              : this.handleRequestAddManagerTimeCorrection
          }
          onTimelineIntervalClick={
            editTimeCorrectionsAllowed({
              taskCompleted: completed,
              taskValidated: validated,
              userIsManager,
              userIsOther,
            })
              ? this.handleTimelineIntervalClick
              : undefined
          }
          onTimerButton={this.handleTimerButton}
        />
      );
      const deletable =
        !validated && (!completed || !userIsOnlyMachineOperator) && !userIsOtherMachineOperator;
      let machineChips = null;
      const entries = task.machineuseSet || [];
      const machineRemovalAllowed =
        !task.order || !!customerSettings.allowCustomerTaskMachineChange;
      const renderedEntries = entries.map((entry, index) => {
        const machineURL = entry.machine;
        let machineID = "";
        let machineName = "";
        const foundMachine = machineLookup(machineURL);
        if (foundMachine) {
          machineID = foundMachine.c5_machine;
          machineName = foundMachine.name;
        }
        const text = machineName + (machineID ? ` (${machineID})` : "");

        return (
          <MachineChip
            key={index}
            deletable={deletable && machineRemovalAllowed}
            index={index}
            text={text}
            onDelete={this.handleRemoveMachine}
          />
        );
      });
      machineChips = <div>{renderedEntries}</div>;
      const machineCard = (
        <Card style={{margin: "1em"}}>
          <CardContent>
            <Grid>
              <Cell style={{width: "100%"}}>
                <FormattedMessage defaultMessage="Maskine" tagName="h4" />
                <Button
                  color="secondary"
                  disabled={completed || validated || userIsOtherMachineOperator}
                  style={{marginBottom: 8, width: "100%"}}
                  variant="contained"
                  onClick={this.handleSelectMachineButton}
                >
                  {formatMessage(messages.selectMachineButton)}
                </Button>
                {machineChips}
              </Cell>
            </Grid>
          </CardContent>
        </Card>
      );
      tabContent = (
        <div>
          {timeCard}
          {machineCard}
        </div>
      );
    } else if (tab === "route") {
      tabContent = (
        <RouteTab
          order={order}
          task={task}
          onRouteTaskCompleteClick={this.handleRouteTaskCompleteClick}
          onStartClick={this.handleRouteTaskStartClick}
        />
      );
    } else if (tab === "log") {
      tabContent = (
        <LogTab
          hasActivity={hasActivity}
          order={order}
          task={task}
          onEditClick={this.handleLogEditClick}
          onRequestBuildReport={this.handleRequestBuildReport}
        />
      );
    } else if (tab === "info") {
      const createdByURL = task ? task.createdBy : null;
      const createdByUserProfile = createdByURL ? userUserProfileLookup(createdByURL) : null;
      const createdByInitials = createdByUserProfile ? createdByUserProfile.alias : null;
      tabContent = (
        <Card style={{margin: "1em"}}>
          <CardContent>
            <Grid>
              <Cell size="10/12">
                <h5 style={{marginBottom: 24}}>
                  <FormattedMessage
                    defaultMessage="Oprettet af: {createdByInitials}"
                    id="route.label.created-by"
                    values={{createdByInitials}}
                  />
                </h5>
              </Cell>
              <Cell>
                <IconButton
                  disabled={userIsOtherMachineOperator || userIsJobber}
                  style={{float: "right"}}
                  onClick={this.handleGoToTaskEdit}
                >
                  <PencilIcon color="#000" />
                </IconButton>
              </Cell>
            </Grid>
            <Grid>
              <Cell>
                <FormattedMessage defaultMessage="Opgaveansvarlig" id="route.header.responsible" />
                <h4>{machineOperatorInitials}</h4>
              </Cell>
            </Grid>
            {task.date ? (
              <Grid>
                <Cell>
                  <FormattedMessage defaultMessage="Dato" id="route.label.date" />
                  <h2>{formatDate(task.date)}</h2>
                </Cell>
              </Grid>
            ) : null}
            {task.time ? (
              <Grid>
                <Cell>
                  <FormattedMessage defaultMessage="Klokkeslæt" id="route.label.expected-start" />
                  <h1>{formatTime(task.time)}</h1>
                </Cell>
              </Grid>
            ) : null}
            <Grid>
              <Cell>
                <FormattedMessage
                  defaultMessage="Noter fra administration:"
                  id="route.header.notes-from-administration"
                  tagName="h4"
                />
                <Linkify>{task.notesFromManager}</Linkify>
              </Cell>
            </Grid>
          </CardContent>
        </Card>
      );
    } else if (tab === "photos") {
      tabContent = (
        <PhotosTab
          completed={completed}
          task={task}
          userIsOnlyMachineOperator={userIsOnlyMachineOperator}
          userIsOtherMachineOperator={userIsOtherMachineOperator}
          validated={validated}
          onPhotoDisplay={this.handlePhotoDisplay}
          onRequestFileDelete={this.handleRequestFileDelete}
          onRequestPhotoDelete={this.handleRequestPhotoDelete}
        />
      );
    } else if (tab === "invoicing" && !userIsOnlyMachineOperator) {
      tabContent = <RouteInvoicingTab completed={completed} task={task} validated={validated} />;
    } else if (tab === "geolocation" && !userIsOnlyMachineOperator) {
      tabContent = <GeolocationTab taskURL={task.url} />;
    }

    let canRegisterAccommodation = false;
    let accommodationAllowanceRegistratedOnTaskDate = false;
    if (this.state.completedDialogOpen) {
      canRegisterAccommodation =
        !!machineOperatorProfile &&
        userCanRegisterAccommodation(customerSettings, machineOperatorProfile);
      accommodationAllowanceRegistratedOnTaskDate =
        canRegisterAccommodation &&
        !!accomodationAllowanceArray.find(
          (accommodationAllowance) =>
            accommodationAllowance.employee === machineOperatorURL &&
            accommodationAllowance.date === task.date,
        );
    }

    let computedStartTime;
    let computedEndTime;
    if (this.props.computedIntervals.length) {
      computedStartTime = this.props.computedIntervals[0].fromTimestamp;
      computedEndTime =
        this.props.computedIntervals[this.props.computedIntervals.length - 1].toTimestamp ||
        now.toISOString();
    }

    let primaryMinutes = 0;
    if (
      !!this.state.routeTaskCompletedDialogOpenFor &&
      !this.state.routeTaskCompletedDialogEditing
    ) {
      const {
        // routePlanTaskUrl,
        routeTaskUrl,
      } = this.state.routeTaskCompletedDialogOpenFor;
      let started: string | undefined;
      if (this.props.customerSettings.routeTaskStartStop) {
        const currentRouteTask = routeTaskUrl
          ? this.props.routeTaskLookup(routeTaskUrl)
          : undefined;
        started = currentRouteTask?.started || undefined;
      } else {
        let previousCompletedRouteTaskTimestamp: string | undefined;
        const taskURL = task.url;
        this.props.routeTaskArray.forEach((routeTask) => {
          if (routeTask.route !== taskURL) {
            return;
          }
          const completedTimestamp = routeTask.completed;
          if (
            completedTimestamp &&
            (!previousCompletedRouteTaskTimestamp ||
              completedTimestamp > previousCompletedRouteTaskTimestamp)
          ) {
            previousCompletedRouteTaskTimestamp = completedTimestamp;
          }
        });
        if (previousCompletedRouteTaskTimestamp) {
          started = previousCompletedRouteTaskTimestamp;
        } else if (finalStartTime) {
          started = finalStartTime;
        }
      }
      let minuteTruncatedStartTime: string | undefined;
      if (started) {
        minuteTruncatedStartTime = minuteTruncatedTimestamp(started);
      }
      const nowString = now.toISOString();
      if (minuteTruncatedStartTime && minuteTruncatedStartTime < nowString) {
        const overlapping = overlappingIntervals(intervals, minuteTruncatedStartTime, nowString);
        const genericPrimaryTimerURL = genericPrimaryTimer.url;
        overlapping.forEach((interval) => {
          if (interval.timer === genericPrimaryTimerURL) {
            primaryMinutes += intervalMinutes(interval);
          }
        });
        primaryMinutes = Math.round(primaryMinutes);
      }
    }

    const finalIntervals = intervals.map((interval) => ({
      ...interval,
      timer: (interval.timer && timerLookup(interval.timer)) || null,
    }));

    const dialogs = [
      <AddTimeCorrectionDialog
        key="correction-dialog"
        defaultDate={task.date}
        genericPrimaryTimer={this.props.genericPrimaryTimer}
        genericPrimaryTimerLabel={genericPrimaryTimerLabel}
        hidePrimaryTimer={hidePrimaryTimer}
        legalIntervals={this.props.legalIntervals}
        open={
          this.state.managerTimeCorrectionDialogOpen ||
          this.state.machineOperatorTimeCorrectionDialogOpen
        }
        secondaryTimers={secondaryTimers}
        onCancel={this.handleCorrectionDialogCancel}
        onOk={this.handleCorrectionDialogOk}
      />,
      <EditTimeCorrectionDialog
        key="correction-move-dialog"
        correctionDisabled={
          validated || userIsOtherMachineOperator || (completed && userIsOnlyMachineOperator)
        }
        fromTimestamp={this.state.editIntervalStart}
        genericPrimaryTimer={this.props.genericPrimaryTimer}
        genericPrimaryTimerLabel={genericPrimaryTimerLabel}
        hidePrimaryTimer={hidePrimaryTimer}
        legalIntervals={this.props.legalIntervals}
        open={!!(this.state.editIntervalStart || this.state.editIntervalEnd)}
        secondaryTimers={secondaryTimers}
        timer={this.state.editIntervalTimer}
        toTimestamp={this.state.editIntervalEnd}
        onCancel={this.handleTimelineEditIntervalDialogCancel}
        onOk={this.handleTimelineEditIntervalDialogOk}
      />,
      <TaskWarningDialogs
        key="warning-dialogs"
        oldTaskTimerStart={!!this.state.oldTaskTimerStart}
        oldTimerTimerStart={!!this.state.oldTimerTimerStart}
        requestedActiveTimer={!!this.state.requestedActiveTimer}
        task={this.props.task}
        onActiveRouteWarningDialogCancel={this.handleStopTimerDialogCancel}
        onOldTaskDialogCancel={this.handleOldTaskOpenCancel}
        onOldTaskDialogOk={this.handleOldTaskOpenOk}
        onOtherDateDialogCancel={this.handleOtherDateDialogCancel}
        onOtherDateDialogOk={this.handleOtherDateDialogOk}
        onStopTimerDialogCancel={this.handleStopTimerDialogCancel}
        onStopTimerDialogOk={this.handleStopTimerDialogOk}
      />,
      <DeleteDialog
        key="delete-dialog"
        open={this.state.deleteDialogOpen}
        onCancel={this.handleDeleteDialogCancel}
        onOk={this.handleDeleteDialogOk}
      >
        <FormattedMessage defaultMessage="Slet opgave?" id="task-instance.label.do-delete-task" />
      </DeleteDialog>,
      <RouteTaskCompletedDialog
        key="part-completed-dialog"
        editing={this.state.routeTaskCompletedDialogEditing}
        open={!!this.state.routeTaskCompletedDialogOpenFor}
        primaryMinutes={primaryMinutes}
        routePlanTaskUrl={this.state.routeTaskCompletedDialogOpenFor?.routePlanTaskUrl}
        routeTaskUrl={this.state.routeTaskCompletedDialogOpenFor?.routeTaskUrl}
        onCancel={this.handleRouteTaskCompletedDialogCancel}
        onDelete={this.handleRouteTaskCompletedDialogDelete}
        onOk={this.handleRouteTaskCompletedDialogOk}
      />,
      <ConnectedMachineDialogWithoutSmallMachines
        key="machine-dialog"
        open={this.state.machineDialogOpen}
        priceGroup={priceGroup}
        workType={workTypeInstance}
        onCancel={this.handleMachineDialogCancel}
        onOk={this.handleMachineDialogOk}
      />,
      <NotesDialog
        key="notes-dialog"
        dismissed={this.state.notesDialogDismissed}
        order={order}
        task={task}
        onRequestClose={this.handleNotesDialogClose}
      />,
      <PhotoDisplayDialog
        key="photo-display-dialog"
        instance={this.state.displayedImage || undefined}
        onRequestClose={this.handleDisplayImageRequestClose}
      />,
      <DeleteDialog
        key="delete-photo-dialog"
        open={!!this.state.deletingPhoto}
        onCancel={this.handlePhotoDeleteDialogCancel}
        onOk={this.handlePhotoDeleteDialogOk}
      >
        <FormattedMessage defaultMessage="Slet foto?" id="route.label.do-delete-photo" />
      </DeleteDialog>,
      <DeleteDialog
        key="delete-file-dialog"
        open={!!this.state.deletingFile}
        onCancel={this.handleFileDeleteDialogCancel}
        onOk={this.handleFileDeleteDialogOk}
      >
        <FormattedMessage defaultMessage="Slet fil?" id="route.label.do-delete-file" />
      </DeleteDialog>,
      <CompletedDialog
        key="completed-dialog"
        accommodationAllowanceRegistratedOnTaskDate={accommodationAllowanceRegistratedOnTaskDate}
        cancelled={false}
        canRegisterAccommodation={canRegisterAccommodation}
        completedAsInternal={false}
        continuation={false}
        customerSettings={this.props.customerSettings}
        finalIntervals={finalIntervals}
        open={this.state.completedDialogOpen}
        secondaryTimers={secondaryTimers}
        task={this.props.task}
        onAction={this.handleCompletedValidatedDialogAction}
        onCancel={this.handleCompletedDialogCancel}
        onOk={this.handleCompletedDialogOk}
      />,
      <ValidatedDialog
        key="validate-dialog"
        cancelled={task && task.cancelled}
        computedEndTime={computedEndTime}
        computedIntervals={this.props.computedIntervals}
        computedStartTime={computedStartTime}
        continuation={false}
        customerSettings={customerSettings}
        finalEndTime={finalEndTime}
        finalIntervals={finalIntervals}
        finalStartTime={finalStartTime}
        genericPrimaryTimer={this.props.genericPrimaryTimer}
        open={this.state.validatedDialogOpen}
        primaryWorkType={workTypeInstance}
        secondaryTimers={secondaryTimers}
        task={this.props.task}
        onAction={this.handleCompletedValidatedDialogAction}
        onCancel={this.handleValidatedDialogCancel}
        onOk={this.handleValidatedDialogOk}
      />,
      <ValidatedDialog
        key="approve-dialog"
        cancelled={task && task.cancelled}
        computedEndTime={computedEndTime}
        computedIntervals={this.props.computedIntervals}
        computedStartTime={computedStartTime}
        continuation={false}
        customerSettings={customerSettings}
        finalEndTime={finalEndTime}
        finalIntervals={finalIntervals}
        finalStartTime={finalStartTime}
        genericPrimaryTimer={this.props.genericPrimaryTimer}
        open={this.state.approvedDialogOpen}
        primaryWorkType={workTypeInstance}
        secondaryTimers={secondaryTimers}
        task={this.props.task}
        onAction={this.handleCompletedValidatedDialogAction}
        onCancel={this.handleApprovedDialogCancel}
        onOk={this.handleApprovedDialogOk}
      />,
      <MachineRemovalBlockedDialog
        key="machine-removal-blocked-dialog"
        blockedReason={this.state.machineRemovalBlockedReason}
        open={this.state.machineRemovalBlockedDialogOpen}
        onClose={this.handleMachineRemovalBlockedDialogClose}
      />,
    ];

    if (this.props.customerSettings.askRegardingMissingBreakOnExternalTaskCompletion) {
      dialogs.push(
        <MissingBreakDialog
          key={"missing-break-dialog"}
          open={this.state.missingBreakDialogOpen}
          onCancel={this.handleMissingBreakDialogCancel}
          onOk={this.handleMissingBreakDialogOk}
        />,
      );
    }
    const routePlanURL = order && order.routePlan;
    const routePlan = routePlanURL ? this.props.routePlanLookup(routePlanURL) : undefined;
    let validateBlock: JSX.Element | null = null;
    if (!userIsOnlyMachineOperator) {
      validateBlock = (
        <Cell key="validated-button" palm="12/12" style={{paddingBottom: 8}}>
          <Button
            color="secondary"
            disabled={!completed || validated}
            style={{width: "100%"}}
            variant="contained"
            onClick={this.handleValidatedButton}
          >
            {formatMessage(messages.validate)}
          </Button>
        </Cell>
      );
    }
    const customerURL = order ? order.customer : null;
    const customer = customerURL ? this.props.customerLookup(customerURL) : null;
    const customerName = customer ? customer.name : "";
    const customerC5Account =
      customer && customerSettings.showC5AccountOnTaskInstanceAnList
        ? ` - ${customer.c5_account}`
        : "";

    return (
      <PageLayout
        dialogs={dialogs}
        tabs={
          <Tabs
            value={tab}
            variant={bowser.mobile ? "fullWidth" : "standard"}
            onChange={this.handleTabChange}
          >
            <Tab label={formatMessage(messages.timeTab)} value="time" />
            <Tab label={formatMessage(messages.routeTab)} value="route" />
            <Tab label={formatMessage(messages.infoTab)} value="info" />
            <Tab label={formatMessage(messages.logTab)} value="log" />
            <Tab label={formatMessage(messages.photosTab)} value="photos" />
            {this.props.customerSettings.enableInvoiceCorrections && !userIsOnlyMachineOperator ? (
              <Tab
                label={formatMessage(
                  bowser.mobile ? messages.invoicingTabShort : messages.invoicingTab,
                )}
                value="invoicing"
              />
            ) : null}
            {this.props.customerSettings.enableGPSList && !userIsOnlyMachineOperator ? (
              <Tab label={formatMessage(messages.GPS)} value="geolocation" />
            ) : null}
          </Tabs>
        }
        toolbar={formatMessage(messages.title)}
      >
        {!bowser.mobile || (bowser.mobile && tab !== "products") ? (
          <div style={{margin: "1em 1em 0"}}>
            <h3>{workTypeString}</h3>
            <h4>
              {routePlan ? routePlan.name : null}
              <div>{customerName + customerC5Account + machineOperatorString}</div>
            </h4>
            {task && task.cancelled && task.completed ? (
              <h4 style={{color: colorMap.ERROR}}>{formatMessage(messages.taskCancelled)}</h4>
            ) : null}
            {task && task.completedAsInternal && task.completed ? (
              <h4 style={{color: colorMap.ERROR}}>
                {formatMessage(messages.taskCompletedAsInternal)}
              </h4>
            ) : null}
          </div>
        ) : (
          <div style={{margin: "1em 1em 0"}} />
        )}
        {tabContent}
        <Grid style={{padding: "1em"}}>
          <Cell palm="12/12" style={{paddingBottom: 8}}>
            <ErrorColorButton
              disabled={completed || validated || userIsOtherMachineOperator || disableDelete}
              style={{width: "100%"}}
              variant="contained"
              onClick={this.handleDeleteButton}
            >
              {formatMessage(messages.delete)}
            </ErrorColorButton>
          </Cell>
          <Cell palm="12/12" style={{paddingBottom: 8}}>
            <Button
              color="secondary"
              disabled={completed || validated || userIsOtherMachineOperator}
              style={{width: "100%"}}
              variant="contained"
              onClick={this.handleCompletedButton}
            >
              {formatMessage(messages.deliverToAdministration)}
            </Button>
          </Cell>
          {validateBlock}
        </Grid>
      </PageLayout>
    );
  }
}

const ConnectedRoute: React.ComponentType<RouteOwnProps> = connect<
  RouteStateProps,
  RouteDispatchProps,
  RouteOwnProps,
  AppState
>(
  createStructuredSelector<AppState, RouteStateProps>({
    accomodationAllowanceArray: getAccomodationAllowanceArray,
    currentRole: getCurrentRole,
    currentUserSortedTimerStartArray: getCurrentUserSortedTimerStartArray,
    currentUserURL: getCurrentUserURL,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
    machineLookup: getMachineLookup,
    orderLookup: getOrderLookup,
    priceGroupLookup: getPriceGroupLookup,
    priceItemLookup: getPriceItemLookup,
    queryParameters: getQueryParameters,
    reportingSpecificationLookup: getReportingSpecificationLookup,
    routeLogReportArray: getRouteLogReportArray,
    routePlanLookup: getRoutePlanLookup,
    routePlanTaskActivityOptionArray: getRoutePlanTaskActivityOptionArray,
    routePlanTaskArray: getRoutePlanTaskArray,
    routePlanTaskLookup: getRoutePlanTaskLookup,
    routePlanTaskResultArray: getRoutePlanTaskResultArray,
    routeTaskActivityOptionArray: getRouteTaskActivityOptionArray,
    routeTaskActivityOptionLookup: getRouteTaskActivityOptionLookup,
    routeTaskArray: getRouteTaskArray,
    routeTaskLookup: getRouteTaskLookup,
    routeTaskResultArray: getRouteTaskResultArray,
    shareToken: getShareToken,
    tab: makeQueryParameterGetter("tab", "time"),
    taskArray: getTaskArray,
    taskFileArray: getTaskFileArray,
    taskLookup: getTaskLookup,
    taskPhotoArray: getTaskPhotoArray,
    timerArray: getTimerArray,
    timerLookup: getTimerLookup,
    timerStartArray: getTimerStartArray,
    token: getToken,
    unitLookup: getUnitLookup,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeArray: getWorkTypeArray,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    addToOffline: actions.addToOffline,
    back: actions.back,
    backSkip: actions.backSkip,
    create: actions.create,
    forwardBackSkip: actions.forwardBackSkip,
    go: actions.go,
    putQueryKey: actions.putQueryKey,
    registerTaskPosition: actions.registerTaskPosition,
    registerTimerStartPosition: actions.registerTimerStartPosition,
    remove: actions.remove,
    setMessage: actions.setMessage,
    update: actions.update,
  },
)(Route);

export {ConnectedRoute as Route};
