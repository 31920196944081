import {Config, PoolSpecification} from "@co-common-libs/config";
import {DaysAbsence, MachineUrl, PriceGroupUrl, WorkTypeUrl} from "@co-common-libs/resources";
import {
  HolidayCalendarLabel,
  getDateStringSequence,
  hourMinutesStringDaySeconds,
} from "@co-common-libs/utils";
import _ from "lodash";
import {
  BonusSpecification,
  CommonRemunerationSettings,
  CountBonusSpecification,
  InputBonusSpecification,
  InputCountBonusSpecification,
  InputIntervalMatchCriteria,
  InputRateOverrideSpecification,
  InputSwitchGroupSpecification,
  IntervalMatchCriteria,
  RateOverrideSpecification,
  RemunerationGroup,
  RemunerationGroupInput,
  SwitchGroupSpecification,
  WeekDayHoursRatesSpecification,
  WeekThresholdsSpecification,
} from "./types";

type Mutable<T> = {-readonly [P in keyof T]: T[P]};

export function monthDayStringToNumbers(monthDayString: string): [number, number] {
  const [monthString, dayString] = monthDayString.split("-");
  const month = parseInt(monthString);
  const day = parseInt(dayString);
  return [month, day];
}

function buildMatchCriteria(
  input: InputIntervalMatchCriteria,
  workTypeIDURLMapping: {readonly [id: string]: WorkTypeUrl},
  machineIDURLMapping: {readonly [id: string]: MachineUrl},
  priceGroupIDURLMapping: {readonly [id: string]: PriceGroupUrl},
): IntervalMatchCriteria {
  const result: Mutable<IntervalMatchCriteria> = {};
  if (input.workTypeID) {
    result.workTypeURL = workTypeIDURLMapping[input.workTypeID];
  }
  if (input.workTypeId) {
    result.workTypeURL = workTypeIDURLMapping[input.workTypeId];
  }
  if (input.machineID) {
    result.machineURL = machineIDURLMapping[input.machineID];
  }
  if (input.machineId) {
    result.machineURL = machineIDURLMapping[input.machineId];
  }
  if (input.priceGroupID) {
    result.priceGroupURL = priceGroupIDURLMapping[input.priceGroupID];
  }
  if (input.priceGroupId) {
    result.priceGroupURL = priceGroupIDURLMapping[input.priceGroupId];
  }
  if (input.departmentID !== undefined) {
    result.departmentID = input.departmentID;
  }
  if (input.departmentId !== undefined) {
    result.departmentID = input.departmentId;
  }
  if (input.taskPriceGroupID) {
    result.taskPriceGroupURL = priceGroupIDURLMapping[input.taskPriceGroupID];
  }
  if (input.taskPriceGroupId) {
    result.taskPriceGroupURL = priceGroupIDURLMapping[input.taskPriceGroupId];
  }
  if (input.taskWorkTypeID) {
    result.taskWorkTypeURL = workTypeIDURLMapping[input.taskWorkTypeID];
  }
  if (input.taskWorkTypeId) {
    result.taskWorkTypeURL = workTypeIDURLMapping[input.taskWorkTypeId];
  }
  if (input.rate !== undefined) {
    result.rate = input.rate;
  }
  if (input.customerTask !== undefined) {
    result.customerTask = input.customerTask;
  }
  if (input.effectiveTime !== undefined) {
    result.effectiveTime = input.effectiveTime;
  }
  if (input.checkInterval !== undefined) {
    const {fromTime, toTime} = input.checkInterval;
    const fromDaySeconds = hourMinutesStringDaySeconds(fromTime);
    const toDaySeconds = hourMinutesStringDaySeconds(toTime);
    result.checkInterval = {
      fromDaySeconds,
      toDaySeconds,
    };
  }
  if (input.checkDates !== undefined) {
    const {fromDate, toDate} = input.checkDates;
    const [fromMonth, fromDay] = monthDayStringToNumbers(fromDate);
    const [toMonth, toDay] = monthDayStringToNumbers(toDate);
    result.checkDates = {fromDay, fromMonth, toDay, toMonth};
  }
  if (input.dayOfTheWeek !== undefined) {
    result.dayOfTheWeek = input.dayOfTheWeek;
  }
  if (input.nextDayIsHoliday !== undefined) {
    result.nextDayIsHoliday = input.nextDayIsHoliday;
  }
  if (input.afterThreshold) {
    result.afterThreshold = input.afterThreshold;
  }
  return result;
}

function buildBonusSpecification(
  input: InputBonusSpecification,
  workTypeIDURLMapping: {readonly [id: string]: WorkTypeUrl},
  machineIDURLMapping: {readonly [id: string]: MachineUrl},
  priceGroupIDURLMapping: {readonly [id: string]: PriceGroupUrl},
): BonusSpecification {
  const criteria = buildMatchCriteria(
    input,
    workTypeIDURLMapping,
    machineIDURLMapping,
    priceGroupIDURLMapping,
  );
  const result: BonusSpecification = {...criteria, label: input.label};
  return result;
}

export function buildBonusSpecifications(
  bonusSpecifications: readonly InputBonusSpecification[] | null | undefined,
  workTypeIDURLMapping: {readonly [id: string]: WorkTypeUrl},
  machineIDURLMapping: {readonly [id: string]: MachineUrl},
  priceGroupIDURLMapping: {readonly [id: string]: PriceGroupUrl},
): BonusSpecification[] {
  if (bonusSpecifications) {
    return bonusSpecifications.map((inputBonus) =>
      buildBonusSpecification(
        inputBonus,
        workTypeIDURLMapping,
        machineIDURLMapping,
        priceGroupIDURLMapping,
      ),
    );
  } else {
    return [];
  }
}

function buildRateOverrideSpecification(
  input: InputRateOverrideSpecification,
  workTypeIDURLMapping: {readonly [id: string]: WorkTypeUrl},
  machineIDURLMapping: {readonly [id: string]: MachineUrl},
  priceGroupIDURLMapping: {readonly [id: string]: PriceGroupUrl},
): RateOverrideSpecification {
  const criteria = buildMatchCriteria(
    input,
    workTypeIDURLMapping,
    machineIDURLMapping,
    priceGroupIDURLMapping,
  );
  const result: RateOverrideSpecification = {
    ...criteria,
    resultingRate: input.resultingRate,
  };
  return result;
}

function buildRateOverrideSpecifications(
  rateOverrideSpecifications: readonly InputRateOverrideSpecification[] | null | undefined,
  workTypeIDURLMapping: {readonly [id: string]: WorkTypeUrl},
  machineIDURLMapping: {readonly [id: string]: MachineUrl},
  priceGroupIDURLMapping: {readonly [id: string]: PriceGroupUrl},
): RateOverrideSpecification[] {
  if (rateOverrideSpecifications) {
    return rateOverrideSpecifications.map((inputRateOverride) =>
      buildRateOverrideSpecification(
        inputRateOverride,
        workTypeIDURLMapping,
        machineIDURLMapping,
        priceGroupIDURLMapping,
      ),
    );
  } else {
    return [];
  }
}

function buildSwitchGroupsSpecification(
  input: InputSwitchGroupSpecification,
  workTypeIDURLMapping: {readonly [id: string]: string},
): SwitchGroupSpecification {
  const result: Mutable<SwitchGroupSpecification> = _.omit(
    input,
    "workTypeID",
    "workTypeId",
    "departmentId",
  );
  if (input.workTypeID) {
    result.workTypeURL = workTypeIDURLMapping[input.workTypeID];
  }
  if (input.workTypeId) {
    result.workTypeURL = workTypeIDURLMapping[input.workTypeId];
  }
  if (input.departmentId) {
    result.departmentID = input.departmentId;
  }
  return result;
}

function buildSwitchGroupsSpecifications(
  switchGroupSpecifications: readonly InputSwitchGroupSpecification[] | null | undefined,
  workTypeIDURLMapping: {readonly [id: string]: string},
): SwitchGroupSpecification[] {
  if (switchGroupSpecifications) {
    return switchGroupSpecifications.map((inputSwitchGroup) =>
      buildSwitchGroupsSpecification(inputSwitchGroup, workTypeIDURLMapping),
    );
  } else {
    return [];
  }
}

function buildCountBonusSpecification(
  input: InputCountBonusSpecification,
): CountBonusSpecification {
  const result: Mutable<CountBonusSpecification> = {
    label: input.label,
    multiplier: input.multiplier != null ? input.multiplier : 1,
  };
  if (input.priceItemUUID) {
    result.priceItemUUID = input.priceItemUUID;
  }
  if (input.priceItemUuid) {
    result.priceItemUUID = input.priceItemUuid;
  }
  return result;
}

function buildCountBonusSpecifications(
  countBonusSpecifications: readonly InputCountBonusSpecification[] | null | undefined,
): CountBonusSpecification[] {
  if (countBonusSpecifications) {
    return countBonusSpecifications.map((inputCountBonusSpecification) =>
      buildCountBonusSpecification(inputCountBonusSpecification),
    );
  } else {
    return [];
  }
}

const emptyHoursRates: WeekDayHoursRatesSpecification = [[], [], [], [], [], [], []];

const emptyOvertimeThresholds: WeekThresholdsSpecification = [[], [], [], [], [], [], []];

function buildHolidays(
  extraHolidays: {readonly [date: string]: string} | null | undefined,
  extraHolidayAbsenceTypes: readonly string[] | null | undefined,
  daysAbsenceList: readonly DaysAbsence[],
): ReadonlyMap<string, string> {
  const result = extraHolidays ? new Map(Object.entries(extraHolidays)) : new Map<string, string>();
  if (extraHolidayAbsenceTypes && extraHolidayAbsenceTypes.length) {
    daysAbsenceList.forEach((absence) => {
      if (extraHolidayAbsenceTypes.includes(absence.absenceType)) {
        const label = absence.note || "X";
        getDateStringSequence(absence.fromDate, absence.toDate).forEach((dateString) => {
          if (!result.has(dateString)) {
            result.set(dateString, label);
          }
        });
      }
    });
  }
  return result;
}

const defaultHolidayCalendars: readonly HolidayCalendarLabel[] = ["Helligdage i Danmark"];

export function getRemunerationGroupHolidayCalendars(
  remunerationGroup: RemunerationGroupInput | undefined,
): readonly HolidayCalendarLabel[] {
  return remunerationGroup?.holidayCalendars || defaultHolidayCalendars;
}

export function buildGroupOptions(
  input: RemunerationGroupInput,
  settings: Pick<Config, "dayEndRounding" | "dayStartRounding">,
  workTypeIDURLMapping: {readonly [id: string]: WorkTypeUrl},
  machineIDURLMapping: {readonly [id: string]: MachineUrl},
  priceGroupIDURLMapping: {readonly [id: string]: PriceGroupUrl},
  daysAbsenceList: readonly DaysAbsence[],
): RemunerationGroup {
  const result: RemunerationGroup = {
    accomodationAllowance: input.accomodationAllowance || false,
    accumulateCompensatoryLimit:
      input.accumulateCompensatoryLimit != null ? input.accumulateCompensatoryLimit : null,
    calendarDayBonus: buildBonusSpecifications(
      input.calendarDayBonus,
      workTypeIDURLMapping,
      machineIDURLMapping,
      priceGroupIDURLMapping,
    ),
    compensatoryMultiplier:
      input.compensatoryMultiplier != null ? input.compensatoryMultiplier : null,
    compensatorySubtractOnly: input.compensatorySubtractOnly || null,
    countBonus: buildCountBonusSpecifications(input.countBonus),
    countWeekdayHolidays: !!input.countWeekdayHolidays,
    dayEndRounding:
      input.dayEndRounding !== undefined ? input.dayEndRounding : settings.dayEndRounding,
    dayStartRounding:
      input.dayStartRounding !== undefined ? input.dayStartRounding : settings.dayStartRounding,
    extraHalfHolidays: buildHolidays(
      input.extraHalfHolidays,
      input.extraHalfHolidayAbsenceTypes,
      daysAbsenceList,
    ),
    extraHolidays: buildHolidays(
      input.extraHolidays,
      input.extraHolidayAbsenceTypes,
      daysAbsenceList,
    ),
    forcedUnpaidBreakMinutes:
      input.forcedUnpaidBreakMinutes != null ? input.forcedUnpaidBreakMinutes : null,
    halfHolidayHalfThresholds: input.halfHolidayHalfThresholds || false,
    halfHolidayHoursRates: input.halfHolidayHoursRates || null,
    halfHolidaySundayAfterNoon: input.halfHolidaySundayAfterNoon || false,
    holidayCalendars: getRemunerationGroupHolidayCalendars(input),
    hoursRates: input.hoursRates || emptyHoursRates,
    ignoreTimerIDs: input.ignoreTimers || [],
    intervalBonus: buildBonusSpecifications(
      input.intervalBonus,
      workTypeIDURLMapping,
      machineIDURLMapping,
      priceGroupIDURLMapping,
    ),
    overtimeThresholds: input.overtimeThresholds || emptyOvertimeThresholds,
    paidBreaks: input.paidBreaks || false,
    pools:
      input.pools && Object.keys(input.pools).length ? (input.pools as PoolSpecification) : null,
    projectDistanceBonusLabel: input.projectDistanceBonusLabel || null,
    projectTravelTimeBonusLabel: input.projectTravelTimeBonusLabel || null,
    rateOverride: buildRateOverrideSpecifications(
      input.rateOverride,
      workTypeIDURLMapping,
      machineIDURLMapping,
      priceGroupIDURLMapping,
    ),
    rateSwitch: buildRateOverrideSpecifications(
      input.rateSwitch,
      workTypeIDURLMapping,
      machineIDURLMapping,
      priceGroupIDURLMapping,
    ),
    reportIgnoreAbsenceOnHolidays: input.reportIgnoreAbsenceOnHolidays || false,
    specialStartRateMinutes:
      input.specialStartRateMinutes != null ? input.specialStartRateMinutes : null,
    switchGroups: buildSwitchGroupsSpecifications(input.switchGroups, workTypeIDURLMapping),
    taskBonus: buildBonusSpecifications(
      input.taskBonus,
      workTypeIDURLMapping,
      machineIDURLMapping,
      priceGroupIDURLMapping,
    ),
    workDayBonus: buildBonusSpecifications(
      input.workDayBonus,
      workTypeIDURLMapping,
      machineIDURLMapping,
      priceGroupIDURLMapping,
    ),
  };
  return result;
}

export function buildCommonOptions(
  settings: Config,
  workTypeIDURLMapping: {readonly [id: string]: string},
): CommonRemunerationSettings {
  const result: CommonRemunerationSettings = {
    paidDayAbsenceTypes: settings.paidDayAbsenceTypes,
    paidTransportWorkType: settings.remunerationPaidTransportWorkType
      ? workTypeIDURLMapping[settings.remunerationPaidTransportWorkType]
      : undefined,
    periodSplitThresholdMinutes: settings.periodSplitThresholdMinutes,
    unregisteredBreakAfterMinutes: settings.unregisteredBreakAfterMinutes,
    validAbsenceTypes: settings.remunerationAbsenceTypes,
    workDaySplitThresholdMinutes: settings.workDaySplitThresholdMinutes,
  };
  return result;
}
