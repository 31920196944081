import {Config} from "@co-common-libs/config";
import {
  Culture,
  CultureUrl,
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Machine,
  MachineUse,
  Order,
  PatchUnion,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUse,
  Product,
  ProductUse,
  Project,
  ProjectUrl,
  Task,
  Timer,
  TimerStart,
  TimerUrl,
  Unit,
  UnitUrl,
  WorkType,
  WorkTypeUrl,
  urlToId,
} from "@co-common-libs/resources";
import {formatDuration, getUnitString, priceItemIsVisible} from "@co-common-libs/resources-utils";
import {MINUTE_MILLISECONDS, formatTime} from "@co-common-libs/utils";
import {TrimTextField} from "@co-frontend-libs/components";
import {
  AppState,
  PathTemplate,
  actions,
  getCultureLookup,
  getCustomerLookup,
  getCustomerSettings,
  getExtendedCustomerSettings,
  getLocationLookup,
  getPriceGroupLookup,
  getProjectLookup,
  getTimerLookup,
  getTimerStartArray,
  getUnitLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {colorMap} from "@co-frontend-libs/utils";
import {Checkbox, IconButton, TableCell, TableRow} from "@material-ui/core";
import {grey, red, yellow} from "@material-ui/core/colors";
import {
  ButtonStyle,
  EditCustomerButton,
  Linkify,
  OrderIconLink,
  TaskStatusIcon,
} from "app-components";
import {PureComponent, concurrencyAllowedForTask, getPriceItemNameAndIdentifier} from "app-utils";
import {bind} from "bind-decorator";
import ImageIcon from "mdi-react/ImageIcon";
import PencilIcon from "mdi-react/PencilIcon";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect, useSelector} from "react-redux";
import {createStructuredSelector} from "reselect";
import {generateVariableBookeepingInfo} from "../../bookkeeping/utils";
import {
  MINUTES_STYLE,
  orderIconLinkColumnStyle,
  photoIconColumnStyle,
  statusIconColumnStyle,
} from "./style";

const messages = defineMessages({
  ours: {
    defaultMessage: "vores",
    id: "task-table.label.ours",
  },
  referenceNumber: {
    defaultMessage: "Referencenr.",
    id: "task-table.label.reference-number",
  },
  theirs: {
    defaultMessage: "deres",
    id: "task-table.label.theirs",
  },
});

const CustomerName = React.memo(function CustomerName({
  customer,
  task,
}: {
  customer: Customer;
  task: Task;
}): JSX.Element {
  const {
    customer: {canManage},
    customerApprovalGate,
  } = useSelector(getExtendedCustomerSettings);

  const customerMissingRemoteUrl = !customer.remoteUrl;

  return (
    <EditCustomerButton
      allowChangeCurrentTaskOnly
      buttonStyle={ButtonStyle.TEXT}
      color={
        customerMissingRemoteUrl && canManage
          ? customerApprovalGate === "TASK_BOOKKEEPING"
            ? "warning"
            : "error"
          : "default"
      }
      customer={customer}
      disabled={!canManage}
      taskUrl={task.url}
    />
  );
});

interface TaskRowStateProps {
  cultureLookup: (url: CultureUrl) => Culture | undefined;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  projectLookup: (url: ProjectUrl) => Project | undefined;
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerStartArray: readonly TimerStart[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface TaskRowDispatchProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface TaskRowOwnProps {
  customer?: Customer | undefined;
  customerHasActiveProjects: boolean;
  department: string;
  externalPrimaryMinutes: number;
  hasOverlap: boolean;
  hasPhoto: boolean;
  isManager: boolean;
  machineUseList: readonly (Omit<MachineUse, "machine"> & {
    machine: Machine | undefined;
  })[];
  nextTask?: Task | undefined;
  order?: Order | undefined;
  previousTask?: Task | undefined;
  priceItemUseList: (Omit<PriceItemUse, "priceItem"> & {
    priceItem: PriceItem | undefined;
  })[];
  productUseList: (Omit<ProductUse, "product"> & {
    product: Product | undefined;
  })[];
  project?: Project | undefined;
  showDepartment: boolean;
  task: Task;
  totalRegistered: number;
  workType?: WorkType | undefined;
}

type TaskRowProps = TaskRowDispatchProps & TaskRowOwnProps & TaskRowStateProps;

class TaskRow extends PureComponent<TaskRowProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleGoToTaskEdit(event: React.MouseEvent<unknown, MouseEvent>): void {
    event.stopPropagation();
    const {task} = this.props;
    const taskID = urlToId(task.url);
    const orderURL = task ? task.order : null;
    if (orderURL) {
      const {order} = this.props;
      if (order && order.routePlan) {
        this.props.go("/taskEdit/:id", {id: taskID});
      } else {
        const orderID = urlToId(orderURL);
        this.props.go("/order/:id/:taskID", {id: orderID, taskID});
      }
    } else {
      this.props.go("/internalTask/:id", {id: urlToId(task.url)});
    }
  }
  @bind
  handleRowClick(): void {
    const id = urlToId(this.props.task.url);
    this.props.go("/task/:id", {id});
  }
  @bind
  handlePhotoClick(): void {
    const id = urlToId(this.props.task.url);
    this.props.go("/task/:id", {id}, {tab: "photos"});
  }
  // eslint-disable-next-line class-methods-use-this
  @bind
  handleReferenceNumberClick(event: React.SyntheticEvent): void {
    event.stopPropagation();
  }
  @bind
  handleCalledInChanged(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    this.props.update(this.props.task.url, [
      {
        member: "calledIn",
        value: checked,
      },
    ]);
  }

  @bind
  handleTaskReferenceNumberChange(value: string): void {
    const {task, update} = this.props;
    update(task.url, [{member: "referenceNumber", value}]);
  }
  @bind
  handleOrderReferenceNumberChange(value: string): void {
    const {task, update} = this.props;
    if (task.order) {
      update(task.order, [{member: "referenceNumber", value}]);
    }
  }

  getDepartmentLabel = (departmentID: string): string =>
    this.props.customerSettings.departments[departmentID] || departmentID;

  render(): JSX.Element {
    const {formatMessage, formatNumber} = this.context;
    const {
      cultureLookup,
      customer,
      customerHasActiveProjects,
      customerLookup,
      customerSettings,
      department,
      externalPrimaryMinutes,
      go,
      hasOverlap,
      hasPhoto,
      isManager,
      locationLookup,
      machineUseList,
      nextTask,
      order,
      previousTask,
      priceGroupLookup,
      priceItemUseList,
      productUseList,
      project,
      projectLookup,
      showDepartment,
      task,
      timerLookup,
      timerStartArray,
      totalRegistered,
      unitLookup,
      update,
      workType,
      workTypeLookup,
      ...others
    } = this.props;

    const {fielduseSet} = task;

    const variableBookeepingInfo = generateVariableBookeepingInfo(
      {
        cultureURL: (order && order.culture) || null,
        customerURL: null,
        fielduseSet,
        pickupLocationURL: task.relatedPickupLocation,
        projectURL: project ? project.url : null,
        task,
        workplaceURL: task.relatedWorkplace,
      },
      {
        cultureLookup,
        customerLookup,
        customerSettings,
        locationLookup,
        projectLookup,
      },
    );

    const variableBookeepingInfoWithNewlines: React.ReactNode[] = [];
    if (customer) {
      variableBookeepingInfoWithNewlines.push(
        <CustomerName key={customer.name} customer={customer} task={task} />,
      );
    }
    variableBookeepingInfo.forEach((info, infoIndex) => {
      variableBookeepingInfoWithNewlines.push(info);
      variableBookeepingInfoWithNewlines.push(<br key={infoIndex} />);
    });
    if (variableBookeepingInfoWithNewlines.length > 1) {
      variableBookeepingInfoWithNewlines.pop();
    }

    let workTypeString;
    if (workType) {
      if (customerSettings.bookkeepingWorkTypeAsWorkTypeAndVariant) {
        const priceGroupURL = task ? task.priceGroup : null;
        const priceGroup = priceGroupURL ? priceGroupLookup(priceGroupURL) : null;
        if (priceGroup) {
          workTypeString = `${workType.name} (${priceGroup.name})`;
        } else {
          workTypeString = workType.name;
        }
      } else {
        workTypeString = workType.name;
      }
    }

    let projectWarning;
    if (
      customerSettings.enableProjects &&
      !project &&
      customerHasActiveProjects &&
      customerSettings.projectMissingValidateError &&
      (!customerSettings.onlyEnableProjectsForDepartments.length ||
        customerSettings.onlyEnableProjectsForDepartments.includes(task.department))
    ) {
      projectWarning = (
        <span style={{color: red[500], fontWeight: "bold"}}>
          {customerSettings.projectLabelVariant === "PROJECT" ? (
            <FormattedMessage defaultMessage="Projekt mangler" />
          ) : (
            <FormattedMessage defaultMessage="Sag mangler" />
          )}
        </span>
      );
    }

    const machines = machineUseList
      .map((machineUse) => {
        const {machine} = machineUse;
        if (machine) {
          if (customerSettings.bookkeepingMachineNames) {
            const {name} = machine;
            const identifier = machine.c5_machine;
            if (name && identifier) {
              return `${name} (${identifier})`;
            } else if (name) {
              return name;
            } else if (identifier) {
              return identifier;
            } else {
              return "";
            }
          } else {
            return machine.c5_machine;
          }
        } else {
          return "";
        }
      })
      .join(", ");
    const {materialDecimals} = customerSettings;
    const productRows: JSX.Element[] = [];
    productUseList.forEach((instance, index) => {
      if (customerSettings.bookkeepingDayHideBlankProducts && instance.count == null) {
        return;
      }

      const count =
        instance.count != null
          ? formatNumber(instance.count, {
              maximumFractionDigits: materialDecimals,
            })
          : formatMessage({defaultMessage: "?"});
      const {ours} = instance;
      const {product} = instance;
      const note = instance.notes;
      let productString = null;
      let unitString = null;
      if (product) {
        productString = `${product.name} (${product.catalogNumber})`;
        unitString = getUnitString(product, unitLookup);
      }
      if (customerSettings.showOursToggle) {
        const oursString = ours ? formatMessage(messages.ours) : formatMessage(messages.theirs);
        productRows.push(
          <TableRow key={`product${index}`}>
            <TableCell>{productString}</TableCell>
            <TableCell>
              {count} {unitString} ({oursString})
            </TableCell>
          </TableRow>,
        );
      } else {
        productRows.push(
          <TableRow key={`product${index}`}>
            <TableCell>{productString}</TableCell>
            <TableCell>
              {count} {unitString}
            </TableCell>
          </TableRow>,
        );
      }
      if (note) {
        productRows.push(
          <TableRow key={`product-note${index}`}>
            <TableCell />
            <TableCell>
              <em>
                <Linkify>{note}</Linkify>
              </em>
            </TableCell>
          </TableRow>,
        );
      }
    });
    const priceItemRows: JSX.Element[] = [];
    priceItemUseList.forEach((instance, index) => {
      const {priceItem} = instance;
      if (!priceItem || !priceItemIsVisible(priceItem, false, priceItemUseList, unitLookup)) {
        return;
      }
      if (customerSettings.bookkeepingDayHideBlankPriceItems && instance.count == null) {
        return;
      }
      let value;
      if (instance.count === null) {
        value = formatMessage({defaultMessage: "?"});
      } else if (instance.priceItem?.booleanSelection) {
        value = instance.count
          ? formatMessage({defaultMessage: "Ja"})
          : formatMessage({defaultMessage: "Nej"});
      } else {
        value = formatNumber(instance.count, {
          maximumFractionDigits: materialDecimals,
        });
      }
      const note = instance.notes;
      priceItemRows.push(
        <TableRow key={`priceitem${index}`}>
          <TableCell>{getPriceItemNameAndIdentifier(priceItem, customerSettings)}</TableCell>
          <TableCell>
            {value} {getUnitString(priceItem, this.props.unitLookup)}
          </TableCell>
        </TableRow>,
      );
      if (note) {
        priceItemRows.push(
          <TableRow key={`priceitem-note${index}`}>
            <TableCell />
            <TableCell>
              <em>
                <Linkify>{note}</Linkify>
              </em>
            </TableCell>
          </TableRow>,
        );
      }
    });

    const rowStyle: React.CSSProperties = {cursor: "pointer"};

    const start = task.workFromTimestamp;
    const end = task.workToTimestamp;
    if (task.validatedAndRecorded) {
      rowStyle.color = grey[500];
    }

    let startStyle = MINUTES_STYLE;
    if (previousTask && previousTask.workToTimestamp) {
      const previousEnd = previousTask.workToTimestamp;
      console.assert(previousTask);
      if (
        hasOverlap &&
        !customerSettings.concurrentTasksAllowed &&
        start &&
        previousEnd > start &&
        (!concurrencyAllowedForTask(task, workTypeLookup, customerSettings) ||
          !concurrencyAllowedForTask(previousTask, workTypeLookup, customerSettings))
      ) {
        const overlapMilliseconds = new Date(previousEnd).valueOf() - new Date(start).valueOf();
        const overlapMinutes = overlapMilliseconds / MINUTE_MILLISECONDS;
        if (overlapMinutes > customerSettings.taskOverlapWarningAfterMinutes) {
          startStyle = Object.assign({}, MINUTES_STYLE, {
            backgroundColor: red[100],
          });
        }
      } else if (start) {
        const inactivityMilliseconds = new Date(start).valueOf() - new Date(previousEnd).valueOf();
        const inactivityMinutes = inactivityMilliseconds / MINUTE_MILLISECONDS;
        if (
          inactivityMinutes > customerSettings.unregisteredWarningAfterMinutes &&
          inactivityMinutes < customerSettings.unregisteredBreakAfterMinutes
        ) {
          startStyle = Object.assign({}, MINUTES_STYLE, {
            backgroundColor: yellow[100],
          });
        }
      }
    }
    let endStyle = MINUTES_STYLE;
    if (nextTask && nextTask.workFromTimestamp) {
      const nextStart = nextTask.workFromTimestamp;
      if (
        hasOverlap &&
        !customerSettings.concurrentTasksAllowed &&
        end &&
        end > nextStart &&
        (!concurrencyAllowedForTask(task, workTypeLookup, customerSettings) ||
          !concurrencyAllowedForTask(nextTask, workTypeLookup, customerSettings))
      ) {
        const overlapMilliseconds = new Date(end).valueOf() - new Date(nextStart).valueOf();
        const overlapMinutes = overlapMilliseconds / MINUTE_MILLISECONDS;
        if (overlapMinutes > customerSettings.taskOverlapWarningAfterMinutes) {
          endStyle = Object.assign({}, MINUTES_STYLE, {
            backgroundColor: red[100],
          });
        }
      } else if (end) {
        const inactivityMilliseconds = new Date(nextStart).valueOf() - new Date(end).valueOf();
        const inactivityMinutes = inactivityMilliseconds / MINUTE_MILLISECONDS;
        if (
          inactivityMinutes > customerSettings.unregisteredWarningAfterMinutes &&
          inactivityMinutes < customerSettings.unregisteredBreakAfterMinutes
        ) {
          endStyle = Object.assign({}, MINUTES_STYLE, {
            backgroundColor: yellow[100],
          });
        }
      }
    }

    const noteBlockStyle: React.CSSProperties = customerSettings.bookkeepingDayRespectNoteLineBreaks
      ? {whiteSpace: "pre-line"}
      : {};
    const {notesFromManager} = task;
    const orderNotes = this.props.order && this.props.order.notes;

    let notesFromManagerBlock;
    if (notesFromManager || orderNotes) {
      notesFromManagerBlock = (
        <span style={noteBlockStyle}>
          A:{" "}
          {notesFromManager ? (
            <>
              <Linkify>{notesFromManager}</Linkify>
              <br />
            </>
          ) : null}
          {orderNotes ? (
            <>
              <Linkify>{orderNotes}</Linkify>
              <br />
            </>
          ) : null}
        </span>
      );
    }

    const managerInternalNotes = [this.props.order?.managerInternalNotes, task.managerInternalNotes]
      .filter(Boolean)
      .join(". ");

    let managerInternalNotesBlock;
    if (managerInternalNotes) {
      managerInternalNotesBlock = (
        <span style={noteBlockStyle}>
          I: <Linkify>{managerInternalNotes}</Linkify>
          <br />
        </span>
      );
    }
    const {notesFromMachineOperator, timernotesSet} = task;
    let notesFromMachineOperatorBlock;
    if (notesFromMachineOperator) {
      notesFromMachineOperatorBlock = (
        <span style={noteBlockStyle}>
          M: <Linkify>{notesFromMachineOperator}</Linkify>
          <br />
        </span>
      );
    }

    let timerNotesBlock;
    if (timernotesSet && timernotesSet.length) {
      timerNotesBlock = (
        <span style={noteBlockStyle}>
          {timernotesSet.map((entry, index) => {
            const timer = timerLookup(entry.timer);
            const timerLabel = timer ? timer.label : "";
            return (
              <Linkify key={index}>
                {timerLabel}: {entry.notes}
                <br />
              </Linkify>
            );
          })}
        </span>
      );
    }

    let invoiceNoteBlock;
    if (customerSettings.showInvoiceNote && isManager) {
      const {invoiceNote} = task;
      if (invoiceNote) {
        invoiceNoteBlock = (
          <span style={noteBlockStyle}>
            F: <Linkify>{invoiceNote}</Linkify>
            <br />
          </span>
        );
      }
    }
    let materialsTable: JSX.Element | undefined;
    if (priceItemRows.length || productRows.length) {
      materialsTable = (
        <table>
          <tbody>
            {productRows}
            {priceItemRows}
          </tbody>
        </table>
      );
    }
    let orderIconLinkColumn;
    let statusIconColumn;
    if (isManager) {
      let orderIconLink;
      if (customerSettings.orderListPageEnabled && task.order && !(order && order.routePlan)) {
        orderIconLink = <OrderIconLink go={go} url={task.order} />;
      }
      orderIconLinkColumn = <TableCell style={orderIconLinkColumnStyle}>{orderIconLink}</TableCell>;

      statusIconColumn = (
        <TableCell style={statusIconColumnStyle} onClick={this.handleRowClick}>
          <TaskStatusIcon task={task} timerStartArray={timerStartArray} />
        </TableCell>
      );
    }

    let photoButton;
    if (hasPhoto) {
      photoButton = (
        <IconButton color="primary" onClick={this.handlePhotoClick}>
          <ImageIcon />
        </IconButton>
      );
    }
    const photoIconColumn = <TableCell style={photoIconColumnStyle}>{photoButton}</TableCell>;

    let taskReferenceNumberColumn: JSX.Element | undefined;
    if (customerSettings.enableTaskReferenceNumber) {
      taskReferenceNumberColumn = (
        <TableCell style={{width: 200}} onClick={this.handleReferenceNumberClick}>
          <TrimTextField
            fullWidth
            disabled={task.validatedAndRecorded}
            inputProps={{maxLength: 255}}
            label={
              customerSettings.taskReferenceNumberLabel || formatMessage(messages.referenceNumber)
            }
            margin="dense"
            value={task.referenceNumber || ""}
            onChange={this.handleTaskReferenceNumberChange}
          />
        </TableCell>
      );
    }
    let orderReferenceNumberColumn: JSX.Element | undefined;
    if (customerSettings.enableOrderReferenceNumber) {
      if (order) {
        orderReferenceNumberColumn = (
          <TableCell style={{width: 200}} onClick={this.handleReferenceNumberClick}>
            <TrimTextField
              fullWidth
              disabled={
                !customerSettings.allowOrderReferenceNumberEdit || task.validatedAndRecorded
              }
              inputProps={{maxLength: 255}}
              label={
                customerSettings.orderReferenceNumberLabel ||
                formatMessage(messages.referenceNumber)
              }
              margin="dense"
              value={order.referenceNumber || ""}
              onChange={this.handleOrderReferenceNumberChange}
            />
          </TableCell>
        );
      } else {
        orderReferenceNumberColumn = <TableCell style={{width: 200}} />;
      }
    }

    let cancelled;
    if (task.cancelled) {
      cancelled = (
        <span style={{color: colorMap.ERROR}}>
          <FormattedMessage defaultMessage="Opgaven er lukket som aflyst" />
          <br />
        </span>
      );
    }

    let completedAsInternal;
    if (task.completedAsInternal) {
      completedAsInternal = (
        <span style={{color: colorMap.ERROR}}>
          <FormattedMessage defaultMessage="Opgaven er afleveret som intern" />
          <br />
        </span>
      );
    }

    let calledInCell;
    if (customerSettings.showCalledInDialogAfterMinutes) {
      calledInCell = (
        <TableCell style={{width: 70}}>
          {task.order ? (
            <Checkbox
              checked={!!task.calledIn}
              disabled={task.validatedAndRecorded}
              onChange={this.handleCalledInChanged}
            />
          ) : null}
        </TableCell>
      );
    }

    const customerColumnPaddingLeft = orderIconLinkColumn ? 0 : 24;
    return (
      <TableRow {...others} style={rowStyle}>
        <TableCell
          style={{
            whiteSpace: "normal",

            width: 102 + (customerSettings.bookkeepingMachineNames ? 104 : 0),
          }}
          onClick={this.handleRowClick}
        >
          {machines}
        </TableCell>
        <TableCell style={{width: 206}} onClick={this.handleRowClick}>
          {workTypeString}
        </TableCell>
        {showDepartment ? (
          <TableCell style={{width: 206}} onClick={this.handleRowClick}>
            {this.getDepartmentLabel(department)}
          </TableCell>
        ) : null}
        {orderIconLinkColumn}
        {orderReferenceNumberColumn}
        {taskReferenceNumberColumn}
        <TableCell
          style={{
            paddingLeft: customerColumnPaddingLeft,
            paddingRight: 0,
            width: 206,
          }}
        >
          <IconButton style={{float: "right"}} onClick={this.handleGoToTaskEdit}>
            <PencilIcon color="#000" />
          </IconButton>
          {variableBookeepingInfoWithNewlines}
          {projectWarning}
        </TableCell>
        <TableCell style={startStyle} onClick={this.handleRowClick}>
          {formatTime(start || undefined)}
        </TableCell>
        <TableCell style={endStyle} onClick={this.handleRowClick}>
          {formatTime(end || undefined)}
        </TableCell>
        <TableCell style={MINUTES_STYLE} onClick={this.handleRowClick}>
          {formatDuration(customerSettings.durationFormat, totalRegistered)}
        </TableCell>
        <TableCell style={MINUTES_STYLE} onClick={this.handleRowClick}>
          <strong>{formatDuration(customerSettings.durationFormat, externalPrimaryMinutes)}</strong>
        </TableCell>
        <TableCell onClick={this.handleRowClick}>
          {cancelled}
          {completedAsInternal}
          {notesFromManagerBlock}
          {managerInternalNotesBlock}
          {notesFromMachineOperatorBlock}
          {timerNotesBlock}
          {invoiceNoteBlock}
          {materialsTable}
        </TableCell>
        {calledInCell}
        {statusIconColumn}
        {photoIconColumn}
      </TableRow>
    );
  }
}

const ConnectedTaskRow: React.ComponentType<TaskRowOwnProps> = connect<
  TaskRowStateProps,
  TaskRowDispatchProps,
  TaskRowOwnProps,
  AppState
>(
  createStructuredSelector<AppState, TaskRowStateProps>({
    cultureLookup: getCultureLookup,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
    priceGroupLookup: getPriceGroupLookup,
    projectLookup: getProjectLookup,
    timerLookup: getTimerLookup,
    timerStartArray: getTimerStartArray,
    unitLookup: getUnitLookup,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    go: actions.go,
    update: actions.update,
  },
)(TaskRow);

export {ConnectedTaskRow as TaskRow};
