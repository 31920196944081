import {Config} from "@co-common-libs/config";
import {
  Contact,
  Customer,
  CustomerUrl,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  PatchUnion,
  RoutePlan,
  RoutePlanUrl,
  Task,
  TaskUrl,
  TimerStart,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  urlToId,
} from "@co-common-libs/resources";
import {
  DAY_MILLISECONDS,
  dateFromString,
  dateStringDifference,
  dateToString,
  firstDate,
  identifierComparator,
  lastDate,
} from "@co-common-libs/utils";
import {PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {grey} from "@material-ui/core/colors";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import React from "react";
import {FILTER_BAR_HEIGHT, FilterBar} from "../filter-bar";
import {
  INCREMENT,
  INITIAL,
  ORDER_CALENDAR_COLUMN_WIDTH,
  PARTIALLY_PLANNED_ORDER_HEIGHT,
  THRESHOLD,
} from "./constants";
import {DayColumn} from "./day-column";
import {DayColumnHeader} from "./day-column-header";
import {OrderBlock} from "./order-block";
import {PartiallyPlannedOrderBlock} from "./partially-planned-order-block";
import {UnplannedOrderBlock} from "./unplanned-order-block";

const EMPTY_MAP = new Map();

const getFirstTaskWorkTypeURL = (taskSeq: readonly Task[]): WorkTypeUrl | null => {
  const oldestTaskWithWorkType = _.minBy(
    taskSeq.filter((t) => t.workType),
    (task) => task.created,
  );
  if (oldestTaskWithWorkType) {
    return oldestTaskWithWorkType.workType;
  }
  return null;
};

interface OrderTabContentProps {
  allFolded: boolean;
  completedOrderMap: ReadonlyMap<string, boolean>;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  customerURLToDefaultContactMap: ReadonlyMap<string, Contact>;
  dateToOrderGroupedTaskListMap: ReadonlyMap<string, ReadonlyMap<OrderUrl, readonly Task[]>>;
  dateToOrderMap: ReadonlyMap<string, readonly Order[]>;
  dndMode: boolean;
  dndSave: boolean;
  filteredOrderLookup: (url: OrderUrl) => Order | undefined;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  isManager: boolean;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  onRequestFilterClear?: () => void;
  onRequestWorkTypeFilterClear?: () => void;
  orderToTaskListMap: ReadonlyMap<string, readonly Task[]>;
  partiallyPlannedOrders: readonly Order[];
  periodEnd: string;
  periodLengthDays: number;
  periodStart: string;
  plannedTasksMap: ReadonlyMap<string, readonly Task[]>;
  putQueryKeys: (
    update: {readonly [key: string]: string | undefined},
    navigationKind?: PartialNavigationKind,
  ) => void;
  routePlanLookup: (url: RoutePlanUrl) => RoutePlan | undefined;
  scrollX: string;
  scrollY: string;
  selectedDate: string;
  selectedDepartmentIdentifierSet?: ReadonlySet<string>;
  selectedWorkTypeURLSet?: ReadonlySet<string>;
  taskArray: readonly Task[];
  timerStartArray: readonly TimerStart[];
  unplannedOrders: readonly Order[];
  update: (url: string, patch: PatchUnion) => void;
  userIsOnlyMachineOperator: boolean;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeArray: readonly WorkType[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface OrderTabContentState {
  firstScrollVisibleDay: number;
  movingOrderMap: ReadonlyMap<
    OrderUrl,
    {
      readonly days: number;
      readonly fromDate: string | null;
      readonly toDate: string;
    }
  >;
  movingTaskMap: ReadonlyMap<
    TaskUrl,
    {
      readonly days: number;
      readonly fromDate: string | null;
      readonly toDate: string;
    }
  >;
  scrollbarWidth: number;
}

export class PureOrderTabContent extends PureComponent<OrderTabContentProps, OrderTabContentState> {
  state: OrderTabContentState = {
    firstScrollVisibleDay: 0,
    movingOrderMap: new Map(),
    movingTaskMap: new Map(),
    scrollbarWidth: 0,
  };
  constructor(props: OrderTabContentProps) {
    super(props);
    this.updateScrollQuery = _.debounce((left, top) => {
      const scrollX = `${left}`;
      const scrollY = `${top}`;
      this.props.putQueryKeys({
        scrollX,
        scrollY,
      });
    }, 200);
  }

  componentDidMount(): void {
    this.scrollToSelectedDate();
    const scrollNode = this._calendarScrollContainer.current;
    if (scrollNode) {
      scrollNode.addEventListener("scroll", this.handleCalendarScroll);
      const {scrollX, scrollY} = this.props;
      if (scrollX) {
        const left = parseFloat(scrollX);
        if (!isNaN(left)) {
          scrollNode.scrollLeft = left;
        }
      }
      if (scrollY) {
        const top = parseFloat(scrollY);
        if (!isNaN(top)) {
          scrollNode.scrollTop = top;
        }
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps: OrderTabContentProps): void {
    if (this.props.dndMode && !nextProps.dndMode) {
      // leaving dnd mode
      if (!nextProps.dndSave) {
        this.setState({movingOrderMap: EMPTY_MAP, movingTaskMap: EMPTY_MAP});
      } else {
        window.setTimeout(this.performSave, 0);
      }
    }
  }
  componentDidUpdate(prevProps: OrderTabContentProps, _prevState: OrderTabContentState): void {
    if (this.props.selectedDate !== prevProps.selectedDate) {
      this.scrollToSelectedDate();
    }
  }
  componentWillUnmount(): void {
    const scrollNode = this._calendarScrollContainer.current;
    if (scrollNode) {
      scrollNode.removeEventListener("scroll", this.handleCalendarScroll);
    }
    this.updateScrollQuery.cancel();
  }
  private updateScrollQuery: _.DebouncedFunc<(left: number, top: number) => void>;
  private _calendarScrollContainer = React.createRef<HTMLDivElement>();
  private _unplannedTasks = React.createRef<HTMLDivElement>();
  private _daysHeader = React.createRef<HTMLDivElement>();

  @bind
  performSave(): void {
    const {
      customerSettings,
      dateToOrderGroupedTaskListMap,
      filteredOrderLookup,
      taskArray,
      timerStartArray,
    } = this.props;
    if (customerSettings.orderCalendarAsTaskCalendar) {
      this.state.movingTaskMap.forEach((dateChange, taskUrl) => {
        const task = taskArray.find((t) => t.url === taskUrl);
        if (
          !task ||
          task.date === dateChange.toDate ||
          task.completed ||
          task.workFromTimestamp ||
          task.machineOperatorTimeCorrectionSet.length ||
          task.managerTimeCorrectionSet.length ||
          timerStartArray.some((t) => t.task === taskUrl && t.timer !== null)
        ) {
          return;
        }
        this.props.update(task.url, [{member: "date", value: dateChange.toDate}]);
      });
      this.setState({movingTaskMap: EMPTY_MAP});
    } else {
      this.state.movingOrderMap.forEach((dateChange, orderURL) => {
        if (!dateChange.fromDate) {
          // no fromDate --- moved from unplanned/partially planned
          const {partiallyPlannedOrders, plannedTasksMap, unplannedOrders} = this.props;
          const order =
            unplannedOrders.find((o) => o.url === orderURL) ||
            partiallyPlannedOrders.find((o) => o.url === orderURL);
          if (!order) {
            return;
          }
          const taskList = (plannedTasksMap.get("") || []).filter((t) => t.order === orderURL);
          if (order.date !== dateChange.toDate) {
            this.props.update(order.url, [{member: "date", value: dateChange.toDate}]);
          }
          taskList.forEach((task) => {
            if (task.date !== dateChange.toDate) {
              this.props.update(task.url, [{member: "date", value: dateChange.toDate}]);
            }
          });
        } else {
          // has fromDate, days --- moved from other date/dates
          const order = filteredOrderLookup(orderURL);
          if (!order) {
            return;
          }
          console.assert(order.date);
          const orderDateDate = dateFromString(order.date || dateChange.fromDate) as Date;
          orderDateDate.setDate(orderDateDate.getDate() + dateChange.days);
          const orderDate = dateToString(orderDateDate);
          if (order.date !== orderDate) {
            this.props.update(order.url, [{member: "date", value: orderDate}]);
          }
          dateToOrderGroupedTaskListMap.forEach((orderTasksForDate) => {
            (orderTasksForDate.get(orderURL) || [])
              .filter((task) => {
                if (task.completed || task.workFromTimestamp) {
                  return false;
                }
                const taskURL = task.url;
                return !timerStartArray.some((t) => t.task === taskURL && t.timer !== null);
              })
              .forEach((task) => {
                if (task.date) {
                  console.assert(task.date);
                  const taskDateDate = dateFromString(task.date) as Date;
                  taskDateDate.setDate(taskDateDate.getDate() + dateChange.days);
                  const taskDate = dateToString(taskDateDate);
                  if (task.date !== taskDate) {
                    this.props.update(task.url, [{member: "date", value: taskDate}]);
                  }
                }
              });
          });
        }
      });
      this.setState({movingOrderMap: EMPTY_MAP});
    }
  }
  @bind
  handleCalendarScroll(): void {
    const node = this._calendarScrollContainer.current;
    if (node) {
      const innerWidth = node.clientWidth;
      const rect = node.getBoundingClientRect();
      const outerWidth = rect.right - rect.left;
      this.setState({scrollbarWidth: outerWidth - innerWidth});
      const {scrollLeft} = node;
      if (this._daysHeader.current) {
        this._daysHeader.current.scrollLeft = scrollLeft;
      }
      if (this._unplannedTasks.current) {
        this._unplannedTasks.current.scrollLeft = scrollLeft;
      }
      const firstVisibleDay = Math.ceil(node.scrollLeft / ORDER_CALENDAR_COLUMN_WIDTH);
      if (firstVisibleDay !== this.state.firstScrollVisibleDay) {
        this.setState({firstScrollVisibleDay: firstVisibleDay});
      }
      this.updateScrollQuery(node.scrollLeft, node.scrollTop);
    }
  }
  @bind
  handleGoToOrder(orderId: string): void {
    if (!this.props.dndMode) {
      if (this.props.userIsOnlyMachineOperator) {
        this.props.go("/order/:id", {id: orderId});
      } else {
        this.props.go("/orderEntry/:id", {id: orderId});
      }
    }
  }

  @bind
  handleGoToTask(taskId: string): void {
    if (!this.props.dndMode) {
      this.props.go("/task/:id", {id: taskId});
    }
  }

  @bind
  handleTaskEditClick(orderUrl: OrderUrl, taskId: string): void {
    const {customerSettings} = this.props;
    if (!customerSettings.orderCalendarAsTaskCalendar) {
      return;
    }
    if (!this.props.dndMode) {
      if (!taskId) {
        return;
      }

      const order = this.props.filteredOrderLookup(orderUrl);

      if (order?.routePlan) {
        this.props.go("/taskEdit/:id", {
          id: taskId,
        });
      } else {
        this.props.go("/order/:id/:taskID", {
          id: urlToId(orderUrl),
          taskID: taskId,
        });
      }
    }
  }

  scrollToSelectedDate(): void {
    const {periodStart, selectedDate} = this.props;
    if (!this._calendarScrollContainer.current) {
      return;
    }
    console.assert(selectedDate);
    const selectedDateDate = dateFromString(selectedDate) as Date;
    console.assert(periodStart);
    const periodStartDate = dateFromString(periodStart) as Date;
    // Might be a 23-hour or 25-hour day in there with DST-switch...

    const days = Math.round(
      (selectedDateDate.valueOf() - periodStartDate.valueOf()) / DAY_MILLISECONDS,
    );
    // Hack to make sure the element is rendered before trying to scroll to it
    setTimeout(() => {
      if (this._calendarScrollContainer.current) {
        this._calendarScrollContainer.current.scrollLeft = days * ORDER_CALENDAR_COLUMN_WIDTH;
      }
    }, 1);
  }

  @bind
  handleDropOnDate(
    item: {fromDate: string | null; orderUrl: OrderUrl; taskUrl?: TaskUrl},
    toDate: string,
  ): void {
    const {customerSettings} = this.props;
    const {fromDate, orderUrl, taskUrl} = item;
    const days = dateStringDifference(toDate, fromDate || "");
    this.setState(
      (
        previousState: Readonly<OrderTabContentState>,
        _currentProps: Readonly<OrderTabContentProps>,
      ):
        | OrderTabContentState
        | Pick<
            OrderTabContentState,
            "firstScrollVisibleDay" | "movingOrderMap" | "movingTaskMap" | "scrollbarWidth"
          >
        | null => {
        if (customerSettings.orderCalendarAsTaskCalendar) {
          const url = taskUrl;
          if (!url) {
            // HACK -- TypeScript type for setState with update function
            // apparently demands all fields...
            return {
              firstScrollVisibleDay: previousState.firstScrollVisibleDay,
              movingOrderMap: previousState.movingOrderMap,
              movingTaskMap: previousState.movingTaskMap,
              scrollbarWidth: previousState.scrollbarWidth,
            };
          }
          const previousStateMovingMap = previousState.movingTaskMap;
          const previousDateChange = previousStateMovingMap.get(url);
          const newMovingMap = new Map(previousStateMovingMap);
          if (!previousDateChange) {
            const dateChange = {days, fromDate, toDate};
            newMovingMap.set(url, dateChange);
          } else {
            let dateChange = {...previousDateChange, toDate};
            if (previousDateChange.fromDate) {
              dateChange = {
                ...dateChange,
                days: previousDateChange.days + days,
              };
            }
            if (dateChange.fromDate && !dateChange.days) {
              // moved back to start...
              newMovingMap.delete(url);
            } else {
              newMovingMap.set(url, dateChange);
            }
          }
          // HACK -- TypeScript type for setState with update function
          // apparently demands all fields...
          return {
            firstScrollVisibleDay: previousState.firstScrollVisibleDay,
            movingOrderMap: previousState.movingOrderMap,
            movingTaskMap: newMovingMap,
            scrollbarWidth: previousState.scrollbarWidth,
          };
        } else {
          const url = orderUrl;
          if (!url) {
            // HACK -- TypeScript type for setState with update function
            // apparently demands all fields...
            return {
              firstScrollVisibleDay: previousState.firstScrollVisibleDay,
              movingOrderMap: previousState.movingOrderMap,
              movingTaskMap: previousState.movingTaskMap,
              scrollbarWidth: previousState.scrollbarWidth,
            };
          }
          const previousStateMovingMap = previousState.movingOrderMap;
          const previousDateChange = previousStateMovingMap.get(url);
          const newMovingMap = new Map(previousStateMovingMap);
          if (!previousDateChange) {
            const dateChange = {days, fromDate, toDate};
            newMovingMap.set(url, dateChange);
          } else {
            let dateChange = {...previousDateChange, toDate};
            if (previousDateChange.fromDate) {
              dateChange = {
                ...dateChange,
                days: previousDateChange.days + days,
              };
            }
            if (dateChange.fromDate && !dateChange.days) {
              // moved back to start...
              newMovingMap.delete(url);
            } else {
              newMovingMap.set(url, dateChange);
            }
          }
          // HACK -- TypeScript type for setState with update function
          // apparently demands all fields...
          return {
            firstScrollVisibleDay: previousState.firstScrollVisibleDay,
            movingOrderMap: newMovingMap,
            movingTaskMap: previousState.movingTaskMap,
            scrollbarWidth: previousState.scrollbarWidth,
          };
        }
      },
    );
  }
  movedOrdersForDate(dateString: string): {
    order: Order;
    orderTasksForDate: readonly Task[];
    taskList: readonly Task[];
  }[] {
    const {
      customerSettings,
      dateToOrderGroupedTaskListMap,
      dateToOrderMap,
      filteredOrderLookup,
      orderToTaskListMap,
      partiallyPlannedOrders,
      plannedTasksMap,
      taskArray,
      timerStartArray,
      unplannedOrders,
    } = this.props;
    const result: {
      order: Order;
      orderTasksForDate: readonly Task[];
      taskList: readonly Task[];
    }[] = [];
    if (customerSettings.orderCalendarAsTaskCalendar) {
      this.state.movingTaskMap.forEach((dateChange, taskUrl) => {
        if (dateChange.toDate !== dateString) {
          return;
        }
        const task = taskArray.find((t) => t.url === taskUrl);
        if (!task || !task.order) {
          return;
        }
        const order = filteredOrderLookup(task.order);
        if (!order) {
          return;
        }
        if (
          task.completed ||
          task.workFromTimestamp ||
          task.machineOperatorTimeCorrectionSet.length ||
          task.managerTimeCorrectionSet.length ||
          timerStartArray.some((t) => t.task === taskUrl && t.timer !== null)
        ) {
          return;
        }
        result.push({
          order,
          orderTasksForDate: [task],
          taskList: [task],
        });
      });
    } else {
      this.state.movingOrderMap.forEach((dateChange, orderURL) => {
        if (!dateChange.fromDate) {
          // no fromDate --- moved from unplanned/partially planned
          if (dateChange.toDate > dateString) {
            return;
          }
          const order =
            unplannedOrders.find((o) => o.url === orderURL) ||
            partiallyPlannedOrders.find((o) => o.url === orderURL);
          if (!order) {
            return;
          }
          let taskList: readonly Task[];
          if (dateChange.toDate === dateString) {
            taskList = (plannedTasksMap.get("") || []).filter((t) => t.order === orderURL);
          } else {
            const {durationDays} = order;
            if (!durationDays || durationDays <= 1) {
              return;
            }
            console.assert(dateChange.toDate);
            const endDate = dateFromString(dateChange.toDate) as Date;
            endDate.setDate(endDate.getDate() + durationDays - 1);
            if (dateToString(endDate) < dateString) {
              return;
            }
            taskList = [];
          }
          result.push({
            order,
            orderTasksForDate: taskList,
            taskList: orderToTaskListMap.get(orderURL) || [],
          });
        } else {
          // has fromDate, days --- moved from other date/dates
          console.assert(dateString);
          const shiftedDateDate = dateFromString(dateString) as Date;
          shiftedDateDate.setDate(shiftedDateDate.getDate() - dateChange.days);
          const shiftedDate = dateToString(shiftedDateDate);
          // get task list from "original" date; check for unmovable
          const unshiftedTaskList = (
            (
              dateToOrderGroupedTaskListMap.get(dateString) || new Map<string, readonly Task[]>()
            ).get(orderURL) || []
          ).filter((task) => {
            if (task.completed || task.workFromTimestamp) {
              return true;
            }
            const taskURL = task.url;
            return timerStartArray.some((t) => t.task === taskURL && t.timer !== null);
          });
          // get task list from "shifted" date; check for movable
          const shiftedTaskList = (
            (
              dateToOrderGroupedTaskListMap.get(shiftedDate) || new Map<string, readonly Task[]>()
            ).get(orderURL) || []
          ).filter((task) => {
            if (task.completed || task.workFromTimestamp) {
              return false;
            }
            const taskURL = task.url;
            return !timerStartArray.some((t) => t.task === taskURL && t.timer !== null);
          });
          const taskList = unshiftedTaskList.concat(shiftedTaskList);
          if (taskList.length) {
            const order = this.props.filteredOrderLookup(orderURL);
            if (order) {
              result.push({
                order,
                orderTasksForDate: taskList,
                taskList: orderToTaskListMap.get(orderURL) || [],
              });
            }
          } else {
            // check whether order was planned for "shifted" date
            const plannedShiftedOrder = (dateToOrderMap.get(shiftedDate) || []).find(
              (o) => o.url === orderURL,
            );
            if (plannedShiftedOrder) {
              result.push({
                order: plannedShiftedOrder,
                orderTasksForDate: [],
                taskList: orderToTaskListMap.get(orderURL) || [],
              });
            }
          }
        }
      });
    }
    return result;
  }
  @bind
  orderBlockComparator(
    aData: {
      order: Order;
      orderTasksForDate: readonly Task[];
      taskList: readonly Task[];
    },
    bData: {
      order: Order;
      orderTasksForDate: readonly Task[];
      taskList: readonly Task[];
    },
  ): number {
    const aOrder = aData.order;
    const bOrder = bData.order;
    // check priority first
    const aPriority = aOrder.priority;
    const bPriority = bOrder.priority;
    if (aPriority != null && bPriority != null) {
      // low numbers for priority first
      return aPriority - bPriority;
    } else if (aPriority != null) {
      // priority before no priority
      return -1;
    } else if (bPriority != null) {
      return 1;
    }
    const aOrderURL = aOrder.url;
    const bOrderURL = bOrder.url;
    const aOrderCompleted = !!this.props.completedOrderMap.get(aOrderURL);
    const bOrderCompleted = !!this.props.completedOrderMap.get(bOrderURL);
    if (aOrderCompleted !== bOrderCompleted) {
      // incomplete before complete
      return Number(aOrderCompleted) - Number(bOrderCompleted);
    }
    const aOrderTasksForDate = aData.taskList;
    const bOrderTasksForDate = bData.taskList;
    const aWorkTypeURL = getFirstTaskWorkTypeURL(aOrderTasksForDate);
    const bWorkTypeURL = getFirstTaskWorkTypeURL(bOrderTasksForDate);
    if (aWorkTypeURL && bWorkTypeURL) {
      const aWorkType = this.props.workTypeLookup(aWorkTypeURL);
      const bWorkType = this.props.workTypeLookup(bWorkTypeURL);
      if (aWorkType && bWorkType) {
        return identifierComparator(aWorkType.identifier, bWorkType.identifier);
      }
    } else if (aWorkTypeURL) {
      // those missing worktypes first
      return 1;
    } else if (bWorkTypeURL) {
      return -1;
    }
    return 0;
  }
  customerOrdersForDate(dateString: string): {
    order: Order;
    orderTasksForDate: readonly Task[];
    taskList: readonly Task[];
  }[] {
    const {customerSettings, dateToOrderGroupedTaskListMap, dateToOrderMap, orderToTaskListMap} =
      this.props;
    const result: {
      order: Order;
      orderTasksForDate: readonly Task[];
      taskList: readonly Task[];
    }[] = [];
    let tasksByOrder =
      dateToOrderGroupedTaskListMap.get(dateString) || new Map<OrderUrl, readonly Task[]>();
    const emptyOrderGroups = new Map(
      (dateToOrderMap.get(dateString) || []).map((o) => [o.url, []]),
    );
    tasksByOrder = new Map([...emptyOrderGroups, ...tasksByOrder]);
    tasksByOrder.forEach((orderTasksForDate, orderURL) => {
      const order = this.props.filteredOrderLookup(orderURL);
      if (!order) {
        return;
      }
      if (customerSettings.orderCalendarAsTaskCalendar) {
        orderTasksForDate.forEach((task) => {
          if (this.state.movingTaskMap.has(task.url)) {
            return;
          }
          result.push({
            order,
            orderTasksForDate: [task],
            taskList: [task],
          });
        });
      } else {
        if (this.state.movingOrderMap.has(orderURL)) {
          return;
        }
        result.push({
          order,
          orderTasksForDate,
          taskList: orderToTaskListMap.get(orderURL) || [],
        });
      }
    });
    return result;
  }
  @bind
  handleDropOnOrder(
    item: {orderUrl: OrderUrl /*, taskUrl?: string */},
    toDate: string,
    onOrder: Order,
    /* onTask?: Task, */
  ): void {
    if (this.props.customerSettings.orderCalendarAsTaskCalendar) {
      return;
    }
    const {orderUrl: movedURL} = item;
    const targetPriority = onOrder.priority;
    const movedOrders = this.movedOrdersForDate(toDate);
    const customerOrders = this.customerOrdersForDate(toDate);
    const allOrders = customerOrders.concat(movedOrders);
    const withPriorityOnDate = allOrders
      .filter((data) => data.order.priority != null)
      .sort(this.orderBlockComparator)
      .map((data) => data.order);
    if (targetPriority == null) {
      const oldLast = withPriorityOnDate[withPriorityOnDate.length - 1];
      console.assert(!oldLast || oldLast.priority != null);
      const newLastPriority = oldLast ? (oldLast.priority as number) + INCREMENT : INITIAL;
      this.props.update(movedURL, [{member: "priority", value: newLastPriority}]);
    } else {
      const moved = this.props.filteredOrderLookup(movedURL);
      console.assert(moved);
      if (!moved) {
        return;
      }
      const targetURL = onOrder.url;
      const oldPriority = moved.priority;
      const targetIndex = withPriorityOnDate.findIndex((t) => t.url === targetURL);
      let newPriority;
      let insertAfter;
      if (oldPriority != null && oldPriority < targetPriority) {
        // Old position of moved object was before/above target;
        // move it down past it.
        insertAfter = true;
        const afterTarget = withPriorityOnDate[targetIndex + 1];
        if (afterTarget) {
          console.assert(afterTarget.priority != null);

          newPriority = (targetPriority + (afterTarget.priority as number)) / 2;
        } else {
          newPriority = targetPriority + INCREMENT;
        }
      } else {
        // Old position of moved object was after/below target;
        // (possibly due to not having a priority defined) move it up past it.
        insertAfter = false;
        const beforeTarget = targetIndex ? withPriorityOnDate[targetIndex - 1] : null;
        if (beforeTarget) {
          console.assert(beforeTarget.priority != null);
          newPriority = (targetPriority + (beforeTarget.priority as number)) / 2;
        } else {
          newPriority = targetPriority / 2;
        }
      }
      if (Math.abs(targetPriority - newPriority) > THRESHOLD) {
        this.props.update(movedURL, [{member: "priority", value: newPriority}]);
      } else {
        // renumbers everything now...
        const newOrder = withPriorityOnDate
          .map((task) => task.url)
          .filter((url) => url !== movedURL);
        const newTargetIndex = newOrder.indexOf(targetURL);
        if (insertAfter) {
          newOrder.splice(newTargetIndex + 1, 0, movedURL);
        } else {
          newOrder.splice(newTargetIndex, 0, movedURL);
        }
        for (let i = newOrder.length - 1; i >= 0; i -= 1) {
          const url = newOrder[i];
          const priority = INITIAL + i * INCREMENT;
          this.props.update(url, [{member: "priority", value: priority}]);
        }
      }
    }
  }
  render(): JSX.Element {
    const {
      completedOrderMap,
      customerSettings,
      onRequestFilterClear,
      orderToTaskListMap,
      partiallyPlannedOrders,
      periodEnd,
      periodLengthDays,
      periodStart,
      selectedDepartmentIdentifierSet,
      selectedWorkTypeURLSet,
      unplannedOrders,
    } = this.props;
    console.assert(periodStart);
    const mondayLastWeek = dateFromString(periodStart) as Date;
    console.assert(mondayLastWeek);
    console.assert(periodEnd);
    const sundayNextWeek = dateFromString(periodEnd) as Date;
    console.assert(sundayNextWeek);
    const weekDaysHeaderCells = _.range(0, periodLengthDays).map((day) => {
      const weekDayDate = new Date(mondayLastWeek);
      weekDayDate.setDate(weekDayDate.getDate() + day);
      return <DayColumnHeader key={weekDayDate.toISOString()} date={weekDayDate} />;
    });

    let partiallyPlannedOrderBlocks: JSX.Element[] | undefined;
    const partiallyPlannedOrderRows: Order[][] = [];
    if (!customerSettings.orderCalendarAsTaskCalendar && partiallyPlannedOrders.length) {
      _.sortBy(
        partiallyPlannedOrders,
        (order) =>
          new Date(order.latestDate as string).valueOf() -
          new Date(order.earliestDate as string).valueOf(),
      )
        .reverse()
        .forEach((order) => {
          const orderStartDate = order.earliestDate as string;
          console.assert(orderStartDate);
          const orderEndDate = order.latestDate as string;
          console.assert(orderEndDate);
          let foundRowWithSpace = false;
          partiallyPlannedOrderRows.forEach((otherOrders, row) => {
            if (foundRowWithSpace) {
              return;
            }
            const overlaps = otherOrders.some((otherOrder) => {
              const otherOrderStartDate = otherOrder.earliestDate as string;
              console.assert(otherOrderStartDate);
              const otherOrderEndDate = otherOrder.latestDate as string;
              console.assert(otherOrderEndDate);
              // For there to be overlap, there must be some date where
              // both have started and neither have ended.
              return orderStartDate <= otherOrderEndDate && otherOrderStartDate <= orderEndDate;
            });
            if (overlaps) {
              return;
            } else {
              partiallyPlannedOrderRows[row] = [...otherOrders, order];
              foundRowWithSpace = true;
            }
          });
          if (!foundRowWithSpace) {
            partiallyPlannedOrderRows.push([order]);
          }
        });
      partiallyPlannedOrderBlocks = partiallyPlannedOrderRows
        .map((orders, row) => {
          const orderBlocks = orders.map((order) => {
            if (this.state.movingOrderMap.has(order.url)) {
              return null;
            }
            const customerURL = order.customer;
            const customer = customerURL ? this.props.customerLookup(customerURL) : undefined;
            console.assert(order.earliestDate);
            const startDate = lastDate(
              mondayLastWeek,
              dateFromString(order.earliestDate as string) as Date,
            );
            console.assert(order.latestDate);
            const endDate = firstDate(
              sundayNextWeek,
              dateFromString(order.latestDate as string) as Date,
            );
            const days = Math.round(endDate.valueOf() - startDate.valueOf()) / DAY_MILLISECONDS + 1;
            const daysOffset =
              Math.round(startDate.valueOf() - mondayLastWeek.valueOf()) / DAY_MILLISECONDS;
            console.assert(order.earliestDate);
            const overflowsStart = mondayLastWeek > new Date(order.earliestDate as string);
            console.assert(order.latestDate);
            const overflowsEnd = sundayNextWeek < new Date(order.latestDate as string);
            return (
              <PartiallyPlannedOrderBlock
                key={order.url}
                customer={customer}
                dndMode={this.props.dndMode}
                firstScrollVisibleDay={this.state.firstScrollVisibleDay}
                order={order}
                overflowsEnd={overflowsEnd}
                overflowsStart={overflowsStart}
                taskList={orderToTaskListMap.get(order.url)}
                visibleDays={days}
                visibleDaysOffset={daysOffset}
                onGoToOrder={this.handleGoToOrder}
              />
            );
          });
          const style: React.CSSProperties = {
            height: PARTIALLY_PLANNED_ORDER_HEIGHT,
            marginTop: 4,
            position: "relative",

            width: ORDER_CALENDAR_COLUMN_WIDTH * periodLengthDays + 15,
          };
          return (
            <div key={row} style={style}>
              {orderBlocks}
            </div>
          );
        })
        .reverse();
    }
    const unplannedOrderBlocks: JSX.Element[] = [];
    unplannedOrders.forEach((order) => {
      if (this.state.movingOrderMap.has(order.url)) {
        return;
      }
      const customerURL = order.customer;
      const customer = customerURL ? this.props.customerLookup(customerURL) : undefined;
      if (customerSettings.orderCalendarAsTaskCalendar) {
        (orderToTaskListMap.get(order.url) || []).forEach((task) => {
          if (this.state.movingTaskMap.has(task.url)) {
            return;
          }
          unplannedOrderBlocks.push(
            <UnplannedOrderBlock
              key={task.url}
              customer={customer}
              customerSettings={customerSettings}
              dndMode={this.props.dndMode}
              order={order}
              task={task}
              taskList={undefined}
              onEditTaskClick={this.props.isManager ? this.handleTaskEditClick : undefined}
              onGoToOrder={this.handleGoToOrder}
              onGoToTask={this.handleGoToTask}
            />,
          );
        });
      } else {
        unplannedOrderBlocks.push(
          <UnplannedOrderBlock
            key={order.url}
            customer={customer}
            customerSettings={customerSettings}
            dndMode={this.props.dndMode}
            order={order}
            taskList={orderToTaskListMap.get(order.url)}
            onGoToOrder={this.handleGoToOrder}
          />,
        );
      }
    });

    let filteringBlock: JSX.Element | undefined;
    const filtering = !!(
      (selectedWorkTypeURLSet?.size || selectedDepartmentIdentifierSet?.size) &&
      onRequestFilterClear
    );
    if (filtering) {
      filteringBlock = (
        <FilterBar
          selectedDepartmentIdentifierSet={selectedDepartmentIdentifierSet}
          selectedWorkTypeURLSet={selectedWorkTypeURLSet}
          onRequestFilterClear={onRequestFilterClear as () => void}
        />
      );
    }

    const partiallyPlannedOrderBlocksHeight =
      partiallyPlannedOrderRows.length * (PARTIALLY_PLANNED_ORDER_HEIGHT + 4) + 4;
    const partiallyPlannedOrderBlocksMaxHeight = 3 * (PARTIALLY_PLANNED_ORDER_HEIGHT + 4) + 4;

    const unplannedOrdersHeight = PARTIALLY_PLANNED_ORDER_HEIGHT + 8 + 15;
    const daysHeaderHeight = 26;
    const scrollContainerStyle: React.CSSProperties = {
      height: `calc(100% - ${
        unplannedOrdersHeight +
        Math.min(partiallyPlannedOrderBlocksHeight, partiallyPlannedOrderBlocksMaxHeight) +
        (filtering ? FILTER_BAR_HEIGHT : 0) +
        daysHeaderHeight
      }px)`,
      overflow: "auto",
      whiteSpace: "nowrap",
      width: "100%",
    };

    const dayColumns = _.range(0, periodLengthDays).map((n) => {
      const date = new Date(mondayLastWeek);
      date.setDate(date.getDate() + n);
      const dateString = dateToString(date);
      const movedOrders = this.movedOrdersForDate(dateString);
      const customerOrders = this.customerOrdersForDate(dateString);
      const allOrders = customerOrders.concat(movedOrders);
      const sortedOrders = allOrders.sort(this.orderBlockComparator);
      let orderBlocks: JSX.Element[] | undefined;
      if (customerSettings.orderCalendarAsTaskCalendar) {
        orderBlocks = sortedOrders.flatMap(({order, orderTasksForDate}) => {
          return orderTasksForDate.map((task) => {
            const taskURL = task.url;
            const draggable = !task.completed && !task.workFromTimestamp;
            return (
              <OrderBlock
                key={`${dateString}-task-${taskURL}`}
                completed={task.completed}
                customerLookup={this.props.customerLookup}
                customerSettings={this.props.customerSettings}
                customerURLToDefaultContactMap={this.props.customerURLToDefaultContactMap}
                dndMode={this.props.dndMode}
                draggable={draggable}
                folded={this.props.allFolded}
                forDate={dateString}
                isManager={this.props.isManager}
                machineLookup={this.props.machineLookup}
                order={order}
                orderTasksForDate={[task]}
                routePlanLookup={this.props.routePlanLookup}
                taskList={[task]}
                timerStartArray={this.props.timerStartArray}
                userUserProfileLookup={this.props.userUserProfileLookup}
                workTypeLookup={this.props.workTypeLookup}
                onDrop={this.handleDropOnOrder}
                onEditTaskClick={this.handleTaskEditClick}
                onGoToOrder={this.handleGoToOrder}
                onGoToTask={this.handleGoToTask}
              />
            );
          });
        });
      } else {
        orderBlocks = sortedOrders.map(({order, orderTasksForDate, taskList}) => {
          const orderURL = order.url;
          return (
            <OrderBlock
              key={`${dateString}-order-${orderURL}`}
              completed={!!completedOrderMap.get(orderURL)}
              customerLookup={this.props.customerLookup}
              customerSettings={this.props.customerSettings}
              customerURLToDefaultContactMap={this.props.customerURLToDefaultContactMap}
              dndMode={this.props.dndMode}
              draggable={!completedOrderMap.get(orderURL)}
              folded={this.props.allFolded}
              forDate={dateString}
              isManager={this.props.isManager}
              machineLookup={this.props.machineLookup}
              order={order}
              orderTasksForDate={orderTasksForDate}
              routePlanLookup={this.props.routePlanLookup}
              taskList={taskList}
              timerStartArray={this.props.timerStartArray}
              userUserProfileLookup={this.props.userUserProfileLookup}
              workTypeLookup={this.props.workTypeLookup}
              onDrop={this.handleDropOnOrder}
              onEditTaskClick={this.handleTaskEditClick}
              onGoToOrder={this.handleGoToOrder}
            />
          );
        });
      }
      return (
        <DayColumn key={n} date={dateString} onDrop={this.handleDropOnDate}>
          {orderBlocks}
        </DayColumn>
      );
    });
    return (
      <div style={{height: "100%", width: "100%"}}>
        {filteringBlock}
        <div
          style={{
            height: unplannedOrdersHeight,
            overflowX: "auto",
            overflowY: "hidden",
            padding: "4px 0px 4px 0px",
            whiteSpace: "nowrap",
            width: "100%",
          }}
        >
          {unplannedOrderBlocks}
        </div>
        <div
          ref={this._unplannedTasks}
          style={{
            height: partiallyPlannedOrderBlocksHeight,
            maxHeight: partiallyPlannedOrderBlocksMaxHeight,
            overflowX: "hidden",
            overflowY: "auto",
            paddingBottom: 4,
            width: "100%",
          }}
        >
          {partiallyPlannedOrderBlocks}
        </div>
        <div
          ref={this._daysHeader}
          style={{
            borderColor: "rgb(221, 221, 221)",
            borderStyle: "solid",
            borderWidth: "1px 0px 1px 0px",
            height: daysHeaderHeight,
            overflow: "hidden",
            width: "100%",
          }}
        >
          <div
            style={{
              backgroundColor:
                (dateFromString(periodEnd) as Date).getDate() % 2 ? grey[100] : grey[200],
              height: daysHeaderHeight,
              whiteSpace: "nowrap",
              width: ORDER_CALENDAR_COLUMN_WIDTH * periodLengthDays + this.state.scrollbarWidth,
            }}
          >
            {weekDaysHeaderCells}
            <div style={{display: "inline-block", width: 15}} />
          </div>
        </div>
        <div
          ref={this._calendarScrollContainer}
          className="scrollable"
          style={scrollContainerStyle}
        >
          <div
            style={{
              alignItems: "stretch",
              display: "flex",
              width: ORDER_CALENDAR_COLUMN_WIDTH * periodLengthDays,
            }}
          >
            {dayColumns}
          </div>
        </div>
      </div>
    );
  }
}
