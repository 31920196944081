import {Config} from "@co-common-libs/config";
import {
  UserData,
  computePoolCompensatoryResults,
  findVisibleRateBonusLabelSources,
} from "@co-common-libs/payroll";
import {Role} from "@co-common-libs/resources";
import {formatDuration} from "@co-common-libs/resources-utils";
import {
  HOUR_MINUTES,
  MINUTE_MILLISECONDS,
  WEEKDAY_SATURDAY,
  WEEKDAY_SUNDAY,
  dateFromString,
  formatDate,
  formatDateShort,
  formatTime,
} from "@co-common-libs/utils";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {PureComponent} from "app-utils";
import _ from "lodash";
import CheckIcon from "mdi-react/CheckIcon";
import React from "react";
import {FormattedMessage, FormattedNumber, IntlContext, defineMessages} from "react-intl";
import {Linkify} from "./linkify";

const messages = defineMessages({
  absenceDecimal: {
    defaultMessage: "{absenceType} (decimal)",
    id: "user-salary-report-card.label.absence-decimal",
  },
  calledIn: {
    defaultMessage: "Tilkald",
    id: "user-salary-report-card.label.called-in",
  },
  childsFirstSickDay: {
    defaultMessage: "Barns første sygedag",
    id: "user-salary-report-card.label.childs-first-sick-day",
  },
  compensatory: {
    defaultMessage: "Afspadsering",
    id: "user-salary-report-card.label.compensatory",
  },
  daysOffWithoutPay: {
    defaultMessage: "Fridage uden løn",
    id: "user-salary-report-card.label.days-off-without-pay",
  },
  dinnerBookings: {
    defaultMessage: "Aft.madbest.",
    id: "user-salary-report-card.label.dinner-bookings",
  },
  floatingHoliday: {
    defaultMessage: "Ferie-fridage",
    id: "user-salary-report-card.label.floating-holiday",
  },
  lunchBookings: {
    defaultMessage: "Fro.best.",
    id: "user-salary-report-card.label.lunch-bookings",
  },
  salaryVoucherPeriod: {
    defaultMessage: "Lønbilag: {from}–{to}",
    id: "user-salary-report-card.title.remuneration-report-format",
  },
  sickLeave: {
    defaultMessage: "Sygefravær",
    id: "user-salary-report-card.label.sick-leave",
  },
  timeOff: {
    defaultMessage: "FRI",
    id: "user-salary-report-card.label.time-off",
  },
  vacation: {
    defaultMessage: "Feriedage",
    id: "user-salary-report-card.label.vacation",
  },
});

const COLUMN_STYLE = {paddingLeft: 5, paddingRight: 5} as const;
const DATE_COLUMN_STYLE = {
  minWidth: 124,
  whiteSpace: "normal",
  ...COLUMN_STYLE,
} as const;
const PERIOD_COLUMN_STYLE = {
  minWidth: 104,
  whiteSpace: "normal",
  ...COLUMN_STYLE,
} as const;
const TIME_COLUMN_STYLE = {
  minWidth: 74,
  whiteSpace: "normal",
  ...COLUMN_STYLE,
} as const;
const PROJECTS_COLUMN_STYLE = {
  minWidth: 104,
  whiteSpace: "normal",
  ...COLUMN_STYLE,
} as const;

interface PoolTableProps {
  readonly accumulatedCompensatoryLimit?: number | undefined;
  readonly data: readonly {
    readonly aboveLimit: number;
    readonly added: number;
    readonly after: number;
    readonly before: number;
    readonly fromDate: string;
    readonly subtracted: number;
    readonly toDate: string;
  }[];
}

class PoolTable extends PureComponent<PoolTableProps> {
  render(): JSX.Element {
    const {accumulatedCompensatoryLimit, data} = this.props;
    const rows = data.map(({aboveLimit, added, after, before, fromDate, subtracted, toDate}) => {
      return (
        <TableRow key={fromDate}>
          <TableCell>
            {formatDateShort(fromDate)} – {formatDateShort(toDate)}
          </TableCell>
          <TableCell>
            <FormattedNumber
              maximumFractionDigits={2}
              minimumFractionDigits={2}
              value={(subtracted || 0) / HOUR_MINUTES}
            />
          </TableCell>
          <TableCell>
            <FormattedNumber
              maximumFractionDigits={2}
              minimumFractionDigits={2}
              value={(added || 0) / HOUR_MINUTES}
            />
          </TableCell>
          {accumulatedCompensatoryLimit != null ? (
            <TableCell>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={(aboveLimit || 0) / HOUR_MINUTES}
              />
            </TableCell>
          ) : null}
          <TableCell>
            <FormattedNumber
              maximumFractionDigits={2}
              minimumFractionDigits={2}
              value={(before || 0) / HOUR_MINUTES}
            />
          </TableCell>
          <TableCell>
            <FormattedNumber
              maximumFractionDigits={2}
              minimumFractionDigits={2}
              value={(after || 0) / HOUR_MINUTES}
            />
          </TableCell>
        </TableRow>
      );
    });
    return (
      <CardMedia>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Afspadsering periode"
                  id="remuneration-report.table-header.compensatory-period"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Trukket"
                  id="remuneration-report.table-header.compensatory-subtracted"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Indsat"
                  id="remuneration-report.table-header.compensatory-added"
                />
              </TableCell>
              {accumulatedCompensatoryLimit != null ? (
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Over grænse"
                    id="remuneration-report.table-header.compensatory-above-limit"
                  />
                </TableCell>
              ) : null}
              <TableCell>
                <FormattedMessage
                  defaultMessage="Opsparet, før"
                  id="remuneration-report.table-header.compensatory-accumulated-before"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Opsparet, efter"
                  id="remuneration-report.table-header.compensatory-accumulated-after"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </CardMedia>
    );
  }
}

const UNPAID_RATE = "-2";
// enum value -2 from TypeScript; transformed to string through
// being used as key in JSON object...

interface UserSalaryReportCardProps {
  calendarDayBonusLabels: readonly string[];
  countBonusLabels: readonly string[];
  currentRole?: Role | undefined;
  customerSettings: Config;
  intervalBonusLabels: readonly string[];
  rateLabels: readonly string[];
  showBreaks: boolean;
  startRateLabel?: string | undefined;
  taskBonusLabels: readonly string[];
  userData: UserData;
  visibleBonusLabels: readonly string[] | undefined;
  visibleLabels: readonly string[] | undefined;
  visibleRateLabels: readonly string[] | undefined;
  workDayBonusLabels: readonly string[];
}

export class UserSalaryReportCard extends PureComponent<UserSalaryReportCardProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  render(): JSX.Element {
    const {formatMessage, formatNumber} = this.context;
    const {
      calendarDayBonusLabels,
      countBonusLabels,
      intervalBonusLabels,
      rateLabels,
      showBreaks,
      startRateLabel,
      taskBonusLabels,
      userData,
      visibleBonusLabels,
      visibleLabels,
      visibleRateLabels,
      workDayBonusLabels,
    } = this.props;
    const usesStartRate = !!startRateLabel;
    const {dinnerBookings, lunchBookings, showCalledInDialogAfterMinutes} =
      this.props.customerSettings;
    const role = this.props.currentRole;
    const userIsManager = role && role.manager;
    const {
      accomodationAllowance,
      accomodationAllowanceLabel,
      allowNegativeAccumulateCompensatory,
      countWeekdayHolidays,
      includeCompensatoryOverLimitInPaid,
      initials,
      name,
      projectDistanceBonusLabel,
      projectTravelTimeBonusLabel,
    } = userData;
    // accomodationAllowance true implies accomodationAllowanceIncludeHours
    // true for data from before the later was introduced
    const accomodationAllowanceIncludeHours =
      userData.accomodationAllowanceIncludeHours != null
        ? userData.accomodationAllowanceIncludeHours
        : accomodationAllowance;
    const dateRows: JSX.Element[] = [];

    const distributionSum = new Map<string, number>();
    const intervalBonusSum = new Map<string, number>();
    const workDayBonusSum = new Map<string, number>();
    const calendarDayBonusSum = new Map<string, number>();
    const taskBonusSum = new Map<string, number>();
    let projectDistanceBonusSum = 0;
    let projectTravelTimeBonusSum = 0;
    const countBonusSum = new Map<string, number>();
    let paidSum = 0;
    let actualWorkMinutesSum = 0;
    let dinnerBookingsSum = 0;
    let lunchBookingsSum = 0;
    let calledInSum = 0;
    const daysAbsenceSums = new Map<string, number>();
    const minutesAbsenceSums = new Map<string, number>();

    let weekdayHolidaySum = 0;

    const includeCompensatory =
      !!userData.accumulatedCompensatoryLimit ||
      !!userData.periodCompensatoryLimit ||
      !!userData.compensatoryMultiplier;

    let poolTableBlock;
    let compensatoryTotalAdded = 0;
    let compensatoryTotalSubtracted = 0;

    let aboveLimitSum = 0;

    if (includeCompensatory) {
      const {
        accumulatedCompensatoryBefore,
        accumulatedCompensatoryLimit,
        accumulatedCompensatoryLimitType = "balance",
        increaseOnlyAccumulatedCompensatoryBefore,
        periodCompensatoryLimit,
      } = userData;
      const poolTableData: {
        aboveLimit: number;
        added: number;
        after: number;
        before: number;
        fromDate: string;
        subtracted: number;
        toDate: string;
      }[] = [];
      if (userData.pools) {
        const poolCompensatoryResults = computePoolCompensatoryResults(userData.pools, {
          accumulateCompensatoryLimit: accumulatedCompensatoryLimit,
          accumulateCompensatoryLimitType: accumulatedCompensatoryLimitType,
          accumulatedCompensatoryBefore: accumulatedCompensatoryBefore || 0,
          allowNegativeAccumulateCompensatory,
          increaseOnlyAccumulatedCompensatoryBefore:
            (increaseOnlyAccumulatedCompensatoryBefore ?? accumulatedCompensatoryBefore) || 0,
          periodCompensatoryLimit,
        });
        compensatoryTotalSubtracted = _.sumBy(poolCompensatoryResults, (x) => x.subtracted);
        compensatoryTotalAdded = _.sumBy(poolCompensatoryResults, (x) => x.added);
        aboveLimitSum = _.sumBy(poolCompensatoryResults, (x) => x.aboveLimit);

        userData.pools.forEach((entry, index) => {
          const {fromDate, toDate} = entry;
          const {aboveLimit, added, after, before, subtracted} = poolCompensatoryResults[index];

          poolTableData.push({
            aboveLimit,
            added,
            after,
            before,
            fromDate,
            subtracted,
            toDate,
          });
        });
      }

      poolTableBlock = (
        <PoolTable
          accumulatedCompensatoryLimit={accumulatedCompensatoryLimit}
          data={poolTableData}
        />
      );
    }

    let accomodationDaysSum = 0;
    let accomodationMinutesSum = 0;

    const resultingVisibleLabels = findVisibleRateBonusLabelSources({
      calendarDayBonusLabels,
      countBonusLabels,
      intervalBonusLabels,
      projectDistanceBonusLabel,
      projectTravelTimeBonusLabel,
      rateLabels,
      taskBonusLabels,
      visibleBonusLabels,
      visibleLabels,
      visibleRateLabels,
      workDayBonusLabels,
    });

    const totalColumns =
      // date
      1 +
      // work periods
      1 +
      (showBreaks ? 1 : 0) +
      // actual work time/total paid time
      1 +
      (includeCompensatory ? 1 : 0) +
      (usesStartRate ? 1 : 0) +
      resultingVisibleLabels.length +
      (dinnerBookings ? 1 : 0) +
      (lunchBookings ? 1 : 0) +
      (showCalledInDialogAfterMinutes !== null && calledInSum ? 1 : 0) +
      (accomodationAllowance ? 1 : 0) +
      (accomodationAllowance && accomodationAllowanceIncludeHours ? 1 : 0) +
      (this.props.customerSettings.remunerationReportShowProjects ? 1 : 0) +
      (this.props.customerSettings.remunerationReportShowReferenceNumbers ? 1 : 0);

    userData.dates.forEach((dateData) => {
      const {
        accomodationDay,
        accomodationMinutes,
        calledIn,
        date,
        daysAbsence,
        holiday,
        minutesAbsence,
      } = dateData;
      const breaks = dateData.breakMinutes;
      const distribution = dateData.rateMinutes;
      const timePeriods = dateData.workPeriods;
      let paid = 0;
      Object.entries(distribution).forEach(([rate, minutes]: [string, number]) => {
        if (rate !== UNPAID_RATE) {
          paid += minutes;
        }
        distributionSum.set(rate, (distributionSum.get(rate) || 0) + minutes);
      });
      paidSum += paid;
      dinnerBookingsSum += dateData.dinnerBookings;
      lunchBookingsSum += dateData.lunchBookings;

      if (calledIn) {
        calledInSum += calledIn;
      }

      let output = false;

      if (holiday) {
        output = true;
        dateRows.push(
          <TableRow key={`${date}-holiday-${holiday}`}>
            <TableCell style={DATE_COLUMN_STYLE}>{formatDate(date)}</TableCell>
            <TableCell colSpan={totalColumns - 1} style={PERIOD_COLUMN_STYLE}>
              {holiday}
            </TableCell>
          </TableRow>,
        );
        const weekday = (dateFromString(date) as Date).getDay();
        if (weekday !== WEEKDAY_SATURDAY && weekday !== WEEKDAY_SUNDAY) {
          weekdayHolidaySum += 1;
        }
      }

      if (
        paid ||
        timePeriods.length ||
        dateData.dinnerBookings ||
        dateData.lunchBookings ||
        accomodationMinutes ||
        accomodationDay
      ) {
        const periodElements = timePeriods.map((period) => {
          const periodString = `${formatTime(period.fromTimestamp)}–${formatTime(
            period.toTimestamp,
          )}`;
          return <div key={`${formatDate(date)}-${periodString}`}>{periodString}</div>;
        });

        const labelColumns: JSX.Element[] = resultingVisibleLabels.map(
          ({label, source}): JSX.Element => {
            if (source === "rate") {
              const index = rateLabels.indexOf(label);
              const value = distribution[index] || 0;
              return (
                <TableCell key={`rate-${label}`} style={TIME_COLUMN_STYLE}>
                  {formatDuration(this.props.customerSettings.durationFormat, value)}
                </TableCell>
              );
            } else if (source === "interval-bonus") {
              const extraData = dateData.bonusMinutes;
              const key = label.toLocaleLowerCase();
              const value = extraData[key] != null ? extraData[key] : extraData[label];
              intervalBonusSum.set(label, (intervalBonusSum.get(label) || 0) + (value || 0));
              return (
                <TableCell key={`interval-bonus-${label}`} style={TIME_COLUMN_STYLE}>
                  {formatDuration(this.props.customerSettings.durationFormat, value)}
                </TableCell>
              );
            } else if (source === "work-day-bonus") {
              const extraData = dateData.workDayBonus;
              const present = extraData.includes(label);
              if (present) {
                workDayBonusSum.set(label, (workDayBonusSum.get(label) || 0) + 1);
                return (
                  <TableCell key={`work-day-bonus-${label}`} style={TIME_COLUMN_STYLE}>
                    <CheckIcon />
                  </TableCell>
                );
              } else {
                return <TableCell key={`work-day-bonus-${label}`} style={TIME_COLUMN_STYLE} />;
              }
            } else if (source === "calendar-day-bonus") {
              const extraData = dateData.calendarDayBonus;
              const key = label.toLocaleLowerCase();
              const value = extraData[key] != null ? extraData[key] : extraData[label];
              calendarDayBonusSum.set(label, (calendarDayBonusSum.get(label) || 0) + (value || 0));
              return (
                <TableCell key={`calendar-day-bonus-${label}`} style={TIME_COLUMN_STYLE}>
                  {value}
                </TableCell>
              );
            } else if (source === "task-bonus") {
              const extraData = dateData.taskBonus;
              const key = label.toLocaleLowerCase();
              const value = extraData[key] != null ? extraData[key] : extraData[label];
              taskBonusSum.set(label, (taskBonusSum.get(label) || 0) + (value || 0));
              return (
                <TableCell key={`task-bonus-${label}`} style={TIME_COLUMN_STYLE}>
                  {value}
                </TableCell>
              );
            } else if (source === "project-distance-bonus") {
              const value = dateData.projectDistance || 0;
              projectDistanceBonusSum += value;
              return (
                <TableCell
                  key={`project-distance-bonus-${projectDistanceBonusLabel}`}
                  style={TIME_COLUMN_STYLE}
                >
                  <FormattedNumber
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                    value={value}
                  />
                </TableCell>
              );
            } else if (source === "project-travel-time-bonus") {
              const value = dateData.projectTravelTime || 0;
              projectTravelTimeBonusSum += value;
              return (
                <TableCell
                  key={`project-travel-time-bonus-${projectTravelTimeBonusLabel}`}
                  style={TIME_COLUMN_STYLE}
                >
                  {formatDuration(this.props.customerSettings.durationFormat, value)}
                </TableCell>
              );
            } else if (source === "count-bonus") {
              const extraData = dateData.countBonus;
              const key = label.toLocaleLowerCase();
              const value = extraData[key] != null ? extraData[key] : extraData[label];
              countBonusSum.set(label, (countBonusSum.get(label) || 0) + (value || 0));
              return (
                <TableCell key={`count-bonus-${label}`} style={TIME_COLUMN_STYLE}>
                  {value}
                </TableCell>
              );
            } else {
              const x: never = source;
              throw new Error(`unknown source ${x}`);
            }
          },
        );

        const specialColumns = [];
        if (usesStartRate) {
          const value = distribution["-1"];
          const label = startRateLabel;
          specialColumns.push(
            <TableCell key={`start-rate-${label}`} style={TIME_COLUMN_STYLE}>
              {value != null
                ? formatDuration(this.props.customerSettings.durationFormat, value)
                : ""}
            </TableCell>,
          );
        }

        accomodationMinutesSum += accomodationMinutes || 0;
        accomodationDaysSum += accomodationDay ? 1 : 0;
        output = true;
        // actualWorkMinutes not present on old data
        const actualWorkMinutes = dateData.actualWorkMinutes ?? paid;
        actualWorkMinutesSum += actualWorkMinutes;
        dateRows.push(
          <TableRow key={`${date}-${timePeriods?.[0]?.fromTimestamp ?? ""}`}>
            <TableCell style={DATE_COLUMN_STYLE}>{formatDate(date)}</TableCell>
            <TableCell style={PERIOD_COLUMN_STYLE}>{periodElements}</TableCell>
            {showBreaks ? (
              <TableCell style={TIME_COLUMN_STYLE}>
                {formatDuration(this.props.customerSettings.durationFormat, breaks)}
              </TableCell>
            ) : null}
            <TableCell style={TIME_COLUMN_STYLE}>
              {includeCompensatory
                ? formatDuration(this.props.customerSettings.durationFormat, actualWorkMinutes)
                : formatDuration(this.props.customerSettings.durationFormat, paid)}
            </TableCell>
            {includeCompensatory ? <TableCell style={TIME_COLUMN_STYLE} /> : null}
            {specialColumns}
            {labelColumns}
            {dinnerBookings ? (
              <TableCell style={TIME_COLUMN_STYLE}>{dateData.dinnerBookings}</TableCell>
            ) : null}
            {lunchBookings ? (
              <TableCell style={TIME_COLUMN_STYLE}>{dateData.lunchBookings}</TableCell>
            ) : null}
            {showCalledInDialogAfterMinutes !== null && calledInSum ? (
              <TableCell style={TIME_COLUMN_STYLE}>{calledIn ? calledIn : ""}</TableCell>
            ) : null}
            {accomodationAllowance ? (
              <TableCell style={TIME_COLUMN_STYLE}>
                {dateData.accomodationDay ? <CheckIcon /> : null}
              </TableCell>
            ) : null}
            {accomodationAllowance && accomodationAllowanceIncludeHours ? (
              <TableCell style={TIME_COLUMN_STYLE}>
                {formatDuration(
                  this.props.customerSettings.durationFormat,
                  dateData.accomodationMinutes,
                )}
              </TableCell>
            ) : null}
            {this.props.customerSettings.remunerationReportShowProjects ? (
              <TableCell style={PROJECTS_COLUMN_STYLE}>
                {dateData.projects
                  ? dateData.projects.map((p) => p.projectNumber).join(", ")
                  : null}
              </TableCell>
            ) : null}
            {this.props.customerSettings.remunerationReportShowReferenceNumbers ? (
              <TableCell style={PROJECTS_COLUMN_STYLE}>
                {(dateData.taskReferenceNumbers || [])
                  .concat(dateData.orderReferenceNumbers || [])
                  .join(", ")}
              </TableCell>
            ) : null}
          </TableRow>,
        );
      }
      if (minutesAbsence && Object.keys(minutesAbsence).length) {
        Object.entries(minutesAbsence).forEach(([absenceType, entryList]) => {
          entryList.forEach((entry, index) => {
            const {fromTimestamp, toTimestamp} = entry;
            const milliSeconds =
              new Date(toTimestamp).valueOf() - new Date(fromTimestamp).valueOf();
            const minutes = milliSeconds / MINUTE_MILLISECONDS;
            minutesAbsenceSums.set(
              absenceType,
              (minutesAbsenceSums.get(absenceType) || 0) + minutes,
            );
            let note;
            if (userIsManager) {
              note = `M: ${entry.employeeReason} (A: ${entry.note})`;
            } else {
              note = entry.employeeReason;
            }
            const absenceTypeLabel =
              this.props.customerSettings.absenceTypeLabels[absenceType] ||
              ((messages as any)[absenceType]
                ? formatMessage((messages as any)[absenceType])
                : absenceType);
            output = true;
            dateRows.push(
              <TableRow key={`${date}-${absenceType}-${index}`}>
                <TableCell style={DATE_COLUMN_STYLE}>{formatDate(date)}</TableCell>
                <TableCell colSpan={totalColumns - 1} style={PERIOD_COLUMN_STYLE}>
                  {formatTime(fromTimestamp)}–{formatTime(toTimestamp)}: {absenceTypeLabel}{" "}
                  <Linkify>{note} </Linkify>
                </TableCell>
              </TableRow>,
            );
          });
        });
      }
      if (daysAbsence && Object.keys(daysAbsence).length) {
        if (!Array.isArray(daysAbsence)) {
          Object.entries(daysAbsence).forEach(([absenceType, entryList]) => {
            entryList.forEach((entry, index) => {
              daysAbsenceSums.set(absenceType, (daysAbsenceSums.get(absenceType) || 0) + 1);
              let note;
              if (userIsManager) {
                note = `M: ${entry.employeeReason} (A: ${entry.note})`;
              } else {
                note = entry.employeeReason;
              }
              const absenceTypeLabel =
                this.props.customerSettings.absenceTypeLabels[absenceType] ||
                ((messages as any)[absenceType]
                  ? formatMessage((messages as any)[absenceType])
                  : absenceType);
              output = true;
              dateRows.push(
                <TableRow key={`${date}-${absenceType}-${index}`}>
                  <TableCell style={DATE_COLUMN_STYLE}>{formatDate(date)}</TableCell>
                  <TableCell colSpan={totalColumns - 1} style={PERIOD_COLUMN_STYLE}>
                    {absenceTypeLabel} {note}
                  </TableCell>
                </TableRow>,
              );
            });
          });
        } else {
          daysAbsence.forEach((absenceType) => {
            const absenceTypeLabel =
              this.props.customerSettings.absenceTypeLabels[absenceType] ||
              ((messages as any)[absenceType]
                ? formatMessage((messages as any)[absenceType])
                : absenceType);
            output = true;
            dateRows.push(
              <TableRow key={`${date}-${absenceType}`}>
                <TableCell style={DATE_COLUMN_STYLE}>{formatDate(date)}</TableCell>
                <TableCell colSpan={totalColumns - 1} style={PERIOD_COLUMN_STYLE}>
                  {absenceTypeLabel}
                </TableCell>
              </TableRow>,
            );
          });
        }
      }
      if (!output && this.props.customerSettings.remunerationIncludeEmptyDatesInReport) {
        dateRows.push(
          <TableRow key={`empty-${date}`}>
            <TableCell style={DATE_COLUMN_STYLE}>{formatDate(date)}</TableCell>
            <TableCell colSpan={totalColumns - 1} style={PERIOD_COLUMN_STYLE} />
          </TableRow>,
        );
      }
    });

    const labelSumElements: JSX.Element[] = [];
    const labelSumDecimalElements: JSX.Element[] = [];

    resultingVisibleLabels.forEach(({label, source}) => {
      if (source === "rate") {
        const index = rateLabels.indexOf(label);
        const value = distributionSum.get(`${index}`) || 0;
        labelSumElements.push(
          <TableCell key={`rate-${label}`} style={TIME_COLUMN_STYLE}>
            <strong>{formatDuration(this.props.customerSettings.durationFormat, value)}</strong>
          </TableCell>,
        );
        labelSumDecimalElements.push(
          <TableCell key={`rate-${label}`} style={TIME_COLUMN_STYLE}>
            <strong>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={(value || 0) / HOUR_MINUTES}
              />
            </strong>
          </TableCell>,
        );
      } else if (source === "interval-bonus") {
        const value = intervalBonusSum.get(label) || 0;
        labelSumElements.push(
          <TableCell key={`interval-bonus-${label}`} style={TIME_COLUMN_STYLE}>
            <strong>
              {value ? formatDuration(this.props.customerSettings.durationFormat, value) : ""}
            </strong>
          </TableCell>,
        );
        labelSumDecimalElements.push(
          <TableCell key={`interval-bonus-${label}`} style={TIME_COLUMN_STYLE}>
            <strong>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={(value || 0) / HOUR_MINUTES}
              />
            </strong>
          </TableCell>,
        );
      } else if (source === "work-day-bonus") {
        const value = workDayBonusSum.get(label) || 0;
        labelSumElements.push(
          <TableCell key={`work-day-bonus-${label}`} style={TIME_COLUMN_STYLE}>
            <strong>{value}</strong>
          </TableCell>,
        );
        labelSumDecimalElements.push(
          <TableCell key={`work-day-bonus-${label}`} style={TIME_COLUMN_STYLE} />,
        );
      } else if (source === "calendar-day-bonus") {
        const value = calendarDayBonusSum.get(label) || 0;
        labelSumElements.push(
          <TableCell key={`calendar-day-bonus-${label}`} style={TIME_COLUMN_STYLE}>
            <strong>{value}</strong>
          </TableCell>,
        );
        labelSumDecimalElements.push(
          <TableCell key={`calendar-day-bonus-${label}`} style={TIME_COLUMN_STYLE} />,
        );
      } else if (source === "task-bonus") {
        const value = taskBonusSum.get(label) || 0;
        labelSumElements.push(
          <TableCell key={`task-bonus-${label}`} style={TIME_COLUMN_STYLE}>
            <strong>{value}</strong>
          </TableCell>,
        );
        labelSumDecimalElements.push(
          <TableCell key={`task-bonus-${label}`} style={TIME_COLUMN_STYLE} />,
        );
      } else if (source === "project-distance-bonus") {
        labelSumElements.push(
          <TableCell
            key={`project-distance-bonus-${projectDistanceBonusLabel}`}
            style={TIME_COLUMN_STYLE}
          >
            <strong>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={projectDistanceBonusSum}
              />
            </strong>
          </TableCell>,
        );
        labelSumDecimalElements.push(
          <TableCell
            key={`project-distance-bonus-${projectDistanceBonusLabel}`}
            style={TIME_COLUMN_STYLE}
          />,
        );
      } else if (source === "project-travel-time-bonus") {
        labelSumElements.push(
          <TableCell
            key={`project-travel-time-bonus-${projectTravelTimeBonusLabel}`}
            style={TIME_COLUMN_STYLE}
          >
            <strong>
              {formatDuration(
                this.props.customerSettings.durationFormat,
                projectTravelTimeBonusSum,
              )}
            </strong>
          </TableCell>,
        );
        labelSumDecimalElements.push(
          <TableCell
            key={`project-travel-time-bonus-${projectTravelTimeBonusLabel}`}
            style={TIME_COLUMN_STYLE}
          >
            {" "}
            <strong>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={projectTravelTimeBonusSum / HOUR_MINUTES}
              />
            </strong>
          </TableCell>,
        );
      } else if (source === "count-bonus") {
        const value = countBonusSum.get(label) || 0;
        labelSumElements.push(<TableCell key={`count-bonus-${label}`} style={TIME_COLUMN_STYLE} />);
        labelSumDecimalElements.push(
          <TableCell key={`count-bonus-${label}`} style={TIME_COLUMN_STYLE}>
            <strong>{value}</strong>
          </TableCell>,
        );
      } else {
        const x: never = source;
        throw new Error(`unknown source ${x}`);
      }
    });

    const specialSumElements: JSX.Element[] = [];
    const specialSumDecimalElements: JSX.Element[] = [];
    if (usesStartRate) {
      const value = distributionSum.get("-1");
      const label = startRateLabel;
      specialSumElements.push(
        <TableCell key={`start-rate-${label}`} style={TIME_COLUMN_STYLE}>
          <strong>
            {value != null ? formatDuration(this.props.customerSettings.durationFormat, value) : ""}
          </strong>
        </TableCell>,
      );
      specialSumDecimalElements.push(
        <TableCell key={`start-rate-${label}`} style={TIME_COLUMN_STYLE}>
          <strong>
            <FormattedNumber
              maximumFractionDigits={2}
              minimumFractionDigits={2}
              value={(value || 0) / HOUR_MINUTES}
            />
          </strong>
        </TableCell>,
      );
    }

    const paidNormalHours =
      paidSum +
      compensatoryTotalSubtracted -
      compensatoryTotalAdded / (userData.compensatoryMultiplier || 1) +
      (includeCompensatoryOverLimitInPaid ? aboveLimitSum : 0);

    const sumRow = (
      <TableRow key="-SUM-">
        <TableCell style={DATE_COLUMN_STYLE}>
          <strong>
            {this.props.customerSettings.durationFormat === "<HOURS>:<MINUTES>" ? (
              <FormattedMessage defaultMessage="Sum (timer:minutter)" />
            ) : (
              <FormattedMessage defaultMessage="Sum" />
            )}
          </strong>
        </TableCell>
        <TableCell style={PERIOD_COLUMN_STYLE} />
        {showBreaks ? <TableCell style={TIME_COLUMN_STYLE} /> : null}
        <TableCell style={TIME_COLUMN_STYLE}>
          <strong>
            {includeCompensatory
              ? formatDuration(this.props.customerSettings.durationFormat, actualWorkMinutesSum)
              : formatDuration(this.props.customerSettings.durationFormat, paidSum)}
          </strong>
        </TableCell>
        {includeCompensatory ? (
          <TableCell style={TIME_COLUMN_STYLE}>
            <strong>
              {formatDuration(this.props.customerSettings.durationFormat, paidNormalHours)}
            </strong>
          </TableCell>
        ) : null}
        {specialSumElements}
        {labelSumElements}
        {dinnerBookings ? (
          <TableCell style={TIME_COLUMN_STYLE}>
            <strong>{dinnerBookingsSum}</strong>
          </TableCell>
        ) : null}
        {lunchBookings ? (
          <TableCell style={TIME_COLUMN_STYLE}>
            <strong>{lunchBookingsSum}</strong>
          </TableCell>
        ) : null}
        {showCalledInDialogAfterMinutes !== null && calledInSum ? (
          <TableCell style={TIME_COLUMN_STYLE}>
            <strong>{calledInSum}</strong>
          </TableCell>
        ) : null}
        {accomodationAllowance ? (
          <TableCell style={TIME_COLUMN_STYLE}>
            <strong>{accomodationDaysSum}</strong>
          </TableCell>
        ) : null}
        {accomodationAllowance && accomodationAllowanceIncludeHours ? (
          <TableCell style={TIME_COLUMN_STYLE}>
            <strong>
              {formatDuration(this.props.customerSettings.durationFormat, accomodationMinutesSum)}
            </strong>
          </TableCell>
        ) : null}
        {this.props.customerSettings.remunerationReportShowProjects ? (
          <TableCell style={PROJECTS_COLUMN_STYLE} />
        ) : null}
        {this.props.customerSettings.remunerationReportShowReferenceNumbers ? (
          <TableCell style={PROJECTS_COLUMN_STYLE} />
        ) : null}
      </TableRow>
    );
    const decimalSumRow = (
      <TableRow key="-DECIMAL-SUM-">
        <TableCell style={DATE_COLUMN_STYLE}>
          <strong>
            <FormattedMessage
              defaultMessage="Sum (decimal)"
              id="remuneration-report.label.sum-decimal"
            />
          </strong>
        </TableCell>
        <TableCell style={PERIOD_COLUMN_STYLE} />
        {showBreaks ? <TableCell style={TIME_COLUMN_STYLE} /> : null}
        <TableCell style={TIME_COLUMN_STYLE}>
          <strong>
            <FormattedNumber
              maximumFractionDigits={2}
              minimumFractionDigits={2}
              value={((includeCompensatory ? actualWorkMinutesSum : paidSum) || 0) / HOUR_MINUTES}
            />
          </strong>
        </TableCell>
        {includeCompensatory ? (
          <TableCell style={TIME_COLUMN_STYLE}>
            <strong>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={(paidNormalHours || 0) / HOUR_MINUTES}
              />
            </strong>
          </TableCell>
        ) : null}
        {specialSumDecimalElements}
        {labelSumDecimalElements}
        {accomodationAllowance ? (
          <TableCell style={TIME_COLUMN_STYLE}>
            <strong>{accomodationDaysSum}</strong>
          </TableCell>
        ) : null}
        {accomodationAllowance && accomodationAllowanceIncludeHours ? (
          <TableCell style={TIME_COLUMN_STYLE}>
            <strong>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={(accomodationMinutesSum || 0) / HOUR_MINUTES}
              />
            </strong>
          </TableCell>
        ) : null}
        {this.props.customerSettings.remunerationReportShowProjects ? (
          <TableCell style={PROJECTS_COLUMN_STYLE} />
        ) : null}
        {this.props.customerSettings.remunerationReportShowReferenceNumbers ? (
          <TableCell style={PROJECTS_COLUMN_STYLE} />
        ) : null}
      </TableRow>
    );

    let absenceRows;
    if (
      (daysAbsenceSums && daysAbsenceSums.size) ||
      (minutesAbsenceSums && minutesAbsenceSums.size)
    ) {
      absenceRows = [];
      minutesAbsenceSums.forEach((minutes, absenceType) => {
        const absenceTypeLabel =
          this.props.customerSettings.absenceTypeLabels[absenceType] ||
          ((messages as any)[absenceType]
            ? formatMessage((messages as any)[absenceType])
            : absenceType);
        absenceRows.push(
          <TableRow key={`-absence-row-${absenceType}`}>
            <TableCell style={DATE_COLUMN_STYLE}>
              <strong>{absenceTypeLabel}</strong>
            </TableCell>
            <TableCell colSpan={totalColumns - 1} style={PERIOD_COLUMN_STYLE}>
              {formatDuration(this.props.customerSettings.durationFormat, minutes)} timer
            </TableCell>
          </TableRow>,
        );
        absenceRows.push(
          <TableRow key={`-absence-row-decimal-${absenceType}`}>
            <TableCell style={DATE_COLUMN_STYLE}>
              <strong>
                {formatMessage(messages.absenceDecimal, {
                  absenceType: absenceTypeLabel,
                })}
              </strong>
            </TableCell>
            <TableCell colSpan={totalColumns - 1} style={PERIOD_COLUMN_STYLE}>
              {formatNumber(minutes / HOUR_MINUTES, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}{" "}
              timer
            </TableCell>
          </TableRow>,
        );
      });
      daysAbsenceSums.forEach((numberOfDays, absenceType) => {
        const absenceTypeLabel =
          this.props.customerSettings.absenceTypeLabels[absenceType] ||
          ((messages as any)[absenceType]
            ? formatMessage((messages as any)[absenceType])
            : absenceType);
        absenceRows.push(
          <TableRow key={`-absence-row-${absenceType}`}>
            <TableCell style={DATE_COLUMN_STYLE}>
              <strong>{absenceTypeLabel}</strong>
            </TableCell>
            <TableCell colSpan={totalColumns - 1} style={PERIOD_COLUMN_STYLE}>
              {numberOfDays} dage
            </TableCell>
          </TableRow>,
        );
      });
    }

    let weekdayHolidayCountRow: JSX.Element | undefined;
    if (countWeekdayHolidays) {
      weekdayHolidayCountRow = (
        <TableRow>
          <TableCell style={DATE_COLUMN_STYLE}>
            <strong>
              <FormattedMessage defaultMessage="Helligdage faldende på hverdage" />
            </strong>
          </TableCell>
          <TableCell colSpan={totalColumns - 1} style={PERIOD_COLUMN_STYLE}>
            <strong>{weekdayHolidaySum}</strong>
          </TableCell>
        </TableRow>
      );
    }

    const labelHeaders: JSX.Element[] = resultingVisibleLabels.map(({label, source}) => {
      return (
        <TableCell key={`${source}-${label}`} style={TIME_COLUMN_STYLE}>
          {label}
        </TableCell>
      );
    });

    const specialHeaders = [];
    if (usesStartRate) {
      const label = startRateLabel;
      specialHeaders.push(
        <TableCell key={`start-rate-${label}`} style={TIME_COLUMN_STYLE}>
          {label}
        </TableCell>,
      );
    }
    let dinnerBookingHeader;
    if (dinnerBookings) {
      dinnerBookingHeader = (
        <TableCell style={TIME_COLUMN_STYLE}>{formatMessage(messages.dinnerBookings)}</TableCell>
      );
    }
    let lunchBookingHeader;
    if (lunchBookings) {
      lunchBookingHeader = (
        <TableCell style={TIME_COLUMN_STYLE}>{formatMessage(messages.lunchBookings)}</TableCell>
      );
    }

    let calledInHeader;
    if (showCalledInDialogAfterMinutes !== null && calledInSum) {
      calledInHeader = (
        <TableCell style={TIME_COLUMN_STYLE}>{formatMessage(messages.calledIn)}</TableCell>
      );
    }

    let title = initials;
    if (
      userData.remunerationGroup &&
      userData.remunerationGroup !== userData.employeeDefaultRemunerationGroup
    ) {
      title = `${initials} (${userData.reportTitle})`;
    }

    return (
      <div style={{paddingTop: "1em"}}>
        <Card style={{minWidth: 750}}>
          <CardHeader subheader={name} title={title} />
          {userData.periodContainsUncompletedTasks ? (
            <CardContent style={{color: "red"}}>
              <FormattedMessage
                defaultMessage="ADVARSEL: Medarbejderen har uafsluttede opgaver i perioden."
                id="remuneration-report.table-header.uncompleted-warning"
              />
            </CardContent>
          ) : null}
          {userData.hasPoolPeriodMismatchIssue ? (
            <CardContent style={{color: "red"}}>
              <FormattedMessage defaultMessage="ADVARSEL: Valgt periode for udskrift matcher ikke puljeperioder. Overtid/afspadsering kan ikke regnes med." />
            </CardContent>
          ) : null}
          {poolTableBlock}
          <CardMedia>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={DATE_COLUMN_STYLE}>
                    <FormattedMessage defaultMessage="Dato" />
                  </TableCell>
                  <TableCell style={PERIOD_COLUMN_STYLE}>
                    <FormattedMessage
                      defaultMessage="Arbejdsperioder"
                      id="remuneration-report.table-header.work-periods"
                    />
                  </TableCell>
                  {showBreaks ? (
                    <TableCell style={TIME_COLUMN_STYLE}>
                      <FormattedMessage defaultMessage="Pauser" />
                    </TableCell>
                  ) : null}
                  <TableCell style={TIME_COLUMN_STYLE}>
                    {includeCompensatory ? (
                      <FormattedMessage
                        defaultMessage="Reel arbejdstid"
                        id="user-salary-report.table-header.actual-work-time"
                      />
                    ) : (
                      <FormattedMessage defaultMessage="Betalt tid" />
                    )}
                  </TableCell>
                  {includeCompensatory ? (
                    <TableCell style={TIME_COLUMN_STYLE}>
                      <FormattedMessage
                        defaultMessage="Udbetalt normal tid"
                        id="user-salary-report.table-header.paid-normal-hours"
                      />
                    </TableCell>
                  ) : null}
                  {specialHeaders}
                  {labelHeaders}
                  {dinnerBookingHeader}
                  {lunchBookingHeader}
                  {calledInHeader}
                  {accomodationAllowance ? (
                    <TableCell style={TIME_COLUMN_STYLE}>
                      {accomodationAllowanceLabel ? (
                        <FormattedMessage
                          defaultMessage="{label} dage"
                          id="remuneration-report.table-header.accomodation-allowance-labelled-days"
                          values={{label: accomodationAllowanceLabel}}
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage="Diæt-heldage"
                          id="remuneration-report.table-header.accomodation-allowance-days"
                        />
                      )}
                    </TableCell>
                  ) : null}
                  {accomodationAllowance && accomodationAllowanceIncludeHours ? (
                    <TableCell style={TIME_COLUMN_STYLE}>
                      {accomodationAllowanceLabel ? (
                        <FormattedMessage
                          defaultMessage="{label} timer"
                          id="remuneration-report.table-header.accomodation-allowance-labelled-hours"
                          values={{label: accomodationAllowanceLabel}}
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage="Diæt-timer"
                          id="remuneration-report.table-header.accomodation-allowance-hours"
                        />
                      )}
                    </TableCell>
                  ) : null}
                  {this.props.customerSettings.remunerationReportShowProjects ? (
                    <TableCell style={PROJECTS_COLUMN_STYLE}>
                      <FormattedMessage
                        defaultMessage="Projekter"
                        id="remuneration-report.table-header.projects"
                      />
                    </TableCell>
                  ) : null}
                  {this.props.customerSettings.remunerationReportShowReferenceNumbers ? (
                    <TableCell style={PROJECTS_COLUMN_STYLE}>
                      {this.props.customerSettings.taskReferenceNumberLabel ||
                        this.props.customerSettings.orderReferenceNumberLabel || (
                          <FormattedMessage
                            defaultMessage="Ref. nr."
                            id="remuneration-report.table-header.reference-numbers"
                          />
                        )}
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {dateRows}
                {sumRow}
                {decimalSumRow}
                {absenceRows}
                {weekdayHolidayCountRow}
              </TableBody>
            </Table>
          </CardMedia>
        </Card>
      </div>
    );
  }
}
