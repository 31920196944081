import {Config} from "@co-common-libs/config";
import {
  AccomodationAllowance,
  AccomodationAllowanceUrl,
  Availability,
  CalendarOrdering,
  CalendarWorkHours,
  Customer,
  CustomerUrl,
  DaysAbsence,
  DaysAbsenceUrl,
  EmployeeGroup,
  EmployeeGroupUrl,
  FieldUse,
  HoursAbsence,
  HoursAbsenceUrl,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  MachineUse,
  Order,
  OrderUrl,
  PatchUnion,
  PriceGroupUrl,
  ProjectUrl,
  ResourceTypeUnion,
  Role,
  RoutePlan,
  RoutePlanUrl,
  Task,
  TaskUrl,
  TimerStart,
  User,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  emptyOrder,
  emptyTask,
  resourceNameFor,
  urlToId,
} from "@co-common-libs/resources";
import {
  HOUR_MINUTES,
  SECOND_MILLISECONDS,
  dateFromString,
  dateToString,
  formatAddress,
  notUndefined,
} from "@co-common-libs/utils";
import {
  AppbarDateSelection,
  AppbarSearchField,
  ResponsiveDialog,
  UserDialog,
} from "@co-frontend-libs/components";
import {
  ConnectedInternalWorkTypeDialog,
  ConnectedRoutePlanDialog,
  ConnectedUserDialog,
} from "@co-frontend-libs/connected-components";
import {Query} from "@co-frontend-libs/db-resources";
import {
  AppState,
  PathTemplate,
  actions,
  getAccomodationAllowanceArray,
  getAccomodationAllowanceLookup,
  getAvailabilityArray,
  getCalendarOrderingArray,
  getCalendarWorkHoursArray,
  getCurrentRole,
  getCurrentUser,
  getCurrentUserProfile,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getDaysAbsenceArray,
  getDaysAbsenceLookup,
  getDefaultTaskEmployeeUrl,
  getDeviceConfigKey,
  getEmployeeGroupLookup,
  getExtraHalfHolidaysPerRemunerationGroup,
  getExtraHolidaysPerRemunerationGroup,
  getHoursAbsenceArray,
  getHoursAbsenceLookup,
  getLocationLookup,
  getMachineArray,
  getOrderLookup,
  getPathName,
  getRoleArray,
  getRoutePlanArray,
  getRoutePlanLookup,
  getTaskArray,
  getTaskLookup,
  getTimerStartArray,
  getUserArray,
  getUserId,
  getUserLookup,
  getUserRoleLookup,
  getUserUserProfileLookup,
  getWorkTypeArray,
  getWorkTypeLookup,
  makeQueryParameterGetter,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {DialogContent, IconButton, Tab, Tabs, Toolbar} from "@material-ui/core";
import {
  AccomodationAllowanceDialog,
  CustomerSelectCreateDialog,
  CustomerTaskCreationWrapper,
  DrawerMarginAppBar,
  EditAbsenceDialog,
  FilterButton,
  FloatingActionButtonData,
  FloatingActionButtons,
  MenuToolbar,
  OrderTabContent,
  RegisterAbsenceDialog,
  TouchPullRefreshHandler,
} from "app-components";
import {
  computeDepartment,
  createNewRouteTask,
  createOrder,
  currentUserMayRegisterOthersAbsence,
  currentUserMayRegisterOwnAbsence,
  machineOperatorCanSeeFutureTasksUntil,
  registerAbsence,
  userCanRegisterAccommodation,
} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import AccountAlertIcon from "mdi-react/AccountAlertIcon";
import AccountClockIcon from "mdi-react/AccountClockIcon";
import ArrowLeftBoldCircleOutlineIcon from "mdi-react/ArrowLeftBoldCircleOutlineIcon";
import ArrowRightBoldCircleOutlineIcon from "mdi-react/ArrowRightBoldCircleOutlineIcon";
import CheckIcon from "mdi-react/CheckIcon";
import CornIcon from "mdi-react/CornIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import HomeIcon from "mdi-react/HomeIcon";
import HotelIcon from "mdi-react/HotelIcon";
import PencilIcon from "mdi-react/PencilIcon";
import RoutesIcon from "mdi-react/RoutesIcon";
import TagMultipleIcon from "mdi-react/TagMultipleIcon";
import TagTextOutlineIcon from "mdi-react/TagTextOutlineIcon";
import UndoIcon from "mdi-react/UndoIcon";
import UnfoldLessHorizontalIcon from "mdi-react/UnfoldLessHorizontalIcon";
import UnfoldMoreHorizontalIcon from "mdi-react/UnfoldMoreHorizontalIcon";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createSelector, createStructuredSelector} from "reselect";
import type {Writable} from "ts-essentials";
import {v4 as uuid} from "uuid";
import {registerAccommodationAllowance} from "../time-overview";
import AbsenceTabContent from "./absence-tab";
import EmployeeTabContent from "./employee-tab";
import MachinesTabContent from "./machines-tab";
import SetCalendarWorkHoursDialog from "./set-calendar-work-hours-dialog";
import {WeekDay} from "./types";

const messages = defineMessages({
  absenceTab: {
    defaultMessage: "Fravær",
    id: "task-calendar.tab-header.absence-tab",
  },
  availability: {
    defaultMessage: "Rådighed",
    id: "task-calendar.dialog-title.availability",
  },
  chauffeurs: {
    defaultMessage: "Chauffører",
    id: "task-calendar.label.chauffeurs",
  },
  childsFirstSickDay: {
    defaultMessage: "Barns første sygedag",
    id: "task-calendar.label.childs-first-sick-day",
  },
  compensatory: {
    defaultMessage: "Afspadsering",
    id: "task-calendar.label.compensatory",
  },
  daysOffWithoutPay: {
    defaultMessage: "Fridage uden løn",
    id: "task-calendar.label.days-off-without-pay",
  },
  deleteAbsence: {
    defaultMessage: "Slet fravær?",
    id: "task-calendar.dialog-title.delete-absence",
  },
  employees: {
    defaultMessage: "Medarbejdere",
    id: "task-calendar.label.employees",
  },
  floatingHoliday: {
    defaultMessage: "Ferie-fridage",
    id: "task-calendar.label.floating-holiday",
  },
  machineOperators: {
    defaultMessage: "Maskinførere",
    id: "task-calendar.label.machine-operators",
  },
  machinesTab: {
    defaultMessage: "Maskiner",
    id: "task-calendar.tab-header.machines-tab",
  },
  orderTab: {
    defaultMessage: "Ordrer",
    id: "task-calendar.tab-header.orders-tab",
  },
  orderTabAsTaskTab: {
    defaultMessage: "Opgaver",
    id: "task-calendar.tab-header.orders-tab-as-task-tab",
  },
  registerAccomedationAllowance: {
    defaultMessage: "Registrer diæt/udetillæg for ansat",
    id: "task-calendar.dialog-title.register-accomodation-allowance-employee",
  },
  reportAbsence: {
    defaultMessage: "Indmeld fravær for ansat",
    id: "task-calendar.dialog-title.report-absence-employee",
  },
  setCalendarWorkHours: {
    defaultMessage: "Angiv almindelig arbejdstid",
    id: "task-calendar.dialog-title.set-calendar-work-hours",
  },
  sickLeave: {
    defaultMessage: "Sygefravær",
    id: "task-calendar.label.sick-leave",
  },
  timeOff: {
    defaultMessage: "FRI",
    id: "time-overview.label.time-off",
  },
  title: {
    defaultMessage: "Kalender",
    id: "task-calendar.title.calendar",
  },
  usersTab: {
    defaultMessage: "Medarbejdere",
    id: "task-calendar.tab-header.users-tab",
  },

  usersTabHandheld: {
    defaultMessage: "Medarb.",
    id: "task-calendar.tab-header.users-tab-handheld",
  },
  vacation: {
    defaultMessage: "Feriedage",
    id: "task-calendar.label.vacation",
  },
  vehiclesTab: {
    defaultMessage: "Køretøjer",
    id: "task-calendar.tab-header.vehicles-tab",
  },
});

const getActiveUserWithAccomodationOption: (state: AppState) => readonly User[] = createSelector(
  getUserArray,
  getUserUserProfileLookup,
  getCustomerSettings,
  (userArray, userUserProfileLookup, customerSettings) =>
    userArray.filter(
      (user) =>
        user.active &&
        userCanRegisterAccommodation(customerSettings, userUserProfileLookup(user.url)),
    ),
);

interface UserDialogStateProps {
  labelVariant: "CHAUFFEUR" | "EMPLOYEE" | "MACHINEOPERATOR";
  userArray: readonly User[];
  userRoleLookup: (url: UserUrl) => Role | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface UserDialogOwnProps {
  onCancel(): void;
  onOk(url: string): void;
  open: boolean;
  title?: string;
}

export const ActiveAccomodationUserDialog: React.ComponentType<UserDialogOwnProps> = connect<
  UserDialogStateProps,
  object,
  UserDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, UserDialogStateProps>({
    labelVariant: (_state: AppState) => "EMPLOYEE",
    userArray: getActiveUserWithAccomodationOption,
    userRoleLookup: getUserRoleLookup,
    userUserProfileLookup: getUserUserProfileLookup,
  }),
  {},
)(UserDialog);

const minutesSinceMidnightFromDatetimeIsoString = (isoString: Date | string): number => {
  const date = new Date(isoString);
  return date.getHours() * HOUR_MINUTES + date.getMinutes();
};

function objectFromMapOrObject<V>(
  data?: ReadonlyMap<string, V> | {readonly [key: string]: V | undefined},
): {readonly [key: string]: V | undefined} | undefined {
  if (data instanceof Map) {
    const result: {[key: string]: V | undefined} = {};
    (data as ReadonlyMap<string, V>).forEach((value, key) => {
      result[key] = value;
    });
    return result;
  } else {
    const result = data as {readonly [key: string]: V | undefined} | undefined;
    return result;
  }
}
interface TaskCalendarStateProps {
  accomodationAllowanceArray: readonly AccomodationAllowance[];
  accomodationAllowanceLookup: (url: AccomodationAllowanceUrl) => AccomodationAllowance | undefined;
  availabilityArray: readonly Availability[];
  calendarOrderingArray: readonly CalendarOrdering[];
  calendarWorkHoursArray: readonly CalendarWorkHours[];
  currentRole: Role | null;
  currentUser: User | null;
  currentUserID: string | null;
  currentUserProfile: UserProfile | null;
  currentUserURL: UserUrl | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  date: string;
  daysAbsenceArray: readonly DaysAbsence[];
  daysAbsenceLookup: (url: DaysAbsenceUrl) => DaysAbsence | undefined;
  defaultTaskEmployeeUrl: UserUrl | null;
  employeeGroupLookup: (url: EmployeeGroupUrl) => EmployeeGroup | undefined;
  extraHalfHolidaysPerRemunerationGroup: (
    remunerationGroup: string,
    date: string,
  ) => string | undefined;
  extraHolidaysPerRemunerationGroup: (
    remunerationGroup: string,
    date: string,
  ) => string | undefined;
  hoursAbsenceArray: readonly HoursAbsence[];
  hoursAbsenceLookup: (url: HoursAbsenceUrl) => HoursAbsence | undefined;
  locationLookup: (url: LocationUrl) => Location | undefined;
  machineArray: readonly Machine[];
  orderLookup: (url: OrderUrl) => Order | undefined;
  pathName: string;
  q: string;
  roleArray: readonly Role[];
  routePlanArray: readonly RoutePlan[];
  routePlanLookup: (url: RoutePlanUrl) => RoutePlan | undefined;
  scrollX: string;
  scrollY: string;
  tab: string;
  taskArray: readonly Task[];
  taskLookup: (url: TaskUrl) => Task | undefined;
  timerStartArray: readonly TimerStart[];
  userArray: readonly User[];
  userCalendarMachineOrder: unknown;
  userCalendarVisibleDepartments?: readonly string[];
  // Map ?
  userCalendarVisibleWorkTypes?:
    | ReadonlyMap<UserUrl, readonly WorkTypeUrl[]>
    | {readonly [userURL: UserUrl]: readonly WorkTypeUrl[] | undefined};
  userLookup: (url: UserUrl) => User | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeArray: readonly WorkType[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface TaskCalendarDispatchProps {
  configPut: (data: {key: string; value: any}) => void;
  create: (instance: ResourceTypeUnion) => void;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  putQueryKeys: (
    update: {readonly [key: string]: string | undefined},
    navigationKind?: PartialNavigationKind,
  ) => void;
  remove: (url: string) => void;
  temporaryQueriesDiscardedForPath: (pathName: string, key: string) => void;
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface TaskCalendarOwnProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type TaskCalendarProps = TaskCalendarDispatchProps & TaskCalendarOwnProps & TaskCalendarStateProps;

interface TaskCalendarState {
  absenceMap: DaysAbsence | HoursAbsence | null;
  allFolded: boolean;
  customerDialogOpen: boolean;
  internalWorkTypeDialogOpen: boolean;
  machineCalendarOrder: readonly string[];
  machineOrderingMode: boolean;
  nowMinutes: number | null;
  ordering: readonly string[] | null;
  orderingMode: boolean;
  orderingResetDialogOpen: boolean;
  planningMode: boolean;
  planningSave: boolean;
  registerAbsenceDialogOpen: boolean;
  registerAbsenceEmployee: UserUrl | null;
  registerAbsenceInitialFromDate: string | null;
  registerAccomodationAllowanceDialogOpen: boolean;
  registerAccomodationAllowanceEmployee: UserUrl | null;
  routePlanDialogOpen: boolean;
  selectedDepartmentIdentifierSet: ReadonlySet<string>;
  selectedWorkTypeURLSet: ReadonlySet<WorkTypeUrl>;
  setCalendarWorkHoursDialogOpen: boolean;
  setCalendarWorkHoursEmployee: UserUrl | null;
}

class TaskCalendar extends React.Component<TaskCalendarProps, TaskCalendarState> {
  state: TaskCalendarState = {
    absenceMap: null,
    allFolded: this.props.customerSettings.orderCalendarFoldAllDefault,
    customerDialogOpen: false,
    internalWorkTypeDialogOpen: false,
    machineCalendarOrder:
      (this.props.currentUserProfile && this.props.currentUserProfile.machineCalendarOrder) || [],
    machineOrderingMode: false,
    nowMinutes: null,
    ordering: null,
    orderingMode: false,
    orderingResetDialogOpen: false,
    planningMode: false,
    planningSave: false,
    registerAbsenceDialogOpen: false,
    registerAbsenceEmployee: null,
    registerAbsenceInitialFromDate: null,
    registerAccomodationAllowanceDialogOpen: false,
    registerAccomodationAllowanceEmployee: null,
    routePlanDialogOpen: false,
    selectedDepartmentIdentifierSet: new Set(this.props.userCalendarVisibleDepartments || []),
    selectedWorkTypeURLSet: new Set(
      ((this.props.userCalendarVisibleWorkTypes &&
        objectFromMapOrObject(this.props.userCalendarVisibleWorkTypes)) ||
        {})[this.props.currentUserURL || ""] || [],
    ),
    setCalendarWorkHoursDialogOpen: false,
    setCalendarWorkHoursEmployee: null,
  };

  componentDidMount(): void {
    const {date} = this.props;
    if (!date) {
      this.handleDateChange(dateToString(new Date()));
    }
    this.updateNowMinutes();
    const updateIntervalSeconds = 10;
    this.intervalID = window.setInterval(
      this.updateNowMinutes,
      updateIntervalSeconds * SECOND_MILLISECONDS,
    );
  }
  componentWillUnmount(): void {
    if (this.intervalID) {
      window.clearInterval(this.intervalID);
      this.intervalID = null;
    }
  }

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  intervalID: number | null = null;
  getLastVisibleDate(): string | undefined {
    const role = this.props.currentRole;
    const userIsManager = role && role.manager;
    const userIsSeniorMachineOperator = role && role.seniorMachineOperator;
    if (!userIsManager && !userIsSeniorMachineOperator && this.props.tab !== "absence") {
      return machineOperatorCanSeeFutureTasksUntil(this.props.customerSettings);
    }
    return undefined;
  }
  getSelectedDate(): string {
    const today = dateToString(new Date());
    const selectedDate = this.props.date || today;
    const lastVisibleDate = this.getLastVisibleDate();
    if (lastVisibleDate && selectedDate > lastVisibleDate) {
      return lastVisibleDate;
    }
    return selectedDate;
  }
  @bind
  updateNowMinutes(): void {
    const now = new Date();
    const today = dateToString(now);
    const selectedDate = this.getSelectedDate();
    if (selectedDate === today) {
      const nowMinutes = minutesSinceMidnightFromDatetimeIsoString(now);
      this.setState({nowMinutes});
    }
  }
  getActiveCalendarOrdering(): CalendarOrdering | undefined {
    const {calendarOrderingArray} = this.props;
    const selectedDate = this.getSelectedDate();
    let calendarOrderingCandidateSeq = calendarOrderingArray.filter(
      (calendarOrdering) => calendarOrdering.fromDate <= selectedDate,
    );
    if (this.props.customerSettings.employeeCalendarManualOrderPerUser) {
      const {currentUserURL} = this.props;
      calendarOrderingCandidateSeq = calendarOrderingCandidateSeq.filter(
        (calendarOrdering) => calendarOrdering.createdBy === currentUserURL,
      );
    }
    return _.maxBy(calendarOrderingCandidateSeq, (calendarOrdering) => calendarOrdering.fromDate);
  }
  getActiveCalendarWorkHours(userURL: string): CalendarWorkHours | undefined {
    const {calendarWorkHoursArray} = this.props;
    const selectedDate = this.getSelectedDate();
    const calendarWorkHoursCandidateSeq = calendarWorkHoursArray.filter(
      (c) => c.user === userURL && c.fromDate <= selectedDate,
    );
    return _.maxBy(calendarWorkHoursCandidateSeq, (c) => c.fromDate);
  }
  @bind
  handleCustomerTaskCreationWizardOk(data: {
    customer: CustomerUrl;
    department: string | null;
    fields: readonly LocationUrl[];
    machines: readonly {
      readonly machine: MachineUrl;
      readonly priceGroup: PriceGroupUrl | null;
    }[];
    priceGroup: PriceGroupUrl | null;
    project: ProjectUrl | null;
    workPlace: LocationUrl | null;
    workType: WorkTypeUrl | null;
  }): void {
    const {customer, department, fields, machines, priceGroup, project, workPlace, workType} = data;
    const machineuseSet = machines.map(
      ({machine, priceGroup: machinePriceGroup}): MachineUse => ({
        machine,
        priceGroup: machinePriceGroup,
        transporter: false,
      }),
    );
    const {locationLookup} = this.props;
    const fielduseSet = fields
      .map((fieldURL) => locationLookup(fieldURL))
      .filter(notUndefined)
      .map(
        (field): FieldUse => ({
          fieldAreaHa: field.fieldAreaHa,
          fieldCrop: field.fieldCrop,
          geojson: field.geojson,
          notes: "",
          relatedField: field.url,
        }),
      );
    const {currentRole, currentUserURL, customerSettings} = this.props;
    if (!currentUserURL) {
      return;
    }
    const userIsOnlyMachineOperator = currentRole && !currentRole.manager;
    console.assert(userIsOnlyMachineOperator);
    const date = dateToString(new Date());
    const workPlaceInstance = (workPlace && locationLookup(workPlace)) || undefined;
    const address = formatAddress(workPlaceInstance);
    const newOrder: Writable<Order> = createOrder({
      address,
      created: new Date().toISOString(),
      createdBy: currentUserURL,
      customer,
      date,
      durationDays: 1,
      project,
      relatedWorkplace: workPlace,
    });

    if (
      customerSettings.enableOrderReferenceNumber &&
      customerSettings.autoFillReferenceNumberWithCustomerAccount
    ) {
      const customerInstance = this.props.customerLookup(customer);
      const referenceNumber = (customerInstance && customerInstance.c5_account) || "";
      newOrder.referenceNumber = referenceNumber;
    }
    this.props.create(newOrder);

    const taskID = uuid();
    const taskURL = instanceURL("task", taskID);
    const newTask: Writable<Task> = {
      ...emptyTask,
      address,
      created: new Date().toISOString(), // for local ordering until set by server
      createdBy: currentUserURL,
      date,
      department: department || "",
      id: taskID,
      machineOperator: currentUserURL,
      order: newOrder.url,
      priceGroup,
      priority: Math.pow(2, 28),
      project,
      relatedWorkplace: workPlace,
      url: taskURL,
      workType,
    };
    if (machineuseSet) {
      newTask.machineuseSet = machineuseSet;
    }
    if (fielduseSet) {
      newTask.fielduseSet = fielduseSet;
    }
    this.props.create(newTask);
    this.props.go("/task/:id", {id: taskID});
  }
  @bind
  handleCustomerDialogCancel(): void {
    this.setState({customerDialogOpen: false});
  }
  @bind
  handleCustomerDialogOk(url: CustomerUrl): void {
    this.setState({customerDialogOpen: false});
    this.newTaskOrOrder(url);
  }
  @bind
  handleAddButton(): void {
    const {currentRole, customerSettings} = this.props;
    const userIsManager = currentRole && currentRole.manager;
    const userIsSeniorMachineOperator = currentRole && currentRole.seniorMachineOperator;

    if (
      customerSettings.customerTaskCreationWizard &&
      !userIsManager &&
      !userIsSeniorMachineOperator
    ) {
      if (this.taskCreationWizardControl.current) {
        const {currentUser, currentUserProfile, employeeGroupLookup} = this.props;
        const employeeGroupURL = currentUserProfile && currentUserProfile.employeeGroup;
        const employeeGroup = employeeGroupURL && employeeGroupLookup(employeeGroupURL);
        const defaultDepartment =
          (currentUser &&
            computeDepartment(currentUserProfile, employeeGroup || null, customerSettings)) ||
          null;
        this.taskCreationWizardControl.current.start({defaultDepartment});
      }
      return;
    }
    if (customerSettings.orderEntryAutoOpenCustomerSelection) {
      this.setState({customerDialogOpen: true});
      return;
    }
    this.newTaskOrOrder();
  }
  newTaskOrOrder(customerURL?: CustomerUrl): void {
    const {currentRole, customerSettings} = this.props;
    const activeTab = this.props.tab;
    const userIsSeniorMachineOperator = currentRole && currentRole.seniorMachineOperator;
    if (
      activeTab === "employees" ||
      activeTab === "machines" ||
      (customerSettings.orderCalendarAsTaskCalendar && activeTab === "orders")
    ) {
      const {currentUser, currentUserProfile, currentUserURL, employeeGroupLookup} = this.props;
      if (!currentUserURL) {
        return;
      }

      const newOrderPart: Writable<Partial<Order>> = {
        created: new Date().toISOString(),
        createdBy: currentUserURL,
        date: this.getSelectedDate(),
      };
      if (customerURL) {
        newOrderPart.customer = customerURL;
      }

      const newOrder: Writable<Order> = createOrder(newOrderPart);
      const userIsOnlyMachineOperator = currentRole && !currentRole.manager;
      if (userIsOnlyMachineOperator) {
        const today = dateToString(new Date());
        if (customerSettings.ownCompanyId) {
          newOrder.customer = instanceURL("customer", customerSettings.ownCompanyId);
        }
        newOrder.date = today;
      }
      this.props.create(newOrder);

      const employeeGroupURL = currentUserProfile && currentUserProfile.employeeGroup;
      const employeeGroup = employeeGroupURL && employeeGroupLookup(employeeGroupURL);

      const taskID = uuid();
      const taskURL = instanceURL("task", taskID);
      const newTask: Writable<Task> = {
        ...emptyTask,
        created: new Date().toISOString(), // for local ordering until set by server
        createdBy: currentUserURL,
        date: customerSettings.taskAndOrderDatesFromCalendarOnCreate
          ? this.getSelectedDate()
          : null,
        department: currentUser
          ? computeDepartment(currentUserProfile, employeeGroup || null, customerSettings)
          : "",
        id: taskID,
        machineOperator: customerSettings.defaultTaskEmployee
          ? instanceURL("user", customerSettings.defaultTaskEmployee)
          : null,
        order: newOrder.url,

        priority: Math.pow(2, 28),
        url: taskURL,
      };

      if (
        userIsOnlyMachineOperator &&
        !(userIsSeniorMachineOperator && customerSettings.seniorMachineOperatorCanManageOrders)
      ) {
        newTask.machineOperator = currentUserURL;
      }
      this.props.create(newTask);

      window.setTimeout(() => {
        this.props.go("/order/:id", {id: urlToId(newOrder.url)});
      }, 0);
    } else {
      const userURL = this.props.currentUserURL || undefined;
      const id = uuid();
      const url = instanceURL("order", id);
      const order: Order = {
        ...emptyOrder,
        createdBy: userURL || null,
        customer: customerURL || null,
        date: customerSettings.taskAndOrderDatesFromCalendarOnCreate
          ? this.getSelectedDate()
          : null,
        draft: customerSettings.orderDraftsAllwaysCreateDraft && customerSettings.orderDrafts,
        durationDays: 1,
        id,
        url,
      };
      this.props.create(order);
      window.setTimeout(() => {
        this.props.go("/orderEntry/:id", {id});
      }, 0);
    }
  }

  @bind
  handlePlanningModeButton(): void {
    this.setState({
      planningMode: true,
      planningSave: false,
    });
  }
  @bind
  handlePlanningOkButton(): void {
    this.setState({
      planningMode: false,
      planningSave: true,
    });
  }
  @bind
  handlePlanningCancelButton(): void {
    this.setState({
      planningMode: false,
      planningSave: false,
    });
  }
  @bind
  handleOrderingModeButton(): void {
    this.setState({
      orderingMode: true,
    });
  }
  @bind
  handleOrderingOkButton(): void {
    const {ordering} = this.state;
    this.setState({
      orderingMode: false,
    });
    if (ordering) {
      const orderingString = JSON.stringify(ordering);
      const selectedDate = this.getSelectedDate();
      const {currentUserURL} = this.props;
      const createdBy = this.props.customerSettings.employeeCalendarManualOrderPerUser
        ? currentUserURL
        : null;
      const existing = this.getActiveCalendarOrdering();
      if (existing && existing.fromDate === selectedDate) {
        if (existing.ordering !== orderingString) {
          this.props.update(existing.url, [
            {member: "createdBy", value: createdBy},
            {member: "ordering", value: orderingString},
          ]);
        }
      } else {
        const id = uuid();
        const url = instanceURL("calendarOrdering", id);
        const instance = {
          createdBy,
          fromDate: selectedDate,
          id,
          ordering: orderingString,
          url,
        };
        this.props.create(instance);
      }
    }
  }
  @bind
  handleOrderingCancelButton(): void {
    this.setState({
      ordering: null,
      orderingMode: false,
    });
  }

  @bind
  handleOrderingResetButton(): void {
    this.setState({orderingResetDialogOpen: true});
  }

  @bind
  handleOrderingResetDialogCancel(): void {
    this.setState({orderingResetDialogOpen: false});
  }

  @bind
  handleOrderingResetDialogOK(): void {
    this.setState({
      ordering: null,
      orderingMode: false,
      orderingResetDialogOpen: false,
    });
    const selectedDate = this.getSelectedDate();
    const {currentUserURL} = this.props;
    const createdBy = this.props.customerSettings.employeeCalendarManualOrderPerUser
      ? currentUserURL
      : null;
    const activeCalendarOrdering = this.getActiveCalendarOrdering();
    if (activeCalendarOrdering && activeCalendarOrdering.ordering) {
      if (activeCalendarOrdering.fromDate === selectedDate) {
        this.props.update(activeCalendarOrdering.url, [
          {member: "createdBy", value: createdBy},
          {member: "ordering", value: ""},
        ]);
      } else {
        const id = uuid();
        const url = instanceURL("calendarOrdering", id);
        const instance = {
          createdBy,
          fromDate: selectedDate,
          id,
          ordering: "",
          url,
        };
        this.props.create(instance);
      }
    }
  }
  @bind
  handleRegisterAbsenceButton(): void {
    const {currentRole, currentUserURL, customerSettings} = this.props;
    this.setState({
      registerAbsenceDialogOpen: true,
      registerAbsenceEmployee: currentUserMayRegisterOthersAbsence(customerSettings, currentRole)
        ? null
        : currentUserURL,
      registerAbsenceInitialFromDate: null,
    });
  }
  @bind
  handleRegisterAbsenceDialogCancel(): void {
    this.setState({
      registerAbsenceDialogOpen: false,
      registerAbsenceEmployee: null,
      registerAbsenceInitialFromDate: null,
    });
  }
  @bind
  handleRegisterAbsenceMachineOperatorDialogOk(url: UserUrl): void {
    this.setState({registerAbsenceEmployee: url});
  }
  @bind
  handleRegisterAbsenceDialogOk(data: {
    absenceType: string;
    employeeReason: string;
    fromDate: string;
    fromTime: string | null;
    note: string;
    onlyWeekdays: boolean | undefined;
    toDate: string;
    toTime: string | null;
  }): void {
    const {registerAbsenceEmployee} = this.state;
    if (!registerAbsenceEmployee) {
      return;
    }
    const {absenceType, employeeReason, fromDate, fromTime, note, onlyWeekdays, toDate, toTime} =
      data;
    const {customerSettings} = this.props;
    const {
      create,
      daysAbsenceArray,
      extraHalfHolidaysPerRemunerationGroup,
      extraHolidaysPerRemunerationGroup,
      remove,
      update,
      userUserProfileLookup,
    } = this.props;
    registerAbsence(
      {
        absenceType,
        employeeReason,
        fromDate,
        fromTime,
        note,
        onlyWeekdays,
        profile: userUserProfileLookup(registerAbsenceEmployee),
        toDate,
        toTime,
        user: registerAbsenceEmployee,
      },
      {
        create,
        extraHalfHolidaysPerRemunerationGroup,
        extraHolidaysPerRemunerationGroup,
        remove,
        update,
      },
      daysAbsenceArray,
      customerSettings,
    );
    this.setState({
      registerAbsenceDialogOpen: false,
      registerAbsenceEmployee: null,
      registerAbsenceInitialFromDate: null,
    });
  }
  @bind
  handleRegisterAccomodationAllowanceButton(): void {
    this.setState({
      registerAccomodationAllowanceDialogOpen: true,
      registerAccomodationAllowanceEmployee: null,
    });
  }
  @bind
  handleRegisterAccomodationAllowanceDialogCancel(): void {
    this.setState({
      registerAccomodationAllowanceDialogOpen: false,
      registerAccomodationAllowanceEmployee: null,
    });
  }
  @bind
  handleRegisterAccomodationAllowanceMachineOperatorDialogOk(url: UserUrl): void {
    this.setState({registerAccomodationAllowanceEmployee: url});
  }
  @bind
  handleRegisterAccomodationAllowanceDialogOk(
    fromDate: string,
    toDate: string,
    remunerationGroup: string,
    employee: UserUrl,
  ): void {
    const {accomodationAllowanceArray, create} = this.props;
    this.setState({
      registerAccomodationAllowanceDialogOpen: false,
      registerAccomodationAllowanceEmployee: null,
    });
    registerAccommodationAllowance(fromDate, toDate, remunerationGroup, {
      accomodationAllowanceArray,
      create,
      userURL: employee,
    });
  }
  @bind
  handleInternalWorkTypeDialogOk(workTypeURL: WorkTypeUrl): void {
    // NOTE: This only supports "internal" work --- no pricegroup/priceitem logic
    const {customerSettings} = this.props;

    const id = uuid();
    const url = instanceURL("task", id);
    const {currentUser, currentUserProfile, currentUserURL, employeeGroupLookup} = this.props;
    const employeeGroupURL = currentUserProfile && currentUserProfile.employeeGroup;
    const employeeGroup = employeeGroupURL && employeeGroupLookup(employeeGroupURL);
    const machineOperator = currentUserURL;
    if (!machineOperator) {
      return;
    }
    const task: Task = {
      ...emptyTask,
      createdBy: currentUserURL,
      date: dateToString(new Date()),
      department: currentUser
        ? computeDepartment(currentUserProfile, employeeGroup || null, customerSettings)
        : "",
      id,
      machineOperator, // managers and senior machineoperators are excluded.
      order: null,
      url,
      workType: workTypeURL,
    };

    this.props.create(task);
    this.props.go("/task/:id", {id});
  }
  @bind
  handleInternalWorkButton(): void {
    const {
      currentRole,
      currentUser,
      currentUserProfile,
      currentUserURL,
      customerSettings,
      employeeGroupLookup,
    } = this.props;
    const userIsManager = currentRole && currentRole.manager;
    const userIsSeniorMachineOperator = currentRole && currentRole.seniorMachineOperator;
    if (!userIsManager && !userIsSeniorMachineOperator) {
      this.setState({internalWorkTypeDialogOpen: true});
    } else {
      const employeeGroupURL = currentUserProfile && currentUserProfile.employeeGroup;
      const employeeGroup = employeeGroupURL && employeeGroupLookup(employeeGroupURL);
      const id = uuid();
      const url = instanceURL("task", id);
      const params: Task = {
        ...emptyTask,
        createdBy: currentUserURL,
        date: customerSettings.taskAndOrderDatesFromCalendarOnCreate
          ? this.getSelectedDate()
          : null,
        department: currentUser
          ? computeDepartment(currentUserProfile, employeeGroup || null, customerSettings)
          : "",
        id,
        machineOperator: customerSettings.defaultTaskEmployee
          ? instanceURL("user", customerSettings.defaultTaskEmployee)
          : null,
        order: null,
        url,
      };
      this.props.create(params);
      this.props.go("/internalTask/:id", {id});
    }
  }
  @bind
  handleInternalWorkTypeDialogCancel(): void {
    this.setState({internalWorkTypeDialogOpen: false});
  }
  @bind
  handleSetCalendarWorkHoursClick(): void {
    this.setState({
      setCalendarWorkHoursDialogOpen: true,
      setCalendarWorkHoursEmployee: null,
    });
  }
  @bind
  handleSetCalendarWorkHoursDialogCancel(): void {
    this.setState({
      setCalendarWorkHoursDialogOpen: false,
      setCalendarWorkHoursEmployee: null,
    });
  }
  @bind
  handleSetCalendarWorkHoursMachineOperatorDialogOk(url: UserUrl): void {
    this.setState({setCalendarWorkHoursEmployee: url});
  }
  @bind
  handleSetCalendarWorkHoursDialogOk(fromHour: string | null, toHour: string | null): void {
    const userURL = this.state.setCalendarWorkHoursEmployee;
    if (!userURL) {
      return;
    }
    this.setState({
      setCalendarWorkHoursDialogOpen: false,
      setCalendarWorkHoursEmployee: null,
    });
    const previous = this.getActiveCalendarWorkHours(userURL);
    const selectedDate = this.getSelectedDate();
    if (previous && previous.fromDate === selectedDate) {
      this.props.update(previous.url, [
        {member: "fromHour", value: fromHour},
        {member: "toHour", value: toHour},
      ]);
    } else {
      const id = uuid();
      const url = instanceURL("calendarWorkHours", id);
      this.props.create({
        fromDate: selectedDate,
        fromHour,
        id,
        toHour,
        url,
        user: userURL,
      });
    }
  }

  saveUserCalendarVisibleWorkTypes(selected: ReadonlySet<WorkTypeUrl>): void {
    const userURL = this.props.currentUserURL;
    if (!userURL) {
      return;
    }
    const oldValue =
      (this.props.userCalendarVisibleWorkTypes &&
        objectFromMapOrObject(this.props.userCalendarVisibleWorkTypes)) ||
      {};
    const newValue = {...oldValue};
    if (selected.size === 0) {
      delete newValue[userURL];
    } else {
      newValue[userURL] = Array.from(selected);
    }
    this.props.configPut({
      key: "userCalendarVisibleWorkTypes",
      value: newValue,
    });
  }
  @bind
  handleWorkTypeFilterChange(selected: ReadonlySet<WorkTypeUrl>): void {
    this.setState({
      selectedWorkTypeURLSet: selected,
    });
    this.saveUserCalendarVisibleWorkTypes(selected);
  }

  @bind
  handleDepartmentFilterChange(selectedDepartmentIdentifierSet: ReadonlySet<string>): void {
    this.setState({
      selectedDepartmentIdentifierSet,
    });

    this.props.configPut({
      key: "userCalendarVisibleDepartments",
      value: [...selectedDepartmentIdentifierSet],
    });
  }

  @bind
  handleRequestFilterClear(): void {
    this.setState({
      selectedDepartmentIdentifierSet: new Set(),
      selectedWorkTypeURLSet: new Set(),
    });
    this.saveUserCalendarVisibleWorkTypes(new Set());
    this.props.configPut({
      key: "userCalendarVisibleDepartments",
      value: [],
    });
  }

  @bind
  handleAvailabilityChange(data: {
    currentValue: boolean | undefined;
    user: Readonly<User>;
    userProfile: Readonly<UserProfile>;
    weekday: Readonly<WeekDay>;
    weekNumber: number;
    year: number;
  }): void {
    const {currentValue, user, weekday, weekNumber, year} = data;
    const value = currentValue != null ? currentValue : null;
    const userURL = user.url;

    const savedAvailability = this.props.availabilityArray.find((entry) => {
      return entry.user === userURL && entry.weekNumber === weekNumber && entry.year === year;
    });
    if (savedAvailability) {
      if (savedAvailability[weekday] !== value) {
        this.props.update(savedAvailability.url, [{member: weekday, value}]);
      }
    } else {
      const id = uuid();
      const url = instanceURL("availability", id);
      const obj: Writable<Availability> = {
        friday: null,
        id,
        monday: null,
        saturday: null,
        sunday: null,
        thursday: null,
        tuesday: null,
        url,
        user: userURL,
        wednesday: null,
        weekNumber,
        year,
      };
      obj[weekday] = value;
      this.props.create(obj);
    }
  }
  @bind
  handleRequestOrdering(ordering: readonly string[]): void {
    this.setState({ordering});
  }
  @bind
  handleAbsenceClick(url: DaysAbsenceUrl | HoursAbsenceUrl): void {
    if (!url) {
      return;
    }
    const resourceName = resourceNameFor(url);
    if (resourceName === "hoursAbsence") {
      const absenceMap = this.props.hoursAbsenceLookup(url as HoursAbsenceUrl) || null;
      this.setState({absenceMap});
    } else if (resourceName === "daysAbsence") {
      const absenceMap = this.props.daysAbsenceLookup(url as DaysAbsenceUrl) || null;
      this.setState({absenceMap});
    }
  }
  @bind
  handleEmptyClick(url: UserUrl, date: string): void {
    this.setState({
      registerAbsenceDialogOpen: true,
      registerAbsenceEmployee: url,
      registerAbsenceInitialFromDate: date,
    });
  }

  @bind
  handleEditAbsenceDialogCancel(): void {
    this.setState({absenceMap: null});
  }
  @bind
  handleEditAbsenceDialogOk(url: string, note: string): void {
    this.props.update(url, [{member: "note", value: note}]);
    this.setState({absenceMap: null});
  }
  @bind
  handleEditAbsenceDialogDelete(url: string): void {
    this.setState({absenceMap: null});
    this.props.remove(url);
  }

  @bind
  handleDateChange(date: string | null): void {
    this.props.putQueryKey("date", date || "");
  }
  @bind
  handleGoToYesterday(): void {
    const selectedDate = dateFromString(this.getSelectedDate()) as Date;
    console.assert(selectedDate);
    const yesterday = new Date(selectedDate);
    yesterday.setDate(selectedDate.getDate() - 1);
    this.handleDateChange(dateToString(yesterday));
  }
  @bind
  handleGoToTomorrow(): void {
    const selectedDate = dateFromString(this.getSelectedDate()) as Date;
    console.assert(selectedDate);
    const tomorrow = new Date(selectedDate);
    tomorrow.setDate(selectedDate.getDate() + 1);
    this.handleDateChange(dateToString(tomorrow));
  }
  @bind
  handleTabChange(_event: React.ChangeEvent<unknown>, value: string): void {
    this.props.putQueryKeys({
      scrollX: "",
      scrollY: "",
      tab: value,
    });
  }
  @bind
  handleFoldUnfoldAll(): void {
    this.setState({allFolded: !this.state.allFolded});
  }

  @bind
  handleFilterStringChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const {value} = event.target;
    this.props.putQueryKey("q", value || "");
  }

  showRouteFabButton(): boolean {
    return (
      this.props.customerSettings.routesEnabled &&
      this.props.routePlanArray.some((routePlan) => routePlan.active)
    );
  }

  @bind
  handleRouteFabButton(): void {
    this.setState({routePlanDialogOpen: true});
  }

  @bind
  handleToggleMachineOrderingMode(on: boolean): void {
    this.setState({
      machineOrderingMode: on,
    });
  }

  @bind
  handleMachinesSaveOrderButton(): void {
    const {currentUserProfile, update} = this.props;

    if (!currentUserProfile) {
      return;
    }
    update(currentUserProfile.url, [
      {member: "machineCalendarOrder", value: this.state.machineCalendarOrder},
    ]);
    this.handleToggleMachineOrderingMode(false);
  }

  @bind
  handleMachinesOrderModeCancelButton(): void {
    this.setState({
      machineCalendarOrder:
        (this.props.currentUserProfile && this.props.currentUserProfile.machineCalendarOrder) || [],
    });
    this.handleToggleMachineOrderingMode(false);
  }

  @bind
  handleMachinesOrderModeButton(): void {
    this.handleToggleMachineOrderingMode(true);
  }

  @bind
  updateMachinesOrder(machineCalendarOrder: string[]): void {
    this.setState({machineCalendarOrder});
  }

  @bind
  handleRoutePlanDialogOk(url: RoutePlanUrl): void {
    this.setState({routePlanDialogOpen: false});
    this.newRoute(url);
  }
  @bind
  handleRoutePlanDialogCancel(): void {
    this.setState({routePlanDialogOpen: false});
  }

  @bind
  newRoute(routePlanURL: RoutePlanUrl): void {
    const {create, currentRole, currentUserURL, defaultTaskEmployeeUrl, routePlanLookup} =
      this.props;
    const task = createNewRouteTask(
      routePlanURL,
      currentUserURL,
      currentRole,
      create,
      false,
      routePlanLookup,
      this.props.customerSettings.defaultTaskEmployee,
    );
    window.setTimeout(() => {
      if (!task.id) {
        return;
      }
      const {machineOperator: machineOperatorUrl} = task;
      if (machineOperatorUrl && machineOperatorUrl !== defaultTaskEmployeeUrl) {
        this.props.go("/task/:id", {id: task.id});
      } else {
        this.props.go("/taskEdit/:id", {id: task.id});
      }
    }, 0);
  }

  taskCreationWizardControl = React.createRef<{
    start: (data?: {defaultDepartment?: string | null}) => void;
  }>();

  render(): JSX.Element {
    const selectedDate = this.getSelectedDate();
    const lastVisibleDate = this.getLastVisibleDate();

    const {customerSettings} = this.props;
    const {externalTaskCulture, externalTaskCustomer} = customerSettings;
    const {formatMessage} = this.context;
    const role = this.props.currentRole;
    const userIsOnlyMachineOperator = !role || !role.manager;

    const activeTab = this.props.tab;
    let right;
    {
      let filterElement;
      if (activeTab === "employees" && !bowser.mobile) {
        filterElement = (
          <AppbarSearchField
            placeholder={
              customerSettings.employeeLabelVariant === "MACHINEOPERATOR"
                ? formatMessage(messages.machineOperators)
                : customerSettings.employeeLabelVariant === "EMPLOYEE"
                  ? formatMessage(messages.employees)
                  : formatMessage(messages.chauffeurs)
            }
            value={this.props.q}
            onChange={this.handleFilterStringChange}
          />
        );
      }
      let filterButton;
      if (
        !bowser.mobile &&
        (customerSettings.enableOrderTaskDepartmentFilter ||
          customerSettings.enableOrderTaskWorkTypeFilter) &&
        activeTab !== "machines"
      ) {
        filterButton = (
          <span style={{display: "inline-block", verticalAlign: "top"}}>
            <FilterButton
              selectedDepartmentIdentifierSet={this.state.selectedDepartmentIdentifierSet}
              selectedWorkTypeURLSet={this.state.selectedWorkTypeURLSet}
              onSelectedDepartmentIdentifierSetChange={this.handleDepartmentFilterChange}
              onSelectedWorkTypeURLSetChange={this.handleWorkTypeFilterChange}
            />
          </span>
        );
      }
      const yesterdayButton = (
        <span style={{display: "inline-block", verticalAlign: "top"}}>
          <IconButton onClick={this.handleGoToYesterday}>
            <ArrowLeftBoldCircleOutlineIcon color="#fff" />
          </IconButton>
        </span>
      );
      const tomorrowButton = (
        <span style={{display: "inline-block", verticalAlign: "top"}}>
          <IconButton disabled={selectedDate === lastVisibleDate} onClick={this.handleGoToTomorrow}>
            <ArrowRightBoldCircleOutlineIcon color="#fff" />
          </IconButton>
        </span>
      );
      let foldUnfoldAllButton;
      if (activeTab === "orders") {
        let foldIcon;
        if (this.state.allFolded) {
          foldIcon = <UnfoldMoreHorizontalIcon color="#fff" />;
        } else {
          foldIcon = <UnfoldLessHorizontalIcon color="#fff" />;
        }
        foldUnfoldAllButton = (
          <span style={{display: "inline-block", verticalAlign: "top"}}>
            <IconButton onClick={this.handleFoldUnfoldAll}>{foldIcon}</IconButton>
          </span>
        );
      }
      right = (
        <>
          {foldUnfoldAllButton}
          {filterElement}
          {filterButton}
          <AppbarDateSelection
            maxDate={lastVisibleDate}
            value={this.getSelectedDate()}
            onChange={this.handleDateChange}
          />
          {yesterdayButton}
          {tomorrowButton}
        </>
      );
    }
    const userIsSeniorMachineOperator = role && role.seniorMachineOperator;

    const speeddialButtonProps: FloatingActionButtonData[] = [];
    if (
      (activeTab === "employees" && !this.state.orderingMode) ||
      (activeTab === "machines" && !this.state.machineOrderingMode)
    ) {
      speeddialButtonProps.push({
        buttonIcon: <HomeIcon />,
        name: "create-internal-task",
        onClick: this.handleInternalWorkButton,
        tooltipTitle: formatMessage({defaultMessage: "Intern"}),
      });
    }

    if (
      (activeTab === "employees" &&
        customerSettings.adminCanCreateCustomerTask &&
        !this.state.orderingMode &&
        (customerSettings.machineOperatorCanCreateCustomerTask || !userIsOnlyMachineOperator)) ||
      (activeTab === "orders" &&
        !this.state.planningMode &&
        (!userIsOnlyMachineOperator ||
          (userIsSeniorMachineOperator && customerSettings.seniorMachineOperatorCanManageOrders)) &&
        (!customerSettings.orderCalendarAsTaskCalendar ||
          customerSettings.adminCanCreateCustomerTask)) ||
      (activeTab === "machines" &&
        customerSettings.adminCanCreateCustomerTask &&
        !this.state.machineOrderingMode)
    ) {
      if (activeTab === "orders" && !customerSettings.orderCalendarAsTaskCalendar) {
        // order creation
        speeddialButtonProps.push({
          buttonIcon: <TagTextOutlineIcon />,
          name: "add",
          onClick: this.handleAddButton,
          tooltipTitle: formatMessage({defaultMessage: "Ordre"}),
        });
      } else {
        // "external" task creation
        if (externalTaskCulture && externalTaskCustomer) {
          speeddialButtonProps.push({
            buttonIcon: <TagMultipleIcon />,
            name: "add",
            onClick: this.handleAddButton,
            tooltipTitle: formatMessage({defaultMessage: "Kunde/kultur"}),
          });
        } else if (externalTaskCustomer) {
          speeddialButtonProps.push({
            buttonIcon: <TagMultipleIcon />,
            name: "create-customer-task",
            onClick: this.handleAddButton,
            tooltipTitle: formatMessage({defaultMessage: "Kunde"}),
          });
        } else if (externalTaskCulture) {
          speeddialButtonProps.push({
            buttonIcon: <CornIcon />,
            name: "create-culture-task",
            onClick: this.handleAddButton,
            tooltipTitle: formatMessage({defaultMessage: "Kultur"}),
          });
        }
      }
    }
    if (
      ((activeTab === "employees" && !this.state.orderingMode) ||
        (activeTab === "machines" && !this.state.machineOrderingMode) ||
        (activeTab === "orders" && !this.state.planningMode)) &&
      this.showRouteFabButton()
    ) {
      speeddialButtonProps.push({
        buttonIcon: <RoutesIcon />,
        name: "route",
        onClick: this.handleRouteFabButton,
        tooltipTitle: formatMessage({defaultMessage: "Rute"}),
      });
    }

    if (
      currentUserMayRegisterOwnAbsence(customerSettings, role) &&
      ((activeTab === "employees" && !this.state.orderingMode) || activeTab === "absence")
    ) {
      speeddialButtonProps.push({
        buttonIcon: <AccountAlertIcon />,
        name: "start-register-absence",
        onClick: this.handleRegisterAbsenceButton,
        tooltipTitle: formatMessage({defaultMessage: "Fravær"}),
      });
    }
    if (
      !userIsOnlyMachineOperator &&
      activeTab === "employees" &&
      !this.state.orderingMode &&
      Object.values(customerSettings.remunerationGroups).some((r) => r.accomodationAllowance)
    ) {
      speeddialButtonProps.push({
        buttonIcon: <HotelIcon />,
        name: "start-register-accomodation-allowance",
        onClick: this.handleRegisterAccomodationAllowanceButton,
        tooltipTitle: formatMessage({defaultMessage: "Tillæg"}),
      });
    }
    if (
      customerSettings.useCalendarWorkHours &&
      activeTab === "employees" &&
      !userIsOnlyMachineOperator &&
      !this.state.orderingMode
    ) {
      speeddialButtonProps.push({
        buttonIcon: <AccountClockIcon />,
        name: "set-calendar-work-hours",
        onClick: this.handleSetCalendarWorkHoursClick,
        tooltipTitle: formatMessage({defaultMessage: "Arbejdstid"}),
      });
    }
    if (
      activeTab === "orders" &&
      (!userIsOnlyMachineOperator ||
        (userIsSeniorMachineOperator && customerSettings.seniorMachineOperatorCanManageOrders))
    ) {
      if (!this.state.planningMode) {
        speeddialButtonProps.push({
          buttonIcon: <PencilIcon />,
          name: "start-planning",
          onClick: this.handlePlanningModeButton,
          tooltipTitle: formatMessage({defaultMessage: "Rækkefølge"}),
        });
      } else {
        speeddialButtonProps.push({
          buttonIcon: <CheckIcon />,
          name: "ok-planning",
          onClick: this.handlePlanningOkButton,
          tooltipTitle: formatMessage({defaultMessage: "Godkend"}),
        });
        speeddialButtonProps.push({
          buttonIcon: <UndoIcon />,
          name: "cancel-planning",
          onClick: this.handlePlanningCancelButton,
          tooltipTitle: formatMessage({defaultMessage: "Annuller"}),
        });
      }
    }
    if (
      customerSettings.enableEmployeeCalendarManualOrder &&
      activeTab === "employees" &&
      !bowser.mobile &&
      !bowser.tablet &&
      !userIsOnlyMachineOperator
    ) {
      if (!this.state.orderingMode) {
        speeddialButtonProps.push({
          buttonIcon: <PencilIcon />,
          name: "starts-ordering",
          onClick: this.handleOrderingModeButton,
          tooltipTitle: formatMessage({defaultMessage: "Rækkefølge"}),
        });
      } else {
        speeddialButtonProps.push({
          buttonIcon: <CheckIcon />,
          name: "ok-ordering",
          onClick: this.handleOrderingOkButton,
          tooltipTitle: formatMessage({defaultMessage: "Godkend"}),
        });
        speeddialButtonProps.push({
          buttonIcon: <UndoIcon />,
          name: "cancel-ordering",
          onClick: this.handleOrderingCancelButton,
          tooltipTitle: formatMessage({defaultMessage: "Annuller"}),
        });
        speeddialButtonProps.push({
          buttonIcon: <DeleteIcon />,
          name: "reset-ordering",
          onClick: this.handleOrderingResetButton,
          tooltipTitle: formatMessage({defaultMessage: "Nulstil"}),
        });
      }
    }

    let employeeContent;
    let orderContent;
    if (activeTab === "employees") {
      let ordering;
      if (!customerSettings.enableEmployeeCalendarManualOrder) {
        ordering = null;
      } else if (this.state.ordering) {
        ({ordering} = this.state);
      } else {
        const activeCalendarOrdering = this.getActiveCalendarOrdering();
        if (activeCalendarOrdering && activeCalendarOrdering.ordering) {
          ordering = JSON.parse(activeCalendarOrdering.ordering);
        } else {
          ordering = null;
        }
      }
      employeeContent = (
        <EmployeeTabContent
          dndMode={this.state.orderingMode}
          filterString={this.props.q}
          nowMinutes={this.state.nowMinutes != null ? this.state.nowMinutes : undefined}
          ordering={ordering}
          pathName={this.props.pathName}
          scrollX={this.props.scrollX}
          scrollY={this.props.scrollY}
          selectedDate={selectedDate}
          selectedDepartmentIdentifierSet={this.state.selectedDepartmentIdentifierSet}
          selectedWorkTypeURLSet={this.state.selectedWorkTypeURLSet}
          temporaryQueriesDiscardedForPath={this.props.temporaryQueriesDiscardedForPath}
          temporaryQueriesRequestedForPath={this.props.temporaryQueriesRequestedForPath}
          userIsManager={!userIsOnlyMachineOperator}
          userIsSeniorMachineOperator={!!role && role.seniorMachineOperator}
          onAbsenceClick={this.handleAbsenceClick}
          onAvailabilityChange={this.handleAvailabilityChange}
          onRequestFilterClear={this.handleRequestFilterClear}
          onRequestOrdering={this.handleRequestOrdering}
        />
      );
    } else if (activeTab === "orders") {
      orderContent = (
        <OrderTabContent
          allFolded={this.state.allFolded}
          dndMode={this.state.planningMode}
          dndSave={this.state.planningSave}
          lastVisibleDate={lastVisibleDate}
          pathName={this.props.pathName}
          scrollX={this.props.scrollX}
          scrollY={this.props.scrollY}
          selectedDate={selectedDate}
          selectedDepartmentIdentifierSet={this.state.selectedDepartmentIdentifierSet}
          selectedWorkTypeURLSet={this.state.selectedWorkTypeURLSet}
          userIsOnlyMachineOperator={userIsOnlyMachineOperator}
          onRequestFilterClear={this.handleRequestFilterClear}
        />
      );
    }
    let calendarWorkHoursFromHour;
    let calendarWorkHoursToHour;
    if (this.state.setCalendarWorkHoursEmployee) {
      const calendarWorkHours = this.getActiveCalendarWorkHours(
        this.state.setCalendarWorkHoursEmployee,
      );
      if (calendarWorkHours) {
        calendarWorkHoursFromHour = calendarWorkHours.fromHour || undefined;
        calendarWorkHoursToHour = calendarWorkHours.toHour || undefined;
      }
    }
    let absenceTab;
    let absenceTabContent;
    if (
      customerSettings.absenceCalendar &&
      (!userIsOnlyMachineOperator || customerSettings.machineOperatorsCanSeeAbsenceCalendar)
    ) {
      if (activeTab === "absence") {
        absenceTabContent = (
          <AbsenceTabContent
            currentRole={this.props.currentRole || undefined}
            customerSettings={this.props.customerSettings}
            daysAbsenceArray={this.props.daysAbsenceArray}
            extraHalfHolidaysPerRemunerationGroup={this.props.extraHalfHolidaysPerRemunerationGroup}
            extraHolidaysPerRemunerationGroup={this.props.extraHolidaysPerRemunerationGroup}
            fullscreen={false}
            hoursAbsenceArray={this.props.hoursAbsenceArray}
            roleArray={this.props.roleArray}
            selectedDate={selectedDate}
            userArray={this.props.userArray}
            userUserProfileLookup={this.props.userUserProfileLookup}
            onAbsenceClick={this.handleAbsenceClick}
            onEmptyClick={this.handleEmptyClick}
          />
        );
      }
      absenceTab = <Tab label={formatMessage(messages.absenceTab)} value="absence" />;
    }
    let machinesTab;
    let machinesTabContent;
    if (
      customerSettings.machineCalendar &&
      (!userIsOnlyMachineOperator || customerSettings.machineCalendarForMachineOperators)
    ) {
      if (activeTab === "machines") {
        machinesTabContent = (
          <MachinesTabContent
            currentUserProfile={this.props.currentUserProfile || undefined}
            customerLookup={this.props.customerLookup}
            customerSettings={customerSettings}
            go={this.props.go}
            machineArray={this.props.machineArray}
            machineCalendarOrder={this.state.machineCalendarOrder}
            machineOrderingMode={this.state.machineOrderingMode}
            orderLookup={this.props.orderLookup}
            pathName={this.props.pathName}
            putQueryKeys={this.props.putQueryKeys}
            scrollX={this.props.scrollX}
            scrollY={this.props.scrollY}
            selectedDate={selectedDate}
            taskArray={this.props.taskArray}
            temporaryQueriesRequestedForPath={this.props.temporaryQueriesRequestedForPath}
            update={this.props.update}
            updateOrder={this.updateMachinesOrder}
            userUserProfileLookup={this.props.userUserProfileLookup}
            workTypeLookup={this.props.workTypeLookup}
            onToggleMachineOrderingMode={this.handleToggleMachineOrderingMode}
          />
        );
        if (this.state.machineOrderingMode) {
          speeddialButtonProps.push({
            buttonIcon: <CheckIcon />,
            name: "ok-machine-ordering",
            onClick: this.handleMachinesSaveOrderButton,
            tooltipTitle: formatMessage({defaultMessage: "Godkend"}),
          });

          speeddialButtonProps.push({
            buttonIcon: <UndoIcon />,
            name: "cancel-machine-ordering",
            onClick: this.handleMachinesOrderModeCancelButton,
            tooltipTitle: formatMessage({defaultMessage: "Annuller"}),
          });
        } else {
          speeddialButtonProps.push({
            buttonIcon: <PencilIcon />,
            name: "machine-ordering",
            onClick: this.handleMachinesOrderModeButton,
            tooltipTitle: formatMessage({defaultMessage: "Rækkefølge"}),
          });
        }
      }
      machinesTab = (
        <Tab
          label={
            customerSettings.machineLabelVariant === "MACHINE"
              ? formatMessage(messages.machinesTab)
              : formatMessage(messages.vehiclesTab)
          }
          value="machines"
        />
      );
    }
    const hideOrderCalendar =
      !customerSettings.orderCalendar ||
      (userIsSeniorMachineOperator && !customerSettings.seniorMachineOperatorCanManageOrders) ||
      (userIsOnlyMachineOperator &&
        (customerSettings.machineOperatorsCanOnlySeeThemselvesOnCalendar ||
          customerSettings.hideOrderCalendarTab));
    const dialogs = (
      <>
        <ConnectedInternalWorkTypeDialog
          open={this.state.internalWorkTypeDialogOpen}
          onCancel={this.handleInternalWorkTypeDialogCancel}
          onOk={this.handleInternalWorkTypeDialogOk}
        />
        <ConnectedRoutePlanDialog
          open={this.state.routePlanDialogOpen}
          onCancel={this.handleRoutePlanDialogCancel}
          onOk={this.handleRoutePlanDialogOk}
        />
        <EditAbsenceDialog
          absenceInstance={this.state.absenceMap || undefined}
          customerSettings={this.props.customerSettings}
          open={!!this.state.absenceMap}
          onCancel={this.handleEditAbsenceDialogCancel}
          onDelete={this.handleEditAbsenceDialogDelete}
          onOk={this.handleEditAbsenceDialogOk}
        />
        <ConnectedUserDialog
          open={this.state.registerAbsenceDialogOpen && !this.state.registerAbsenceEmployee}
          title={formatMessage(messages.reportAbsence)}
          onCancel={this.handleRegisterAbsenceDialogCancel}
          onOk={this.handleRegisterAbsenceMachineOperatorDialogOk}
        />

        <RegisterAbsenceDialog
          currentRole={this.props.currentRole || undefined}
          currentUserURL={this.props.currentUserURL || undefined}
          customerSettings={customerSettings}
          initialFromDate={this.state.registerAbsenceInitialFromDate ?? undefined}
          open={this.state.registerAbsenceDialogOpen && !!this.state.registerAbsenceEmployee}
          userProfile={
            this.state.registerAbsenceEmployee
              ? this.props.userUserProfileLookup(this.state.registerAbsenceEmployee)
              : undefined
          }
          onCancel={this.handleRegisterAbsenceDialogCancel}
          onOk={this.handleRegisterAbsenceDialogOk}
        />
        <ActiveAccomodationUserDialog
          open={
            this.state.registerAccomodationAllowanceDialogOpen &&
            !this.state.registerAccomodationAllowanceEmployee
          }
          title={formatMessage(messages.registerAccomedationAllowance)}
          onCancel={this.handleRegisterAccomodationAllowanceDialogCancel}
          onOk={this.handleRegisterAccomodationAllowanceMachineOperatorDialogOk}
        />
        <AccomodationAllowanceDialog
          employee={this.state.registerAccomodationAllowanceEmployee || undefined}
          open={
            this.state.registerAccomodationAllowanceDialogOpen &&
            !!this.state.registerAccomodationAllowanceEmployee
          }
          onCancel={this.handleRegisterAccomodationAllowanceDialogCancel}
          onOk={this.handleRegisterAccomodationAllowanceDialogOk}
        />
        <ConnectedUserDialog
          open={
            this.state.setCalendarWorkHoursDialogOpen && !this.state.setCalendarWorkHoursEmployee
          }
          title={formatMessage(messages.setCalendarWorkHours)}
          onCancel={this.handleSetCalendarWorkHoursDialogCancel}
          onOk={this.handleSetCalendarWorkHoursMachineOperatorDialogOk}
        />
        <SetCalendarWorkHoursDialog
          fromHour={calendarWorkHoursFromHour}
          open={
            this.state.setCalendarWorkHoursDialogOpen && !!this.state.setCalendarWorkHoursEmployee
          }
          toHour={calendarWorkHoursToHour}
          userProfile={
            this.state.setCalendarWorkHoursEmployee
              ? this.props.userUserProfileLookup(this.state.setCalendarWorkHoursEmployee)
              : undefined
          }
          onCancel={this.handleSetCalendarWorkHoursDialogCancel}
          onOk={this.handleSetCalendarWorkHoursDialogOk}
        />
        {customerSettings.customerTaskCreationWizard ? (
          <CustomerTaskCreationWrapper
            ref={this.taskCreationWizardControl}
            onCustomerTaskCreation={this.handleCustomerTaskCreationWizardOk}
          />
        ) : null}
        {customerSettings.orderEntryAutoOpenCustomerSelection ? (
          <CustomerSelectCreateDialog
            open={this.state.customerDialogOpen}
            onCancel={this.handleCustomerDialogCancel}
            onOk={this.handleCustomerDialogOk}
          />
        ) : null}
        <ResponsiveDialog
          open={this.state.orderingResetDialogOpen}
          title={formatMessage({defaultMessage: "Nulstil rækkefølge?"})}
          onCancel={this.handleOrderingResetDialogCancel}
          onOk={this.handleOrderingResetDialogOK}
        >
          <DialogContent>
            <FormattedMessage defaultMessage="Du er ved at nulstille kolonnesorteringsrækkefølgen, vil du fortsætte?" />
          </DialogContent>
        </ResponsiveDialog>
      </>
    );

    const forcingSpeedDialOpen =
      (activeTab === "machines" && this.state.machineOrderingMode) ||
      (activeTab === "orders" && this.state.planningMode) ||
      (activeTab === "employees" && this.state.orderingMode);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <DrawerMarginAppBar>
          <MenuToolbar
            rightElement={right}
            title={bowser.mobile ? "" : formatMessage(messages.title)}
            onMenuButton={this.props.onMenuButton}
          />
          <Tabs
            value={this.props.tab}
            variant={bowser.mobile ? "fullWidth" : "standard"}
            onChange={this.handleTabChange}
          >
            <Tab
              label={
                bowser.mobile
                  ? formatMessage(messages.usersTabHandheld)
                  : formatMessage(messages.usersTab)
              }
              value="employees"
            />
            {hideOrderCalendar ? null : (
              <Tab
                label={
                  customerSettings.orderCalendarAsTaskCalendar
                    ? formatMessage(messages.orderTabAsTaskTab)
                    : formatMessage(messages.orderTab)
                }
                value="orders"
              />
            )}
            {absenceTab}
            {machinesTab}
          </Tabs>
        </DrawerMarginAppBar>
        <Toolbar />
        <Toolbar variant="dense" />
        {this.props.currentRole &&
        !this.props.currentRole.manager &&
        !this.props.currentRole.seniorMachineOperator ? (
          <TouchPullRefreshHandler />
        ) : null}
        <div style={{backgroundColor: "#fff", flex: 1, overflow: "auto"}}>
          {this.props.tab === "employees" ? employeeContent : null}
          {hideOrderCalendar ? null : this.props.tab === "orders" ? orderContent : null}
          {absenceTabContent}
          {machinesTabContent}
        </div>
        <FloatingActionButtons
          buttons={speeddialButtonProps}
          disabled={
            this.state.planningMode || this.state.orderingMode || this.state.machineOrderingMode
          }
          forcedOpen={forcingSpeedDialOpen}
          name="task-calendar"
          variant="page"
        />
        {dialogs}
      </div>
    );
  }
}

const ConnectedTaskCalendar: React.ComponentType<TaskCalendarOwnProps> = connect<
  TaskCalendarStateProps,
  TaskCalendarDispatchProps,
  TaskCalendarOwnProps,
  AppState
>(
  createStructuredSelector<AppState, TaskCalendarStateProps>({
    accomodationAllowanceArray: getAccomodationAllowanceArray,
    accomodationAllowanceLookup: getAccomodationAllowanceLookup,
    availabilityArray: getAvailabilityArray,
    calendarOrderingArray: getCalendarOrderingArray,
    calendarWorkHoursArray: getCalendarWorkHoursArray,
    currentRole: getCurrentRole,
    currentUser: getCurrentUser,
    currentUserID: getUserId,
    currentUserProfile: getCurrentUserProfile,
    currentUserURL: getCurrentUserURL,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    date: makeQueryParameterGetter("date"),
    daysAbsenceArray: getDaysAbsenceArray,
    daysAbsenceLookup: getDaysAbsenceLookup,
    defaultTaskEmployeeUrl: getDefaultTaskEmployeeUrl,
    employeeGroupLookup: getEmployeeGroupLookup,
    extraHalfHolidaysPerRemunerationGroup: getExtraHalfHolidaysPerRemunerationGroup,
    extraHolidaysPerRemunerationGroup: getExtraHolidaysPerRemunerationGroup,
    hoursAbsenceArray: getHoursAbsenceArray,
    hoursAbsenceLookup: getHoursAbsenceLookup,
    locationLookup: getLocationLookup,
    machineArray: getMachineArray,
    orderLookup: getOrderLookup,
    pathName: getPathName,
    q: makeQueryParameterGetter("q"),
    roleArray: getRoleArray,
    routePlanArray: getRoutePlanArray,
    routePlanLookup: getRoutePlanLookup,
    scrollX: makeQueryParameterGetter("scrollX"),
    scrollY: makeQueryParameterGetter("scrollY"),
    tab: makeQueryParameterGetter("tab", "employees"),
    taskArray: getTaskArray,
    taskLookup: getTaskLookup,
    timerStartArray: getTimerStartArray,
    userArray: getUserArray,
    userCalendarMachineOrder: getDeviceConfigKey("userCalendarMachineOrder"),
    userCalendarVisibleDepartments: getDeviceConfigKey("userCalendarVisibleDepartments"),
    userCalendarVisibleWorkTypes: getDeviceConfigKey("userCalendarVisibleWorkTypes"),
    userLookup: getUserLookup,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeArray: getWorkTypeArray,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    configPut: actions.configPut,
    create: actions.create,
    go: actions.go,
    putQueryKey: actions.putQueryKey,
    putQueryKeys: actions.putQueryKeys,
    remove: actions.remove,
    temporaryQueriesDiscardedForPath: actions.temporaryQueriesDiscardedForPath,
    temporaryQueriesRequestedForPath: actions.temporaryQueriesRequestedForPath,
    update: actions.update,
  },
)(TaskCalendar);

export default ConnectedTaskCalendar;
