/* eslint-disable sort-keys-fix/sort-keys-fix */

/**
 * This file was generated from config-schema.json using json-schema-to-typescript.
 * Run ./scripts/rebuild.ts to update.
 */
import {JSONSchema7} from "json-schema";
import type {DeepReadonly} from "ts-essentials";

export interface JSONSchemaWithProperties extends JSONSchema7 {
  properties: {
    [k: string]: JSONSchema7;
  };
}

const baseSchema = {
  $schema: "http://json-schema.org/draft-07/schema",
  additionalProperties: false,
  definitions: {
    barcodeFormats: {
      $comment:
        "Formats supported for both Android and iOS by phonegap-plugin-barcodescanner-android12 version 8.1.2. See plugin documentation: https://github.com/phonegap/phonegap-plugin-barcodescanner",
      items: {
        enum: [
          "QR_CODE",
          "DATA_MATRIX",
          "UPC_A",
          "UPC_E",
          "EAN_8",
          "EAN_13",
          "CODE_39",
          "CODE_93",
          "CODE_128",
          "ITF",
          "PDF_417",
          "AZTEC",
        ],
      },
      type: "array",
      uniqueItems: true,
    },
    bonusSpecification: {
      additionalProperties: false,
      properties: {
        afterThreshold: {
          additionalProperties: false,
          properties: {minutes: {type: "number"}, sum: {enum: ["calendarDay", "workDay"]}},
          required: ["minutes", "sum"],
          type: "object",
        },
        checkDates: {
          additionalProperties: false,
          properties: {fromDate: {type: "string"}, toDate: {type: "string"}},
          required: ["fromDate", "toDate"],
          type: "object",
        },
        checkInterval: {
          additionalProperties: false,
          properties: {fromTime: {type: "string"}, toTime: {type: "string"}},
          required: ["fromTime", "toTime"],
          type: "object",
        },
        customerTask: {type: "boolean"},
        dayOfTheWeek: {enum: [0, 1, 2, 3, 4, 5, 6]},
        departmentID: {type: "string"},
        departmentId: {type: "string"},
        effectiveTime: {type: "boolean"},
        label: {type: "string"},
        machineID: {type: "string"},
        machineId: {type: "string"},
        nextDayIsHoliday: {type: "boolean"},
        priceGroupID: {type: "string"},
        priceGroupId: {type: "string"},
        rate: {enum: [-2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]},
        taskPriceGroupID: {type: "string"},
        taskPriceGroupId: {type: "string"},
        taskWorkTypeID: {type: "string"},
        taskWorkTypeId: {type: "string"},
        workTypeID: {type: "string"},
        workTypeId: {type: "string"},
      },
      required: ["label"],
      type: "object",
    },
    bookkeepingListColumnsColumnChoices: {
      items: {
        enum: [
          "approved",
          "completed",
          "customerCultureProjectWorkplace",
          "customerCultureProjectWorkplaceMobile",
          "date",
          "dateMobile",
          "departments",
          "employee",
          "employeeName",
          "open",
          "posted",
          "referenceNumber",
        ],
      },
      type: "array",
    },
    dataLoenMapping: {
      additionalProperties: {
        anyOf: [
          {$ref: "#/definitions/dataLoenOption"},
          {type: "array", items: {$ref: "#/definitions/dataLoenOption"}},
        ],
      },
      type: "object",
    },
    dataLoenOption: {
      additionalProperties: false,
      properties: {
        multiplier: {
          anyOf: [
            {type: "number"},
            {type: "array", items: {type: "number"}, maxItems: 7, minItems: 7},
          ],
        },
        payTypeCode: {type: "string"},
      },
      required: ["payTypeCode"],
      type: "object",
    },
    dateStringObject: {
      additionalProperties: {type: "string"},
      propertyNames: {pattern: "^([0-9]{4}-)?[0-1][0-9]-[0-3][0-9]$"},
      type: "object",
    },
    dayHoursRatesSpecification: {
      items: {
        items: [{type: "string"}, {type: "string"}, {type: "number"}],
        maxItems: 3,
        minItems: 3,
        type: "array",
      },
      type: "array",
    },
    dayThresholdsSpecification: {
      items: {
        anyOf: [
          {type: "number"},
          {type: "array", minItems: 2, maxItems: 2, items: {type: "number"}},
        ],
      },
      type: "array",
    },
    fourWeekPoolSpecification: {
      additionalProperties: false,
      properties: {
        overtimeThreshold: {type: "number"},
        overtimeThresholds: {items: {type: "number"}, type: "array"},
        startDate: {type: "string"},
        type: {enum: ["fourWeeks"]},
        weekAbsenceCompensatoryNormalHoursMinutes: {
          $ref: "#/definitions/nullableWeekAbsenceCompensatoryNormalHoursMinutesSpecification",
        },
      },
      required: ["type", "startDate"],
      type: "object",
    },
    monthPoolSpecification: {
      additionalProperties: false,
      properties: {
        overtimeThreshold: {type: "number"},
        overtimeThresholds: {items: {type: "number"}, type: "array"},
        periodStart: {type: "number"},
        type: {enum: ["month"]},
        weekAbsenceCompensatoryNormalHoursMinutes: {
          $ref: "#/definitions/nullableWeekAbsenceCompensatoryNormalHoursMinutesSpecification",
        },
      },
      required: ["type", "periodStart"],
      type: "object",
    },
    normalisedWeekPoolSpecification: {
      additionalProperties: false,
      properties: {
        dayThresholds: {$ref: "#/definitions/weekThresholdsSpecification"},
        type: {enum: ["normalisedWeek"]},
        weekStart: {type: "number"},
      },
      required: ["type", "weekStart"],
      type: "object",
    },
    nullableDataLoenMapping: {anyOf: [{$ref: "#/definitions/dataLoenMapping"}, {type: "null"}]},
    nullableDataLoenOption: {anyOf: [{$ref: "#/definitions/dataLoenOption"}, {type: "null"}]},
    nullableDateStringObject: {anyOf: [{$ref: "#/definitions/dateStringObject"}, {type: "null"}]},
    nullableNumberPair: {anyOf: [{$ref: "#/definitions/numberPair"}, {type: "null"}]},
    nullableStringArray: {anyOf: [{$ref: "#/definitions/stringArray"}, {type: "null"}]},
    nullableStringObject: {anyOf: [{$ref: "#/definitions/stringObject"}, {type: "null"}]},
    nullableStringPair: {anyOf: [{$ref: "#/definitions/stringPair"}, {type: "null"}]},
    nullableWeekAbsenceCompensatoryNormalHoursMinutesSpecification: {
      anyOf: [
        {$ref: "#/definitions/weekAbsenceCompensatoryNormalHoursMinutesSpecification"},
        {type: "null"},
      ],
    },
    numberPair: {items: {type: "number"}, maxItems: 2, minItems: 2, type: "array"},
    orderListColumnsColumnChoices: {
      items: {
        enum: [
          "address",
          "createdDate",
          "createdDateShort",
          "customer",
          "customerAddress",
          "customerAddressShort",
          "customerShort",
          "date",
          "dateShort",
          "deliveryLogLocations",
          "department",
          "employees",
          "machineName",
          "machineNumber",
          "machineNumberAndName",
          "managerInternalNotes",
          "merge",
          "note",
          "pickupLogLocations",
          "projectNumbers",
          "referenceNumber",
          "status",
          "time",
          "workType",
          "workTypeColor",
          "workTypeShort",
          "workTypes",
        ],
      },
      type: "array",
    },
    orderListColumnsDeviceTypesSpecification: {
      additionalProperties: false,
      properties: {
        desktop: {$ref: "#/definitions/orderListColumnsColumnChoices"},
        mobile: {$ref: "#/definitions/orderListColumnsColumnChoices"},
        tablet: {$ref: "#/definitions/orderListColumnsColumnChoices"},
      },
      required: ["mobile", "tablet", "desktop"],
      type: "object",
    },
    orderListColumnsUserTypesSpecification: {
      additionalProperties: false,
      properties: {
        employee: {$ref: "#/definitions/orderListColumnsDeviceTypesSpecification"},
        manager: {$ref: "#/definitions/orderListColumnsDeviceTypesSpecification"},
      },
      required: ["employee", "manager"],
      type: "object",
    },
    poolSpecification: {
      anyOf: [
        {$ref: "#/definitions/weekPoolSpecification"},
        {$ref: "#/definitions/monthPoolSpecification"},
        {$ref: "#/definitions/normalisedWeekPoolSpecification"},
        {$ref: "#/definitions/twoWeekPoolSpecification"},
        {$ref: "#/definitions/fourWeekPoolSpecification"},
      ],
    },
    rateOverrideSpecification: {
      additionalProperties: false,
      properties: {
        checkDates: {
          additionalProperties: false,
          properties: {fromDate: {type: "string"}, toDate: {type: "string"}},
          required: ["fromDate", "toDate"],
          type: "object",
        },
        checkInterval: {
          additionalProperties: false,
          properties: {fromTime: {type: "string"}, toTime: {type: "string"}},
          required: ["fromTime", "toTime"],
          type: "object",
        },
        customerTask: {type: "boolean"},
        dayOfTheWeek: {enum: [0, 1, 2, 3, 4, 5, 6]},
        departmentID: {type: "string"},
        departmentId: {type: "string"},
        effectiveTime: {type: "boolean"},
        machineID: {type: "string"},
        machineId: {type: "string"},
        priceGroupID: {type: "string"},
        priceGroupId: {type: "string"},
        rate: {enum: [-2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]},
        resultingRate: {enum: [-2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]},
        taskPriceGroupID: {type: "string"},
        taskPriceGroupId: {type: "string"},
        taskWorkTypeID: {type: "string"},
        taskWorkTypeId: {type: "string"},
        workTypeID: {type: "string"},
        workTypeId: {type: "string"},
      },
      required: ["resultingRate"],
      type: "object",
    },
    remunerationGroup: {
      additionalProperties: false,
      properties: {
        accomodationAllowance: {type: ["boolean", "null"]},
        accomodationAllowanceIncludeHours: {type: ["boolean", "null"]},
        accomodationAllowanceLabel: {type: ["string", "null"]},
        accumulateCompensatoryLimit: {type: ["number", "null"]},
        accumulateCompensatoryLimitType: {enum: ["balance", "accumulatedAdditions"]},
        allowNegativeAccumulateCompensatory: {type: ["boolean"]},
        calendarDayBonus: {
          anyOf: [
            {items: {$ref: "#/definitions/bonusSpecification"}, type: "array"},
            {type: "null"},
          ],
        },
        compensatoryMultiplier: {type: ["number", "null"]},
        compensatorySubtractOnly: {$ref: "#/definitions/nullableStringArray"},
        computedSalaryVoucherStartDay: {type: ["integer", "null"]},
        countBonus: {
          anyOf: [
            {
              items: {
                additionalProperties: false,
                properties: {
                  label: {type: "string"},
                  multiplier: {type: "number"},
                  priceItemUUID: {type: "string"},
                  priceItemUuid: {type: "string"},
                },
                required: ["label", "multiplier"],
                type: "object",
              },
              type: "array",
            },
            {type: "null"},
          ],
        },
        countWeekdayHolidays: {type: ["boolean", "null"]},
        dataloen: {
          additionalProperties: false,
          properties: {
            accomodationDay: {$ref: "#/definitions/nullableDataLoenOption"},
            accomodationMinutesAsHours: {$ref: "#/definitions/nullableDataLoenOption"},
            actualWorkTimeHours: {$ref: "#/definitions/nullableDataLoenOption"},
            bonusMinutesAsHours: {$ref: "#/definitions/nullableDataLoenMapping"},
            calendarDayBonus: {$ref: "#/definitions/nullableDataLoenMapping"},
            calledIn: {$ref: "#/definitions/nullableDataLoenOption"},
            compensatoryDifferenceAsHours: {$ref: "#/definitions/nullableDataLoenOption"},
            countBonus: {$ref: "#/definitions/nullableDataLoenMapping"},
            countWeekdayHolidays: {$ref: "#/definitions/nullableDataLoenOption"},
            daysAbsence: {$ref: "#/definitions/nullableDataLoenMapping"},
            daysAbsenceAsHours: {$ref: "#/definitions/nullableDataLoenMapping"},
            dinnerBookings: {$ref: "#/definitions/nullableDataLoenOption"},
            lunchBookings: {$ref: "#/definitions/nullableDataLoenOption"},
            minutesAbsenceAsHours: {$ref: "#/definitions/nullableDataLoenMapping"},
            projectDistance: {$ref: "#/definitions/nullableDataLoenOption"},
            projectTravelTime: {$ref: "#/definitions/nullableDataLoenOption"},
            rateMinutesAsHours: {$ref: "#/definitions/nullableDataLoenMapping"},
            salaryPeriodCode: {type: "string"},
            sumAsHours: {$ref: "#/definitions/nullableDataLoenOption"},
            sumWithCompensatoryAsHours: {$ref: "#/definitions/nullableDataLoenOption"},
            taskBonus: {$ref: "#/definitions/nullableDataLoenMapping"},
            workDayBonus: {$ref: "#/definitions/nullableDataLoenMapping"},
          },
          required: ["salaryPeriodCode"],
          type: "object",
        },
        dayEndRounding: {$ref: "#/definitions/nullableStringPair"},
        dayStartRounding: {$ref: "#/definitions/nullableStringPair"},
        extraHalfHolidayAbsenceTypes: {$ref: "#/definitions/nullableStringArray"},
        extraHalfHolidays: {$ref: "#/definitions/nullableDateStringObject"},
        extraHolidayAbsenceTypes: {$ref: "#/definitions/nullableStringArray"},
        extraHolidays: {$ref: "#/definitions/nullableDateStringObject"},
        forcedUnpaidBreakMinutes: {type: ["number", "null"]},
        halfHolidayHalfThresholds: {type: ["boolean", "null"]},
        halfHolidayHoursRates: {
          anyOf: [{$ref: "#/definitions/dayHoursRatesSpecification"}, {type: "null"}],
        },
        halfHolidaySundayAfterNoon: {type: ["boolean", "null"]},
        hiddenFromGroupSelection: {type: ["boolean", "null"]},
        holidayCalendars: {
          items: {enum: ["Helligdage i Danmark"]},
          type: "array",
          uniqueItems: true,
        },
        hoursRates: {
          anyOf: [{$ref: "#/definitions/weekDayHoursRatesSpecification"}, {type: "null"}],
        },
        ignoreTimers: {items: {type: "string"}, type: "array"},
        includeCompensatoryOverLimitInPaid: {type: ["boolean", "null"]},
        intervalBonus: {
          anyOf: [
            {items: {$ref: "#/definitions/bonusSpecification"}, type: "array"},
            {type: "null"},
          ],
        },
        overtimeThresholds: {
          anyOf: [{$ref: "#/definitions/weekThresholdsSpecification"}, {type: "null"}],
        },
        overtimeThresholdsReset: {enum: [null, false]},
        paidBreaks: {type: "boolean"},
        periodCompensatoryLimit: {type: ["number", "null"]},
        pools: {anyOf: [{$ref: "#/definitions/poolSpecification"}, {type: "null"}]},
        projectDistanceBonusLabel: {type: ["string", "null"]},
        projectTravelTimeBonusLabel: {type: ["string", "null"]},
        rateOverride: {
          anyOf: [
            {items: {$ref: "#/definitions/rateOverrideSpecification"}, type: "array"},
            {type: "null"},
          ],
        },
        rateSwitch: {
          anyOf: [
            {items: {$ref: "#/definitions/rateOverrideSpecification"}, type: "array"},
            {type: "null"},
          ],
        },
        reportIgnoreAbsenceOnHolidays: {type: ["boolean", "null"]},
        reportTitle: {type: "string"},
        specialStartRateMinutes: {type: ["number", "null"]},
        switchGroups: {
          anyOf: [
            {items: {$ref: "#/definitions/switchGroupSpecification"}, type: "array"},
            {type: "null"},
          ],
        },
        taskBonus: {
          anyOf: [
            {items: {$ref: "#/definitions/bonusSpecification"}, type: "array"},
            {type: "null"},
          ],
        },
        visibleBonusLabels: {
          anyOf: [{items: {type: "string"}, type: "array"}, {type: "null"}],
          default: null,
          description: "",
        },
        visibleLabels: {
          anyOf: [{items: {type: "string"}, type: "array"}, {type: "null"}],
          default: null,
          description: "",
        },
        visibleRateLabels: {
          anyOf: [{items: {type: "string"}, type: "array"}, {type: "null"}],
          default: null,
          description: "",
        },
        workDayBonus: {
          anyOf: [
            {items: {$ref: "#/definitions/bonusSpecification"}, type: "array"},
            {type: "null"},
          ],
        },
      },
      required: ["reportTitle"],
      type: "object",
    },
    roles: {
      enum: [
        "manager",
        "senior_machine_operator",
        "project_manager",
        "machine_operator",
        "mechanic",
        "jobber",
      ],
    },
    stringArray: {items: {type: "string"}, type: "array"},
    stringObject: {additionalProperties: {type: "string"}, type: "object"},
    stringPair: {items: {type: "string"}, maxItems: 2, minItems: 2, type: "array"},
    switchGroupSpecification: {
      additionalProperties: false,
      properties: {
        departmentID: {type: "string"},
        departmentId: {type: "string"},
        identifier: {type: "string"},
        workTypeID: {type: "string"},
        workTypeId: {type: "string"},
      },
      required: ["identifier"],
      type: "object",
    },
    taskListColumnsColumnChoices: {
      items: {
        enum: [
          "created",
          "createdBy",
          "createdByShort",
          "createdDate",
          "createdDateShort",
          "createdShort",
          "culture",
          "cultureMachine",
          "customer",
          "customerCultureMachine",
          "customerMachine",
          "customerRoute",
          "customerRouteCultureMachine",
          "customerRouteMachine",
          "date",
          "dateShort",
          "deliveryLocation",
          "deliveryLocationLong",
          "deliveryPostalCode",
          "department",
          "editTask",
          "fields",
          "invoiceNote",
          "machine",
          "machineOperator",
          "machineOperatorName",
          "machineOperatorShort",
          "machineShort",
          "machines",
          "managerInternalNotes",
          "notes",
          "photo",
          "pickupLocation",
          "pickupLocationLong",
          "pickupPostalCode",
          "priceGroup",
          "project",
          "projectWithAlias",
          "referenceNumber",
          "status",
          "time",
          "timeShort",
          "workType",
          "workTypeColor",
          "workTypeName",
          "workTypeShort",
          "workplace",
          "workplaceLong",
          "workplacePostalCode",
        ],
      },
      type: "array",
    },
    taskListColumnsDeviceTypesSpecification: {
      additionalProperties: false,
      properties: {
        desktop: {$ref: "#/definitions/taskListColumnsColumnChoices"},
        mobile: {$ref: "#/definitions/taskListColumnsColumnChoices"},
        tablet: {$ref: "#/definitions/taskListColumnsColumnChoices"},
      },
      required: ["mobile", "tablet", "desktop"],
      type: "object",
    },
    taskListColumnsUserTypesSpecification: {
      additionalProperties: false,
      properties: {
        employee: {$ref: "#/definitions/taskListColumnsDeviceTypesSpecification"},
        manager: {$ref: "#/definitions/taskListColumnsDeviceTypesSpecification"},
      },
      required: ["employee", "manager"],
      type: "object",
    },
    twoWeekPoolSpecification: {
      additionalProperties: false,
      properties: {
        overtimeThreshold: {type: "number"},
        overtimeThresholds: {items: {type: "number"}, type: "array"},
        startDate: {type: "string"},
        type: {enum: ["twoWeeks"]},
        weekAbsenceCompensatoryNormalHoursMinutes: {
          $ref: "#/definitions/nullableWeekAbsenceCompensatoryNormalHoursMinutesSpecification",
        },
      },
      required: ["type", "startDate"],
      type: "object",
    },
    userListColumnsColumnChoices: {
      items: {
        enum: [
          "alias",
          "cellphone",
          "cellphoneExtra",
          "cellphoneIcon",
          "email",
          "emailIcon",
          "name",
          "role",
          "username",
        ],
      },
      type: "array",
    },
    userListColumnsDeviceTypesSpecification: {
      additionalProperties: false,
      properties: {
        desktop: {$ref: "#/definitions/userListColumnsColumnChoices"},
        mobile: {$ref: "#/definitions/userListColumnsColumnChoices"},
        tablet: {$ref: "#/definitions/userListColumnsColumnChoices"},
      },
      required: ["mobile", "tablet", "desktop"],
      type: "object",
    },
    userListColumnsManagerColumnChoices: {
      items: {
        enum: [
          "accumulatedCompensatory",
          "alias",
          "averageHourlyCost",
          "cellphone",
          "cellphoneExtra",
          "cellphoneIcon",
          "email",
          "emailIcon",
          "iceWarningIcon",
          "name",
          "remunerationGroup",
          "role",
          "username",
        ],
      },
      type: "array",
    },
    userListColumnsManagerDeviceTypesSpecification: {
      additionalProperties: false,
      properties: {
        desktop: {$ref: "#/definitions/userListColumnsManagerColumnChoices"},
        mobile: {$ref: "#/definitions/userListColumnsManagerColumnChoices"},
        tablet: {$ref: "#/definitions/userListColumnsManagerColumnChoices"},
      },
      required: ["mobile", "tablet", "desktop"],
      type: "object",
    },
    weekAbsenceCompensatoryNormalHoursMinutesSpecification: {
      items: {type: "number"},
      maxItems: 7,
      minItems: 7,
      type: "array",
    },
    weekDayHoursRatesSpecification: {
      items: {$ref: "#/definitions/dayHoursRatesSpecification"},
      maxItems: 7,
      minItems: 7,
      type: "array",
    },
    weekPoolSpecification: {
      additionalProperties: false,
      properties: {
        overtimeThreshold: {type: "number"},
        overtimeThresholds: {items: {type: "number"}, type: "array"},
        type: {enum: ["week"]},
        weekAbsenceCompensatoryNormalHoursMinutes: {
          $ref: "#/definitions/nullableWeekAbsenceCompensatoryNormalHoursMinutesSpecification",
        },
        weekStart: {type: "number"},
      },
      required: ["type", "weekStart"],
      type: "object",
    },
    weekThresholdsSpecification: {
      items: {$ref: "#/definitions/dayThresholdsSpecification"},
      maxItems: 7,
      minItems: 7,
      type: "array",
    },
  },
  properties: {
    absenceCalendar: {
      default: false,
      description: "Ferie- & fraværskalender aktiveret 😎",
      type: "boolean",
    },
    absenceCalendarBlockColor: {
      default: "rgba(255, 204, 128, 0.2)",
      description: "Standard kalenderfarve til fraværs-registreringer",
      type: "string",
    },
    absenceCalendarDisplayLabel: {
      default: null,
      description:
        "Tekst som for ikke-admin-brugere vises fremfor fraværsårsag i kalender (medarbejdere må ikke se hvorfor der er fravær - kun at manden er fraværende)",
      type: ["string", "null"],
    },
    absenceTypeColors: {
      additionalProperties: {type: "string"},
      default: {},
      description: "Fraværs labels farver",
      type: "object",
    },
    absenceTypeLabels: {
      additionalProperties: {type: "string"},
      default: {},
      description: "Ændring af fraværs labels",
      type: "object",
    },
    absenceTypesAccessibleToEmployees: {
      anyOf: [{items: {type: "string"}, type: "array"}, {type: "null"}],
      default: null,
      description: "Liste af fraværds typer medarbejderne selv kan administrere",
    },
    absenceTypeShortLabels: {
      additionalProperties: {type: "string"},
      default: {},
      description: "Fraværslabels til brug på fraværskalenderen",
      type: "object",
    },
    absenceTypesVisibleOnFullscreenAbsenceCalendar: {
      anyOf: [{items: {type: "string"}, type: "array"}, {type: "null"}],
      default: null,
      description: "Begræns fraværskalenderens fuldskærmsvisningen til følgende fraværstyper:",
    },
    absenceWarningDisabledFor: {
      default: [],
      description: "Vis ikke advarsel om fravær for disse fraværstyper",
      items: {type: "string"},
      type: "array",
    },
    addEditLogLocationSkipsCustomerSelection: {
      default: true,
      description: "Anvend den nye adfærd for generiske log",
      type: "boolean",
    },
    addMaterialNotesToProjectCostActivity: {
      default: false,
      description: "Send materiellinjenote med over til vareforbrugsaktiveteten",
      type: "boolean",
    },
    addNewAgricultureTaskAsDefault: {
      default: [],
      description: "",
      items: {type: "string"},
      type: "array",
    },
    addNewContractorTaskAsDefault: {
      default: [],
      description: "",
      items: {type: "string"},
      type: "array",
    },
    addNewContractorTaskAsDefaultGroup: {
      default: [],
      description: "",
      items: {type: "string"},
      type: "array",
    },
    address: {default: "", description: "Virksomhedens adresse", type: "string"},
    adjustBilledMinutes: {
      default: "ROUND_UP_15",
      description:
        "Korrigerer automatisk det målet tidsforbrug efter nedenstående metode (Automatisk afrunding af fakturalinje)",
      enum: [
        "ADD_10",
        "ADD_15",
        "MIN_30_ROUND_NEAREST_15",
        "MIN_30_ROUND_UP_15",
        "ROUND_NEAREST_15",
        "ROUND_UP_15",
        "ROUND_UP_30",
        "NO_ADJUSTMENT",
      ],
    },
    adminCanCreateCustomerTask: {
      default: true,
      description: "Admin kan oprette kundeopgaver (og ordre hvis aktiveret)",
      type: "boolean",
    },
    allowCustomerTaskEmployeeChange: {
      default: true,
      description:
        "Tillad at man kan tilføje/ændre medarbejder/maskinfører/chauffør på kundeopgaver. Denne setting vil normalt kun være slået fra hvis kundeopgaver f.eks. importeres fra NAV og medarbejder på opgaven derfor ikke må redigeres i CustomOffice",
      type: "boolean",
    },
    allowCustomerTaskMachineChange: {
      default: true,
      description:
        "Tillad at man kan tilføje/ændre maskiner på kundeopgaver. Denne setting vil normalt kun være slået fra hvis kundeopgaver f.eks. importeres fra NAV og maskiner på opgaverne derfor ikke må redigeres i CustomOffice",
      type: "boolean",
    },
    allowDuplicateProductUses: {
      default: false,
      description: "Tilladt at samme vare, tilføjes flere gange til en opgave",
      type: "boolean",
    },
    allowMachineOperatorChangeOnTaskInfoPage: {
      default: false,
      description: "Gør det muligt for Admin, at skifte medarbejder",
      type: "boolean",
    },
    allowMachineOperatorToEditLocationLocationType: {
      default: true,
      description: "Medarbejder har tilladelse til at rette i lokationers lokationstype.",
      type: "boolean",
    },
    allowMachineOperatorToEditTaskDate: {
      default: false,
      description: '"Planlagt dato" vises for medarbejderen (Hvorfor skal han se den?)',
      type: "boolean",
    },
    allowMoreThanTwoMachinesForDepartments: {
      default: [],
      description: "Tillad mere end 2 maskiner pr. opgave for specifikke afdelinger",
      items: {type: "string"},
      type: "array",
    },
    allowOrderReferenceNumberEdit: {
      default: true,
      description: "Gør det muligt at redigere referencenummeret på ordre",
      type: "boolean",
    },
    allowSeniorMachineOperatorsToManageFoodBookings: {
      default: false,
      description: "Senior medarbejder kan rette madbestilling",
      type: "boolean",
    },
    allowTakingPendingTasksFromOthers: {
      default: true,
      description:
        '"Tag opgave" knap vises, som gør det muligt for medarbejderne at tage opgaver fra hinanden',
      type: "boolean",
    },
    allowTransportlogAmountMismatch: {
      default: false,
      description:
        "Hvis hentet mængde er forskellig fra leveret mængde, gives en advarsel snarere end en fejl. (gammel",
      type: "boolean",
    },
    alternativeMaterialsMobileLayout: {
      default: false,
      description: "Alternativt layout på mobil under Materiel, hvor note-feltet, altid vises",
      type: "boolean",
    },
    alwaysAllowEditingOrderReferenceNumber: {
      default: false,
      description:
        "Der kan altid rettes i referencenummeret, selv når ordren har opgaver der er bogført",
      type: "boolean",
    },
    alwaysAllowEditingOrderWorkplace: {
      default: false,
      description: "Ændring af arbejdssted",
      type: "boolean",
    },
    alwaysAllowManagerTaskAndOrderNotesEdit: {
      default: false,
      description:
        'Admin kan altid tilføje opgave og rediger i ordre noter - også ved "lukkede" ordre',
      type: "boolean",
    },
    alwaysAllowManagerTaskPhotosEdit: {
      default: false,
      description: "Admin kan tilføje / fjerne fotos efter godkendelse",
      type: "boolean",
    },
    alwaysAllowMoreThanTwoMachines: {
      default: false,
      description: "Det er tilladt at have mere end 2 maskiner pr. opgave",
      type: "boolean",
    },
    alwaysUseLogTab: {
      default: false,
      description: "Log vises som fane (OBS: Sådan vises de altid på mobil)",
      type: "boolean",
    },
    anniversariesEnabled: {
      default: false,
      description: "Aktiver indtastning og påmindelse af mærkedage",
      type: "boolean",
    },
    askForPriceGroupSelectionAfterWorkTypeSelection: {
      default: false,
      description: "Prisgruppe/variant efter valgt arbejdsområde",
      type: "boolean",
    },
    askRegardingMissingBreakOnExternalTaskCompletion: {
      default: false,
      description: 'Advarsel vises: "Er der afholdt pause?"',
      type: "boolean",
    },
    askRegardingMissingBreakOnInternalTaskCompletion: {
      default: false,
      description: 'Advarsel "Er der afholdt pause?" på interneopgaver vises',
      type: "boolean",
    },
    autoAddSprayLogFor: {
      default: [],
      description: "Indsæt automatisk sprøjtelog  for følgende områder/maskiner (gammel)",
      items: {type: "string"},
      type: "array",
    },
    autoAddTransportLogFor: {
      default: [],
      description: "Indsæt automatisk transportlog for følgende områder (gammel)",
      items: {type: "string"},
      type: "array",
    },
    autoAddYieldLogFor: {
      default: [],
      description: "Indsæt automatisk udbyttelog for følgende områder/maskiner (gammel)",
      items: {type: "string"},
      type: "array",
    },
    autoComputedSalaryVouchers: {
      default: true,
      description: "Min arbejdstid er aktiveret",
      type: "boolean",
    },
    autoCopyMaterialNoteToSupplementingProductNote: {
      default: false,
      description: "Kopierer materiale noten til råstofafgiften",
      type: "boolean",
    },
    autoCreateLogPrint: {
      default: false,
      description: "Generer automatisk første pdf/csv log, når opgave bliver udført",
      type: "boolean",
    },
    autoFillReferenceNumberWithCustomerAccount: {
      default: false,
      description: "Automatisk udfyldning af referencenummer med kundens kontonummer",
      type: "boolean",
    },
    autoFillUnregisteredMinutesOnStart: {
      default: null,
      description:
        "Kortere uregistrerede perioder mellem opgaver auto-indføres som rettelse af valgt type ved start; hvis sat vil der endvidere spørges for sådanne perioder mellem den her angivne tid og unregisteredBreakAfterMinutes",
      type: ["number", "null"],
    },
    autoInsertOrderDateAsNewTaskDate: {
      default: true,
      description: 'Indsæt ordrerens "Planlagt dato" som dato på nye opgaver',
      type: "boolean",
    },
    autoInsertSearchStringAsNewCustomerName: {
      default: true,
      description: 'Indsæt automatisk søgeteksten som navn når der trykkes på "ny kunde"',
      type: "boolean",
    },
    autoSupplementingProducts: {
      anyOf: [
        {additionalProperties: {items: {type: "string"}, type: "array"}, type: "object"},
        {type: "null"},
      ],
      default: null,
      description:
        "Opsætning af varer som skal auto-indsættes med samme antal og note som andre varer (Oprindeligt behov var/er ifht registrering af råstofafgift)",
    },
    availabilityWeekdays: {
      anyOf: [
        {
          items: {
            enum: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
          },
          type: "array",
        },
        {type: "null"},
      ],
      default: null,
      description: "Listen med alle hverdage vises, når der spørges om rådighed",
    },
    availabilityWeekdaysAskFromDay: {
      default: 1,
      description: "Hvilken dag spørges der om de kan arbejde over i hverdagene",
      enum: [0, 1, 2, 3, 4, 5, 6],
    },
    availabilityWeekdaysAskFromHour: {
      default: 12,
      description: "Hvad tid på dagen spørges der om overarbejde",
      maximum: 23,
      minimum: 0,
      type: "integer",
    },
    availabilityWeekends: {
      default: false,
      description: "Listen med dage i weekenden vises, når der spørges om rådighed",
      type: "boolean",
    },
    availabilityWeekendsAskFromDay: {
      default: 4,
      description: "Hvilken dag spørges der om de kan arbejde over i weekenden",
      enum: [0, 1, 2, 3, 4, 5, 6],
    },
    backgroundPositionInterval: {
      default: 0,
      description: "Minut interval, for hvornår der skal logges gps position: 0 = aldrig",
      type: "integer",
    },
    barcodeScannerMachineDialog: {
      anyOf: [{$ref: "#/definitions/barcodeFormats"}, {type: "null"}],
      default: null,
      description:
        "Vælg mindst ét stregkodeformat som scanneren skal understøtte og der vises en QR-kode/stregkodescanner-knap i vælg maskine-dialogen",
    },
    barcodeScannerProductDialog: {
      anyOf: [{$ref: "#/definitions/barcodeFormats"}, {type: "null"}],
      default: null,
      description:
        "Vælg mindst ét stregkodeformat som scanneren skal understøtte og der vises en QR-kode/stregkodescanner-knap i vælg materiel-dialogen",
    },
    billedBreaks: {default: false, description: "billedBreaks", type: "boolean"},
    bookkeepingDayHideBlankPriceItems: {
      default: false,
      description: "Skjul prislinje-materiellinjer på dagsedlen hvis de ikke er udfyldt",
      type: "boolean",
    },
    bookkeepingDayHideBlankProducts: {
      default: false,
      description: "Skjul vare-materiellinjer på dagsedlen hvis de ikke er udfyldt",
      type: "boolean",
    },
    bookkeepingDayLinePerTimer: {
      default: false,
      description: "Internt registreret tid vises med ekstra linjer på dagsedlen",
      type: "boolean",
    },
    bookkeepingDayPDF: {
      default: false,
      description: "Der kan laves udskrifter fra dagseddel-siden.",
      type: "boolean",
    },
    bookkeepingDayRespectNoteLineBreaks: {
      default: false,
      description:
        "Noter brudt med en [enter] tast holder formateringen på godkendelse af dagsedler siden.",
      type: "boolean",
    },
    bookkeepingListColumns: {
      additionalProperties: false,
      default: {
        desktop: [
          "employee",
          "employeeName",
          "completed",
          "open",
          "approved",
          "posted",
          "customerCultureProjectWorkplace",
          "date",
        ],
        mobile: [
          "employee",
          "completed",
          "open",
          "approved",
          "posted",
          "customerCultureProjectWorkplaceMobile",
          "dateMobile",
        ],
        tablet: [
          "employee",
          "employeeName",
          "completed",
          "open",
          "approved",
          "posted",
          "customerCultureProjectWorkplace",
          "date",
        ],
      },
      description: "Kolonner på dagseddelslisterne",
      properties: {
        desktop: {$ref: "#/definitions/bookkeepingListColumnsColumnChoices"},
        mobile: {$ref: "#/definitions/bookkeepingListColumnsColumnChoices"},
        tablet: {$ref: "#/definitions/bookkeepingListColumnsColumnChoices"},
      },
      required: ["mobile", "tablet", "desktop"],
      type: "object",
    },
    bookkeepingMachineNames: {
      default: false,
      description: "Dagseddelsiden viser både navn og nummer for maskiner; snarere end kun nummer",
      type: "boolean",
    },
    bookkeepingWorkplaceAddressReplaceName: {
      default: false,
      description: "På [dagsedler] vises adressen fremfor søgenavnet på arbejdsstedet",
      type: "boolean",
    },
    bookkeepingWorkTypeAsWorkTypeAndVariant: {
      default: false,
      description: "Område og Variant vises fremfor kun Område på dagseddel visning",
      type: "boolean",
    },
    brugerdataCreateOrderButton: {
      default: false,
      description: '"Opret i Brugerdata" knap vises på ordre.',
      type: "boolean",
    },
    brugerdataMaterialProductGroupRanges: {
      anyOf: [{items: {$ref: "#/definitions/numberPair"}, type: "array"}, {type: "null"}],
      default: null,
      description: "Varer fra disse udvalgte varegrupper importeres som produkter i CustomOffice",
    },
    brugerdataPriceitemProductGroupRanges: {
      anyOf: [{items: {$ref: "#/definitions/numberPair"}, type: "array"}, {type: "null"}],
      default: null,
      description: "Varer fra disse udvalgte varergrupper importeres som prislinjer i CustomOffice",
    },
    brugerdataShowMachineOperatorAlias: {
      default: false,
      description: "Maskinfører tilføjes til ordrelinje-note (Initialer)",
      type: "boolean",
    },
    brugerdataSync: {
      default: false,
      description: 'Menupunktet "Brugerdata" vises og synkronisering med BrugerData er aktiveret',
      type: "boolean",
    },
    brugerdataSyncOnCompleted: {
      default: false,
      description:
        'Generer ordrelinjer for opgaver og overfør dem til brugerdata når opgaven markeres fuldført. Dette skjuler "Send til brugerdata"-knappen.',
      type: "boolean",
    },
    brugerdataSyncOnValidated: {
      default: false,
      description: "Ordrelinjer for opgaver genereres og overføres til brugerdata ved godkendelse",
      type: "boolean",
    },
    c5Sync: {default: false, description: "Synkronisering til C5 er aktiveret", type: "boolean"},
    calendarForMachineOperators: {
      default: true,
      description: "Kalenderen vises også for medarbejderne",
      type: "boolean",
    },
    calendarPlannedTasksBehindMeasured: {
      default: false,
      description:
        "Planlagt opgave-blok fra en Admin vises bagved  medarbejderens målte opgave-blok",
      type: "boolean",
    },
    calendarPlanningFunctions: {
      default: true,
      description: 'Aktivér "klik og træk" opgaveoprettelse på kalenderen',
      type: "boolean",
    },
    calendarShowEmployeeAlias: {
      default: true,
      description: "Medarbejderens initialer vises i kolonneoverskrifterne",
      type: "boolean",
    },
    calendarShowEmployeeName: {
      default: false,
      description: "Medarbejderens navn vises i kolonneoverskrifterne",
      type: "boolean",
    },
    calendarShowEmployeeNumber: {
      default: false,
      description: "Medarbejderens nummer vises i kolonneoverskrifterne",
      type: "boolean",
    },
    calendarShowMaterialsWithUnits: {
      default: [],
      description: "Mængden af følgende enheder vises på opgave-blokken",
      items: {type: "string"},
      type: "array",
    },
    calendarShowWorkTypeNumber: {
      default: true,
      description: "Nummeret for arbejdsområdet vises på opgave-blokken",
      type: "boolean",
    },
    canAddProject: {
      default: null,
      description:
        "Man kan selv oprette projekt  OBS - må ikke samtidig synkroniseres med andet projektsystem!!",
      enum: [null, "manager", "all"],
    },
    canChangeTransportLogTime: {
      default: true,
      description: "Det er tilladt at ændre i transportloggens tider (gammel)",
      type: "boolean",
    },
    canCreateCultures: {
      default: false,
      description: 'Fanen "AFGRØDER" vises. Her man kan oprette afgrødetyper',
      type: "boolean",
    },
    canCreateExternalWorkType: {
      default: false,
      description: "Arbejdsområder kan oprettes (grøn +-knap i nederste højre hjørne)",
      type: "boolean",
    },
    canCreateMachines: {
      default: false,
      description: "Kan oprette maskiner i [Maskiner] fanen",
      type: "boolean",
    },
    canCreatePriceGroups: {
      default: false,
      description: "Varianter kan oprettes (+-knap på variantlinjen)",
      type: "boolean",
    },
    canCreatePriceItems: {
      default: false,
      description:
        'Der kan oprettes prislinjer i CustomOffice "+" vises over retteblyant ved variantvælgeren',
      type: "boolean",
    },
    canEditAllUserDataOnProfilePage: {
      default: false,
      description: "Gør det muligt at redigere alle informationer på brugere",
      type: "boolean",
    },
    canEditCultures: {default: false, description: "Afgrøder kan opsættes", type: "boolean"},
    canEditCustomerVATNumber: {
      default: false,
      description: "CVR nummer kan redigereres",
      type: "boolean",
    },
    canEditExternalWorkType: {
      default: false,
      description: "Eksterne arbejdsområder kan redigeres",
      type: "boolean",
    },
    canEditInternalWorkTypes: {
      default: false,
      description: 'Fanen "INTERNE OMRÅDER" vises og interneområder kan opsættes',
      type: "boolean",
    },
    canEditMachines: {
      default: false,
      description: "Kan redigere nummer og navn på maskiner",
      type: "boolean",
    },
    canEditPriceGroups: {default: false, description: "Varianter kan rettes", type: "boolean"},
    canEditPriceItemActive: {
      default: false,
      description: "Prislinjer kan gøres aktive/inaktive.",
      type: "boolean",
    },
    canEditPriceItemBillable: {
      default: false,
      description: '"Overføres til økonomisystem" vises på prislinjen.',
      type: "boolean",
    },
    canEditPriceItemID: {
      default: false,
      description: "ID'et på prislinjen kan ændres",
      type: "boolean",
    },
    canEditPriceItemInputConditions: {
      default: false,
      description:
        "Radio buttons (valgfri, værdi effektiv, skal udfyldes, større end 0) vises på prislinjen.",
      type: "boolean",
    },
    canEditPriceItemName: {
      default: false,
      description: "Navnet på prislinjen kan ændres",
      type: "boolean",
    },
    canEditPriceItemOnlyVisibleOnOverview: {
      default: false,
      description: "Prislinjen er kun synlig på oversigtssiden - HVORFOR?",
      type: "boolean",
    },
    canEditPriceItemProduct: {
      default: false,
      description:
        "Der kan vælges vare inde på prislinjen. Kan fx knytte en vare til en anden text",
      type: "boolean",
    },
    canEditPriceItemRelevantForExecution: {
      default: false,
      description: '"Synlig ved udførsel" toggle vises på prislinjen',
      type: "boolean",
    },
    canEditPriceItems: {
      default: false,
      description: "Prislinjer kan redigeres. En retteblyant vises yderst til højre.",
      type: "boolean",
    },
    canEditPriceItemUnit: {
      default: false,
      description: "Kan vælge/skifte enheder på prislinjer",
      type: "boolean",
    },
    canEditPriceItemUseManualDistribution: {
      default: false,
      description: '"Anvend fordelingstabel" toggle vises på prislinie opsætning',
      type: "boolean",
    },
    canEditProductGroups: {
      default: false,
      description: "Kan tilføje og redigere varegrupper",
      type: "boolean",
    },
    canEditProducts: {
      default: false,
      description: "Kan tilføje og redigere varer og varegrupper",
      type: "boolean",
    },
    canEditProjectDistance: {
      default: false,
      description:
        "Der kan angives  det antal af km, som der skal afregnes til medarbejderne ved transport til projektet (denne og ovenstående skal testes",
      type: "boolean",
    },
    canEditProjects: {
      default: false,
      description:
        "Det er muligt at rette i projekt nummer og navn (kun når man selv kan tilføje og redigere interne projekter)",
      type: "boolean",
    },
    canEditUnits: {default: false, description: "Enheder kan oprettes/rettes", type: "boolean"},
    checkoutHideInactive: {
      default: false,
      description: "Kun brugere med et gyldigt/muligt aktiv/pause-skift vises på frokoststue-side.",
      type: "boolean",
    },
    chooseWorkTypesOnCustomerJournal: {
      default: true,
      description: "Der kan vælges arbejdsområde for note på kundejournal",
      type: "boolean",
    },
    colorInternalTaskWorkTypesOnEmployeeCalendar: {
      default: false,
      description: "Områdefarver vises i toppen af opgaveblokken, for interne opgaver.",
      type: "boolean",
    },
    colorUnscheduledCalendar: {
      default: false,
      description: 'Arbejdsområdernes farver vises på "ikke planlagte" opgaver',
      type: "boolean",
    },
    colorWorkTypesOnEmployeeCalendar: {
      default: false,
      description: "Område farver vises i toppen af opgave-blokken",
      type: "boolean",
    },
    companyName: {default: "CustomOffice", description: "Virksomhedens navn", type: "string"},
    completeTaskAsInternal: {
      default: true,
      description:
        '"Afslut som intern" knap vises, som konvertere opgaven og samtidig opretter en kopi af opgaven til dagen efter',
      type: "boolean",
    },
    completeTaskAsInternalTargetDate: {
      default: "NEXT_DAY",
      description:
        'Bestemmer hvilken dato den nye opgave-kopi ved klik på "Afslut som intern" får.',
      enum: ["TODAY", "NEXT_DAY", "NEXT_WEEKDAY", "NEXT_NON_SUNDAY"],
    },
    computedSalaryVoucherStartDay: {
      default: 1,
      description: "Startdato for lønperioden som vises i Min Arbejdstid",
      type: "integer",
    },
    concurrentTasksAllowed: {
      default: false,
      description:
        'Gør det muligt at starte tid på flere opgaver samtidig, for alle områder, uden at få advarslen: "Du måler allerede tid på en anden opgave"',
      type: "boolean",
    },
    concurrentTasksAllowedForDepartments: {
      default: [],
      description:
        'Gør det muligt at starte tid på flere opgaver samtidig, for disse afdelinger, uden at få advarslen: "Du måler allerede tid på en anden opgave"',
      items: {type: "string"},
      type: "array",
    },
    concurrentTasksAllowedForWorkTypes: {
      default: [],
      description:
        'Gør det muligt at starte tid på flere opgaver samtidig, for disse områder, uden at få advarslen: "Du måler allerede tid på en anden opgave"',
      items: {type: "string"},
      type: "array",
    },
    consultantNotes: {default: "", description: "Konsulentnoter", type: "string"},
    contactMail: {default: "", description: "Kontaktpersonens email adresse", type: "string"},
    contactPerson: {default: "", description: "Kontaktpersonens navn", type: "string"},
    contactPhone: {default: "", description: "Kontaktpersonens telefonnummer", type: "string"},
    contactsMayHaveCellphone: {
      default: true,
      description:
        "Mobilnummer kan tilknyttes til kontaktpersoner. Vises både på detaljer og listesiden",
      type: "boolean",
    },
    continuationCopyFields: {
      default: [
        "workType",
        "priceGroup",
        "department",
        "relatedWorkplace",
        "relatedPickupLocation",
        "machineOperator",
        "machineGroup",
        "order",
        "date",
        "minutesExpectedTotalTaskDuration",
        "notesFromManager",
        "notesFromMachineOperator",
        "machineuseSet",
        "workshopchecklistanswerSet",
        "fielduseSet",
        "priority",
        "billedPreparation",
        "billedTransportation",
        "reportingSpecification",
        "reportingLocations",
        "invoiceNote",
        "project",
        "referenceNumber",
      ],
      description:
        'Definerer hvilke felter der skal kopieres når en opgave videreføres ( F.eks. ifm "Afslut og viderefør" eller "Aflever som intern"',
      items: {type: "string"},
      type: "array",
    },
    continuationTaskTargetDate: {
      default: "NEXT_DAY",
      description:
        'Bestemmer hvilken dato den nye opgave-kopi ved klik på "Afslut og viderefør" får\n"NEXT_DAY"',
      enum: ["NEXT_DAY", "NEXT_WEEKDAY", "NEXT_NON_SUNDAY"],
    },
    conversionFieldDecimals: {
      default: 2,
      description:
        "Bestemmer hvor mange decimaler, der bruges i konverteringsfeltet på materiel tabellen (og andre steder hvor output bruges)",
      type: "integer",
    },
    createCostLines: {default: false, description: "Send projektlinjer", type: "boolean"},
    createCustomer: {
      default: null,
      description: "Kunder kan oprettes i CustomOffice (Tilføj kunde / opret ny kunde)",
      enum: [null, "manager", "all"],
    },
    createSalesLines: {default: false, description: "Send fakturalinjer", type: "boolean"},
    customerAliasEdit: {
      default: false,
      description: 'Redigering af "søgenavn" er aktiv',
      type: "boolean",
    },
    customerApprovalGate: {
      default: "TASK_BOOKKEEPING",
      description:
        "På hvilket tidspunkt skal en kunde senest være godkendt (oprettet i økonomisystem)? Ved godkendelse (TASK_APPROVE) eller bogføring (TASK_BOOKKEEPING) af opgave",
      enum: ["TASK_APPROVE", "TASK_BOOKKEEPING"],
    },
    customerCreateInvoicesSetup: {
      default: false,
      description:
        "Knap, hvor man kan vælge om kundens opgaver skal overføres til fakturakladde eller ej, vises",
      type: "boolean",
    },
    customerFields: {
      default: true,
      description: "Slå mark-fane til under kunde og gør mark-upload på kunder mulig",
      type: "boolean",
    },
    customerFileUpload: {
      default: true,
      description: "Filer kan uploades på kunder",
      type: "boolean",
    },
    customerJournal: {default: true, description: "Kundejournal er aktiveret", type: "boolean"},
    customerOrderListNewestFirst: {
      default: false,
      description: "Ordre sorteres med nyeste øverst",
      type: "boolean",
    },
    customerOrderOverview: {
      default: true,
      description: '"Ordreoverblik" fanen vises',
      type: "boolean",
    },
    customerProjectManager: {
      default: false,
      description: '"Formand" felt vises på kunden, som kan redigeres.',
      type: "boolean",
    },
    customerRequiredFields: {
      default: [],
      description:
        "Vælg hvilke felter der skal udfyldes når der oprettes en ny kunde. (Udover navn)",
      items: {enum: ["address", "postalCode", "city", "phone", "billingEmail", "c5_account"]},
      type: "array",
    },
    customerStatsVisibleToAll: {
      default: false,
      description: "Statistik fanen vises for alle (ikke kun admin)",
      type: "boolean",
    },
    customerTaskAutoWorkTypePreviousMachines: {
      default: true,
      description:
        "Sidste anvendte maskine indsættes automatisk, når medarbejder og arbejdsområde vælges",
      type: "boolean",
    },
    customerTaskCreationWizard: {
      default: false,
      description:
        'Aktivering af  wizard på på oprettelse af opgaver. (virker kun for admin når der oprettes opgaver i opgavelisten "egen")',
      type: "boolean",
    },
    customOfficeProductVariant: {
      default: "STANDARD",
      description: "CustomOffice produkt",
      enum: ["PROFESSIONEL", "STANDARD"],
    },
    cvr: {
      default: "",
      description: "Virksomhedens cvr nummer (Søg på https://www.proff.dk/)",
      type: "string",
    },
    dayEndRounding: {
      anyOf: [{$ref: "#/definitions/stringPair"}, {type: "null"}],
      default: null,
      description: "Afrunding af dagens sluttidspunkt",
    },
    daysAbsenceTypes: {
      default: ["daysOffWithoutPay", "vacation", "sickLeave", "floatingHoliday"],
      description: "Fraværstyper der dækker en eller flere hele dage",
      items: {type: "string"},
      type: "array",
    },
    daysIntoTheFutureVisibleToMachineOperator: {
      default: 14,
      description:
        'Hvor langt frem en medarbejder kan se planlagte opgaver på kalender og opgavelisten.  "0" begrænser til indeværende dag',
      type: "integer",
    },
    dayStartRounding: {
      anyOf: [{$ref: "#/definitions/stringPair"}, {type: "null"}],
      default: null,
      description: "Afrunding af dagens starttidspunkt",
    },
    defaultTaskEmployee: {
      default: null,
      description:
        'Opgaver der oprettes, og som i forvejen ikke automatisk får medarbejder sat på, vil få denne medarbejder sat på. Denne feature anvendes ofte ifm. med en "Ikke fordelt"-bruger.',
      type: ["string", "null"],
    },
    departmentExtraTimers: {
      additionalProperties: {items: {type: "string"}, type: "array"},
      default: {},
      description:
        'Vis ekstra "effektiv tid"-variant-timer-knapper for specifikke maskiner; specificeret på formen {afdeling-ID: [timer-ID, ...], ...}',
      type: "object",
    },
    departments: {
      additionalProperties: {type: "string"},
      default: {},
      description: "Opsætning af afdelinger",
      type: "object",
    },
    dinnerBookingLocations: {
      default: [],
      description: "Visning af mulig aftensmad lokation",
      items: {type: "string"},
      type: "array",
    },
    dinnerBookings: {
      anyOf: [
        {
          items: {$ref: "#/definitions/nullableStringPair"},
          maxItems: 7,
          minItems: 7,
          type: "array",
        },
        {type: "null"},
      ],
      default: null,
      description: "Madbestilling er aktiveret",
    },
    dinnerBookingsShowThumbDialog: {
      default: false,
      description: "Madbestilling som tumbs fremfor som tekst",
      type: "boolean",
    },
    disabledMachines: {
      default: [],
      description: "Maskiner som ikke kan vælges/bruges for opgaver.",
      items: {type: "string"},
      type: "array",
    },
    disabledWorkTypes: {
      default: [],
      description: "Arbejdsområder som ikke skal anvendes/bruges, skjules.",
      items: {type: "string"},
      type: "array",
    },
    displayCustomerContacts: {
      default: false,
      description: "Kontaktpersoner vises på detaljesiden (og separat i kundelisten)",
      type: "boolean",
    },
    displayProjectsPageMenu: {
      default: "SETTINGS",
      description: 'Vis projekter i "main" eller "settings"',
      enum: ["MAIN", "SETTINGS"],
    },
    distributionTableAdjustTimeMethod: {
      default: "NO_ADJUSTMENT",
      description:
        "Korriger automatisk det indtastede antal timer i fordelingstabellen efter nedenstående metode",
      enum: [
        "ADD_10",
        "ADD_15",
        "MIN_30_ROUND_NEAREST_15",
        "MIN_30_ROUND_UP_15",
        "ROUND_NEAREST_15",
        "ROUND_UP_15",
        "ROUND_UP_30",
        "NO_ADJUSTMENT",
      ],
    },
    dontShowTransportLogDialog: {
      default: false,
      description: "Vis ikke dialog til indstilling af transportloggen (gammel)",
      type: "boolean",
    },
    durationFormat: {
      default: "<HOURS>h <MINUTES>m",
      description:
        "Hvordan varighed (tid) vises i UI. F.eks. med varigheden 9 timer og 8 minutter vil de to formater hhv. vise 9t 8m og 9:08.",
      enum: ["<HOURS>h <MINUTES>m", "<HOURS>:<MINUTES>"],
    },
    economicAppendMaterialNotesToInvoiceLineDescription: {
      default: false,
      description:
        "Materialer noter tilføjes til varens tekstbeskrivelse (i forlængelse af varens navn)",
      type: "boolean",
    },
    economicAutogeneratedCustomerNumbers: {
      default: false,
      description: "Kundenumre oprettes automatisk i økonomisystemet (kun for e-conomic)",
      type: "boolean",
    },
    economicCompanyIdentificationNumber: {
      default: "",
      description:
        'Virksomhedens CVR-nummer, som det står angivet i kundens e-conomic under "Alle indstillinger->Virksomhed->Stamoplysninger". Dette anvendes til kontrol ved kommunikation med e-conomic, for at sikre vi har fat i den rigtige kundens e-conomic',
      type: "string",
    },
    economicCustomerDetailsOnTransportlogOnInvoice: {
      default: false,
      description:
        "Navn, adresse, postnr. by, cvrnr skrives ved hvert sted på transportlog, på faktura",
      type: "boolean",
    },
    economicCustomerGroup: {
      default: 1,
      description: "Kundegruppe, som nye kunder bliver oprettet med (kun for e-conomic)",
      type: "number",
    },
    economicDefaultPaymentTerms: {
      default: null,
      description:
        "Valg af betalingsbetingelser der anvendes ved oprettelse af nye kunder i e-conomic. Disse betalingsbetingelser påføres derefter automatisk på nye fakturakladder til denne kunde.",
      type: ["integer", "null"],
    },
    economicEnableMachinesDepartmentSync: {
      default: false,
      description:
        "Maskiner importeres fra Economic og maskiner der oprettes i CustomOffice bliver oprettet som Afdelinger i e-conomic. Husk at aktivere settingen canCreateMachines for at administratorer kan oprette maskiner i CustomOffice.",
      type: "boolean",
    },
    economicEnableMachinesProjectEmployeesSync: {
      default: false,
      description:
        "Maskiner importeres fra Economic og maskiner der oprettes i CustomOffice bliver oprettet som Medarbejdere (projekt) i e-conomic. Bemærk at settingen economicProjectsMachineEmployeeNumberOffset påvirker hvilket nummer Medarbejderen i e-conomic får. Husk at aktivere settingen canCreateMachines for at administratorer kan oprette maskiner i CustomOffice.",
      type: "boolean",
    },
    economicEnableProjectActivitiesImport: {
      default: false,
      description: "Importerer projektaktiviter som prislinjer",
      type: "boolean",
    },
    economicEnableUploadUsersToProjectEmployees: {
      default: false,
      description:
        "Brugere der oprettes i CustomOffice bliver oprettet ved sync. (CO->econ) som Medarbejdere (projekt) i e-conomic.",
      type: "boolean",
    },
    economicEnableWorkplaceImport: {
      default: false,
      description:
        "Arbejdssteder bliver indlæst fra leveringssteder i e-conomic. Henter de leveringssteder der ligger på kunder og opretter dem som arbejdssteder i CO. Aktiveres, såfremt kunden laver leveringssteder i e-conomic. OBS! man kan ikke give leveringsstedet et navn - kun en adresse.",
      type: "boolean",
    },
    economicEnableWorkTypeFromProductGroupImport: {
      default: false,
      description: "Arbejdsområder bliver indlæst fra Varegrupper i e-conomic",
      type: "boolean",
    },
    economicFuelSurchargeLinesAtBottom: {
      default: true,
      description:
        "Alle generede fakturalinjer for brændstoftillæg lægges ved overførsel til e-conomic-faktura i bunden af fakturaen og ens linjer lægges sammen.",
      type: "boolean",
    },
    economicInvoiceEmployeeNotes: {
      default: true,
      description: "Medarbejder noter indsættes på faktura",
      type: "boolean",
    },
    economicInvoiceIncludeTotalTaskTime: {
      default: false,
      description:
        "Totale arbejdstid vises på faktura (information til kunden, så de ved hvor meget tid man har brugt på dem)",
      type: "boolean",
    },
    economicInvoiceManagerNotes: {
      default: false,
      description: "Admin noter på faktura",
      type: "boolean",
    },
    economicInvoicePerOrderReferenceNumber: {
      default: false,
      description:
        "Faktura opdeles efter ordrens referencenummer og referencenummeret sættes som øvrig ref. på faktura i e-conomic.",
      type: "boolean",
    },
    economicInvoicePerProject: {
      default: false,
      description:
        "Faktura opdeles efter projekt og projektnummer sættes som øvrig ref. på faktura i e-conomic.",
      type: "boolean",
    },
    economicInvoicePerWorkplace: {
      default: false,
      description:
        "Faktura opdeles efter leveringssteder i e-conomic, som er anvendt som arbejdssted på opgaven i CustomOffice. Leveringssted sættes på faktura i e-conomic. Arbejdsteder i CustomOffice skal synkroniseres med leveringssteder i e-conomic via settingen economicEnableWorkplaceImport eller economicUploadWorkPlaces.",
      type: "boolean",
    },
    economicInvoicePerWorkplacePickupLocationFallback: {
      default: false,
      description:
        "Leveringssted sættes til opgavens afhentningssted, hvis opgavens leveringssted ikke tilhører kunden, men opgavens afhentningssted derimod gør.",
      type: "boolean",
    },
    economicInvoiceProjectNumberAndNameAsOtherReference: {
      default: false,
      description:
        "Faktura opdeles efter projekt og projektnummer og navn sættes som øvrig ref. på faktura i e-conomic.",
      type: "boolean",
    },
    economicInvoicePullerAsDeparment: {
      default: false,
      description: "Trækker skrives på faktura frem for maskine",
      type: "boolean",
    },
    economicInvoicesForProjectTasks: {
      default: true,
      description: "Lav fakturakladder og fakturalinjer for projektopgaver",
      type: "boolean",
    },
    economicInvoiceShowOrderWorkplaceNameAsOtherRef: {
      default: false,
      description:
        "Faktura opdeles efter ordrens arbejdsstednavn og arbejdsstedsnavnet sættes som øvrig ref. på faktura i e-conomic.",
      type: "boolean",
    },
    economicInvoiceShowTaskWorkplaceNameAsOtherRef: {
      default: false,
      description:
        "Faktura opdeles efter opgavens arbejdsstednavn og arbejdsstedsnavnet sættes som øvrig ref. på faktura i e-conomic.",
      type: "boolean",
    },
    economicInvoiceWorkplaceAddressReplaceName: {
      default: false,
      description: "Adresse bruges som navn på faktura",
      type: "boolean",
    },
    economicMaterialProductGroups: {
      default: [],
      description:
        'Vare fra disse varegrupper kan vælges under "Tilføj materiel" på en opgave og som vare på Lokationstyper til lager (Husk at synkronisere med e-conomic efterfølgende)',
      items: {type: "number"},
      type: "array",
    },
    economicMergLinesPerOrder: {
      default: false,
      description: "Aktiver Prislinjefletning pr. ordre (e-conomic)",
      type: "boolean",
    },
    economicPrefixCustomerContactNameWith: {
      default: "",
      description:
        "Når kontaktperson skrives på som faktura linje, så sættes denne tekst foran. Ønskes der f.eks. ingen tekst foran så sæt denne indstilling til ingenting.",
      type: "string",
    },
    economicPrefixMachineOperatorNameOrAliasWith: {
      default: "Udført af",
      description:
        'Når maskinførerens navn/initialer skrives på som faktura linje, så sættes denne tekst foran. Ønskes der f.eks. ingen test foran så sæt denne indstilling til ingenting.\n"Udført af"',
      type: "string",
    },
    economicPriceitemProductGroupRanges: {
      anyOf: [{items: {$ref: "#/definitions/numberPair"}, type: "array"}, {type: "null"}],
      default: null,
      description:
        "Nedenstående varegrupper importeres fra e-conomic og prislinjer kan opsættes under indstillinger ud fra disse varegrupper",
    },
    economicProjectsApproveTimesOnTransfer: {
      default: false,
      description: "Auto-godkend tider på aktiviteter når de overføres til e-conomic",
      type: "boolean",
    },
    economicProjectsIncludeNotes: {
      default: true,
      description:
        "Inkluder fakturanoter, noter fra administrationen, noter fra maskinfører i e-conomic projekt-timeregistreringer.",
      type: "boolean",
    },
    economicProjectsMachineEmployeeGroups: {
      default: [],
      description:
        "Liste af heltals IDer på de medarbejdergrupper der indeholder maskiner i stedet for reelle medarbejdere. Eksempel: [2, 3, 4]",
      items: {type: "integer"},
      type: "array",
    },
    economicProjectsMachineEmployeeNumberOffset: {
      default: 0,
      description:
        "Hvis maskinnumre i CustomOffice og medarbejdernumrene i e-conomic ikke er ens, men er forskudt med et givet antal. F.eks. hvis economicProjectsMachineEmployeeNumberOffset = 100000 så vil maskinen i CustomOffice med maskinnummer 23 bliver synkroniseret med medarbejder-ressourcen i e-conomic med ID/nummer 100023. Offset må gerne være negativ.",
      type: "integer",
    },
    economicProjectsMachineHoursActivity: {
      default: null,
      description: 'Nummeret på aktivteten "Maskinarbejde"',
      type: ["number", "null"],
    },
    economicProjectsManHoursActivity: {
      default: null,
      description: 'Nummeret på aktiviteten "Mandsarbejde"',
      type: ["number", "null"],
    },
    economicProjectsManHoursMachines: {
      default: [],
      description:
        'Liste af ID-strenge (maskin-numre/koder) på de maskiner der bruges til at repræsentere forskellige slags mandsarbejde. Eksempel: ["101", "102", "103"]',
      items: {type: "string"},
      type: "array",
    },
    economicProjectsProductUseCostActivity: {
      default: null,
      description: 'Nummeret på aktiviteten "Vareforbrug"',
      type: ["number", "null"],
    },
    economicProjectsProductUseCostEmployee: {
      default: null,
      description: 'Nummeret på den ressource (medearbejder) der repræsentere "Vareforbrug"',
      type: ["number", "null"],
    },
    economicRouteTaskStartStopOnInvoice: {
      default: false,
      description:
        'Overfør "hh:mm - hh:mm" start-stop klokkeslæt til fakturalinje. Sættes på enden af linjen',
      type: "boolean",
    },
    economicSeperateDatesWithBlankLines: {
      default: true,
      description:
        "Blank linje på faktura mellem forgående opgaves sidste linje og en ny datolinje på faktura",
      type: "boolean",
    },
    economicSeperateNoteLines: {
      default: false,
      description:
        "Skriv alle typer noter  på en separat linje, frem for i forlængelse af varenavnet på den tilknyttede varelinje.",
      type: "boolean",
    },
    economicSeperateTasksWithBlankLines: {
      default: true,
      description: "Adskil hver opgaver på fakturaen med en blank fakturalinje",
      type: "boolean",
    },
    economicShowCustomerContactName: {
      default: false,
      description: "Kontaktpersonens fulde navn tilføjes som note til fakturalinjen",
      type: "boolean",
    },
    economicShowFieldUse: {
      default: false,
      description: "Marknumre skrives ind på fakturanoter - hver mark adskilt af et komma",
      type: "boolean",
    },
    economicShowFieldUseCrop: {
      default: false,
      description: "Afgrøde skrives på faktura",
      type: "boolean",
    },
    economicShowGenericTransportlogOnInvoice: {
      default: [],
      description: "Generisk transport logs overføres til e-conomic",
      items: {type: "string"},
      type: "array",
    },
    economicShowMachineName: {
      default: false,
      description: "Maskinens navn tilføjes som note til den første fakturalinje, for opgaven",
      type: "boolean",
    },
    economicShowMachineOperatorAlias: {
      default: false,
      description:
        "Medarbejderens initialer tilføjes som note til den første fakturalinje for opgaven.",
      type: "boolean",
    },
    economicShowMachineOperatorAliasOrNameWithoutLineBreak: {
      default: false,
      description:
        "Medarbejderens intitaler eller navn tilføjes i direkte forlængelse af den første fakturalinje - uden linebreak.",
      type: "boolean",
    },
    economicShowMachineOperatorName: {
      default: false,
      description: "Medarbejderens fulde navn tilføjes som note til fakturalinjen",
      type: "boolean",
    },
    economicShowOrderReferenceNumber: {
      default: false,
      description: "Ordrens referencenr. tilføjes til fakturalinje-note",
      type: "boolean",
    },
    economicShowPickupLocation: {
      default: false,
      description: "Afhentningssted tilføjes til fakturalinje-note",
      type: "boolean",
    },
    economicShowTaskReferenceNumber: {
      default: false,
      description: "Opgave Reference nr. tilføjes til fakturalinje-note",
      type: "boolean",
    },
    economicShowTransportlogOnInvoice: {
      default: true,
      description: "Summerede transport logs på faktura",
      type: "boolean",
    },
    economicShowWorkPlaceOrDeliveryLocation: {
      default: false,
      description: "Arbejdssted/Leveringssted tilføjes til fakturalinje-note",
      type: "boolean",
    },
    economicSubtractDowntimeIdentifiers: {
      default: [],
      description:
        "Ved overførsel til e-conomic laves der en ekstra negativ fakturalinje med driftsstop, så driftsstopstid fremgår som rabat (dvs. tid kunden ikke skal betale for).",
      items: {type: "string"},
      type: "array",
    },
    economicSync: {default: false, description: "e-conomic er aktiveret", type: "boolean"},
    economicSyncListColumns: {
      default: ["date", "customer", "machines", "machineOperator", "notes"],
      description:
        'Opsætning af kolonner på e-conomic sync siden.\n[ "date", "customer", "machines", "machineOperator", "notes" ]',
      items: {
        enum: [
          "bookeepingAction",
          "customer",
          "date",
          "delivery",
          "endDate",
          "fields",
          "invoiceNote",
          "machineOperator",
          "machines",
          "notes",
          "orderAction",
          "photo",
          "pickup",
          "priceGroup",
          "project",
          "refNumber",
          "taskAction",
          "workType",
          "workPlace",
        ],
      },
      type: "array",
    },
    economicTaskDepartmentImport: {
      default: false,
      description:
        "Afdelinger i CustomOffice, dvs. afdeling valgt på opgaven,  svarer til afdelinger i e-conomic. Dvs. afdelings-ID i CustomOffice er lig med samme afdelings ID i e-conomic.",
      type: "boolean",
    },
    economicUploadMachinesToProjectEmployeeGroup: {
      default: null,
      description:
        "Når Medarbejdere (projekt) oprettes i e-conomic ud fra maskiner i CustomOffice så oprettes de i medarbejdergruppen hvis nummer er angivet her.",
      type: ["integer", "null"],
    },
    economicUploadUsersToProjectEmployeeGroup: {
      default: null,
      description:
        "Når Medarbejdere (projekt) oprettes i e-conomic ud fra brugere i CustomOffice (se settingen economicEnableUploadUsersToProjectEmployees) så oprettes de i medarbejdergruppen vis nummer er angivet her.",
      type: ["integer", "null"],
    },
    economicUploadWorkPlaces: {
      default: false,
      description:
        "Arbejdssteder bliver sendt til leveringssteder i e-conomic. Sender de arbejdssteder der ligger på kunder i CO og opretter dem som leveringssteder i econ. Aktiveres, såfremt kunden laver arbejdssteder i CO. OBS! Arbejdsstedests navn i vil blive “skåret” fra ved upload til econ da leveringssteder ikke kan have et navn i econ.",
      type: "boolean",
    },
    economicWorktypeDepartmentImport: {
      anyOf: [
        {
          items: {
            items: [{type: ["number", "null"]}, {type: ["number", "null"]}, {type: "number"}],
            maxItems: 3,
            minItems: 3,
            type: "array",
          },
          type: "array",
        },
        {type: "null"},
      ],
      default: null,
      description: "e-conomic afdelinger importeres til arbejdsområder i CustomOffice",
    },
    economicZeroBlank: {
      default: true,
      description: "Hvis prisen på en vare er 0 - så vises prisen som blankt felt i e-conomic.",
      type: "boolean",
    },
    effectiveTimeLabel: {
      default: "Effektiv tid",
      description: "effectiveTimeLabel",
      type: "string",
    },
    emailFromAddress: {
      default: "noreply@customoffice.dk",
      description:
        'Fra addresse/navn der skal bruges når der sendes e-mails. Kan skrives som "Navn <mail@adresse.dk>" (Må kun ændres af udviklere)',
      type: "string",
    },
    emailReplyToAddress: {
      default: "",
      description: "Hvilken adresse der sættes som svar adresse på mails",
      type: "string",
    },
    employeeCalendarManualOrderPerUser: {
      default: false,
      description: "Rækkefølgen af medarbejder kan bestemmes pr. bruger",
      type: "boolean",
    },
    employeeCanAccessAppendicesPage: {
      default: false,
      description: 'Medarbejdene har adgang til menuen "bilag"',
      type: "boolean",
    },
    employeeLabelVariant: {
      default: "MACHINEOPERATOR",
      description: 'Ansatte betegnes som "maskinfører", "medarbejder", eller "chauffør"',
      enum: ["EMPLOYEE", "MACHINEOPERATOR", "CHAUFFEUR"],
    },
    employeeSameDayTasksWarning: {
      default: true,
      description:
        'Advarsel vises for admin: "Den valgte medarbejder har andre opgaver på den valgte dag"',
      type: "boolean",
    },
    enableAccommodationAllowanceRegistrationOnInternalTasks: {
      default: false,
      description:
        "Stil også brugeren spørgsmål om diæt ved fuldførelse af interne opgaver, givet at medarbejderen tilhører en løngruppe med diæt opsat.",
      type: "boolean",
    },
    enableAddProducts: {
      default: true,
      description:
        'Gør det muligt at tilføje varer under materiel-tabben på en opgave, og at tilføje varer på log-registreringen hvis "logProducts" er slået til på loggens specifikation. Denne setting vil normalt kun blive slået fra hvis f.eks. opgaver importes fra NAV og blot udføres i CustomOffice.',
      type: "boolean",
    },
    enableAppendicesPage: {default: false, description: "Bilag er aktiveret", type: "boolean"},
    enableCalendarDepartmentFilter: {
      default: false,
      description: "Filtrering af afdelinger er aktiveret",
      type: "boolean",
    },
    enableCalendarTaskClickWhenTaskPopupIsOpen: {
      default: true,
      description:
        "Gør det muligt at klikke på en opgave på kalenderen imens opgave info popuppen er åben (Fjerner dog scrollbaren i info-popuppen)",
      type: "boolean",
    },
    enableCalendarTaskInfoPopup: {
      default: true,
      description:
        "Vis opgaveoverblik i popup ved klik på kalenderen (i stedet for at gå til opgavesiden).",
      type: "boolean",
    },
    enableCalendarWorkTypeFilter: {
      default: true,
      description: "Filtrering af arbejdsområder er aktiveret",
      type: "boolean",
    },
    enableConvertInternalToCustomerTask: {
      default: false,
      description:
        '"Til kundeopg" knap vises, som konvertere opgaven til en kundeopgave (kun på interne opgaver)',
      type: "boolean",
    },
    enableCustomerCellphone: {
      default: false,
      description: "Importeret mobilnummer vises under kundeoplysninger",
      type: "boolean",
    },
    enableCustomerContacts: {
      default: true,
      description: "Slå kontakter under kunder til",
      type: "boolean",
    },
    enableCustomerSwitch: {
      default: true,
      description:
        "Gør det muligt at ændre kunde for eksisterende opgaver. Denne settings slås normalt kun fra for kunder hvor opgaver f.eks. oprettes i NAV og udelukkende udføres i CustomOffice.",
      type: "boolean",
    },
    enableDocuments: {default: false, description: "Dokumenter aktiveres", type: "boolean"},
    enableDoneAtEstimateField: {
      default: true,
      description: '"Forventet varighed" vises, for medarbejderen',
      type: "boolean",
    },
    enableEmployeeCalendarManualOrder: {
      default: true,
      description:
        "Admin har mulighed for at bestemme rækkefølgen af medarbejderkolonnerne (sortering af medarbejdere)",
      type: "boolean",
    },
    enableExternalTaskDepartmentField: {
      default: false,
      description: "Aktiver afdelingsvalg for kundeopgaver",
      type: "boolean",
    },
    enableExternalTaskWorkTypeAndVariantSwitch: {
      default: true,
      description:
        "Gør det muligt at ændre på arbejdsområde/variant på en opgave. Denne setting slås normalt kun fra for kunder hvor opgaver oprettes f.eks. via NAV og CustomOffice udlukkende anvendes til udførelse af opgaver.",
      type: "boolean",
    },
    enableExtraWorkButton: {
      default: null,
      description:
        '"Ekstra arbejde" knappen vises, som gør det muligt at lave ekstra arbejde på kundeopgaver',
      enum: ["PROJECT", "REF", null],
    },
    enableFieldsSettingsPage: {
      default: false,
      description:
        "Slå mark-fane til under indstillinger og gør global, ikke kundespecific, mark-upload mulig",
      type: "boolean",
    },
    enableGPSList: {
      default: false,
      description: "GPS knappen vises på [dagsedler] siden.",
      type: "boolean",
    },
    enableInternalTaskDepartmentField: {
      default: false,
      description: "Valg af afdeling på interneopgaver aktiveres",
      type: "boolean",
    },
    enableInvoiceCorrections: {
      default: true,
      description: "Man kan rette i faktura linjerne der føres over i økonomisystemet.",
      type: "boolean",
    },
    enableJobberRole: {
      default: false,
      description: '"Løsarbejder" kan vælges som rolle',
      type: "boolean",
    },
    enableLessorUpload: {
      default: false,
      description:
        'Sender løndata til Lessor (NAV) - når man trykker på "BRUG SOM LØNBILAG" knappen',
      type: "boolean",
    },
    enableLocationStorage: {
      default: false,
      description: "Lager er aktiveret (aktivere også Rapporter - Lagerjournal)",
      type: "boolean",
    },
    enableMaanssonsSalaryUpload: {
      default: false,
      description:
        'Sender løndata til Maansson´s NAV, når du trykker på "BRUG SOM LØNBILAG" knappen',
      type: "boolean",
    },
    enableMachineAnalysis: {
      default: false,
      description: "Maskin analyse tilgængelig",
      type: "boolean",
    },
    enableMachinePriceStructureOverviewSettingsPage: {
      default: false,
      description: "Maskiner vises på [indstillinger]-[oversigt] siden (prisoverblik)",
      type: "boolean",
    },
    enableMaterialNoteFields: {
      default: true,
      description: "Notefelt pr. materiellinje",
      type: "boolean",
    },
    enableMechanicRole: {
      default: false,
      description: "Mekaniker kan vælges som rolle",
      type: "boolean",
    },
    enableNoDepartment: {
      default: false,
      description:
        'Mulighed for at vælge "Uden afdeling" i [vælg afdelinger] knappen på opgavestatistik rapporten.',
      type: "boolean",
    },
    enableOrderMerge: {
      default: false,
      description: "SAMMENFLET knappen vises på ordren",
      type: "boolean",
    },
    enableOrderReferenceNumber: {
      default: false,
      description: "Referencenummer-felt kan tilføjes på ordre",
      type: "boolean",
    },
    enableOrders: {default: true, description: "Ordre er aktiveret", type: "boolean"},
    enableOrderTaskDepartmentFilter: {
      default: false,
      description: "Filtrering af afdelinger er aktiveret",
      type: "boolean",
    },
    enableOrderTaskWorkTypeFilter: {
      default: true,
      description: "Filtrering af arbejdsområder er aktiveret",
      type: "boolean",
    },
    enablePriceItemMinimumCount: {
      default: false,
      description: 'Viser "minimum (antal)" på prislinjen. Virker kun på e-conomic.',
      type: "boolean",
    },
    enablePriceItemTransferAsMinutes: {
      default: false,
      description:
        "Gør det muligt at vælge at en prislinje der tager tid fra tidtagningsknap skal overføres til regnskabssystemet som minutter, i stedet for timer (afrundes ikke, kun for CustomOffice-prislinjer)",
      type: "boolean",
    },
    enablePriceItemVolumeDiscount: {
      default: false,
      description: "Rabatsatser kan opsættes på prislinjer.",
      type: "boolean",
    },
    enableProjectDistance: {
      default: false,
      description: 'Kolonnen "KM" vises (denne og nedenstående skal testes)',
      type: "boolean",
    },
    enableProjectManagerRole: {
      default: false,
      description: "Projektleder kan vælges som rolle",
      type: "boolean",
    },
    enableProjects: {default: false, description: "Projekter er aktiveret", type: "boolean"},
    enableProjectTravelTime: {
      default: false,
      description:
        'Kolonnen "Rejsetid" vises og der kan angives  det antal minutter, som der skal afregnes til medarbejderne ved transport til projektet.',
      type: "boolean",
    },
    enableRecentlyUsedProducts: {
      default: true,
      description: "Senest anvendte produkter vises i toppen af valgdialogen",
      type: "boolean",
    },
    enableReportGenerator: {
      default: false,
      description: "Rapporter er aktiveret med opgave statistik",
      type: "boolean",
    },
    enableRouteJournal: {
      default: false,
      description: "Rutejournal tilgængelig som fane",
      type: "boolean",
    },
    enableSmallMachines: {default: false, description: "Aktivérer småmaskiner", type: "boolean"},
    enableStandardMachinesSettingsPage: {
      default: false,
      description: 'Fanen "Favoritmaskiner" vises under [indstillnger].',
      type: "boolean",
    },
    enableStandardProductsSettingsPage: {
      default: false,
      description: 'Fanen "Favorivarer" vises under [indstillnger].',
      type: "boolean",
    },
    enableTaskEstimation: {
      default: true,
      description: '"Forventet varighed" vises for Admin',
      type: "boolean",
    },
    enableTaskInternalManagerNotes: {
      default: false,
      description: 'Vis feltet "Interne noter" på opgaver, kun synlig for administratorer.',
      type: "boolean",
    },
    enableTaskLogLocationChange: {
      default: true,
      description:
        "Gør det muligt at ændre i lokationer sat på log på opgave. Denne setting slås normalt kun fra ved kunder hvor f.eks. opgaver udelukkende oprettes i NAV og kun opgaveudførsel sker i CustomOffice.",
      type: "boolean",
    },
    enableTaskReferenceNumber: {
      default: false,
      description: "Aktivering af referencenummer for opgaver.",
      type: "boolean",
    },
    enableTimeOverviewPage: {
      default: true,
      description: "Min dagsseddel er aktiveret",
      type: "boolean",
    },
    enableTransferTasks: {
      default: false,
      description: 'Overfør automatisk "ikke påbegyndte" opgaver til næste dag',
      type: "boolean",
    },
    enableTransportLog: {
      default: false,
      description: "Aktivér transportlog (gammel)",
      type: "boolean",
    },
    enableTransportLogStatistics: {
      default: false,
      description: "Aktivér transportlog statistik siden under kunder (gammel)",
      type: "boolean",
    },
    enableTransportReport: {
      default: false,
      description: "Transportrapporten bliver tilgængelig som ny fane",
      type: "boolean",
    },
    enableUnitSettingsPage: {default: false, description: 'Fanen "Enheder" vises', type: "boolean"},
    enableUnscheduledCalendar: {
      default: true,
      description: "Kundeopgaverne uden planlagt dato/medarbejder, vises  over kalenderen",
      type: "boolean",
    },
    enableWorkshopChecklistsFor: {
      default: [],
      description: "Aktivering af tjekliste på værkstedsopgaver",
      items: {type: "string"},
      type: "array",
    },
    enableWorkTypePriceStructureOverviewSettingsPage: {
      default: false,
      description: 'Fanen "OVERSIGT" vises og område/prisstruktur kan opsættes',
      type: "boolean",
    },
    enableWorkTypeSettingsPage: {
      default: false,
      description: 'Fanen "OMRÅDER" vises, hvor man kan redigerer alle områder',
      type: "boolean",
    },
    errorOnOnlyPuller: {
      default: true,
      description: 'Advarsel vises: "Maskinen ikke er selvkørende" (det er en trækker)',
      type: "boolean",
    },
    expectedAmountEnabled: {
      default: false,
      description: "Områdestatus er aktiveret",
      type: "boolean",
    },
    expectedAmountVisibleToMachineOperators: {
      default: false,
      description: "Områdestatus vises for medarbejderne",
      type: "boolean",
    },
    externalTaskCulture: {
      default: false,
      description: 'Menuen "Kulturer" vises - og kulturopgaver kan oprettes/redigeres',
      type: "boolean",
    },
    externalTaskCustomer: {default: true, description: "Kunder er aktiveret", type: "boolean"},
    externalTaskWithoutPriceLinesLegal: {
      default: false,
      description: "Kundeopgaver uden prislinjer er tilladt",
      type: "boolean",
    },
    externalTaskWithoutTaskWorkTypeVariantLegal: {
      default: false,
      description:
        "Kunde-opgaver må slippe igennem selvom der ikke er en variant angivet. Dette giver mening for kunder der bruger arbejdsområder som overskrifter/tags, men bruger maskin-varianter til at styre prissæt.",
      type: "boolean",
    },
    extraWorkButtonCopiesProject: {
      default: false,
      description: "Kopier også opgavens projektnr. med over, når ekstra arbejde knappen anvendes",
      type: "boolean",
    },
    fieldDefaultLocationType: {
      default: null,
      description: "Lokationstype-ID på lokationstypen der anvendes til marker",
      type: ["string", "null"],
    },
    fieldDialogOrFiltering: {
      default: false,
      description:
        'Filtrer med "eller" mellem tekster i markvalgdialog, så man f.eks. kan finde flere marker ved at taste flere marknumre. Standard er "og" så man f.eks. kan skrive et kundenavn og en afgrøde og få noget fornuftigt.',
      type: "boolean",
    },
    foodBookingNotificationTime: {
      anyOf: [
        {
          items: {$ref: "#/definitions/nullableStringPair"},
          maxItems: 7,
          minItems: 7,
          type: "array",
        },
        {type: "null"},
      ],
      default: null,
      description:
        "Intervallet angiver hvornår notifikationer om madbestilling sendes ud, hvis der er en opgave på dagen.",
    },
    fuelSurcharge: {
      default: null,
      description:
        "Aktivér brændstoftillæg? Der kan vælges mellem Salgspristillæg (PRICE_PERCENT) og Litertillæg (KR_PERLITER)",
      enum: [null, "PRICE_PERCENT", "KR_PER_LITER"],
    },
    fullscreenCalendarSlideshow: {
      default: true,
      description:
        "Hvis medarbejderkalenderen vises i fullscreen mode, så skifter den automatisk til næste side, såfremt antallet af medarbejdere overstiger skærmens bredde",
      type: "boolean",
    },
    fullscreenCalendarSlideshowSeconds: {
      default: 120,
      description: "Tid mellem skift af skærmvisninger i fullscreen mode. (sek.)",
      type: "integer",
    },
    geolocation: {
      additionalProperties: false,
      default: {
        activeMarkerWarningThresholdInMinutes: 60,
        enabled: true,
        homeMarkerLatitude: 0,
        homeMarkerLongitude: 0,
        initialPositionLatitude: 0,
        initialPositionLongitude: 0,
        initialZoom: 12,
        registerPositionOnTimerClick: true,
      },
      description: "Opsætning af kort/gps ( herunder aktivering af kortmenupunkt)",
      properties: {
        activeMarkerWarningThresholdInMinutes: {default: 60, type: "number"},
        enabled: {default: true, type: "boolean"},
        homeMarkerLatitude: {type: "number"},
        homeMarkerLongitude: {type: "number"},
        initialPositionLatitude: {type: "number"},
        initialPositionLongitude: {type: "number"},
        initialZoom: {default: 12, type: "number"},
        registerPositionOnTimerClick: {default: true, type: "boolean"},
      },
      required: [
        "activeMarkerWarningThresholdInMinutes",
        "enabled",
        "homeMarkerLatitude",
        "homeMarkerLongitude",
        "initialPositionLatitude",
        "initialPositionLongitude",
        "initialZoom",
        "registerPositionOnTimerClick",
      ],
      type: "object",
    },
    googleMapsAPIKey: {default: "", description: "Standard token for  google API", type: "string"},
    googleMapsCountryRestrictions: {
      default: ["dk"],
      description: "Hvilke lande der skal søges i ifm oprettelse af nyt sted",
      items: {type: "string"},
      type: "array",
    },
    hideCompleteAndMakeCopyButton: {
      default: false,
      description: '"Afslut og viderefør"-knappen skjules',
      type: "boolean",
    },
    hideCultureMenuFromMachineOperators: {
      default: false,
      description: 'Menuen "Kulturer" skjules for medarbejderne',
      type: "boolean",
    },
    hideCustomerMenuFromMachineOperators: {
      default: false,
      description: "Kundemodulet er skjult for medarbejderne",
      type: "boolean",
    },
    hideInternalTaskListWorkTypes: {
      default: [],
      description: "Nedenstående områder skjules",
      items: {type: "string"},
      type: "array",
    },
    hideOrderCalendarTab: {
      default: false,
      description: "Ordrekalenderen skjules for medarbejderen",
      type: "boolean",
    },
    hidePrimaryTimeButtonForExternalWorktypes: {
      default: [],
      description: 'Skjul "Effektiv tid" tidsknappen for følgende arbejdsområder',
      items: {type: "string"},
      type: "array",
    },
    hidePrimaryTimeButtonForInternalWorktypes: {
      default: [],
      description: 'Skjul "Intern" tidsknappen for følgende arbejdsområder',
      items: {type: "string"},
      type: "array",
    },
    hideTasklistBillingTab: {
      default: false,
      description: 'Fanen "Fakturering" skjules',
      type: "boolean",
    },
    hideUnscheduledCalendarForMachineOperators: {
      default: false,
      description: "Medarbejderne kan ikke se listen af opgaver uden dato over kalenderen",
      type: "boolean",
    },
    hideUnscheduledWorkshopTasks: {
      default: false,
      description: "Ikke planlagte værkstedsopgaver skjules i medarbejderkalenderen",
      type: "boolean",
    },
    holidaysVisibleToMachineOperators: {
      default: false,
      description: "Medarbejderne kan se helligdage i kalenderen (***BØR VÆRE FAST SETTING***)",
      type: "boolean",
    },
    hoursAbsenceTypes: {
      default: ["compensatory"],
      description: "Timebaseret fravær",
      items: {type: "string"},
      type: "array",
    },
    ifCountZeroRequireCorrectedCountZero: {
      default: false,
      description:
        "Kræv at administrator taster 0 i faktureringsantal for at den kan overføres med 0 i materialer.",
      type: "boolean",
    },
    importAllFieldsPerVatNumber: {
      default: false,
      description:
        "Hvis slået til så vil opdatering af alle marker medføre at alle marker tilhørende hvert CVR-nr. der findes (for allerede importerede marker) automatisk også blive importeret. Dvs. hvis der på en kunde kun er importeret en enkelt af deres marker og man slår denne setting til og kører en markopdatering så importeres alle andre marker tilhørende CVR-nummeret for den éne mark. Ikke nødvendigvis hvad en kunde ønsker hvis de nøjsomt har udvalgte specifikke marker til import.",
      type: "boolean",
    },
    importExternalWorkTypesFromProductGroups: {
      default: false,
      description: "Importer eksterne arbejdsområder fra udvalgte produktgrupper",
      type: "boolean",
    },
    importInternalWorkTypesFromProducts: {
      default: false,
      description: "Importer interne arbejdsområder fra udvalgte produkter",
      type: "boolean",
    },
    includeTaskCancel: {
      default: false,
      description: '"Afslut som aflyst" knap vises, som konvertere opgaven til intern opgave',
      type: "boolean",
    },
    includeTaskCopy: {
      default: true,
      description: '"Opret kopi" knap vises, som gør det muligt at kopiere opgaven',
      type: "boolean",
    },
    includeTransportlogOverviewVariant: {
      default: false,
      description: 'Inkluder "overblik" (uden log) PDF-variant i transportlog-download-liste',
      type: "boolean",
    },
    industries: {
      default: [],
      description: "Hvilke brancher anvender kunden CustomOffice til",
      items: {
        enum: [
          "AGRICULTURAL_CONTRACTOR",
          "HAULIER",
          "CONTRACTOR",
          "LANDSCAPE_GARDENER",
          "FOREST_CONTRACTOR",
          "WORKSHOP",
          "SEWER_CONTRACTOR",
          "MARKET_GARDENER",
          "AGRICULTURE",
        ],
      },
      type: "array",
      uniqueItems: true,
    },
    informationPageEnabled: {
      default: true,
      description: "Informations/opslags-side er aktiveret",
      type: "boolean",
    },
    internalTaskAutoWorkTypePreviousMachines: {
      default: false,
      description: "Indsætter automatisk sidste valgte maskine, på Interne opgaver",
      type: "boolean",
    },
    internalTaskPhotos: {
      default: false,
      description: "Foto-fanen på interne opgaver er aktiv",
      type: "boolean",
    },
    internalTimeLabel: {default: "Intern", description: "internalTimeLabel", type: "string"},
    invoiceAddGenericTransportLogs: {
      $comment: "economicShowGenericTransportlogOnInvoice",
      default: [],
      description: "Tilføj valgte transport logs",
      items: {type: "string"},
      type: "array",
    },
    invoiceAddTransportLogSum: {
      $comment: "economicShowTransportlogOnInvoice",
      default: true,
      description: "Tilføj summerede transport logs på faktura",
      type: "boolean",
    },
    invoiceAllowRecordedWithoutInvoiceLines: {
      default: false,
      description:
        'Tillad "overførsel" af kundeopgaver som ingen fakturalinjer danner, givet at værdier er "korrigeret" til 0.',
      type: "boolean",
    },
    invoiceExcludeProducts: {
      $comment: "productsExcludedFromInvoice",
      default: [],
      description: "Ekskluder produkter fra faktura",
      items: {type: "string"},
      type: "array",
    },
    invoiceExtraEffectiveTimerIdentifiers: {
      $comment: "timersContributingToEffectiveTime",
      default: [],
      description:
        "Liste af timer-ID på de ekstra timere der skal tælle (og faktureres) som effektiv tid på opgaven.",
      items: {type: "string"},
      type: "array",
    },
    invoiceNoteAddAdminNotes: {
      $comment: "transferOrderAdminNotesToInvoice",
      default: false,
      description: 'Overfør "Ordrens interne administrator noter" til fakturanoter (Interne noter)',
      type: "boolean",
    },
    invoiceNoteAddCustomerContactName: {
      $comment: "economicShowCustomerContactName",
      default: false,
      description:
        'Overfør "kontaktpersonens fulde navn" til fakturanoter (Default præfix: Kontakt…)',
      type: "boolean",
    },
    invoiceNoteAddCustomerDetailsOnTransportLog: {
      $comment: "economicCustomerDetailsOnTransportlogOnInvoice",
      default: false,
      description: "Navn, adresse, postnr. by, cvrnr skrives ved hvert sted på transportlog",
      type: "boolean",
    },
    invoiceNoteAddEmployeeAlias: {
      $comment: "economicShowMachineOperatorAlias",
      default: false,
      description:
        'Overfør "Medarbejderens initialer" til fakturanoter. (Default præfix: Udført af…)',
      type: "boolean",
    },
    invoiceNoteAddEmployeeName: {
      $comment: "economicShowMachineOperatorName",
      default: false,
      description:
        'Overfør "Medarbejderens fulde navn" til fakturanoter. (Default præfix: Udført af…)',
      type: "boolean",
    },
    invoiceNoteAddEmployeeNotes: {
      $comment: "economicInvoiceEmployeeNotes",
      default: true,
      description: 'Overfør "Opgavens medarbejder noter" til fakturanoter (Noter, opgave)',
      type: "boolean",
    },
    invoiceNoteAddFieldUse: {
      $comment: "economicShowFieldUse",
      default: false,
      description:
        'Overfør "Opgavens marknumre" til fakturanoter - hver mark adskilt af et komma. Kan kombineres med navnet på markens afgrøde (invoiceNoteAddFieldUse)',
      type: "boolean",
    },
    invoiceNoteAddFieldUseCrop: {
      $comment: "economicShowFieldUseCrop",
      default: false,
      description:
        'Overfør "Afgrødenavnet på opgavens valgte marker" til fakturanoter. Kan kombineres med marknumre (invoiceNoteAddFieldUse)',
      type: "boolean",
    },
    invoiceNoteAddMachineName: {
      $comment: "economicShowMachineName",
      default: false,
      description: 'Overfør "Opgavens anvendte maskiner" til fakturanoter',
      type: "boolean",
    },
    invoiceNoteAddManagerNotes: {
      $comment: "economicInvoiceManagerNotes",
      default: false,
      description:
        'Overfør "Opgavens synlige administrator noter" til fakturanoter (Noter fra administrator, opgave)',
      type: "boolean",
    },
    invoiceNoteAddOrderManagerNotes: {
      $comment: "transferOrderNotesToInvoice",
      default: false,
      description:
        'Overfør "Ordrens synlige administrator note til medarbejdere" til fakturanoter (Noter til medarbejdere, ordre)',
      type: "boolean",
    },
    invoiceNoteAddOrderReferenceNumber: {
      $comment: "economicShowOrderReferenceNumber",
      default: false,
      description: 'Overfør "Ordrens referencenummer" til fakturanoter',
      type: "boolean",
    },
    invoiceNoteAddPickupLocation: {
      $comment: "economicShowPickupLocation",
      default: false,
      description: 'Overfør "Opgavens afhentningssted" til fakturanoter (Præfix: Afhentning)',
      type: "boolean",
    },
    invoiceNoteAddRouteTaskStartStop: {
      $comment: "economicRouteTaskStartStopOnInvoice",
      default: false,
      description: 'Overfør "Rutens hh:mm - hh:mm" start-stop klokkeslæt til fakturanoter',
      type: "boolean",
    },
    invoiceNoteAddTaskReferenceNumber: {
      $comment: "economicShowTaskReferenceNumber",
      default: false,
      description: 'Overfør "Opgavens referencenummer" til fakturanoter',
      type: "boolean",
    },
    invoiceNoteAddTotalTaskTime: {
      $comment: "economicInvoiceIncludeTotalTaskTime",
      default: false,
      description:
        'Overfør "Samlet arbejdstid" til fakturanoter (information til kunden, så de ved hvor meget tid man har brugt på dem)',
      type: "boolean",
    },
    invoiceNoteAddWorkPlaceOrDeliveryLocation: {
      $comment: "economicShowWorkPlaceOrDeliveryLocation",
      default: false,
      description:
        'Overfør "Opgavens arbejdssted/leveringssted" til fakturanoter (Præfix: Arbejdssted/levering)',
      type: "boolean",
    },
    invoiceNotePrefixCustomerContactNameWith: {
      $comment: "economicPrefixCustomerContactNameWith",
      default: "Kontakt",
      description:
        "Når kontaktpersonens fulde navn overføres til fakturanoter, så sættes denne tekst foran (Default: Kontakt…)",
      type: "string",
    },
    invoiceNotePrefixEmployeeNameOrAliasWith: {
      $comment: "economicPrefixMachineOperatorNameOrAliasWith",
      default: "Udført af",
      description:
        "Når medarbejderens navn/initialer overføres til fakturanoter, så sættes denne tekst foran (Default: Udført af…)",
      type: "string",
    },
    invoicesAddTaskNotesToEffectiveTimeLine: {
      default: true,
      description: "Opgave noter tilføjes på primær faktureringslinje",
      type: "boolean",
    },
    invoiceSplitBy: {
      default: "NONE",
      description: "Opdeling af faktura",
      enum: ["NONE", "PROJECT", "ORDER_REFERENCE", "ORDER_WORKPLACE", "TASK_WORKPLACE"],
      type: "string",
    },
    invoiceSubtractDowntimeTimers: {
      $comment: "economicSubtractDowntimeIdentifiers",
      default: [],
      description:
        "Ved overførsel laves der en ekstra negativ fakturalinje med driftsstop, så driftsstopstid fremgår som rabat (dvs. tid kunden ikke skal betale for).",
      items: {type: "string"},
      type: "array",
    },
    invoiceTaskNotesToEffectiveTimeLine: {
      $comment: "invoicesAddTaskNotesToEffectiveTimeLine",
      default: true,
      description:
        "Fakturanoter tilføjes den primære (første) faktureringslinje. Alternativt tilføjes noterne til en tom linje som den sidste linje til opgaven",
      type: "boolean",
    },
    invoiceUseWorkplaceAddressAsName: {
      $comment: "economicInvoiceWorkplaceAddressReplaceName",
      default: true,
      description: "Adresse bruges som navn på faktura",
      type: "boolean",
    },
    jobberRoleCanCreateInternalTasks: {
      default: false,
      description: '"Løsarbejder" rollen kan oprette interne opgaver',
      type: "boolean",
    },
    locationCrossCustomerSelectionEnabled: {
      default: false,
      description: "Lokationer kan bruges på opgaver, på tværs af kunder (globale arbejdssteder)",
      type: "boolean",
    },
    locationFavoritesEnabled: {
      default: false,
      description: "Lokationer kan afmærkes som favoritter",
      type: "boolean",
    },
    locationStorageJournalStatusColumn: {
      default: false,
      description: "Vis lagerstand kolonnen på lagerjournalen",
      type: "boolean",
    },
    locationStoragePageVisibleToEveryone: {
      default: false,
      description: "Lagersiden er tilgængelig for alle",
      type: "boolean",
    },
    logButtonText: {
      default: "NAME",
      description:
        'Bestemmer hvilken tekst der skal stå på logknapper. "NAME", "ADDRESS", "NAME_AND_ADDRESS" (gammel?)',
      enum: ["NAME", "ADDRESS", "NAME_AND_ADDRESS", "NAME_AND_ADRESS_AND_ZIPCODE_AND_CITY"],
      type: "string",
    },
    logDeliveryButtonDisplayCount: {
      default: false,
      description:
        'Vis antal log-registreringer for stedet på "levering"-knap (når der er mindst en)',
      type: "boolean",
    },
    logEmailText: {
      default: "",
      description: "Text der skal stå i stedet for standard teksten i log e-mails",
      type: "string",
    },
    logPickupButtonDisplayCount: {
      default: false,
      description:
        'Vis antal log-registreringer for stedet på "afhentning"-knap (når der er mindst en)',
      type: "boolean",
    },
    logRecipients: {
      default: [],
      description: "Mailadresser som skal modtage log",
      items: {type: "string"},
      type: "array",
    },
    logWorkplaceDisplayCount: {
      default: false,
      description:
        'Vis antal log-registreringer for stedet på "arbejdssted"-knap (når der er mindst en)',
      type: "boolean",
    },
    lunchBookingLocations: {
      default: [],
      description: "Visning af mulig frokost lokation",
      items: {type: "string"},
      type: "array",
    },
    lunchBookings: {default: false, description: "Frokostbestilling er aktiveret", type: "boolean"},
    machineAnalysisAccounts: {
      additionalProperties: {enum: ["TURNOVER", "DIRECT_COSTS", "INDIRECT_COSTS"]},
      default: {},
      description:
        "Opsætning af hvilke omsætnings-/omkostningskonti der skal udlæses fra e-conomic til brug i maskinanalysen",
      type: "object",
    },
    machineBeforeMachineOperator: {
      default: false,
      description:
        '"Tilføj maskine"-knappen flyttes før "Vælg medarbejder"-knappen, på siden for oprettelse af en opgave',
      type: "boolean",
    },
    machineCalendar: {default: false, description: "Aktivering af maskinkalender", type: "boolean"},
    machineCalendarForMachineOperators: {
      default: false,
      description: "Maskinkalenderen vises også til medarbejderne",
      type: "boolean",
    },
    machineExtraTimers: {
      additionalProperties: {items: {type: "string"}, type: "array"},
      default: {},
      description:
        'Vis ekstra "effektiv tid"-variant-timer-knapper for specifikke maskiner; specificeret på formen {maskine-ID: [timer-ID, ...], ...}',
      type: "object",
    },
    machineLabelVariant: {
      default: "MACHINE",
      description: 'Maskiner betegnes som "maskine" eller "køretøj"',
      enum: ["MACHINE", "VEHICLE"],
    },
    machineOperaterCanEditInvoiceNote: {
      default: false,
      description:
        "Medarbejderen kan se og redigere fakturanote feltet (OBS: Bruges ofte til at skjule priser, så ikke alle vil have den synlig)",
      type: "boolean",
    },
    machineOperatorCanCreateCustomerTask: {
      default: true,
      description: "Medarbejdere kan oprette kundeopgaver",
      type: "boolean",
    },
    machineOperatorCanDeleteAssignedTasks: {
      default: true,
      description:
        "Medarbejderen må slette opgaver han er tildelt, selvom det er en Admin der har oprettet dem",
      type: "boolean",
    },
    machineOperatorMayAddTaskPhotosAfterCompletion: {
      default: false,
      description:
        "Medarbejderen kan tiføje billeder til opgaver, også efter de er markeret udført (men ikke godkendt)",
      type: "boolean",
    },
    machineOperatorsCanChooseProject: {
      default: false,
      description: "Medarbejderen kan vælge projekt",
      type: "boolean",
    },
    machineOperatorsCanOnlySeeTheirOwnTasks: {
      default: false,
      description: "Medarbejderen må ikke kunne se andres opgaver",
      type: "boolean",
    },
    machineOperatorsCanOnlySeeThemselvesOnCalendar: {
      default: false,
      description: "Medarbejdere kan kun se deres egen kalenderkolonne",
      type: "boolean",
    },
    machineOperatorsCanSeeAbsenceCalendar: {
      default: false,
      description: "Medarbejderen kan se ferie- & fraværskalender",
      type: "boolean",
    },
    machineOperatorsCanSeeTheirOwnTasksAndActiveTasks: {
      default: false,
      description:
        "Medarbejderen kan kun se kollegers aktive opgaver, derudover kan de se deres egne opgaver",
      type: "boolean",
    },
    machinePriceGroupExtraTimers: {
      additionalProperties: {
        additionalProperties: {$ref: "#/definitions/stringArray"},
        type: "object",
      },
      default: {},
      description:
        'Vis ekstra "effektiv tid"-variant-timer-knapper for specifikke maskine/prisgruppe-kombinationer; specificeret på formen {maskine-ID: {prisgruppe-ID: [timer-ID, ...], ...}, ...}',
      type: "object",
    },
    managerMenuTasksAfterEmployees: {
      default: false,
      description: "Menupunktet opgaver vises for administratorer efter medarbejdere",
      type: "boolean",
    },
    mapTaskFormat: {
      default: "<DAY>.<MONTH> - <EMPLOYEE ALIAS>: <WORK TYPE NAME>",
      description:
        'Hvordan menupunkterne i popup-menuen på en aktiv opgave skrives. F.eks. for en opgave med område 108 Gylleudbringning til medarbejderen CR, d. 2/4 blive de 2 formater hhv. vise "2/4 - CR: Gylleudbringning" og "CR, 108: Gylleudbringning"',
      enum: [
        "<DAY>.<MONTH> - <EMPLOYEE ALIAS>: <WORK TYPE NAME>",
        "<EMPLOYEE ALIAS>, <WORK TYPE NUMBER>: <WORK TYPE NAME>",
      ],
    },
    mapVisibleToAll: {
      default: false,
      description:
        "Kortet kan ses af alle medarbejdere, undtagen løsarbejdere (admin kan altid se kort)",
      type: "boolean",
    },
    mapVisibleToJobberRole: {
      default: true,
      description: "Kortet kan også ses af løsarbejdere",
      type: "boolean",
    },
    materialDecimals: {
      default: 2,
      description: "Antal decimaler på produkter under tilføj materialer",
      maximum: 5,
      minimum: 0,
      type: "integer",
    },
    materialIssuesErrors: {
      default: true,
      description: "Kræv indtastning af materiel ellers kan opgaven ikke afleveres",
      type: "boolean",
    },
    materialUseAlternativeText: {
      default: false,
      description: "Alternativ tekst for Material",
      type: "boolean",
    },
    materialZeroCausesError: {
      default: [],
      description: "Prislinietyper der ikke må være udfyldt med '0' i materiel",
      items: {type: "integer"},
      type: "array",
    },
    missingEffectiveTimeIsError: {
      default: true,
      description:
        'Når en opgave ingen "effektiv tid" har udløser det en fejl, og ikke en blot en advarsel',
      type: "boolean",
    },
    navImportCopyCustomerAccountToPhone: {
      default: false,
      description:
        "Når kunder importeres fra NAV så kopieres deres kontonummer over som kundens telefonnummer.",
      type: "boolean",
    },
    navImportProjects: {
      default: false,
      description: "Importer projekter fra NAV er aktiveret",
      type: "boolean",
    },
    navSync: {default: false, description: "NAV er aktiveret", type: "boolean"},
    navSyncProfile: {
      default: null,
      description: "Hvilken NAV synk implementation der anvendes.",
      enum: ["dme", "maanssons", "vredodanmark", "hvt", "rosgaard", "bejstrupm", null],
    },
    navSyncUploadEnabled: {
      default: false,
      description: '"Overfør til nav" knappen vises.',
      type: "boolean",
    },
    navTimersToTransferWithoutMachines: {
      default: [],
      description:
        "Tid på disse timere overføres til DME NAV-dagseddel, men uden maskine fra opgaven.",
      items: {type: "string"},
      type: "array",
    },
    navTransferEmployeeNotes: {
      default: true,
      description: "Medarbejder noter tilføjes på DMENAV dagseddelnoter",
      type: "boolean",
    },
    navVariantTypeImports: {
      default: [],
      description:
        "Om område data fra DME NAV skal importeres som område-orienteret eller maskin-orienteret, eller begge dele",
      items: {enum: ["WORKTYPE_VARIANT", "MACHINE_VARIANT"]},
      type: "array",
    },
    navWorktypeRanges: {
      anyOf: [{items: {$ref: "#/definitions/stringPair"}, type: "array"}, {type: "null"}],
      default: null,
      description: "Områder i disse intervaller bliver synkroniseret med CustomOffice.",
    },
    noExternalTaskWorkType: {
      default: false,
      description: "Der anvendes kun maskiner og ikke områder på opgaver",
      type: "boolean",
    },
    noProducts: {
      default: false,
      description: 'Skjul "Tilføj materiel" på kundeopgaver',
      type: "boolean",
    },
    numberOfSalaryVouchersVisibleToEmployees: {
      default: null,
      description: "Antallet af lønrapporter medarbejderne kan se tilbage i tiden",
      type: ["number", "null"],
    },
    oldTaskWarningAgeMinutes: {
      default: 1440,
      description:
        'Medarbejderen får en advarsel "Du har en gammel ikke fuldført opgave"  når der er gået mere end x minutter siden sidste opgave',
      type: ["number", "null"],
    },
    oneDayLessVisibleToMachineOperatorsBefore: {
      default: "00:00",
      description: "Efter dette tidspunkt, kan medarbejderen se opgaverne til i morgen",
      type: "string",
    },
    onlyAdminCanChangeDepartment: {
      default: false,
      description: "Kun admins kan vælge afdeling, medarbejdere kan ikke.",
      type: "boolean",
    },
    onlyAllowEditingNameOnPriceGroupSettingsPage: {
      default: false,
      description: "Kun variantnavn kan rettes",
      type: "boolean",
    },
    onlyEnableProjectsForDepartments: {
      default: [],
      description: "Angivelse af projekter kun mulig for nedenstående afdelinger",
      items: {type: "string"},
      type: "array",
    },
    onlyManagerCreatedTasksCalendarPlanned: {
      default: false,
      description:
        'Planlagt opgave vises kun som "planlagte opgave-blok" i kalender hvis brugeren som oprettede opgaven er administrator. (OBS: Senior Maskinfører betragtes ikke som administrator)',
      type: "boolean",
    },
    onlyManagersCanRegisterAbsence: {
      default: false,
      description: "Kun administration kan registrere fravær",
      type: "boolean",
    },
    onlyShowCalledInDialogForWorktypes: {
      default: [],
      description:
        "Begrænser hvornår tilkaldsdialogen vises. Spørg kun om opgaven er en tilkaldeopgave for de angivede områder.",
      items: {type: "string"},
      type: "array",
    },
    onlyWeekdaysAllowedAbsenceTypes: {
      default: [],
      description: "Liste af fraværstype der kun er tilladt på hverdage",
      items: {type: "string"},
      type: "array",
    },
    orderCalendar: {default: true, description: "Ordrekalender er aktiveret", type: "boolean"},
    orderCalendarAsTaskCalendar: {
      default: false,
      description:
        "Opgavekalender aktiveret (OBS: Må kun anvendes så længe kunden ikke har aktiveret Ordre)",
      type: "boolean",
    },
    orderCalendarAsTaskCalendarBlockExtraInfo: {
      default: "phone",
      description: "Vælg hvad der skal vises i opgaveblokken, til højre for kundenavn",
      enum: ["phone", "projectNumber", "orderReferenceNumber", null],
    },
    orderCalendarBlockExtraInfo: {
      default: "phone",
      description: "Vælg hvad der skal vises i ordreblokken, til højre for kundenavn",
      enum: ["phone", "orderReferenceNumber", "employeeAlias", null],
    },
    orderCalendarFoldAllDefault: {
      default: false,
      description: "Ordre skal som være sammenfoldet som standard",
      type: "boolean",
    },
    orderCalendarMachineLinebreak: {
      default: false,
      description: "Maskinlinjer ombrydes visuelt på ordre-kortet med linjeskift",
      type: "boolean",
    },
    orderCalendarShowCreatedDate: {
      default: true,
      description: "Oprettelsesdato vises i bunden af ordrekortet",
      type: "boolean",
    },
    orderCalendarShowOrderNoteLines: {
      default: 0,
      description: 'Begræns visning af antal linier fra "Noter til medarbejdere"',
      type: "integer",
    },
    orderCopyFields: {
      default: ["customer", "draft"],
      description:
        "Definerering af hvilke felter der skal kopieres når der laves en kopi af en ordre",
      items: {type: "string"},
      type: "array",
    },
    orderDrafts: {
      default: false,
      description: "Ordre kladder er aktiveret (KLADDE fane vises)",
      type: "boolean",
    },
    orderDraftsAllwaysCreateDraft: {
      default: true,
      description: "Hvis ordrekladde er aktiv, lav altid nye ordre som kladde",
      type: "boolean",
    },
    orderDraftsStartOnDraft: {
      default: true,
      description: "Hvis orderkladde er aktiv, start altid på KLADDE fanen",
      type: "boolean",
    },
    orderEntryAutoOpenCustomerSelection: {
      default: false,
      description: "Der spørges automatisk efter kunde, når ny ordre oprettes",
      type: "boolean",
    },
    orderEntryResultsEffective: {
      default: false,
      description: "Effektiv tid medtages i resultat-summen",
      type: "boolean",
    },
    orderEntryResultsWorkplace: {
      default: true,
      description: "Arbejdssteds kolonne vises i resultat-summen",
      type: "boolean",
    },
    orderEntryShowContact: {
      default: true,
      description: "Kontaktpersons oplysninger kan tilføjes på ordren",
      type: "boolean",
    },
    orderEntryShowPriceGroup: {
      default: true,
      description: "Kolonnen Variant vises på opgavetabellen",
      type: "boolean",
    },
    orderEntryShowResults: {
      default: true,
      description: "Resultat-sum vises i bunden af ordren",
      type: "boolean",
    },
    orderEntryShowWorkPlace: {
      default: true,
      description: "Arbejdsstedets oplysninger ved oprettelse",
      type: "boolean",
    },
    orderEntryTaskTableAllwaysShowMachinePriceGroups: {
      default: false,
      description:
        "Vis altid maskinernes varianter, aldrig områdets, på opgavelisten på ordresiden",
      type: "boolean",
    },
    orderEntryTaskTime: {
      default: false,
      description: "Starttidspunkt for ordren (overføres til opgaver)",
      type: "boolean",
    },
    orderListColumns: {
      additionalProperties: false,
      default: {
        archive: {
          employee: {
            desktop: ["merge", "note", "workType", "employees", "date"],
            mobile: ["customerShort", "workTypeShort", "dateShort"],
            tablet: ["merge", "note", "workType", "employees", "date"],
          },
          manager: {
            desktop: ["merge", "note", "workType", "employees", "date", "status"],
            mobile: ["customerShort", "workTypeShort", "dateShort"],
            tablet: ["merge", "note", "workType", "employees", "date", "status"],
          },
        },
        drafts: {
          employee: {
            desktop: ["merge", "note", "workType", "employees", "date"],
            mobile: ["customerShort", "workTypeShort", "dateShort"],
            tablet: ["merge", "note", "workType", "employees", "date"],
          },
          manager: {
            desktop: ["merge", "note", "workType", "employees", "date", "status"],
            mobile: ["customerShort", "workTypeShort", "dateShort"],
            tablet: ["merge", "note", "workType", "employees", "date", "status"],
          },
        },
        open: {
          employee: {
            desktop: ["merge", "note", "workType", "employees", "date"],
            mobile: ["customerShort", "workTypeShort", "dateShort"],
            tablet: ["merge", "note", "workType", "employees", "date"],
          },
          manager: {
            desktop: ["merge", "note", "workType", "employees", "date", "status"],
            mobile: ["customerShort", "workTypeShort", "dateShort"],
            tablet: ["merge", "note", "workType", "employees", "date", "status"],
          },
        },
        readyForBilling: {
          employee: {
            desktop: ["merge", "note", "workType", "employees", "date"],
            mobile: ["customerShort", "workTypeShort", "dateShort"],
            tablet: ["merge", "note", "workType", "employees", "date"],
          },
          manager: {
            desktop: ["merge", "note", "workType", "employees", "date", "status"],
            mobile: ["customerShort", "workTypeShort", "dateShort"],
            tablet: ["merge", "note", "workType", "employees", "date", "status"],
          },
        },
        validation: {
          employee: {
            desktop: ["merge", "note", "workType", "employees", "date"],
            mobile: ["customerShort", "workTypeShort", "dateShort"],
            tablet: ["merge", "note", "workType", "employees", "date"],
          },
          manager: {
            desktop: ["merge", "note", "workType", "employees", "date", "status"],
            mobile: ["customerShort", "workTypeShort", "dateShort"],
            tablet: ["merge", "note", "workType", "employees", "date", "status"],
          },
        },
      },
      description: "Kolonneopsætning for ordrelisten",
      properties: {
        archive: {$ref: "#/definitions/orderListColumnsUserTypesSpecification"},
        drafts: {$ref: "#/definitions/orderListColumnsUserTypesSpecification"},
        open: {$ref: "#/definitions/orderListColumnsUserTypesSpecification"},
        readyForBilling: {$ref: "#/definitions/orderListColumnsUserTypesSpecification"},
        validation: {$ref: "#/definitions/orderListColumnsUserTypesSpecification"},
      },
      required: ["drafts", "open", "validation", "readyForBilling", "archive"],
      type: "object",
    },
    orderListNoteLines: {
      default: 0,
      description: "Antal af notelinjer der vises på liste siden (0 viser alt)",
      type: "integer",
    },
    orderListPageEnabled: {
      default: true,
      description: '"Ordre" vises i menuen og ordreliste er aktiveret',
      type: "boolean",
    },
    orderReferenceNumberLabel: {
      default: null,
      description: "Label for referencenummer-felt",
      type: ["string", "null"],
    },
    orderShowCustomerFiles: {
      default: true,
      description: 'Vis "Filer, kunder" på ordresiden',
      type: "boolean",
    },
    orderShowOrderFiles: {
      default: true,
      description: 'Vis "Filer, ordre" på ordresiden',
      type: "boolean",
    },
    orderTaskTableSort: {
      default: "PRIORITY, CREATED",
      description:
        'Definerer hvordan opgaverne i opgavetabellen på ordresiden sorteres. Sorteringsmuligheder: "PRIORITY, CREATED": Rækkefølge/prioritet ud fra drag\'n\'drop handling på ordrekalenderen, dernæst efter oprettelsestidspunkt. "DATE, WORK TYPE": Dato, arbejdsområdekode.',
      enum: ["PRIORITY, CREATED", "DATE, WORK TYPE"],
    },
    orderValidation: {
      default: false,
      description: 'Alle ordre skal "ordre-godkendes" før de kan overføres til økonomisystem',
      type: "boolean",
    },
    orderValidationForWorkTypes: {
      default: [],
      description: 'Ordre med følgende arbejdsområder kræver "ordre-godkendelse"',
      items: {type: "string"},
      type: "array",
    },
    overviewShowPrices: {
      default: true,
      description: "Priser vises på overbliksfanen under [indstillinger]",
      type: "boolean",
    },
    ownCompanyId: {
      default: null,
      description: "Indsæt automatisk valgte kunde, hvis medarbejderen ikke har valgt en",
      type: ["string", "null"],
    },
    paidDayAbsenceTypes: {
      default: [],
      description:
        "Enkeltdags/flerdags fraværstyper som skal give dagens mulige normaltimer som normaltimer i lønrapport.",
      items: {type: "string"},
      type: "array",
    },
    periodSplitThresholdMinutes: {
      default: 120,
      description:
        '"Automatisk stempelur". Hvor længe på en arbejdsdag skal en medarbejder have været inaktiv før CO betragter det som at medarbejderen har været væk fra arbejde. Bruges ifm. lønrapporten til at opdele arbejdsperioder, inden for samme arbejdsdag, så man nemt kan se medarbejderens "sammenhængende" arbejdsperioder på dagen.',
      type: "integer",
    },
    preparationWorkType: {
      default: null,
      description: "Område som anvendes til automatisk oprettelse af klargøringsopgaver",
      type: ["string", "null"],
    },
    priceGroupHideTimers: {
      additionalProperties: {items: {type: "string"}, type: "array"},
      default: {},
      description:
        'Skjul timere på angivede prisgrupper. {"pricegroup-identifier": ["timer-identifier"]}',
      type: "object",
    },
    priceItemConversionSetup: {
      default: false,
      description:
        "Prislinjer kan have omregning med enhed/faktor opsat; medarbejderens indtastning bliver så i den anden enhed.",
      type: "boolean",
    },
    priceItemRelationUnitConversionHelpers: {
      additionalProperties: {items: {type: "string"}, type: "array"},
      default: {},
      description:
        'Indtastning af prislinje-værdier relativt til andre værdier --- {"Ha": ["kg", "L"]} betyder at "kg" kan indtastes som "kg/Ha" og "L" som "L/Ha", når linjer med begge enheder findes.',
      type: "object",
    },
    productImageSelection: {
      default: false,
      description: "Aktiver vare-dialog med varegruppe-træ og billeder",
      type: "boolean",
    },
    productMultiSelect: {
      default: false,
      description:
        'Gør det muligt at tilføje flere vare af gangen, under "Tilføj materiel" (OBS: Ignoreres hvis productImageSelection er aktiv)',
      type: "boolean",
    },
    productsExcludedFromInvoice: {
      default: [],
      description: "Produkt ekskluderes fra faktura",
      items: {type: "string"},
      type: "array",
    },
    projectLabelVariant: {
      default: "PROJECT",
      description: 'Projekter betegnes som "projekter" eller "sager"',
      enum: ["PROJECT", "CASE"],
    },
    projectManagerCanManageAbsence: {
      default: false,
      description: "Tillad at brugere med projektleder-rollen kan administrere fravær",
      type: "boolean",
    },
    projectMissingCompleteError: {
      default: false,
      description:
        "Kræv projekt på opgaven, hvis kunden har aktive projekter, men intet projekt er valgt på opgaven",
      type: "boolean",
    },
    projectMissingCompleteWarning: {
      default: false,
      description:
        'Advarsel vises: "Kunden har aktive projekter, men intet projekt er valgt på opgaven"',
      type: "boolean",
    },
    projectMissingValidateError: {
      default: true,
      description: '"Fejl: Projekt mangler" vises i godkendelses dialogbox',
      type: "boolean",
    },
    projectMissingValidateWarning: {
      default: false,
      description: 'Advarsel vises: "Projekt mangler"',
      type: "boolean",
    },
    recordCultureTasks: {
      default: true,
      description:
        "Kultur-opgaver skal overføres til regskabssystem eller tilsvarende inden de arkiveres.",
      type: "boolean",
    },
    recordCustomerTasks: {
      default: true,
      description: "Opgaver overføres til økonomisystem",
      type: "boolean",
    },
    recordInternalTasks: {
      default: true,
      description:
        "Interne opgaver skal overføres til regnskabssystem eller tilsvarende inden de arkiveres.",
      type: "boolean",
    },
    registerAbsence: {default: true, description: "Registrering af fravær", type: "boolean"},
    remunerationAbsenceTypes: {
      default: [],
      description: "remunerationAbsenceTypes",
      items: {type: "string"},
      type: "array",
    },
    remunerationCalendarDayBonusLabels: {
      default: [],
      description: "remunerationCalendarDayBonusLabels",
      items: {type: "string"},
      type: "array",
    },
    remunerationCountBonusLabels: {
      default: [],
      description: "remunerationCountBonusLabels",
      items: {type: "string"},
      type: "array",
    },
    remunerationDefaultGroup: {
      default: "default",
      description: "remunerationDefaultGroup",
      type: "string",
    },
    remunerationGroups: {
      additionalProperties: {$ref: "#/definitions/remunerationGroup"},
      default: {
        default: {
          accumulateCompensatoryLimit: null,
          compensatoryMultiplier: null,
          compensatorySubtractOnly: null,
          extraHalfHolidayAbsenceTypes: [],
          extraHalfHolidays: {},
          extraHolidayAbsenceTypes: [],
          extraHolidays: {},
          halfHolidayHalfThresholds: true,
          halfHolidayHoursRates: null,
          halfHolidaySundayAfterNoon: false,
          hiddenFromGroupSelection: false,
          hoursRates: [[], [], [], [], [], [], []],
          includeCompensatoryOverLimitInPaid: null,
          intervalBonus: [],
          overtimeThresholds: [[], [], [], [], [], [], []],
          overtimeThresholdsReset: false,
          periodCompensatoryLimit: null,
          pools: null,
          reportIgnoreAbsenceOnHolidays: false,
          reportTitle: "Standard",
          specialStartRateMinutes: null,
          workDayBonus: [],
        },
      },
      description: "remunerationGroups",
      type: "object",
    },
    remunerationIncludeEmptyDatesInReport: {
      default: false,
      description: "remunerationIncludeEmptyDatesInReport",
      type: "boolean",
    },
    remunerationIntervalBonusLabels: {
      default: [],
      description: "remunerationIntervalBonusLabels",
      items: {type: "string"},
      type: "array",
    },
    remunerationNormalTransportKilometersCountBonusLabel: {
      default: "",
      description: "remunerationNormalTransportKilometersCountBonusLabel",
      type: "string",
    },
    remunerationPaidTransportWorkType: {
      default: null,
      description: "remunerationPaidTransportWorkType",
      type: ["string", "null"],
    },
    remunerationRateLabels: {
      anyOf: [{items: {type: "string"}, type: "array"}, {type: "null"}],
      default: null,
      description: "remunerationRateLabels",
    },
    remunerationReportPerVariation: {
      default: false,
      description: "remunerationReportPerVariation",
      type: "boolean",
    },
    remunerationReportShowAlias: {
      default: false,
      description: "remunerationReportShowAlias",
      type: "boolean",
    },
    remunerationReportShowHolidays: {
      default: true,
      description: "remunerationReportShowHolidays",
      type: "boolean",
    },
    remunerationReportShowProjects: {
      default: false,
      description: "remunerationReportShowProjects",
      type: "boolean",
    },
    remunerationReportShowReferenceNumbers: {
      default: false,
      description: "remunerationReportShowReferenceNumbers",
      type: "boolean",
    },
    remunerationStartRateLabel: {
      default: null,
      description: "remunerationStartRateLabel",
      type: ["string", "null"],
    },
    remunerationTaskBonusLabels: {
      default: [],
      description: "remunerationTaskBonusLabels",
      items: {type: "string"},
      type: "array",
    },
    remunerationVisibleBonusLabels: {
      anyOf: [{items: {type: "string"}, type: "array"}, {type: "null"}],
      default: null,
      description: "remunerationVisibleBonusLabels",
    },
    remunerationVisibleRateLabels: {
      anyOf: [{items: {type: "string"}, type: "array"}, {type: "null"}],
      default: null,
      description: "remunerationVisibleRateLabels",
    },
    remunerationWorkDayBonusLabels: {
      default: [],
      description: "remunerationWorkDayBonusLabels",
      items: {type: "string"},
      type: "array",
    },
    repairWorkType: {
      default: null,
      description:
        "Internt områdenummer, der vil blive anvendt ved automatisk oprettelse af værkstedsopgaver, ved fejlmelding på køretøj",
      type: ["string", "null"],
    },
    reportDataIsValidFrom: {
      default: "",
      description:
        "Data til rapportgenerering er først valid fra nedenstående dato. Format: år-måned-dag (fx 2020-12-31)",
      type: "string",
    },
    reportDepartmentWorkTypeProductiveCombinations: {default: {}, description: "", type: "object"},
    reportEffectiveTimeLabel: {
      default: "effektiv tid",
      description: "Ændrer effektiv tid teksten på lagkage diagrammet, i opgavestatistikrapporten",
      type: "string",
    },
    reportExtraData: {
      default: [],
      description: "DENNE SKAL DØ",
      items: {
        additionalProperties: false,
        properties: {
          from: {type: "string"},
          internalHours: {additionalProperties: {type: "number"}, type: "object"},
          paidHours: {type: "number"},
          to: {type: "string"},
        },
        required: ["from", "to", "paidHours", "internalHours"],
        type: "object",
      },
      type: "array",
    },
    reportExtraNonProductiveWorkTypeNameToLatexNameMap: {
      additionalProperties: {type: "string"},
      default: {},
      description: "DENNE SKAL DØ",
      type: "object",
    },
    reportHourDecimals: {
      default: 1,
      description: "Antal decimaler anvendt for timer i opgavestatisk-rapporten",
      type: "integer",
    },
    reportLegendShift: {
      additionalProperties: false,
      default: {x: "1.3cm", y: "2cm"},
      description: "",
      properties: {x: {type: "string"}, y: {type: "string"}},
      required: ["x", "y"],
      type: "object",
    },
    reportNonProductiveWorkTypeNameToLatexNameMap: {
      additionalProperties: {type: "string"},
      default: {},
      description: "interne områder der skal vises på rapporten",
      type: "object",
    },
    reportSupportedUnits: {
      anyOf: [{items: {type: "string"}, type: "array"}, {type: "null"}],
      default: null,
      description: "Begræns rapportgenerator til ønskede enheder",
    },
    requireEmployeeAssignedToTasksWhenConvertingOrderDrafts: {
      default: false,
      description:
        "Det er påkrævet at der er medarbejder på alle opgaver på en ordrekladde før den kan konverteres til en reel ordre",
      type: "boolean",
    },
    requireFavoriteMachines: {
      default: false,
      description: "Kræv at der kun anvendes de maskiner som er opsat for området",
      type: "boolean",
    },
    requireFieldForCultureTasks: {
      default: false,
      description:
        "Kræv at der er valgt mindst én mark i forbindelse fuldførelse af en kulturopgave",
      type: "boolean",
    },
    requireWorkplaceIfExists: {
      default: false,
      description: "Medarbejderen skal vælge et arbejdssted, hvis de findes på kunden",
      type: "boolean",
    },
    rolesMayCreateLocations: {
      default: [],
      description: "Hvilke roller der kan oprette steder. Gælder både arbejdssteder og logsteder.",
      items: {$ref: "#/definitions/roles"},
      type: "array",
      uniqueItems: true,
    },
    rolesMayEditLocations: {
      default: [],
      description:
        "Hvilke roller der kan redigere i eksisterende steder. Gælder både arbejdssteder og logsteder.",
      items: {$ref: "#/definitions/roles"},
      type: "array",
      uniqueItems: true,
    },
    routesEnabled: {default: false, description: "Ruter aktiveres", type: "boolean"},
    routeTaskStartStop: {
      default: false,
      description: "Startknap for rute opgaver",
      type: "boolean",
    },
    salaryVouchersVisibleToEmployees: {
      default: false,
      description: "Mine lønbilag aktiveret",
      type: "boolean",
    },
    salaryVouchersVisibleToManagers: {
      default: false,
      description: 'Administratoren kan også se "Mine lønbilag"',
      type: "boolean",
    },
    selectInformationvisibleTo: {
      default: false,
      description: "Man kan sende beskeder til bestemte personer, i stedet for til alle",
      type: "boolean",
    },
    sendApprovedTransportlogToCustomers: {
      default: [],
      description: "Transportlog sendes automatisk til denne kunde ved godkendelse af opgaven",
      items: {type: "string"},
      type: "array",
    },
    sendValidatedGenericLogToCustomers: {
      additionalProperties: {$ref: "#/definitions/stringArray"},
      default: {},
      description: "Generiske logs sendes automatisk til denne kunde ved godkendelse af opgaven",
      type: "object",
    },
    seniorMachineOperatorCanEditNotesFromManager: {
      default: false,
      description: "Senior maskinførere kan skrive/rette noter fra Admin",
      type: "boolean",
    },
    seniorMachineOperatorCanManageOrders: {
      default: false,
      description: "Senior maskinfører kan oprette og redigere ordre og opgaver",
      type: "boolean",
    },
    setLogOnlyLocationOnCreate: {
      default: true,
      description:
        'Når logsteder oprettes så sættes de automatisk til "Kan kun anvendes som logsted"',
      type: "boolean",
    },
    setWorkplaceOnlyLocationOnCreate: {
      default: true,
      description:
        'Når arbejdssteder oprettes så sættes de automatisk til "Kan kun anvendes som arbejdssted"',
      type: "boolean",
    },
    sharedTransportLog: {
      default: false,
      description: "Delt transportlog (gammel)",
      type: "boolean",
    },
    showAccountOnCustomerList: {
      default: false,
      description: 'Kolonne "konto" vises i kundelisten',
      type: "boolean",
    },
    showAllProjectsOnAppendicesPage: {
      default: false,
      description:
        "Hvis slået til, så kan der vælges mellem alle projekter (inkl. lukkede) på bilagssiden ifm. filtrering på projekt.",
      type: "boolean",
    },
    showArrivalAtLocationField: {
      default: false,
      description: '"Start ved kunden" vises',
      type: "boolean",
    },
    showAttOnCustomerList: {
      default: true,
      description: 'Kolonne "Att" vises i kundelisten',
      type: "boolean",
    },
    showBrugerdataOrdernumber: {
      default: false,
      description: "Ordrenummeret fra BrugerData vises på ordren",
      type: "boolean",
    },
    showC5AccountOnTaskInstanceAnList: {
      default: false,
      description: "Kundenummer vises (C5)",
      type: "boolean",
    },
    showCalledInDialogAfterMinutes: {
      default: null,
      description:
        "Hvis tilkaldsopgave startes senere end de angivede antal minutter efter normal arbejdstids ophør, så vises tilkalds-dialogen",
      type: ["number", "null"],
    },
    showColleaguesAtCustomer: {
      default: true,
      description:
        'Viser "Kolleger ved kunden denne dag" under Opgave->Info. Ved opgaver med Kultur tilknyttet, vises denne information dog aldrig.',
      type: "boolean",
    },
    showContinueToNextCustomerButton: {
      default: false,
      description:
        '"Fortsæt til næste kunde" knap vises. ( OBS: Bruges hos Vama til vintertjeneste)',
      type: "boolean",
    },
    showCreatedTimestampOnInternalTasks: {
      default: false,
      description: "Oprettelsestidspunktet vises",
      type: "boolean",
    },
    showDateGroupsOnOrderLists: {
      default: false,
      description: "Ordre listen efter dato grupper",
      type: "boolean",
    },
    showDateGroupsOnTaskLists: {
      default: false,
      description: "Opdel opgavelisten i datogrupper",
      type: "boolean",
    },
    showEmployeesAndTasksLayersToMachineOperators: {
      default: true,
      description:
        'Maskinfører kan se deres kolleger på kortet, dvs. de kan se "Medarbejdere"  og "Opgaver" i listen af kort-lag der kan vælges.',
      type: "boolean",
    },
    showFieldNumbersOnCalendar: {
      default: false,
      description: "Marknumre vises på opgave-blokken",
      type: "boolean",
    },
    showFieldsColumnOnOrderEntryTaskList: {
      default: false,
      description: 'Kolonnen "Marker" vises på opgavetabellen 🌾',
      type: "boolean",
    },
    showFieldsOnBookkeepingTables: {
      default: false,
      description: "Mark numre vises på dagsedlen.",
      type: "boolean",
    },
    showFieldsOnGeolocationMap: {
      default: false,
      description: "Marker vises på kortet",
      type: "boolean",
    },
    showGenericPeriodLogView: {
      default: false,
      description: "Generiske summerede rapporter vises",
      type: "boolean",
    },
    showHeaderOnTransportLog: {
      default: false,
      description: "Vis hvad der er kørt med som overskrift på transportloggen (gammel)",
      type: "boolean",
    },
    showInvoiceNote: {
      default: false,
      description:
        "Fakturanote vises på opgaven (skjult for chaufføren med setting machineOperaterCanEditInvoiceNote)",
      type: "boolean",
    },
    showInvoiceNoteOnOrderPdf: {
      default: false,
      description: "Vis fakturanote på ordre pdf",
      type: "boolean",
    },
    showLandlinePhoneNumberOnTaskInfo: {
      default: true,
      description: "Kundens fastnetnummer vises",
      type: "boolean",
    },
    showLogEmailFieldOnCustomerInstance: {
      default: false,
      description: "Log email feltet vises på kunden (opret/ret mail adresse)",
      type: "boolean",
    },
    showLogLocationsOnBookkeeping: {
      default: false,
      description: "Loglokationer vises på dagsedlen",
      type: "boolean",
    },
    showLogPickupAndDeliveryColumnsOnOrderEntryTaskList: {
      default: false,
      description: 'Kolonnerne "Afh., log." og "Lev., log" vises på opgavetabellen',
      type: "boolean",
    },
    showMachineAsWorktypeForContratorTasks: {
      default: false,
      description: "Maskine som arbejdsområde",
      type: "boolean",
    },
    showMachineLastUse: {
      default: false,
      description: "Liste vises over medarbejdere, der sidst har brugt maskinen",
      type: "boolean",
    },
    showMachineOperatorInitialsOnTaskInstance: {
      default: false,
      description: "Medarbejderenes initialer vises",
      type: "boolean",
    },
    showMachineOperatorNameOnTaskInstance: {
      default: false,
      description: "Medarbejderenes navne vises",
      type: "boolean",
    },
    showManagerInternalNotesOnOrderPDF: {
      default: false,
      description: "Vis interne admin-noter på ordre PDFen lige over almindelige admin-noter.",
      type: "boolean",
    },
    showManagerInternalOrderNoteLinesOnOrderCalendar: {
      default: 0,
      description: 'Begræns visning af antal linier fra "Interne noter"',
      type: "integer",
    },
    showManagersUnpaidBillWarning: {
      default: false,
      description:
        'Besked vises: "Der er et forfaldent beløb til CustomOffice, som ikke er betalt."',
      type: "boolean",
    },
    showMaterialAutoLinesToEmployees: {
      default: false,
      description: "Auto-indsatte linjer vises for medarbejderen",
      type: "boolean",
    },
    showNoteOnInvoiceLineTable: {
      default: false,
      description: "Varer-linje-noter vises på opgavens fakturerings-fane og dagsedler",
      type: "boolean",
    },
    showOrderCreatedDateTime: {
      default: false,
      description: "Kolonne med oprettelsesdato vises på ordrelisten",
      type: "boolean",
    },
    showOrderNoteOnTaskList: {
      default: false,
      description: 'Kolonne "Ordrenoter" vises på opgavelisten',
      type: "boolean",
    },
    showOrderReferenceNumberOnTaskInfoTab: {
      default: false,
      description: "Ordre referencenummer vises",
      type: "boolean",
    },
    showOrderWorkplaceOnOrderPdf: {
      default: false,
      description: "Vis ordrens arbejdssted i toppen af ordre-PDFen",
      type: "boolean",
    },
    showOursToggle: {
      default: false,
      description: "Vis vores/deres på materiel linier",
      type: "boolean",
    },
    showPhotoOnCalendar: {
      default: false,
      description:
        "Hvis der er gemt et billede på opgaven, vises et ikon for billeder på kalenderen",
      type: "boolean",
    },
    showProductList: {default: false, description: "Vis listen af produkter", type: "boolean"},
    showProjectAlias: {
      default: false,
      description: "Når et projekts navn vises så vises projektet søgenavn sammen med det",
      type: "boolean",
    },
    showProjectInsteadOfCustomer: {
      default: false,
      description:
        "Projektnummer og projektnavn vises på opgave-blokken (i stedet for kundenavnet)",
      type: "boolean",
    },
    showProjectNameOnTaskInfoTab: {
      default: false,
      description: "Projektnavnet vises",
      type: "boolean",
    },
    showProjectNameOnTimeOverviewPage: {
      default: false,
      description: "Projektnavne vises på min dagseddel",
      type: "boolean",
    },
    showProjectNumberOnTaskInfoTab: {
      default: false,
      description: "Projektnummeret vises",
      type: "boolean",
    },
    showProjectNumberOnTaskInstance: {
      default: true,
      description: "Projektnummeret vises",
      type: "boolean",
    },
    showProjectNumberOnTimeOverviewPage: {
      default: false,
      description: "Projektnummeret vises på min dagseddel",
      type: "boolean",
    },
    showProjectOnTaskInstance: {
      default: false,
      description: "Projektnavnet vises",
      type: "boolean",
    },
    showRealTimeColumnOnOrderPDF: {
      default: false,
      description: "Viser kolonne med reel tid på order PDF",
      type: "boolean",
    },
    showSearchnameOnCustomerList: {
      default: true,
      description: 'Kolonne "Søgenavn" vises i kundelisten',
      type: "boolean",
    },
    showStorageOnTaskFieldMap: {
      default: false,
      description: "Lager status vises ved tilføjelse af mark fra opgavesiden",
      type: "boolean",
    },
    showStorageProductNotOnLocationWarning: {
      default: true,
      description: "Advar hvis der vælges vare som ikke findes på lokationen",
      type: "boolean",
    },
    showTaskArchiveToMachineOperators: {
      default: false,
      description: "Viser arkiv-fanen under Opgaver til maskinførerne.",
      type: "boolean",
    },
    showTaskEffectiveTimeOnTaskInfoTab: {
      default: false,
      description: '"Effektiv tid" vises omregnet til procent',
      type: "boolean",
    },
    showTaskInfoManagerNotesAboveLogButtons: {
      default: false,
      description: '"Noter fra administrationen" vises over log-knapperne, på opgaver med log',
      type: "boolean",
    },
    showTaskInvoiceNoteColumnOnOrderEntryTaskList: {
      default: false,
      description: "Kolonnen fakturanote vises på opgavetabellen",
      type: "boolean",
    },
    showTaskNotesOnOrderEntryPage: {
      default: false,
      description: "Opgavenoter vises i opgavetabellen (når opgaver er udført)",
      type: "boolean",
    },
    showTaskRefColumnOnOrderEntryTaskList: {
      default: false,
      description: 'Kolonnen  "Referencenr."(opgave) vises på opgavetabellen',
      type: "boolean",
    },
    showTimeColumnOnOrderEntryTaskList: {
      default: false,
      description: "Kolonnen klokkeslet vises på opgavetabellen ⌚︎",
      type: "boolean",
    },
    showUnscheduledInternalTaskCalendar: {
      default: true,
      description: "Interne opgaver uden planlagt dato/medarbejder, vises over kalenderen",
      type: "boolean",
    },
    showWhoHasNotReadInformation: {
      default: false,
      description:
        "Liste over hvem der IKKE har læst opslaget vises, frem for dem som HAR læst opslaget",
      type: "boolean",
    },
    showWorkplaceOnTaskInstance: {
      default: false,
      description: "Arbejdssted vises",
      type: "boolean",
    },
    showWorkplacesInCustomerPickupDeliveryLocationDialog: {
      default: true,
      description:
        "Ved valg af arbejdssted ifm. log, vis også kundens arbejdssteder i listen.(gammel)",
      type: "boolean",
    },
    showWorkshopMachineName: {
      default: false,
      description: "Maskinens navn i opgave listen vises  i stedet for maskinens nummer",
      type: "boolean",
    },
    singleDayAbsenceTypes: {
      default: ["childsFirstSickDay"],
      description: "Fraværs typer der dækker én enkelt dag",
      items: {type: "string"},
      type: "array",
    },
    suggestRecentlyUsedCustomers: {
      default: false,
      description:
        "Systemet foreslår de sidste 6 anvendte kunder som enten er anvendt på opgaver oprettet af brugeren eller som brugeren er sat på som medarbejder.",
      type: "boolean",
    },
    suggestRecentlyUsedWorkTypes: {
      default: false,
      description:
        "Systemet foreslår de sidste 6 anvendte arbejdsområder, ved valg af arbejdsområde",
      type: "boolean",
    },
    switchProjectAndCustomerButtonLocation: {
      default: false,
      description: '"Vælg projekt" og "vælg kunde" bytter plads',
      type: "boolean",
    },
    syncRecipients: {
      default: [],
      description: "Liste over modtagere af synkroniseringsrapporten fra C5.",
      items: {type: "string"},
      type: "array",
    },
    taskAndOrderDatesFromCalendarOnCreate: {
      default: true,
      description:
        'Indsæt automatisk dagsdato på "Planlagt dato" ved oprettelse, ud fra hvilken dag man står på i kalenderen',
      type: "boolean",
    },
    taskCalendarFadeCompletedTasks: {
      default: false,
      description: "Vis udførte opgaver nedtonet på opgave kalenderen",
      type: "boolean",
    },
    taskCompleteOverview: {
      default: false,
      description: "Vis opgave overblik i afslut opgave dialogen",
      type: "boolean",
    },
    taskCompletePrepareQuery: {
      default: false,
      description: 'Tilføj beskeden: "Skal der klargøres?"',
      type: "boolean",
    },
    taskCompleteRepairQuery: {
      default: true,
      description: 'Tilføj spørgsmålet: "Er der noget der skal repareres?" i fuldført-dialog',
      type: "boolean",
    },
    taskCompleteRepairQueryRequireNote: {
      default: false,
      description: 'Kræv note om fejl hvis "Er der noget der skal repareres?" er afkrydset',
      type: "boolean",
    },
    taskCopyAlwaysOverridemachineOperator: {
      default: false,
      description:
        "Ved opgave-kopi, Indsættes brugeren selv som opgaveansvarlig (OBS: Gælder også for Admin)",
      type: "boolean",
    },
    taskCopyFields: {
      default: [
        "workType",
        "priceGroup",
        "department",
        "relatedWorkplace",
        "relatedPickupLocation",
        "machineOperator",
        "machineGroup",
        "order",
        "date",
        "minutesExpectedTotalTaskDuration",
        "notesFromManager",
        "notesFromMachineOperator",
        "machineuseSet",
        "workshopchecklistanswerSet",
        "fielduseSet",
        "priority",
        "billedPreparation",
        "billedTransportation",
        "reportingSpecification",
        "reportingLocations",
        "invoiceNote",
        "project",
        "referenceNumber",
      ],
      description: "Definerer hvilke felter der skal kopieres når der laves en kopi af en opgave",
      items: {type: "string"},
      type: "array",
    },
    taskCultureSubheader: {
      default: true,
      description: "Navnet på den valgte kultur vises",
      type: "boolean",
    },
    taskInstancePDF: {default: false, description: '"Gem opgave som pdf" vises', type: "boolean"},
    taskInstancePdfShowWorkplacePickupDelivery: {
      default: false,
      description:
        "På opgave-PDFen vises arbejds-/afhentnings-/leveringssteder fra opgaven og dens log-registreringer. Bemærk: Gammel transportlog, sprøjtelog og udbyttelog understøttes ikke",
      type: "boolean",
    },
    taskListColumns: {
      additionalProperties: false,
      default: {
        archive: {
          employee: {
            desktop: ["workType", "notes", "customerMachine", "machineOperator", "time", "date"],
            mobile: [
              "workTypeShort",
              "notes",
              "customerMachine",
              "machineOperatorShort",
              "dateShort",
            ],
            tablet: [
              "workTypeShort",
              "notes",
              "customerMachine",
              "machineOperator",
              "time",
              "dateShort",
            ],
          },
          manager: {
            desktop: [
              "workType",
              "notes",
              "customerMachine",
              "machineOperator",
              "time",
              "date",
              "status",
              "editTask",
            ],
            mobile: [
              "workTypeShort",
              "notes",
              "customerMachine",
              "machineOperatorShort",
              "dateShort",
              "status",
              "editTask",
            ],
            tablet: [
              "workTypeShort",
              "notes",
              "customerMachine",
              "machineOperator",
              "time",
              "dateShort",
              "status",
              "editTask",
            ],
          },
        },
        culture: {
          employee: {
            desktop: ["workType", "customer", "machineOperator", "time", "date", "photo"],
            mobile: ["workTypeShort", "customer", "machineOperatorShort", "dateShort"],
            tablet: ["workTypeShort", "customer", "machineOperator", "time", "dateShort", "photo"],
          },
          manager: {
            desktop: [
              "workType",
              "customer",
              "machineOperator",
              "time",
              "date",
              "photo",
              "status",
              "editTask",
            ],
            mobile: [
              "workTypeShort",
              "customer",
              "machineOperatorShort",
              "dateShort",
              "status",
              "editTask",
            ],
            tablet: [
              "workTypeShort",
              "customer",
              "machineOperator",
              "time",
              "dateShort",
              "photo",
              "status",
              "editTask",
            ],
          },
        },
        customer: {
          employee: {
            desktop: ["workType", "customer", "machineOperator", "time", "date", "photo"],
            mobile: ["workTypeShort", "customer", "machineOperatorShort", "dateShort"],
            tablet: ["workTypeShort", "customer", "machineOperator", "time", "dateShort", "photo"],
          },
          manager: {
            desktop: [
              "workType",
              "customer",
              "machineOperator",
              "time",
              "date",
              "photo",
              "status",
              "editTask",
            ],
            mobile: [
              "workTypeShort",
              "customer",
              "machineOperatorShort",
              "dateShort",
              "status",
              "editTask",
            ],
            tablet: [
              "workTypeShort",
              "customer",
              "machineOperator",
              "time",
              "dateShort",
              "photo",
              "status",
              "editTask",
            ],
          },
        },
        internal: {
          employee: {
            desktop: ["workType", "machines", "machineOperator", "date", "photo"],
            mobile: ["workTypeShort", "machines", "machineOperatorShort", "dateShort"],
            tablet: ["workTypeShort", "machines", "machineOperator", "date", "photo"],
          },
          manager: {
            desktop: [
              "workType",
              "machines",
              "machineOperator",
              "date",
              "photo",
              "status",
              "editTask",
            ],
            mobile: ["workTypeShort", "machines", "machineOperatorShort", "dateShort", "status"],
            tablet: [
              "workTypeShort",
              "machines",
              "machineOperator",
              "date",
              "photo",
              "status",
              "editTask",
            ],
          },
        },
        own: {
          employee: {
            desktop: ["workType", "customerMachine", "time", "date", "photo"],
            mobile: ["workTypeShort", "customerMachine", "timeShort", "dateShort"],
            tablet: ["workTypeShort", "customerMachine", "time", "dateShort", "photo"],
          },
          manager: {
            desktop: ["workType", "customerMachine", "time", "date", "photo", "status", "editTask"],
            mobile: [
              "workTypeShort",
              "customerMachine",
              "timeShort",
              "dateShort",
              "status",
              "editTask",
            ],
            tablet: [
              "workTypeShort",
              "customerMachine",
              "time",
              "dateShort",
              "photo",
              "status",
              "editTask",
            ],
          },
        },
        readyForBilling: {
          employee: {
            desktop: ["workType", "notes", "customer", "machineOperator", "time", "date", "photo"],
            mobile: ["workTypeShort", "notes", "customer", "machineOperatorShort", "dateShort"],
            tablet: [
              "workTypeShort",
              "notes",
              "customer",
              "machineOperator",
              "time",
              "dateShort",
              "photo",
            ],
          },
          manager: {
            desktop: [
              "workType",
              "notes",
              "customer",
              "machineOperator",
              "time",
              "date",
              "photo",
              "status",
              "editTask",
            ],
            mobile: [
              "workTypeShort",
              "notes",
              "customer",
              "machineOperatorShort",
              "dateShort",
              "status",
              "editTask",
            ],
            tablet: [
              "workTypeShort",
              "notes",
              "customer",
              "machineOperator",
              "time",
              "dateShort",
              "photo",
              "status",
              "editTask",
            ],
          },
        },
      },
      description: "Opsætning af kolonner på opgavelisten",
      properties: {
        archive: {$ref: "#/definitions/taskListColumnsUserTypesSpecification"},
        culture: {$ref: "#/definitions/taskListColumnsUserTypesSpecification"},
        customer: {$ref: "#/definitions/taskListColumnsUserTypesSpecification"},
        internal: {$ref: "#/definitions/taskListColumnsUserTypesSpecification"},
        own: {$ref: "#/definitions/taskListColumnsUserTypesSpecification"},
        readyForBilling: {$ref: "#/definitions/taskListColumnsUserTypesSpecification"},
      },
      required: ["own", "internal", "customer", "culture", "readyForBilling", "archive"],
      type: "object",
    },
    taskListeNoteLines: {default: 0, description: "Antal af notelinjer der vises", type: "integer"},
    taskListsMachineOperatorHideCompleted: {
      default: true,
      description: "Færdige opgaver skjules, for medarbejderen",
      type: "boolean",
    },
    taskListsMachineOperatorHideCompletedExceptToday: {
      default: false,
      description: "Færdige opgaver ældre end dags dato skjules, for medarbejderen",
      type: "boolean",
    },
    taskNoteLinesOnTaskOrderCalender: {
      default: 0,
      description:
        'Begræns visning af antal linier fra "Administrationens noter" og "Medarbejderens noter"',
      type: "integer",
    },
    taskOrderAutoOpenCustomerSelection: {
      default: false,
      description: "Start med kunde-pick liste, uanset hvor man opretter opgaven",
      type: "boolean",
    },
    taskOrderShowContact: {default: true, description: "Kontaktpersoner vises", type: "boolean"},
    taskOrderShowDate: {default: false, description: '"Dato" vises (hvor?)', type: "boolean"},
    taskOrderShowTime: {
      default: true,
      description:
        "Klokkeslætsfelt er tilgængelig inde på opgave-redigér-siden (opgave/info/rediger)",
      type: "boolean",
    },
    taskOrderTaskShowWorkPlace: {
      default: true,
      description:
        "Arbejdsstedsknap er tilgængelig inde på opgave-redigér-siden (opgave/info/rediger)",
      type: "boolean",
    },
    taskOverlapWarningAfterMinutes: {
      default: 2,
      description: "Grænse før kalenderen markerere konflikter mellem opgaver med rød farve",
      type: "integer",
    },
    taskPageCreatedDate: {default: false, description: "Oprettelsesdato vises", type: "boolean"},
    taskPickupDelivery: {
      default: false,
      description:
        '"Afhentningssted"-felt vises og omnavngiv "arbejsdssted" til "arbejds-/leveringssted"',
      type: "boolean",
    },
    taskReferenceNumberLabel: {
      default: null,
      description: 'Alternativ tekst for opgavers "referencenummer"-felt.',
      type: ["string", "null"],
    },
    taskShowRelatedFiles: {
      default: false,
      description: "Vis filer fra relaterede opgaver",
      type: "boolean",
    },
    taskShowRelatedPhotos: {
      default: false,
      description: "Gør det muligt at se fotos fra andre releaterede opgaver",
      type: "boolean",
    },
    taskShowTimeField: {default: false, description: '"Klokkeslæt" vises', type: "boolean"},
    tasksPrintout: {
      default: false,
      description: "Aktiver opgaveudskrifter så man kan få faktura som CSV filer",
      type: "boolean",
    },
    taskWizardAskForWorkPlace: {
      default: false,
      description: "Tilføj valg af Arbejdssted i wizard",
      type: "boolean",
    },
    taskWizardFieldSelection: {
      default: false,
      description: "Wizarden spørger om marker, såfremt der ikke er valgt arbejdssted.",
      type: "boolean",
    },
    timePriceItemsBeforeOtherPriceItems: {
      default: false,
      description:
        'Hvis slået, så vil opgavers prislinjer under material/fakturering sorteres så alle time-prislinjer kommer i toppen, og derefter kommer de andre typer prislinjer. Hvis denne er slået til samtidig med "workTypeBeforeTimerBeforeMachinePriceItems" så vil prislinjerne komme i følgende rækkefølge: 1. Time-prislinjer fra områdets variant 2. Time-prislinjer fra tidsknappers varianter 3. Time-prislinjer fra maskiners varianter 4. Resten af prislinjerne fra områdets variant 5. Resten af prislinjerne fra tidsknappernes varianter 6. Resten af prislinjerne fra maskinernes varianter.',
      type: "boolean",
    },
    timersContributingToEffectiveTime: {
      default: [],
      description:
        "Liste af timer-ID på de ekstra timere der skal tælle (og faktureres) som effektiv tid på opgaven.",
      items: {type: "string"},
      type: "array",
    },
    transferOrderAdminNotesToInvoice: {
      default: false,
      description: "Admin noter overføres til faktura",
      type: "boolean",
    },
    transferOrderNotesToInvoice: {
      default: false,
      description: '"Noter til medarbejder" tilføjes til faktura under admin noter',
      type: "boolean",
    },
    transferTaskRequireOrderApproval: {
      $comment: "orderValidation",
      default: false,
      description: 'Alle ordre skal "ordre-godkendes" før de kan overføres til økonomisystem',
      type: "boolean",
    },
    transferTaskRequireOrderApprovalForWorkTypes: {
      $comment: "orderValidationForWorkTypes",
      default: [],
      description: 'Ordre med følgende arbejdsområder kræver "ordre-godkendelse"',
      items: {type: "string"},
      type: "array",
    },
    transferTaskUnApproveOnError: {
      default: true,
      description:
        'Opgaver bliver "afgodkendt" og ført tilbage til "godkendelseslisten", såfremt der er fejl i overførslen',
      type: "boolean",
    },
    transportLogDecimals: {
      default: 2,
      description: "Antal decimaler ved indtastning i transportlog (gammel)",
      type: "integer",
    },
    transportLogDistanceKmDecimalPlaces: {
      default: 0,
      description: "Antal decimaler der skal kunne angives ved transportlog afstand (km) (gammel)",
      type: "integer",
    },
    transportlogTimeHeader: {default: true, description: "gammel", type: "boolean"},
    transportLogUnitPerLocation: {
      default: true,
      description: "Man kan på transportloggen angive enhed pr. afhentningssted.(gammel)",
      type: "boolean",
    },
    transportResponsibleEffectiveTimers: {
      default: [],
      description: "transportResponsibleEffectiveTimers",
      items: {type: "string"},
      type: "array",
    },
    treatTransportTimeAsEffectiveForDepartments: {
      default: [],
      description: 'Gør "transport tid" til "effektiv tid" for nedenstående afdelinger',
      items: {type: "string"},
      type: "array",
    },
    unicontaDMEUseTaskProjectForInternalCost: {
      default: false,
      description:
        "Omkostninger for interne arbejdsområder sendes til opgavens projekt, ellers anvend projektet 'Intern_CO'",
      type: "boolean",
    },
    unicontaDMEWorkingHours: {
      default: "",
      description: "Produkt der repræsentere effektive timer (varenummer)",
      type: "string",
    },
    unicontaMachineDimension: {
      default: null,
      description: "Vælg dimension der indeholder maskiner",
      enum: [null, 1, 2, 3, 4, 5],
    },
    unicontaManHoursMachines: {
      default: [],
      description:
        'Liste af ID-strenge (Dimension) på de maskiner der bruges til at repræsentere forskellige slags mandsarbejde. Eksempel: ["101", "102", "103"]',
      items: {type: "string"},
      type: "array",
    },
    unicontaPullerDimension: {
      default: null,
      description: "Vælg dimension der indeholder trækkere",
      enum: [null, 1, 2, 3, 4, 5],
    },
    unicontaSync: {default: false, description: "Uniconta er aktiveret", type: "boolean"},
    unicontaVariantDME: {
      default: false,
      description: "Aktiver Uniconta DME (itm8) variant",
      type: "boolean",
    },
    unregisteredBreakAfterMinutes: {
      default: 15,
      description: "unregisteredBreakAfterMinutes",
      type: "integer",
    },
    unregisteredWarningAfterMinutes: {
      default: 2,
      description:
        "Grænse før kalenderen vises markering af uregistreret tid mellem udførsel af opgaver.",
      type: "integer",
    },
    unsetValidatedAndRecordedOnError: {
      default: true,
      description:
        'Opgaver bliver "afgodkendt" og ført tilbage til "godkendelseslisten", såfremt der er fejl i overførslen',
      type: "boolean",
    },
    useApproveReport: {
      default: false,
      description:
        "Enkelte opgaver/dagsedler kan overføres til e-conomic vha selection (2 trins raket)",
      type: "boolean",
    },
    useCalendarWorkHours: {
      default: false,
      description:
        'Illustrer "normal arbejdstid" i kalender. (Opsættes "manuelt" ved CalendarWorkHours i databasen.). (*** BØR FJERNES I SAMRÅD MED SKAVE*****)',
      type: "boolean",
    },
    useCorrectedCountOnCostLines: {
      default: false,
      description: "Skriv korrigerede værdier til projekt",
      type: "boolean",
    },
    useCustomerAddressAsDefaultTaskAddress: {
      default: true,
      description: "useCustomerAddressAsDefaultTaskAddress",
      type: "boolean",
    },
    useEmployeeGroups: {
      default: true,
      description: "Medarbejderkolonnerne grupperes ud fra medarbejdergrupper",
      type: "boolean",
    },
    usePunchInOut: {
      default: false,
      description: "Stempel ind/ud - specialprogrammering kontakt AFT",
      type: "boolean",
    },
    userCellphoneExtra: {
      default: false,
      description: "Vis ekstra mobilnummer felt på medarbejdere (Kun synlig for administratorer)",
      type: "boolean",
    },
    userListColumns: {
      additionalProperties: false,
      default: {
        employee: {
          desktop: [
            "alias",
            "name",
            "username",
            "cellphone",
            "email",
            "cellphoneIcon",
            "emailIcon",
          ],
          mobile: ["alias", "name", "cellphoneIcon", "emailIcon"],
          tablet: ["alias", "name", "username", "cellphone", "email", "cellphoneIcon", "emailIcon"],
        },
        manager: {
          desktop: [
            "username",
            "alias",
            "name",
            "cellphone",
            "cellphoneExtra",
            "email",
            "role",
            "cellphoneIcon",
            "iceWarningIcon",
            "emailIcon",
          ],
          mobile: ["alias", "name", "cellphoneIcon", "emailIcon"],
          tablet: [
            "username",
            "alias",
            "name",
            "cellphone",
            "cellphoneExtra",
            "email",
            "role",
            "cellphoneIcon",
            "iceWarningIcon",
            "emailIcon",
          ],
        },
      },
      description:
        'Kolonneopsætning for medarbejderlisten\n{ "manager": { "mobile": [ "alias", "name", "cellphoneIcon", "emailIcon" ], "tablet": [ "username", "alias", "name", "cellphone", "email", "role", "cellphoneIcon", "iceWarningIcon", "emailIcon" ], "desktop": [ "username", "alias", "name", "cellphone", "email", "role", "cellphoneIcon", "iceWarningIcon", "emailIcon" ] }, "employee": { "mobile": [ "alias", "name", "cellphoneIcon", "emailIcon" ], "tablet": [ "alias", "name", "cellphoneIcon", "emailIcon" ], "desktop": [ "alias", "name", "cellphoneIcon", "emailIcon" ] } }',
      properties: {
        employee: {$ref: "#/definitions/userListColumnsDeviceTypesSpecification"},
        manager: {$ref: "#/definitions/userListColumnsManagerDeviceTypesSpecification"},
      },
      required: ["employee", "manager"],
      type: "object",
    },
    userProfileEmployeeNumber: {
      default: false,
      description: "Medarbejder nummer/ID (til Dataløn og e-conomic projekt)",
      type: "boolean",
    },
    userProfileNormalTransportKilometers: {
      default: false,
      description: "Afstand til arbejde for medarbejder",
      type: "boolean",
    },
    userProfileNormalTransportKilometersDefault: {
      default: 0,
      description: 'Standardværdi for "normal km til arbejde" ved oprettelse af nye brugere.',
      type: "integer",
    },
    userProfileNormalTransportMinutes: {
      default: false,
      description: "Transport tid til arbejde for medarbejder",
      type: "boolean",
    },
    userProfileNormalTransportMinutesDefault: {
      default: 0,
      description:
        'Standardværdi for "normal transporttid til arbejde" ved oprettelse af nye brugere.',
      type: "integer",
    },
    warnIfNoWorkplace: {
      default: false,
      description: 'Advarsel vises hvis "Opgaven mangler et arbejdssted"',
      type: "boolean",
    },
    warnOnIncompleteRouteParts: {
      default: true,
      description: "Advarsel hvis ikke alle punkter på en rute er udført",
      type: "boolean",
    },
    website: {default: "", description: "Virksomhedens hjemmeside", type: "string"},
    workDaySplitThresholdMinutes: {
      default: null,
      description:
        "Hvor længe skal en medarbejder have været inaktiv før CO betragter som det en ny arbejdsdag. Hvis denne setting er null, så hører arbejdsperioder til en arbejdsdag på den kalenderdato som de startede på, og der findes kun en arbejdsdag på datoen. Hvis en grænse er angivet så starter en ny arbejdsdag når der er hul mellem arbejdsperioder større end grænsen og der kan findes flere arbejdsdage for samme kalenderdato. workDaySplitThresholdMinutes, hvis angivet, skal være større end eller lig med periodSplitThresholdMinutes. Overenskomstmæssigt set kan man se workDaySplitThresholdMinutes som den periode en medarbejder skal have haft fri efter én arbejdsdag, før medarbejderen kan møde ind til en ny arbejdsdag.",
      type: ["integer", "null"],
    },
    workplaceButtonUnderCustomer: {
      default: false,
      description: 'Flyt "Vælg arbejdssted" op under "vælg kunde"',
      type: "boolean",
    },
    workshopCreatedByColumn: {
      default: false,
      description:
        "Kolonne med initaler på den person der har værkstedsopgaven vises på opgavelisten",
      type: "boolean",
    },
    workshopCreatedDateColumn: {
      default: false,
      description: "Kolonne med oprettelses dato vises i opgaveliste.",
      type: "boolean",
    },
    workshopEnabled: {default: false, description: "Værksted aktiveres", type: "boolean"},
    workshopVehicleIdentificationNumber: {
      default: false,
      description: 'Kolonne med Stelnummer på køretøjet vises på "maskiner" listen',
      type: "boolean",
    },
    workshopWorkTypes: {
      default: [],
      description: "Interne arbejdsområder der vises i opgavelisten",
      items: {type: "string"},
      type: "array",
    },
    workTypeAutoMachines: {
      anyOf: [
        {additionalProperties: {$ref: "#/definitions/stringArray"}, type: "object"},
        {type: "null"},
      ],
      default: null,
      description: "Områder med faste maskiner",
    },
    workTypeBeforeTimerBeforeMachinePriceItems: {
      default: false,
      description:
        "Hvis slået til, så vil opgavers prislinjer under material/fakturering sorteres pr. variant så eventuelle prislinjer fra områdets variant kommer før prislinjerne fra eventuelle maskiners varianter.",
      type: "boolean",
    },
    workTypeExtraTimers: {
      additionalProperties: {$ref: "#/definitions/stringArray"},
      default: {},
      description: "workTypeExtraTimers",
      type: "object",
    },
    workTypeGenericPrimaryTimerLabel: {
      additionalProperties: {type: "string"},
      default: {},
      description: "workTypeGenericPrimaryTimerLabel",
      type: "object",
    },
    workTypeHideTimers: {
      additionalProperties: {$ref: "#/definitions/stringArray"},
      default: {},
      description: "workTypeHideTimers",
      type: "object",
    },
    workTypeMachineExtraTimers: {
      additionalProperties: {
        additionalProperties: {$ref: "#/definitions/stringArray"},
        type: "object",
      },
      default: {},
      description:
        'Vis ekstra "effektiv tid"-variant-timer-knapper for specifikke maskine+område-kombinationer; specificeret på formen {område-ID: {maskine-ID: [timer-ID, ...], ...}, ...}',
      type: "object",
    },
    workTypePriceGroupExtraTimers: {
      additionalProperties: {
        additionalProperties: {$ref: "#/definitions/stringArray"},
        type: "object",
      },
      default: {},
      description: "workTypePriceGroupExtraTimers",
      type: "object",
    },
    workTypePriceGroupGenericPrimaryTimerLabel: {
      additionalProperties: {$ref: "#/definitions/stringObject"},
      default: {},
      description: "workTypePriceGroupGenericPrimaryTimerLabel",
      type: "object",
    },
    yieldLogDefaultCrop: {
      default: "grass",
      description: "Standard afgrøde (grass, wholecrop, corn) (gammel)",
      enum: ["grass", "wholecrop", "corn", ""],
      type: "string",
    },
    yieldLogDefaultUnit: {
      default: "tonne",
      description: "Standard enhed (tonne, m3) (gammel)",
      enum: ["m3", "tonne", ""],
      type: "string",
    },
  },
  title: "Config",
  type: "object",
} as const;

export const schema = {...baseSchema, required: Object.keys(baseSchema.properties)} as const;

// just to validate that the type of schema matches JSONSchemaWithProperties
export const schemaAsJSONSchemaType: DeepReadonly<JSONSchemaWithProperties> = schema;
