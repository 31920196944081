import {Config} from "@co-common-libs/config";
import {CustomerFormMember} from "./types";

export function computeRequiredInputFields(
  inputFields: ReadonlySet<CustomerFormMember>,
  customerSettings: Pick<Config, "customerRequiredFields" | "economicSync">,
): ReadonlySet<CustomerFormMember> {
  // name is always required
  const required: Set<CustomerFormMember> = new Set([
    CustomerFormMember.NAME,
    ...(customerSettings.customerRequiredFields as CustomerFormMember[]),
  ]);

  // customer number always required if visible when economicSync is enabled
  if (customerSettings.economicSync && inputFields.has(CustomerFormMember.CUSTOMER_NUMBER)) {
    required.add(CustomerFormMember.CUSTOMER_NUMBER);
  }

  return required;
}
