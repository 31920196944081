import {Config} from "@co-common-libs/config";
import {MachineUrl, MachineUse, Task, Timer} from "@co-common-libs/resources";
import {ErrorAction, PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";
import {WizardCompletedDialog} from "./wizard-completed-dialog";

const messages = defineMessages({
  completedAsInternalHeader: {
    defaultMessage:
      "Viderefør til ny opgave, og markér indeværende som fuldført som intern opgave med følgende data:",
    id: "task-instance.header.mark-as-completed-as-internal",
  },
  completedAsInternalHeaderNoData: {
    defaultMessage: "Viderefør til ny opgave, og markér indeværende som fuldført som intern opgave",
    id: "task-instance.header.mark-as-completed-as-internal-no-data",
  },
  completedContinuationHeader: {
    defaultMessage:
      "Viderefør til ny opgave, og markér indeværende som fuldført med følgende data:",
    id: "task-instance.header.mark-as-completed-continuation",
  },
  completedContinuationHeaderNoData: {
    defaultMessage: "Viderefør til ny opgave, og markér indeværende som fuldført",
    id: "task-instance.header.mark-as-completed-continuation-no-data",
  },
  completedHeaderCancelled: {
    defaultMessage: "Aflevér opgaven som aflyst med følgende data:",
    id: "task-instance.header.mark-as-completed-cancelled",
  },
  completedHeaderCancelledNoData: {
    defaultMessage: "Aflevér opgaven som aflyst",
    id: "task-instance.header.mark-as-completed-cancelled-no-data",
  },
  completedHeaderError: {
    defaultMessage: "Opgaven kan ikke afleveres på grund af:",
    id: "task-instance.header.mark-as-completed-error",
  },
  completedHeaderOk: {
    defaultMessage: "Aflevér opgaven med følgende data:",
    id: "task-instance.header.mark-as-completed-ok",
  },

  toAdministrationDialogTitle: {
    defaultMessage: "Aflevér til administrationen?",
  },
});

interface CompletedDialogProps {
  accommodationAllowanceRegistratedOnTaskDate: boolean;
  accommodationRemunerationGroup?: string;
  cancelled: boolean;
  canRegisterAccommodation: boolean;
  completedAsInternal: boolean;
  continuation: boolean;
  customerSettings: Config;
  finalIntervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | null;
    readonly toTimestamp: string;
  }[];
  onAction?: (action: ErrorAction) => void;
  onCancel: (event: unknown) => void;
  onOk?: (
    event: unknown,
    values: {
      machineUse: readonly MachineUse[] | null;
      needsPreparation: boolean;
      needsPreparationMinutes: number;
      needsRepair: boolean;
      repairNote: string;
      selectedMachines: readonly MachineUrl[];
      selectedPreparationMachines: readonly MachineUrl[];
    } | null,
    registerAccommodationGroup: string | null,
  ) => void;
  open: boolean;
  secondaryTimers: ReadonlySet<Timer>;
  task: Task;
}

interface CompletedDialogState {
  registerAccommodationGroup: string | null;
}

class CompletedDialog extends PureComponent<CompletedDialogProps, CompletedDialogState> {
  state: CompletedDialogState = {
    registerAccommodationGroup: null,
  };

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleOk(
    event: unknown,
    values: {
      machineUse: readonly MachineUse[] | null;
      needsPreparation: boolean;
      needsPreparationMinutes: number;
      needsRepair: boolean;
      repairNote: string;
      selectedMachines: readonly MachineUrl[];
      selectedPreparationMachines: readonly MachineUrl[];
    } | null = null,
  ): void {
    const {onOk} = this.props;
    if (onOk) {
      onOk(event, values, this.state.registerAccommodationGroup);
    }
  }

  @bind
  handleCancel(): void {
    this.props.onCancel(null);
  }
  @bind
  handleRegisterAccommodationChanged(
    registerAccommodation: boolean,
    remunerationGroup: string,
  ): void {
    if (registerAccommodation) {
      this.setState({registerAccommodationGroup: remunerationGroup});
    } else {
      this.setState({registerAccommodationGroup: null});
    }
  }

  render(): JSX.Element | null {
    if (!this.props.task) {
      return null;
    }
    const {
      accommodationAllowanceRegistratedOnTaskDate,
      accommodationRemunerationGroup,
      cancelled,
      canRegisterAccommodation,
      completedAsInternal,
      continuation,
      customerSettings,
      finalIntervals,
      onAction,
      open,
      secondaryTimers,
      task,
    } = this.props;
    const {formatMessage} = this.context;
    const errorHeader = formatMessage(messages.completedHeaderError);
    const title = formatMessage(messages.toAdministrationDialogTitle);

    let completedHeader;
    if (customerSettings.taskCompleteOverview || !task.order) {
      if (completedAsInternal) {
        completedHeader = formatMessage(messages.completedAsInternalHeader);
      } else if (continuation) {
        completedHeader = formatMessage(messages.completedContinuationHeader);
      } else if (cancelled) {
        completedHeader = formatMessage(messages.completedHeaderCancelled);
      } else {
        completedHeader = formatMessage(messages.completedHeaderOk);
      }
    } else {
      if (completedAsInternal) {
        completedHeader = formatMessage(messages.completedAsInternalHeaderNoData);
      } else if (continuation) {
        completedHeader = formatMessage(messages.completedContinuationHeaderNoData);
      } else if (cancelled) {
        completedHeader = formatMessage(messages.completedHeaderCancelledNoData);
      } else {
        completedHeader = "";
      }
    }
    return (
      <WizardCompletedDialog
        accommodationAllowanceRegistratedOnTaskDate={accommodationAllowanceRegistratedOnTaskDate}
        accommodationRemunerationGroup={accommodationRemunerationGroup}
        cancelled={cancelled}
        canRegisterAccommodation={canRegisterAccommodation}
        completedAsInternal={completedAsInternal}
        errorHeaderText={errorHeader}
        finalIntervals={finalIntervals}
        okHeaderText={completedHeader}
        open={open}
        registerAccommodation={!!this.state.registerAccommodationGroup}
        secondaryTimers={secondaryTimers}
        task={{...task, cancelled: !!cancelled}}
        title={title}
        onAction={onAction}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
        onRegisterAccommodationChanged={this.handleRegisterAccommodationChanged}
      />
    );
  }
}

export default CompletedDialog;
