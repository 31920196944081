import {Contact, CustomerUrl, emptyContact, urlToId} from "@co-common-libs/resources";
import {actions, getExtendedCustomerSettings} from "@co-frontend-libs/redux";
import {useCallWithFalse} from "@co-frontend-libs/utils";
import {
  Card,
  CardHeader,
  Checkbox,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {addDanishCountryPrefix} from "app-utils";
import {sortBy} from "lodash";
import DeleteIcon from "mdi-react/DeleteIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PlusIcon from "mdi-react/PlusIcon";
import StarIcon from "mdi-react/StarIcon";
import StarOutlineIcon from "mdi-react/StarOutlineIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {DeleteResourceDialog} from "../delete-resource-dialog";
import {
  type FloatingActionButtonData,
  SingleFloatingActionButton,
} from "../floating-action-buttons";
import {CreateCustomerContactDialog} from "./create-customer-contact-dialog";
import {EditCustomerContactDialog} from "./edit-customer-contact-dialog";

const EDIT_DELETE_COLUMN_STYLE: React.CSSProperties = {width: 128} as const;

interface CustomerContactTableRowProps {
  contact: Contact;
  enableContactManagement: boolean;
  onDefaultContactChange: (contact: Contact, selected: boolean) => void;
  onDeleteClicked: (contact: Contact) => void;
  onEditClicked: (contact: Contact) => void;
  showCellphoneColumn: boolean;
  showFaxColumn: boolean;
}
const CustomerContactTableRow = React.memo(function CustomerContactTableRow(
  props: CustomerContactTableRowProps,
): JSX.Element {
  const {
    contact,
    enableContactManagement,
    onDefaultContactChange,
    onDeleteClicked,
    onEditClicked,
    showCellphoneColumn,
    showFaxColumn,
  } = props;

  const handleDefaultContactChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onDefaultContactChange(contact, checked);
    },
    [contact, onDefaultContactChange],
  );
  const editClicked = useCallback(() => onEditClicked(contact), [contact, onEditClicked]);
  const deleteClicked = useCallback(() => onDeleteClicked(contact), [contact, onDeleteClicked]);

  return (
    <TableRow>
      <TableCell>
        <FormControlLabel
          control={
            <Checkbox
              checked={contact.defaultContact}
              checkedIcon={<StarIcon />}
              icon={<StarOutlineIcon />}
              onChange={handleDefaultContactChange}
            />
          }
          label=""
          style={{margin: "auto"}}
        />
      </TableCell>
      <TableCell>{contact.name}</TableCell>
      <TableCell>{contact.email}</TableCell>
      <TableCell>{addDanishCountryPrefix(contact.phone)}</TableCell>
      {showCellphoneColumn ? (
        <TableCell>{addDanishCountryPrefix(contact.cellphone)}</TableCell>
      ) : null}
      {showFaxColumn ? <TableCell>{addDanishCountryPrefix(contact.fax)}</TableCell> : null}
      {enableContactManagement && (
        <TableCell style={{width: 128}}>
          <IconButton onClick={editClicked}>
            <PencilIcon />
          </IconButton>
          <IconButton onClick={deleteClicked}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
});

interface CustomerContactTableProps {
  customerContacts: readonly Contact[];
  customerUrl: CustomerUrl;
}
export const CustomerContactTable = React.memo(function CustomerContactTable(
  props: CustomerContactTableProps,
): JSX.Element {
  const {customerContacts, customerUrl} = props;

  const {
    contact: {canManage, contactsMayHaveCellphone, contactsMayHaveFax},
  } = useSelector(getExtendedCustomerSettings);

  const dispatch = useDispatch();
  const handleDefaultContactChange = useCallback(
    (contact: Contact, selected: boolean) => {
      // The middleware function onlyOneDefaultContactPerCustomer ensures only
      // one contact per customer is marked as default. Hence we just need
      // update the newly selected default contact and the middleware handles the
      // rest. It is okay for there to be no default contact selected.
      if (contact.defaultContact !== selected) {
        dispatch(actions.update(contact.url, [{member: "defaultContact", value: selected}]));
      }
    },
    [dispatch],
  );

  const [deleteDialogOpenFor, setDeleteDialogOpenFor] = useState<Contact | null>(null);
  const closeDeleteDialog = useCallback(
    () => setDeleteDialogOpenFor(null),
    [setDeleteDialogOpenFor],
  );
  const handleDeleteClicked = useCallback(
    (contact: Contact) => setDeleteDialogOpenFor(contact),
    [setDeleteDialogOpenFor],
  );

  const intl = useIntl();

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const closeCreateDialog = useCallWithFalse(setCreateDialogOpen);
  const createContactFabAction = React.useMemo(
    (): FloatingActionButtonData => ({
      buttonIcon: <PlusIcon />,
      name: "",
      onClick: () => {
        setCreateDialogOpen(true);
      },
      tooltipTitle: "",
    }),
    [setCreateDialogOpen],
  );

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const closeEditDialog = useCallWithFalse(setEditDialogOpen);
  const [contactForEditing, setContactForEditing] = useState<Contact | null>(null);
  const handleEditClicked = useCallback(
    (contact: Contact) => {
      setContactForEditing(contact);
      setEditDialogOpen(true);
    },
    [setContactForEditing, setEditDialogOpen],
  );

  return (
    <>
      <Card style={{marginBottom: 22}}>
        {canManage ? (
          <SingleFloatingActionButton button={createContactFabAction} variant="component" />
        ) : null}
        <CardHeader title={intl.formatMessage({defaultMessage: "Kontaktpersoner"})} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{width: 24}} />
              <TableCell>
                <FormattedMessage defaultMessage="Navn" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Email" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Telefon nr." />
              </TableCell>
              {contactsMayHaveCellphone ? (
                <TableCell>
                  <FormattedMessage defaultMessage="Mobil nr." />
                </TableCell>
              ) : null}
              <TableCell>
                <FormattedMessage defaultMessage="Fax nr." />
              </TableCell>
              {canManage && <TableCell style={EDIT_DELETE_COLUMN_STYLE} />}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortBy(customerContacts, (c) => c.name).map((contact: Contact) => (
              <CustomerContactTableRow
                key={urlToId(contact.url)}
                contact={contact}
                enableContactManagement={canManage}
                showCellphoneColumn={contactsMayHaveCellphone}
                showFaxColumn={contactsMayHaveFax}
                onDefaultContactChange={handleDefaultContactChange}
                onDeleteClicked={handleDeleteClicked}
                onEditClicked={handleEditClicked}
              />
            ))}
          </TableBody>
        </Table>
      </Card>
      {deleteDialogOpenFor && (
        <DeleteResourceDialog
          instance={deleteDialogOpenFor}
          open={!!deleteDialogOpenFor}
          onCancel={closeDeleteDialog}
          onOk={closeDeleteDialog}
        >
          {intl.formatMessage({defaultMessage: "Slet kontakt?"})}
        </DeleteResourceDialog>
      )}
      <CreateCustomerContactDialog
        contactTemplate={emptyContact}
        customerUrl={customerUrl}
        open={createDialogOpen}
        onCancel={closeCreateDialog}
        onOk={closeCreateDialog}
      />
      {contactForEditing && (
        <EditCustomerContactDialog
          contact={contactForEditing}
          open={editDialogOpen}
          onCancel={closeEditDialog}
          onOk={closeEditDialog}
        />
      )}
    </>
  );
});
