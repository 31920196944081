import {User, UserProfile} from "@co-common-libs/resources";
import {isValidEmail, notNull} from "@co-common-libs/utils";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {
  getCurrentRole,
  getCurrentUserURL,
  getCustomerSettings,
  getUserArray,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue, useResettingState} from "@co-frontend-libs/utils";
import {DialogContent} from "@material-ui/core";
import {postalCodes} from "app-utils";
import React, {useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ProcessingDialog} from "../processing-dialog";
import {UserCreateData, UserEditData, UserProfileData} from "./types";

interface CreateProps {
  data?: undefined;
  onCancel: () => void;
  onOk: (userData: UserCreateData, userProfileData: UserProfileData) => Promise<void>;
  open: boolean;
  processingTitle: string;
  title: string;
}

interface EditProps {
  data: {
    user: Readonly<User>;
    userProfile: Readonly<UserProfile>;
  };
  onCancel: () => void;
  onOk: (userData: UserEditData, userProfileData: UserProfileData) => void;
  open: boolean;
  processingTitle?: undefined;
  title: string;
}

type UserCreateEditDialogProps = CreateProps | EditProps;

export const UserCreateEditDialog = function UserCreateEditDialog(
  props: UserCreateEditDialogProps,
): JSX.Element | null {
  const {data, onCancel, onOk, open, title} = props;
  const user = data?.user;
  const userProfile = data?.userProfile;
  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);
  const userArray = useSelector(getUserArray);
  const currentRole = useSelector(getCurrentRole);
  const currentUserUrl = useSelector(getCurrentUserURL);

  const [savingPromise, setSavingPromise] = useResettingState<Promise<void> | null>(null, open);

  const [saveInProgress, setSaveInProgress] = useResettingState<boolean>(false, open);
  const setSaveInProgressTrue = useCallWithTrue(setSaveInProgress);
  const setSaveInProgressFalse = useCallWithFalse(setSaveInProgress);

  const [loginIdentifier, setLoginIdentifier] = useResettingState(
    user?.loginIdentifier ?? "",
    open,
  );
  const [password, setPassword] = useResettingState("", open);
  const [address, setAddress] = useResettingState(userProfile?.address ?? "", open);
  const [alias, setAlias] = useResettingState(userProfile?.alias ?? "", open);
  const [cellphone, setCellphone] = useResettingState(userProfile?.cellphone ?? "", open);
  const [cellphoneExtra, setCellphoneExtra] = useResettingState(
    userProfile?.cellphoneExtra ?? "",
    open,
  );
  const [city, setCity] = useResettingState(userProfile?.city ?? "", open);
  const [email, setEmail] = useResettingState(userProfile?.email ?? "", open);
  const [employeeNumber, setEmployeeNumber] = useResettingState(
    userProfile?.employeeNumber ?? "",
    open,
  );
  const [name, setName] = useResettingState(userProfile?.name ?? "", open);
  const [note, setNote] = useResettingState(userProfile?.note ?? "", open);
  const [postalCode, setPostalCode] = useResettingState(userProfile?.postalCode ?? "", open);

  const handleLoginIdentifierFieldBlur = useCallback(() => {
    if (alias === "") {
      setAlias(loginIdentifier.toLocaleUpperCase());
    }
  }, [alias, loginIdentifier, setAlias]);

  const handlePostalCodeChange = useCallback(
    (value: string): void => {
      const cityForPostalCode = postalCodes[value];
      setPostalCode(value);
      if (cityForPostalCode) {
        setCity(cityForPostalCode);
      }
    },
    [setCity, setPostalCode],
  );

  const loginIdentifierDuplicate: boolean = useMemo(() => {
    if (!loginIdentifier) {
      return false;
    } else {
      const upperLoginIdentifier = loginIdentifier.toUpperCase();
      return userArray.some(
        (otherUser) =>
          otherUser.loginIdentifier === upperLoginIdentifier && otherUser.url !== user?.url,
      );
    }
  }, [loginIdentifier, user?.url, userArray]);

  const emailIsValidOrEmpty = useMemo(() => (email ? isValidEmail(email) : true), [email]);

  const handleOk = useCallback(() => {
    const upperLoginIdentifier = loginIdentifier.toUpperCase();
    const userProfileData = {
      address,
      alias,
      cellphone,
      cellphoneExtra,
      city,
      email,
      employeeNumber,
      name,
      note,
      postalCode,
    };
    let processUser: Promise<void> | void;
    if (data === undefined) {
      processUser = onOk({loginIdentifier: upperLoginIdentifier, password}, userProfileData);
    } else {
      processUser = onOk({loginIdentifier: upperLoginIdentifier}, userProfileData);
    }

    if (processUser !== undefined) {
      setSavingPromise(processUser);
      setSaveInProgressTrue();
    }
  }, [
    address,
    alias,
    cellphone,
    cellphoneExtra,
    city,
    data,
    email,
    employeeNumber,
    loginIdentifier,
    name,
    note,
    onOk,
    password,
    postalCode,
    setSaveInProgressTrue,
    setSavingPromise,
  ]);

  if (!currentRole || !currentUserUrl) {
    return null;
  }

  const editingExistingInstance = data !== undefined;
  const userIsManagerOrProjectManager = currentRole.manager;
  const editingOwnUserProfile = editingExistingInstance && user?.url === currentUserUrl;
  if (!userIsManagerOrProjectManager && !editingOwnUserProfile) {
    return null;
  }

  const c5AgromatSync = customerSettings.c5Sync && customerSettings.navSyncProfile !== "bejstrupm";

  const elements = [
    <TrimTextField
      key="loginIdentifier"
      autoFocus
      fullWidth
      error={loginIdentifierDuplicate}
      helperText={
        loginIdentifierDuplicate
          ? intl.formatMessage({
              defaultMessage: "Der eksisterer allerede en bruger med dette brugernavn",
            })
          : undefined
      }
      label={intl.formatMessage({defaultMessage: "Brugernavn"})}
      margin="dense"
      readonly={!userIsManagerOrProjectManager}
      required={userIsManagerOrProjectManager}
      value={loginIdentifier}
      variant="outlined"
      onBlur={handleLoginIdentifierFieldBlur}
      onChange={setLoginIdentifier}
    />,
    !editingExistingInstance ? (
      <TrimTextField
        key="password"
        fullWidth
        required
        autoComplete="new-password"
        label={intl.formatMessage({defaultMessage: "Adgangskode"})}
        margin="dense"
        type="password"
        value={password}
        variant="outlined"
        onChange={setPassword}
      />
    ) : null,
    <TrimTextField
      key="alias"
      fullWidth
      helperText={
        c5AgromatSync
          ? intl.formatMessage({
              defaultMessage: "Initialer som de står i C5",
            })
          : undefined
      }
      label={intl.formatMessage({defaultMessage: "Initialer"})}
      margin="dense"
      readonly={!userIsManagerOrProjectManager}
      required={userIsManagerOrProjectManager && c5AgromatSync}
      value={alias}
      variant="outlined"
      onChange={setAlias}
    />,
    <TrimTextField
      key="name"
      fullWidth
      label={intl.formatMessage({defaultMessage: "Navn"})}
      margin="dense"
      readonly={!userIsManagerOrProjectManager || c5AgromatSync}
      value={name}
      variant="outlined"
      onChange={setName}
    />,
    customerSettings.userProfileEmployeeNumber ? (
      <TrimTextField
        key="employeeNumber"
        fullWidth
        label={intl.formatMessage({defaultMessage: "Medarbejdernummer"})}
        margin="dense"
        readonly={!userIsManagerOrProjectManager}
        value={employeeNumber}
        variant="outlined"
        onChange={setEmployeeNumber}
      />
    ) : null,
    <TrimTextField
      key="address"
      fullWidth
      label={intl.formatMessage({defaultMessage: "Adresse"})}
      margin="dense"
      readonly={c5AgromatSync}
      value={address}
      variant="outlined"
      onChange={setAddress}
    />,
    <TrimTextField
      key="postalCode"
      fullWidth
      label={intl.formatMessage({defaultMessage: "Postnummer"})}
      margin="dense"
      readonly={c5AgromatSync}
      value={postalCode}
      variant="outlined"
      onChange={handlePostalCodeChange}
    />,
    <TrimTextField
      key="city"
      fullWidth
      label={intl.formatMessage({defaultMessage: "By"})}
      margin="dense"
      readonly={c5AgromatSync}
      value={city}
      variant="outlined"
      onChange={setCity}
    />,
    <TrimTextField
      key="email"
      fullWidth
      error={!emailIsValidOrEmpty}
      helperText={
        !emailIsValidOrEmpty
          ? intl.formatMessage({
              defaultMessage: "E-mailadressen er ikke gyldig",
            })
          : undefined
      }
      label={intl.formatMessage({defaultMessage: "E-mail"})}
      margin="dense"
      value={email}
      variant="outlined"
      onChange={setEmail}
    />,
    <TrimTextField
      key="cellphone"
      fullWidth
      label={intl.formatMessage({defaultMessage: "Mobil"})}
      margin="dense"
      value={cellphone}
      variant="outlined"
      onChange={setCellphone}
    />,
    customerSettings.userCellphoneExtra ? (
      <TrimTextField
        key="cellphoneExtra"
        fullWidth
        label={intl.formatMessage({
          defaultMessage: "Mobil (Kun synlig for administrationen)",
        })}
        margin="dense"
        value={cellphoneExtra}
        variant="outlined"
        onChange={setCellphoneExtra}
      />
    ) : null,
    <TrimTextField
      key="note"
      fullWidth
      multiline
      label={intl.formatMessage({
        defaultMessage: "Note",
      })}
      margin="dense"
      readonly={!userIsManagerOrProjectManager}
      value={note}
      variant="outlined"
      onChange={setNote}
    />,
  ].filter(notNull);

  const hasRequiredElements = elements.some((element) => element.props.required);
  const dataIsValid = !elements.some(
    (element) => element.props.error || (element.props.required && !element.props.value),
  );

  return (
    <>
      {savingPromise ? (
        <ProcessingDialog
          processPromise={savingPromise}
          title={props.processingTitle || title}
          onClose={setSaveInProgressFalse}
        />
      ) : null}

      <ResponsiveDialog
        okDisabled={!dataIsValid}
        open={open && !saveInProgress}
        requiredFieldsHelperText={hasRequiredElements}
        title={title}
        onCancel={onCancel}
        onOk={handleOk}
      >
        <DialogContent>{elements}</DialogContent>
      </ResponsiveDialog>
    </>
  );
};
