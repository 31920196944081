import {ImportPreviewWorkType} from "@co-common-libs/resources-utils";
import {WorkTypesPreviewSelectionDialog} from "@co-frontend-libs/components";
import {jsonFetch, translateNetworkError} from "@co-frontend-libs/utils";
import {useAsyncAbortable} from "@react-hookz/web";
import {importPreviewUrl} from "api-endpoint-urls";
import {SyncedResourceHandleKey} from "integration-constants";
import React, {useCallback, useEffect} from "react";
import {useIntl} from "react-intl";

interface ImportWorkTypesPreviewDialogProps {
  filter?: ReadonlySet<string>;
  onCancel(): void;
  onOk(workTypes: ImportPreviewWorkType[]): void;
  open: boolean;
  type: Extract<SyncedResourceHandleKey, "externalWorkType" | "internalWorkType">;
}

export const ImportWorkTypesPreviewDialog = React.memo(
  function ImportExternalWorkTypesPreviewDialog(
    props: ImportWorkTypesPreviewDialogProps,
  ): React.JSX.Element {
    const {filter, open, type} = props;

    const [state, actions] = useAsyncAbortable<readonly ImportPreviewWorkType[] | null>(
      (signal) =>
        jsonFetch(importPreviewUrl(type)).then(({data}) => {
          if (signal.aborted) {
            return [];
          } else {
            return filter
              ? (data as ImportPreviewWorkType[]).filter((entry) => !filter.has(entry.remoteUrl))
              : data;
          }
        }),
      null,
    );

    useEffect(() => {
      if (open) {
        actions.execute();
      } else {
        actions.reset();
      }
    }, [actions, open]);

    const onOk = useCallback(
      (identifiers: ReadonlySet<string>) => {
        props.onOk(
          (state.result || []).filter((productGroup) => identifiers.has(productGroup.identifier)),
        );
      },
      [state.result, props],
    );

    const intl = useIntl();

    return (
      <WorkTypesPreviewSelectionDialog
        {...props}
        error={state.status === "error" ? translateNetworkError(state.error, intl) : undefined}
        previewWorkTypes={state.result}
        onOk={onOk}
      />
    );
  },
);
