import {ContactUrl, CustomerUrl} from "@co-common-libs/resources";
import {useModal} from "@co-frontend-libs/components";
import {getContactLookup} from "@co-frontend-libs/redux";
import {Button, IconButton, Typography} from "@material-ui/core";
import CloseIcon from "mdi-react/CloseIcon";
import React, {Ref, forwardRef, useCallback} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {ContactSelectCreateDialog} from "../contact";
import {ContactInfo} from "./contact-info";
import {InformationBlockPropsBase} from "./information-block-props-base";

interface ContactBlockProps extends InformationBlockPropsBase {
  contactUrl: ContactUrl | null;
  customerUrl: CustomerUrl | null;
  onContactChanged: (contactUrl: ContactUrl | null) => void;
}

export const ContactBlock = forwardRef(function ContactBlock(
  {
    clearDisabled,
    contactUrl,
    customerUrl,
    hideButtons,
    onContactChanged,
    selectDisabled,
  }: ContactBlockProps,
  selectButtonRef: Ref<HTMLButtonElement>,
): JSX.Element {
  const contactLookup = useSelector(getContactLookup);
  const contact = contactUrl ? contactLookup(contactUrl) : undefined;
  const [modal, prompt] = useModal(ContactSelectCreateDialog);

  const selectContactClicked = useCallback(async () => {
    if (customerUrl) {
      const selectedContact = await prompt({customerUrl});
      if (selectedContact) {
        onContactChanged(selectedContact);
      }
    }
  }, [customerUrl, prompt, onContactChanged]);

  const clearContactClicked = useCallback(() => {
    onContactChanged(null);
  }, [onContactChanged]);

  return (
    <>
      <Typography variant="body1">
        <FormattedMessage defaultMessage="Kontaktperson" />
      </Typography>
      {!hideButtons && (
        <>
          <Button
            ref={selectButtonRef}
            color="secondary"
            disabled={selectDisabled}
            variant="contained"
            onClick={selectContactClicked}
          >
            <FormattedMessage defaultMessage="Vælg" />
          </Button>
          <IconButton disabled={clearDisabled} onClick={clearContactClicked}>
            <CloseIcon />
          </IconButton>
        </>
      )}
      {contact && <ContactInfo contact={contact} />}
      {modal}
    </>
  );
});
