import {Role, User, UserProfile, UserUrl} from "@co-common-libs/resources";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {defineMessages, useIntl} from "react-intl";
import {
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericSingleSelectionSearchDialog,
} from "../search-dialog";

const messages = defineMessages({
  searchChauffeur: {
    defaultMessage: "Søg chauffør",
    id: "machine-operator-dialog.dialog-title.search-chauffeur",
  },
  searchEmployee: {
    defaultMessage: "Søg medarbejder",
    id: "machine-operator-dialog.dialog-title.search-employee",
  },
  searchEmployees: {
    defaultMessage: "Søg medarbejdere",
    id: "user-dialog.dialog-title.search-employees",
  },
  searchMachineOperator: {
    defaultMessage: "Søg maskinfører",
    id: "machine-operator-dialog.dialog-title.search-machine-operator",
  },
  selectChauffeur: {
    defaultMessage: "Vælg chauffør",
    id: "machine-operator-dialog.dialog-title.select-chauffeur",
  },
  selectEmployee: {
    defaultMessage: "Vælg medarbejder",
    id: "machine-operator-dialog.dialog-title.select-employee",
  },
  selectEmployees: {
    defaultMessage: "Vælg medarbejdere",
    id: "user-dialog.dialog-title.select-employees",
  },
  selectMachineOperator: {
    defaultMessage: "Vælg maskinfører",
    id: "machine-operator-dialog.dialog-title.select-machine-operator",
  },
});

function computeBaseChoices(
  userArray: readonly User[],
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined,
  userRoleLookup: (url: UserUrl) => Role | undefined,
): readonly EntryData<UserUrl>[] {
  const result: EntryData<UserUrl>[] = userArray
    .filter((instance) => {
      if (!instance.active) {
        return false;
      }
      const role = userRoleLookup(instance.url);
      return !role || (!role.consultant && !role.breakRoom);
    })
    .map((instance) => {
      const {url} = instance;
      const profile = userUserProfileLookup(url);
      const name = profile?.name || "";
      const initials = profile?.alias || "";
      const entry: EntryData<UserUrl> = {
        category: "standard",
        exactMatchValue: initials,
        identifier: url,
        primaryText: initials,
        searchFields: [
          {label: "Initialer", priority: 10, text: initials},
          {label: "Navn", priority: 5, text: name},
        ],
        secondaryText: name,
      };
      return entry;
    });
  return result;
}

interface UserDialogProps {
  labelVariant: "CHAUFFEUR" | "EMPLOYEE" | "MACHINEOPERATOR";
  onCancel(): void;
  onNone?: () => void;
  onOk(url: UserUrl): void;
  open: boolean;
  title?: JSX.Element | string;
  userArray: readonly User[];
  userRoleLookup: (url: UserUrl) => Role | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

export const UserDialog = React.memo(function UserDialog(props: UserDialogProps) {
  const {
    labelVariant,
    onCancel,
    onNone,
    onOk,
    open,
    userArray,
    userRoleLookup,
    userUserProfileLookup,
  } = props;
  const intl = useIntl();
  const title =
    props.title ||
    (labelVariant === "MACHINEOPERATOR"
      ? intl.formatMessage(messages.selectMachineOperator)
      : labelVariant === "EMPLOYEE"
        ? intl.formatMessage(messages.selectEmployee)
        : intl.formatMessage(messages.selectChauffeur));
  const searchTitle =
    labelVariant === "MACHINEOPERATOR"
      ? intl.formatMessage(messages.searchMachineOperator)
      : labelVariant === "EMPLOYEE"
        ? intl.formatMessage(messages.searchEmployee)
        : intl.formatMessage(messages.searchChauffeur);

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(userArray, userUserProfileLookup, userRoleLookup);

  return (
    <GenericSingleSelectionSearchDialog<UserUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={searchTitle}
      title={title}
      onCancel={onCancel}
      onNone={onNone}
      onOk={onOk}
    />
  );
});

interface MultipleUsersDialogProps {
  onCancel(): void;
  onOk(urls: ReadonlySet<UserUrl>): void;
  open: boolean;
  selected?: ReadonlySet<UserUrl> | undefined;
  userArray: readonly User[];
  userRoleLookup: (url: UserUrl) => Role | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

export const MultipleUsersDialog = React.memo(function MultipleUsersDialog(
  props: MultipleUsersDialogProps,
) {
  const {onCancel, onOk, open, selected, userArray, userRoleLookup, userUserProfileLookup} = props;
  const intl = useIntl();
  const title = intl.formatMessage(messages.selectEmployees);
  const searchTitle = intl.formatMessage(messages.searchEmployees);

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(userArray, userUserProfileLookup, userRoleLookup);

  const selectedSet = selected;
  return (
    <GenericMultiSelectionSearchDialog<UserUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={searchTitle}
      selected={selectedSet}
      title={title}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
});
