import {WorkTypeUrl} from "@co-common-libs/resources";
import {
  ConnectedMultipleCombinedWorkTypesDialog,
  ConnectedMultipleDepartmentsDialog,
} from "@co-frontend-libs/connected-components";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {useCallWithFalse} from "@co-frontend-libs/utils";
import {IconButton} from "@material-ui/core";
import FilterIcon from "mdi-react/FilterIcon";
import React, {useCallback, useState} from "react";
import {useSelector} from "react-redux";
import {FilterTypeChoices, FilterTypeSelectionDialog} from "./filter-type-selection-dialog";

interface FilterButtonProps {
  onSelectedDepartmentIdentifierSetChange: (value: ReadonlySet<string>) => void;
  onSelectedWorkTypeURLSetChange: (value: ReadonlySet<WorkTypeUrl>) => void;
  selectedDepartmentIdentifierSet: ReadonlySet<string>;
  selectedWorkTypeURLSet: ReadonlySet<WorkTypeUrl>;
}

export const FilterButton = React.memo(function FilterButton(
  props: FilterButtonProps,
): JSX.Element {
  const {
    onSelectedDepartmentIdentifierSetChange,
    onSelectedWorkTypeURLSetChange,
    selectedDepartmentIdentifierSet,
    selectedWorkTypeURLSet,
  } = props;
  const [selectFilterTypeDialogOpen, setSelectFilterTypeDialogOpen] = useState(false);
  const setSelectFilterTypeDialogOpenFalse = useCallWithFalse(setSelectFilterTypeDialogOpen);
  const [workTypeFilterDialogOpen, setWorkTypeFilterDialogOpen] = useState(false);
  const setWorkTypeFilterDialogOpenFalse = useCallWithFalse(setWorkTypeFilterDialogOpen);
  const [departmentFilterDialogOpen, setDepartmentFilterDialogOpen] = useState(false);
  const setDepartmentFilterDialogOpenFalse = useCallWithFalse(setDepartmentFilterDialogOpen);
  const customerSettings = useSelector(getCustomerSettings);

  const handleFilterTypeDialogOk = useCallback((filterType: FilterTypeChoices) => {
    setSelectFilterTypeDialogOpen(false);
    if (filterType === "workType") {
      setWorkTypeFilterDialogOpen(true);
    } else {
      setDepartmentFilterDialogOpen(true);
    }
  }, []);

  const {enableOrderTaskDepartmentFilter, enableOrderTaskWorkTypeFilter} = customerSettings;
  const handleFilterButtonClick = useCallback(() => {
    if (enableOrderTaskWorkTypeFilter && enableOrderTaskDepartmentFilter) {
      setSelectFilterTypeDialogOpen(true);
    } else if (enableOrderTaskWorkTypeFilter) {
      setWorkTypeFilterDialogOpen(true);
    } else {
      setDepartmentFilterDialogOpen(true);
    }
  }, [enableOrderTaskDepartmentFilter, enableOrderTaskWorkTypeFilter]);

  const handleWorkTypeFilterDialogOk = useCallback(
    (selected: ReadonlySet<WorkTypeUrl>) => {
      setWorkTypeFilterDialogOpen(false);
      onSelectedWorkTypeURLSetChange(selected);
    },
    [onSelectedWorkTypeURLSetChange],
  );

  const handleDepartmentFilterDialogOk = useCallback(
    (selected: ReadonlySet<string>) => {
      onSelectedDepartmentIdentifierSetChange(selected);
      setDepartmentFilterDialogOpen(false);
    },
    [onSelectedDepartmentIdentifierSetChange],
  );

  return (
    <>
      <IconButton onClick={handleFilterButtonClick}>
        <FilterIcon color="#fff" />
      </IconButton>
      <ConnectedMultipleCombinedWorkTypesDialog
        open={workTypeFilterDialogOpen}
        selected={selectedWorkTypeURLSet}
        onCancel={setWorkTypeFilterDialogOpenFalse}
        onOk={handleWorkTypeFilterDialogOk}
      />
      <ConnectedMultipleDepartmentsDialog
        open={departmentFilterDialogOpen}
        selected={selectedDepartmentIdentifierSet}
        onCancel={setDepartmentFilterDialogOpenFalse}
        onOk={handleDepartmentFilterDialogOk}
      />
      <FilterTypeSelectionDialog
        open={selectFilterTypeDialogOpen}
        onCancel={setSelectFilterTypeDialogOpenFalse}
        onOk={handleFilterTypeDialogOk}
      />
    </>
  );
});
