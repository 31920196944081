import {Project, Role} from "@co-common-libs/resources";
import {ExtendedConfig} from "extended-config";
import {createSelector} from "reselect";
import {match} from "ts-pattern";
import {getProjectArray} from "../resources/selectors";
import {AppState} from "../root-reducer";
import {getCurrentRole} from "./current-user";
import {getExtendedCustomerSettings} from "./extended-configs";

function projectIsSelectable(
  customerSettings: ExtendedConfig,
  role: Role,
  project: Project,
): boolean {
  const {consultant, manager, projectManager} = role;
  const showHiddenProjects = match({consultant, manager, projectManager})
    .returnType<boolean>()
    .with({consultant: true}, {manager: true}, {projectManager: true}, () => true)
    .otherwise(() => false);

  const {integrationStatus} = customerSettings;

  return (
    (!integrationStatus.hasActiveEconomySystemIntegration || project.remoteUrl !== "") &&
    (project.access === "open" || (project.access === "hidden" && showHiddenProjects))
  );
}

function projectIsVisible(customerSettings: ExtendedConfig, role: Role, project: Project): boolean {
  const {consultant, manager, projectManager} = role;
  const showAllProjects = match({consultant, manager, projectManager})
    .returnType<boolean>()
    .with({consultant: true}, {manager: true}, {projectManager: true}, () => true)
    .otherwise(() => false);
  const {integrationStatus} = customerSettings;
  return (
    (!integrationStatus.hasActiveEconomySystemIntegration || project.remoteUrl !== "") &&
    (project.access === "open" || showAllProjects)
  );
}

export const getCurrentUserSelectableProjectArray: (state: AppState) => readonly Project[] =
  createSelector(
    getProjectArray,
    getCurrentRole,
    getExtendedCustomerSettings,
    (projects: readonly Project[], role: Role | null, customerSettings: ExtendedConfig) =>
      role === null
        ? []
        : projects.filter((project) => projectIsSelectable(customerSettings, role, project)),
  );

export const getCurrentUserVisibleProjectArray: (state: AppState) => readonly Project[] =
  createSelector(
    getProjectArray,
    getCurrentRole,
    getExtendedCustomerSettings,
    (projects: readonly Project[], role: Role | null, customerSettings: ExtendedConfig) =>
      role === null
        ? []
        : projects.filter((project) => projectIsVisible(customerSettings, role, project)),
  );
