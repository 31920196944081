import {Config} from "@co-common-libs/config";
import {Customer, CustomerUrl, Project, ProjectUrl, Task, UserUrl} from "@co-common-libs/resources";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import _ from "lodash";
import React, {useMemo} from "react";
import {IntlShape, useIntl} from "react-intl";
import type {Writable} from "ts-essentials";
import {
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericSingleSelectionSearchDialog,
} from "../search-dialog";

const PREFERRED_COUNT_LIMIT = 5;

function computeBaseChoices(
  intl: IntlShape,
  customerSettings: Pick<Config, "projectLabelVariant">,
  projectArray: readonly Project[],
  customerLookup: (url: CustomerUrl) => Customer | undefined,
  showAllProjects: boolean,
  showProjectAlias: boolean,
  taskArray: readonly Task[],
  suggestRecentlyUsed: boolean,
  currentUserURL: string | null | undefined,
  customerURL?: string | null,
): readonly EntryData<ProjectUrl>[] {
  const {projectLabelVariant} = customerSettings;
  const lastUsedProjectURLs = new Map<ProjectUrl, string>();
  if (currentUserURL && suggestRecentlyUsed) {
    const currentUserSortedTasks = _.sortBy(
      taskArray.filter(
        (t) => t.order && (t.createdBy === currentUserURL || t.machineOperator === currentUserURL),
      ),
      (t) => t.created || "X",
    );

    for (let i = 0; i < currentUserSortedTasks.length; i += 1) {
      const task = currentUserSortedTasks[i];
      const existing = task.project ? lastUsedProjectURLs.get(task.project) : null;
      if (task.project && task.created && (!existing || existing < task.created)) {
        lastUsedProjectURLs.set(task.project, task.created);
      }
      if (lastUsedProjectURLs.size === PREFERRED_COUNT_LIMIT) {
        break;
      }
    }
  }

  const result: EntryData<ProjectUrl>[] = projectArray
    .filter(
      (instance) =>
        (!customerURL || instance.customer === customerURL) &&
        (showAllProjects || instance.access === "open"),
    )
    .map((instance) => {
      const {url} = instance;
      const name = instance.name || "";
      const projectNumber = instance.projectNumber || "";
      const alias = instance.alias || "";
      const customerName =
        (!customerURL && instance.customer && customerLookup(instance.customer)?.name) || "";
      const lastUsed = lastUsedProjectURLs.get(instance.url);
      const recentlyUsedSortKey = lastUsed ? new Date(lastUsed).valueOf() : undefined;
      const entry: Writable<EntryData<ProjectUrl>> = {
        category: recentlyUsedSortKey ? "recentlyUsed" : "standard",
        exactMatchValue: projectNumber,
        identifier: url,
        primaryText: name,
        searchFields: [
          {
            label:
              projectLabelVariant === "PROJECT"
                ? intl.formatMessage({defaultMessage: "Projektnr."})
                : intl.formatMessage({defaultMessage: "Sagsnr."}),
            priority: 10,
            text: projectNumber,
          },
          {label: intl.formatMessage({defaultMessage: "Søgenavn"}), priority: 7, text: alias},
          {label: intl.formatMessage({defaultMessage: "Navn"}), priority: 5, text: name},
          {
            label: intl.formatMessage({defaultMessage: "Kundenavn"}),
            priority: 5,
            text: customerName,
          },
        ],
        secondaryText: showProjectAlias
          ? [projectNumber, alias, customerName].filter(Boolean).join(", ")
          : [projectNumber, customerName].filter(Boolean).join(", "),
      };
      if (recentlyUsedSortKey) {
        entry.recentlyUsedSortKey = recentlyUsedSortKey;
      }
      return entry;
    });
  return result;
}

interface ProjectDialogProps {
  currentUserURL: UserUrl | null | undefined;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Pick<Config, "projectLabelVariant">;
  customerURL?: CustomerUrl | null | undefined;
  onAdd?: ((searchString: string) => void) | undefined;
  onCancel(): void;
  onNone?(): void;
  onOk(url: ProjectUrl): void;
  open: boolean;
  projectArray: readonly Project[];
  showAllProjects?: boolean;
  showProjectAlias: boolean;
  suggestRecentlyUsed: boolean;
  taskArray: readonly Task[];
}

export const ProjectDialog = React.memo(function ProjectDialog(props: ProjectDialogProps) {
  const {
    currentUserURL,
    customerLookup,
    customerSettings,
    customerURL,
    onAdd,
    onCancel,
    onNone,
    onOk,
    open,
    projectArray,
    showAllProjects = false,
    showProjectAlias,
    suggestRecentlyUsed,
    taskArray,
  } = props;
  const {projectLabelVariant} = customerSettings;
  const intl = useIntl();
  const title =
    projectLabelVariant === "PROJECT"
      ? intl.formatMessage({defaultMessage: "Vælg projekt"})
      : intl.formatMessage({defaultMessage: "Vælg sag"});
  const searchTitle =
    projectLabelVariant === "PROJECT"
      ? intl.formatMessage({defaultMessage: "Søg projekt"})
      : intl.formatMessage({defaultMessage: "Søg sag"});

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(
    intl,
    customerSettings,
    projectArray,
    customerLookup,
    showAllProjects,
    showProjectAlias,
    taskArray,
    suggestRecentlyUsed,
    currentUserURL,
    customerURL,
  );

  return (
    <GenericSingleSelectionSearchDialog<ProjectUrl>
      addShortLabel={
        projectLabelVariant === "PROJECT"
          ? intl.formatMessage({defaultMessage: "Opret projekt"})
          : intl.formatMessage({defaultMessage: "Opret sag"})
      }
      addUnnamedLabel={
        projectLabelVariant === "PROJECT"
          ? intl.formatMessage({defaultMessage: "Opret projekt"})
          : intl.formatMessage({defaultMessage: "Opret sag"})
      }
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={2}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      noneLabel={
        onNone
          ? projectLabelVariant === "PROJECT"
            ? intl.formatMessage({defaultMessage: "Intet projekt"})
            : intl.formatMessage({defaultMessage: "Ingen sag"})
          : undefined
      }
      open={open}
      searchTitle={searchTitle}
      sorting="SECONDARY_IDENTIFIER"
      title={title}
      onAdd={onAdd}
      onCancel={onCancel}
      onNone={onNone}
      onOk={onOk}
    />
  );
});

interface MultipleProjectsDialogProps {
  currentUserURL: UserUrl | null | undefined;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Pick<Config, "projectLabelVariant">;
  customerURL?: string | null;
  onCancel(): void;
  onOk(urls: ReadonlySet<ProjectUrl>): void;
  open: boolean;
  projectArray: readonly Project[];
  selected?: ReadonlySet<ProjectUrl>;
  showAllProjects?: boolean;
  showProjectAlias: boolean;
  suggestRecentlyUsed: boolean;
  taskArray: readonly Task[];
}

export const MultipleProjectsDialog = React.memo(function MultipleProjectsDialog(
  props: MultipleProjectsDialogProps,
) {
  const {
    currentUserURL,
    customerLookup,
    customerSettings,
    customerURL,
    onCancel,
    onOk,
    open,
    projectArray,
    selected,
    showAllProjects = false,
    showProjectAlias,
    suggestRecentlyUsed,
    taskArray,
  } = props;
  const intl = useIntl();
  const {projectLabelVariant} = customerSettings;
  const title =
    projectLabelVariant === "PROJECT"
      ? intl.formatMessage({defaultMessage: "Vælg projekter"})
      : intl.formatMessage({defaultMessage: "Vælg sager"});
  const searchTitle =
    projectLabelVariant === "PROJECT"
      ? intl.formatMessage({defaultMessage: "Søg projekter"})
      : intl.formatMessage({defaultMessage: "Søg sager"});

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(
    intl,
    customerSettings,
    projectArray,
    customerLookup,
    showAllProjects,
    showProjectAlias,
    taskArray,
    suggestRecentlyUsed,
    currentUserURL,
    customerURL,
  );

  const selectedSet = selected;
  return (
    <GenericMultiSelectionSearchDialog<ProjectUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={2}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={searchTitle}
      selected={selectedSet}
      sorting="SECONDARY_IDENTIFIER"
      title={title}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
});
