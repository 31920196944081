import {actions} from "@co-frontend-libs/redux";
import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {BookkeepingTable} from "./bookkeeping-table";

interface BookkeepingNormalTabsProps {
  filterString: string;
  tab: "readyForBilling" | "readyForValidation";
}

export function BookkeepingNormalTabs(props: BookkeepingNormalTabsProps): JSX.Element {
  const {filterString, tab} = props;
  const dispatch = useDispatch();
  const handleTableClick = useCallback(
    (dateAndMachineOperator: string): void => {
      const [date, machineOperatorId] = dateAndMachineOperator.split(":");
      if (date && machineOperatorId) {
        dispatch(
          actions.go("/bookkeepingDay/:date/:machineOperatorId", {
            date,
            machineOperatorId,
          }),
        );
      }
    },
    [dispatch],
  );
  return <BookkeepingTable filterString={filterString} tab={tab} onClick={handleTableClick} />;
}
