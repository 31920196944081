import {CustomerFile, CustomerUrl} from "@co-common-libs/resources";
import {caseAccentInsensitiveCollator} from "@co-common-libs/utils";
import {DeleteDialog} from "@co-frontend-libs/components";
import {AppState, actions, getCustomerFileArray, getShareToken} from "@co-frontend-libs/redux";
import {
  Card,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {useFileUploader} from "app-utils";
import CollectionsIcon from "mdi-react/CollectionsIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import FileIcon from "mdi-react/FileIcon";
import FilePdfBoxIcon from "mdi-react/FilePdfBoxIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PhotoCameraIcon from "mdi-react/PhotoCameraIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, IntlShape, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {DragAndDropUploadOverlay} from "./drag-and-drop-upload-overlay";
import {SourceType, appPhotoHelper, useFileInputChangeHandler} from "./files";
import {type FloatingActionButtonData, FloatingActionButtons} from "./floating-action-buttons";
import {InstanceClickIconButton} from "./instance-click-icon-button";
import {RenameDialog} from "./rename-dialog";

const messages = defineMessages({
  invalidFile: {
    defaultMessage: "{name} er ikke en gyldig pdf eller billedfil",
  },
});

const iconWidth = 48;
const iconColumnStyle = {paddingLeft: 0, paddingRight: 0, width: iconWidth};

interface CustomerFileListTableProps {
  editable: boolean;
  fileList: readonly CustomerFile[];
}

function CustomerFileListTable(props: CustomerFileListTableProps): JSX.Element {
  const {editable, fileList} = props;

  const shareToken = useSelector(getShareToken);

  const dispatch = useDispatch();

  const [deleteDialogFile, setDeleteDialogFile] = useState<CustomerFile | null>(null);
  const handleFileDeleteCancel = useCallback(() => {
    setDeleteDialogFile(null);
  }, []);
  const handleFileDeleteOk = useCallback(() => {
    if (deleteDialogFile) {
      setDeleteDialogFile(null);
      dispatch(actions.remove(deleteDialogFile.url));
    }
  }, [deleteDialogFile, dispatch]);

  const [renameDialogFile, setRenameDialogFile] = useState<CustomerFile | null>(null);
  const handleFileRenameCancel = useCallback(() => {
    setRenameDialogFile(null);
  }, []);
  const handleFileRenameOk = useCallback(
    (newName: string) => {
      if (renameDialogFile) {
        setRenameDialogFile(null);
        dispatch(actions.update(renameDialogFile.url, [{member: "name", value: newName}]));
      }
    },
    [dispatch, renameDialogFile],
  );

  const editIconColumns = 2;

  const tableRowList = fileList.map((file) => {
    return (
      <TableRow key={file.url}>
        <TableCell>{file.name || file.filename}</TableCell>
        {editable ? (
          <TableCell style={iconColumnStyle}>
            <InstanceClickIconButton instance={file} onClick={setRenameDialogFile}>
              <PencilIcon />
            </InstanceClickIconButton>
          </TableCell>
        ) : null}
        {editable ? (
          <TableCell style={iconColumnStyle}>
            <InstanceClickIconButton instance={file} onClick={setDeleteDialogFile}>
              <DeleteIcon />
            </InstanceClickIconButton>
          </TableCell>
        ) : null}
        <TableCell style={iconColumnStyle}>
          <IconButton color="primary" href={`${file.download}?token=${shareToken}`} target="_blank">
            <FileIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                defaultMessage="Navn"
                id="customer-instance.table-header.filename"
              />
            </TableCell>
            <TableCell colSpan={1 + (editable ? editIconColumns : 0)}>
              <FormattedMessage
                defaultMessage="Download"
                id="customer-instance.table-header.download"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableRowList}</TableBody>
      </Table>
      {editable ? (
        <DeleteDialog
          open={!!deleteDialogFile}
          onCancel={handleFileDeleteCancel}
          onOk={handleFileDeleteOk}
        >
          <FormattedMessage
            defaultMessage="Slet fil?"
            id="customer-file-list-card.label.do-delete-file"
          />
        </DeleteDialog>
      ) : null}
      {editable ? (
        <RenameDialog
          key="file-rename-dialog"
          currentName={renameDialogFile ? renameDialogFile.name : ""}
          open={!!renameDialogFile}
          onCancel={handleFileRenameCancel}
          onOk={handleFileRenameOk}
        />
      ) : null}
    </>
  );
}

/*
  const onCompleted = (): void => {
  const message = formatMessage(messages.uploadCompleted);
    this.props.setMessage(message, new Date());
  };
  const onError = (): void => {
    const message = formatMessage(messages.uploadFailed);
    this.props.setMessage(message, new Date());
  };

  uploadCompleted: {
    defaultMessage: "Upload fuldført",
    id: "customer-instance.toast.upload-complete",
  },
  uploadFailed: {
    defaultMessage: "Upload fejlede",
    id: "customer-instance.toast.upload-failed",
  },

*/
interface UploadButtonsProps {
  intl: IntlShape;
  onFilesAccepted: (files: File[]) => void;
  onFilesRejected: (file: File) => void;
}

function UploadButtons(props: UploadButtonsProps): JSX.Element {
  const {intl, onFilesAccepted, onFilesRejected} = props;

  const handleFileInputChange = useFileInputChangeHandler(
    onFilesAccepted,
    onFilesRejected,
    "image/jpeg,image/png,application/pdf",
  );

  const isMobile = !!window.cordova;

  const handleAppCameraButton = useCallback((): void => {
    appPhotoHelper(SourceType.CAMERA, onFilesAccepted);
  }, [onFilesAccepted]);

  const buttonData = useMemo((): FloatingActionButtonData[] => {
    if (isMobile) {
      return [
        {
          buttonIcon: <PhotoCameraIcon />,
          name: "camera-button",
          onClick: handleAppCameraButton,
          tooltipTitle: intl.formatMessage({defaultMessage: "Kamera"}),
        },
        {
          accept: "application/pdf",
          buttonIcon: <FilePdfBoxIcon />,
          id: "customer-pdf-input",
          name: "pdf-button",
          onChange: handleFileInputChange,
          tooltipTitle: intl.formatMessage({defaultMessage: "PDF"}),
        },
        {
          accept: "image/jpeg,image/png",
          buttonIcon: <CollectionsIcon />,
          id: "customer-photos-input",
          name: "photo-button",
          onChange: handleFileInputChange,
          tooltipTitle: intl.formatMessage({defaultMessage: "Foto"}),
        },
      ];
    } else {
      return [
        {
          accept: "image/jpeg,application/pdf",
          buttonIcon: <PlusIcon />,
          id: "customer-file-input",
          name: "file-button",
          onChange: handleFileInputChange,
          tooltipTitle: intl.formatMessage({defaultMessage: "Filer"}),
        },
      ];
    }
  }, [handleAppCameraButton, handleFileInputChange, intl, isMobile]);

  const speedDial = (
    <FloatingActionButtons buttons={buttonData} name="customer-file-list" variant="component" />
  );

  return speedDial;
}

function getFilteredCustomerFileArray(
  customerURL: string,
): (state: AppState) => readonly CustomerFile[] {
  const filterSort = (customerFileArray: readonly CustomerFile[]): readonly CustomerFile[] =>
    customerFileArray
      .filter((customerFile) => customerFile.customer === customerURL)
      .sort((a, b) => caseAccentInsensitiveCollator.compare(a.filename, b.filename));

  return createSelector(getCustomerFileArray, filterSort);
}

interface CustomerFileListCardProps {
  customerURL: CustomerUrl;
  editable?: boolean;
  title?: string;
}

export const CustomerFileListCard = React.memo(function CustomerFileListCard(
  props: CustomerFileListCardProps,
): JSX.Element {
  const {customerURL, editable, title} = props;

  const getSelectedCustomerFiles = useMemo(
    () => getFilteredCustomerFileArray(customerURL),
    [customerURL],
  );
  const files = useSelector(getSelectedCustomerFiles);

  let content: JSX.Element;
  if (!files.length) {
    content = (
      <div style={{padding: 8, textAlign: "center"}}>
        <FormattedMessage defaultMessage="Der er ingen filer tilknyttet kunden." />
      </div>
    );
  } else {
    content = <CustomerFileListTable editable={!!editable} fileList={files} />;
  }

  const intl = useIntl();
  const dispatch = useDispatch();

  const handleFilesRejected = useCallback(
    (rejectedFile: File): void => {
      const message = intl.formatMessage(messages.invalidFile, {
        name: rejectedFile.name,
      });
      window.setTimeout(() => {
        dispatch(actions.setMessage(message, new Date()));
      }, 0);
    },
    [dispatch, intl],
  );

  const uploader = useFileUploader<CustomerFile>("customerFile", "data");

  const handleFilesAccepted = useCallback(
    (acceptedFiles: File[]): void => {
      const commonExtraData = {
        customer: customerURL,
        description: "",
      };
      acceptedFiles.forEach((file) => {
        const name = file.name.replace(/\.[^.]*$/, "");
        const extraData = {name, ...commonExtraData};
        uploader(file, extraData);
      });
    },
    [customerURL, uploader],
  );

  const handleFiles = useCallback(
    (recievedFiles: File[]): void => {
      const invalidFile = recievedFiles.find(
        (file) => !["image/jpeg", "image/png", "application/pdf"].includes(file.type),
      );

      if (invalidFile) {
        handleFilesRejected(invalidFile);
      } else {
        handleFilesAccepted(recievedFiles);
      }
    },
    [handleFilesAccepted, handleFilesRejected],
  );

  if (editable) {
    return (
      <Card style={{overflow: "visible"}}>
        <DragAndDropUploadOverlay onFiles={handleFiles}>
          <UploadButtons
            intl={intl}
            onFilesAccepted={handleFilesAccepted}
            onFilesRejected={handleFilesRejected}
          />
          <CardHeader
            title={
              title ?? (
                <FormattedMessage
                  defaultMessage="Filer"
                  id="customer-file-list-card.card-title.file-list"
                />
              )
            }
          />
          {content}
        </DragAndDropUploadOverlay>
      </Card>
    );
  } else {
    return (
      <Card>
        <CardHeader
          title={
            title ?? (
              <FormattedMessage
                defaultMessage="Filer"
                id="customer-file-list-card.card-title.file-list"
              />
            )
          }
        />
        {content}
      </Card>
    );
  }
});
