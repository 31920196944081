import {ReadonlyConfig} from "@co-common-libs/config";
import {Role} from "@co-common-libs/resources";
import {
  ActiveEconomySystemIntegrationKind,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";
import {ExtendedCustomerSettingsPart, extendedCustomerSettings} from "./extended-customer-settings";

type ExtendedContactSettingsPart = Pick<ReadonlyConfig, "contactsMayHaveCellphone"> &
  ExtendedCustomerSettingsPart;

export interface ExtendedContactSettings {
  canManage: boolean;
  contactsMayHaveCellphone: boolean;
  contactsMayHaveFax: boolean;
}

export function extendedContactSettings(
  config: ExtendedContactSettingsPart,
  role: Pick<Role, "manager" | "projectManager"> | null,
): ExtendedContactSettings {
  const {canManage: roleCanManage} = extendedCustomerSettings(config, role);
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  const integrationAllowsManage =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.NONE ||
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC;

  return {
    canManage: integrationAllowsManage && roleCanManage,
    contactsMayHaveCellphone: config.contactsMayHaveCellphone,
    contactsMayHaveFax: !config.economicSync,
  };
}
