import {AccomodationAllowance} from "@co-common-libs/resources";
import {dateFromString, dateToString, mapMap} from "@co-common-libs/utils";
import {computeIntervalRoundedDurationMinutes} from "./duration";
import {
  RemunerationGroup,
  SimplifiedWorkDay,
  SimplifiedWorkDayWithAccomodation,
  WorkDayWithBonus,
} from "./types";

function dateAfter(dateString: string): string {
  const dateObject = dateFromString(dateString) as Date;
  dateObject.setDate(dateObject.getDate() + 1);
  return dateToString(dateObject);
}

const emptyWorkDayWithAccomodationFields: SimplifiedWorkDayWithAccomodation = {
  accomodationDay: false,
  accomodationMinutes: 0,
  actualWorkMinutes: 0,
  bonusMinutes: new Map(),
  breakMinutes: 0,
  breakPeriods: [],
  calendarDayBonus: new Map(),
  calledIn: 0,
  countBonus: new Map(),
  date: "",
  orderReferenceNumbers: [],
  projectDistance: 0,
  projects: [],
  projectTravelTime: 0,
  rateMinutes: new Map(),
  taskBonus: new Map(),
  taskReferenceNumbers: [],
  workDayBonus: [],
  workPeriods: [],
};

export function attachAccomodationAllowance(
  simplifiedWorkDays: ReadonlyMap<string, readonly SimplifiedWorkDay[]>,
  accomodationAllowanceList: readonly AccomodationAllowance[],
  workDaysFull: ReadonlyMap<string, readonly WorkDayWithBonus[]>,
  remunerationGroups: Map<string, RemunerationGroup>,
): Map<string, SimplifiedWorkDayWithAccomodation[]> {
  const extendedSimplifiedWorkDays = new Map(simplifiedWorkDays);
  // If there are AccomodationAllowance entries, and some remuneration group
  // that the employee *could* have registered time under has accomodation
  // allowance without being part of the input/output from time computation;
  // include that group in output...
  if (accomodationAllowanceList.length) {
    accomodationAllowanceList.forEach((accomodationAllowance) => {
      if (!extendedSimplifiedWorkDays.has(accomodationAllowance.remunerationGroup)) {
        extendedSimplifiedWorkDays.set(accomodationAllowance.remunerationGroup, []);
      }
    });
  }
  const result = mapMap(extendedSimplifiedWorkDays, (workDays, groupID) => {
    if (accomodationAllowanceList.length) {
      const group = remunerationGroups.get(groupID) as RemunerationGroup;
      if (group.accomodationAllowance) {
        const accomodationAllowanceDates = new Set(
          accomodationAllowanceList
            .filter((accomodationAllowance) => accomodationAllowance.remunerationGroup === groupID)
            .map((accomodationAllowance) => accomodationAllowance.date),
        );
        const accomodationAllowanceMinutes = new Map<string, number>();
        accomodationAllowanceDates.forEach((date) => {
          const followingDate = dateAfter(date);
          if (!accomodationAllowanceDates.has(followingDate)) {
            accomodationAllowanceMinutes.set(followingDate, 0);
          }
        });
        const groupWorkDaysFull = workDaysFull.get(groupID) || [];
        groupWorkDaysFull.forEach((workDay) => {
          workDay.workPeriods.forEach((workPeriod) => {
            workPeriod.work.forEach((interval) => {
              const intervalDate = dateToString(new Date(interval.fromTimestamp));
              const previousMinutes = accomodationAllowanceMinutes.get(intervalDate);
              if (previousMinutes != null) {
                const minutes = computeIntervalRoundedDurationMinutes(interval);
                accomodationAllowanceMinutes.set(intervalDate, previousMinutes + minutes);
              }
            });
          });
        });
        const observedDates = new Set<string>();
        const groupResult = workDays.map(
          (workDay: SimplifiedWorkDay): SimplifiedWorkDayWithAccomodation => {
            const {date} = workDay;
            observedDates.add(date);
            if (accomodationAllowanceDates.has(date)) {
              console.assert(!accomodationAllowanceMinutes.has(date));
              return {
                ...workDay,
                accomodationDay: true,
                accomodationMinutes: 0,
              };
            } else {
              const minutes = accomodationAllowanceMinutes.get(date) || 0;
              return {
                ...workDay,
                accomodationDay: false,
                accomodationMinutes: minutes,
              };
            }
          },
        );
        let changed = false;
        accomodationAllowanceDates.forEach((date) => {
          console.assert(!accomodationAllowanceMinutes.has(date));
          if (!observedDates.has(date)) {
            const extraDay: SimplifiedWorkDayWithAccomodation = {
              ...emptyWorkDayWithAccomodationFields,
              accomodationDay: true,
              date,
            };
            groupResult.push(extraDay);
            changed = true;
          }
        });
        accomodationAllowanceMinutes.forEach((minutes, date) => {
          console.assert(!accomodationAllowanceDates.has(date));
          if (!observedDates.has(date) && minutes) {
            const extraDay: SimplifiedWorkDayWithAccomodation = {
              ...emptyWorkDayWithAccomodationFields,
              accomodationMinutes: minutes,
              date,
            };
            groupResult.push(extraDay);
            changed = true;
          }
        });
        if (changed) {
          groupResult.sort((a, b) => a.date.localeCompare(b.date));
        }
        return groupResult;
      }
    }
    return workDays.map(
      (workDay: SimplifiedWorkDay): SimplifiedWorkDayWithAccomodation => ({
        ...workDay,
        accomodationDay: false,
        accomodationMinutes: 0,
      }),
    );
  });
  return result;
}
