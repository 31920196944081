import {ReadonlyConfig} from "@co-common-libs/config";
import {Role} from "@co-common-libs/resources";
import {DeepReadonly} from "ts-essentials";
import {
  ActiveEconomySystemIntegrationKind,
  getEconomySystemIntegrationKind,
} from "./get-economy-system-integration-kind";
import {
  ExtendedContactSettings,
  ExtendedCsvSettings,
  ExtendedCustomerSettings,
  ExtendedLocationSettings,
  ExtendedMachineSettings,
  ExtendedPriceItemSettings,
  ExtendedProductGroupSettings,
  ExtendedProductSettings,
  ExtendedProjectSettings,
  ExtendedTaskSettings,
  ExtendedUnitSettings,
  ExtendedWorkTypeSettings,
  extendedContactSettings,
  extendedCsvSettings,
  extendedCustomerSettings,
  extendedLocationSettings,
  extendedMachineSettings,
  extendedPriceItemSettings,
  extendedProductGroupSettings,
  extendedProductSettings,
  extendedProjectSettings,
  extendedTaskSettings,
  extendedUnitSettings,
  extendedWorkTypeSettings,
} from "./resources";
import {TransferTaskSettings, transferTaskSettings} from "./transfer-task-settings";

interface ExtendedConfig {
  contact: ExtendedContactSettings;
  csv: ExtendedCsvSettings;
  customer: ExtendedCustomerSettings;
  integrationStatus: {
    hasActiveEconomySystemIntegration: boolean;
  };
  locations: ExtendedLocationSettings;
  machines: ExtendedMachineSettings;
  priceItems: ExtendedPriceItemSettings;
  productGroups: ExtendedProductGroupSettings;
  products: ExtendedProductSettings;
  projects: ExtendedProjectSettings;
  tasks: ExtendedTaskSettings;
  transferTask: TransferTaskSettings;
  units: ExtendedUnitSettings;
  workTypes: ExtendedWorkTypeSettings;
}

type ReadonlyExtendedConfig = DeepReadonly<ExtendedConfig> & ReadonlyConfig;

export type {ReadonlyExtendedConfig as ExtendedConfig};

export function extendedConfig(config: ReadonlyConfig, role: Role | null): ReadonlyExtendedConfig {
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  return {
    ...config,
    contact: extendedContactSettings(config, role),
    csv: extendedCsvSettings(config, role),
    customer: extendedCustomerSettings(config, role),
    integrationStatus: {
      hasActiveEconomySystemIntegration:
        economySystemIntegrationKind !== ActiveEconomySystemIntegrationKind.NONE,
    },
    locations: extendedLocationSettings(config, role),
    machines: extendedMachineSettings(config),
    priceItems: extendedPriceItemSettings(config, role),
    productGroups: extendedProductGroupSettings(config),
    products: extendedProductSettings(config),
    projects: extendedProjectSettings(config, role),
    tasks: extendedTaskSettings(config),
    transferTask: transferTaskSettings(config),
    units: extendedUnitSettings(config),
    workTypes: extendedWorkTypeSettings(config, role),
  };
}
