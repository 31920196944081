import {ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent, List, ListItem, ListItemText} from "@material-ui/core";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";

export type FilterTypeChoices = "deparment" | "workType";

const FilterTypeItem = React.memo(function FilterTypeItem({
  filterType,
  name,
  onClick,
}: {
  filterType: FilterTypeChoices;
  name: string;
  onClick: (filterType: FilterTypeChoices) => void;
}): JSX.Element {
  const handleClick = useCallback(() => {
    onClick(filterType);
  }, [onClick, filterType]);
  return (
    <ListItem button onClick={handleClick}>
      <ListItemText>{name}</ListItemText>
    </ListItem>
  );
});

export const FilterTypeSelectionDialog = React.memo(function FilterTypeSelectionDialog({
  onCancel,
  onOk,
  open,
}: {
  onCancel?: () => void;
  onOk: (filterType: FilterTypeChoices) => void;
  open: boolean;
}): JSX.Element {
  const intl = useIntl();
  return (
    <ResponsiveDialog
      open={open}
      title={<FormattedMessage defaultMessage="Vælg filtreing" />}
      onCancel={onCancel}
    >
      <DialogContent style={{padding: 0}}>
        <List>
          <FilterTypeItem
            filterType="workType"
            name={intl.formatMessage({defaultMessage: "Arbejdsområder"})}
            onClick={onOk}
          />
          <FilterTypeItem
            filterType="deparment"
            name={intl.formatMessage({defaultMessage: "Afdelinger"})}
            onClick={onOk}
          />
        </List>
      </DialogContent>
    </ResponsiveDialog>
  );
});
