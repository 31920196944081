import {ProjectFile} from "@co-common-libs/resources";
import {Checkbox} from "@material-ui/core";
import {useEventTargetCheckedUpdater} from "app-utils";
import React from "react";

interface VisibleToAllCheckboxProps {
  checked: boolean;
  instanceURL: string;
}

export const VisibleToAllCheckbox = React.memo(function VisibleToAllCheckbox({
  checked,
  instanceURL: instanceUrl,
}: VisibleToAllCheckboxProps): JSX.Element {
  const handleChange = useEventTargetCheckedUpdater<ProjectFile>(instanceUrl, "visibleToAll");
  return <Checkbox checked={checked} onChange={handleChange} />;
});
